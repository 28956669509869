import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
import login from './modules/login'
import user from './modules/user'
import drawer from './modules/drawer'
import pengkinianApi from './modules/pengkinian'
import pengajuanKpr from './modules/pengajuankpr'
import scholarshipApi from './modules/scholarship'
import seragamApi from './modules/seragam'
import izindanizinSakitApi from './modules/izin&izinsakit'
import riwayatdanapproveizinApi from './modules/riwayat&approveizin'
import firebase from './firebase'
import notification from './modules/notification'
import presensi from './modules/presensi'

const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        login,
        user,
        drawer,
        firebase,
        notification,
        pengkinianApi,
        pengajuanKpr,
        scholarshipApi,
        seragamApi,
        izindanizinSakitApi,
        riwayatdanapproveizinApi,
        presensi
    },
    // plugins: [
    //     createPersistedState({
    //         key: "ess-online",
    //         paths: ['login', ],
    //         storage: {
    //             getItem: key => ls.get(key),
    //             setItem: (key, value) => ls.set(key, value),
    //             removeItem: key => ls.remove(key)
    //         }
    //     })
    // ]
})