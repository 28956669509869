<template>
  <div>
    <c-toolbar />
    <c-navbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2"
              :loading="vloading">
              <template slot="progress">
                <v-progress-linear
                  color="green"
                  indeterminate
                ></v-progress-linear>
              </template>
              <br />
              <v-row justify="center">
                <v-img
                  src="img/indomaret.png"
                  aspect-ratio="3"
                  max-width="250"
                >
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Form Serah Terima Seragam
              </h3>
              <v-form class="pa-4 mx-3" ref="form">
                <div class="my-2">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div>
                        <span> NIK :
                        <v-text-field
                        v-model="nik"
                        :counter="10"
                        label="NIK"
                        solo
                        required
                        readonly
                      ></v-text-field>
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <div>
                        <span> Nama Karyawan :
                        <v-text-field
                          v-model="nama"
                          label="Nama"
                          solo
                          required
                          readonly
                        ></v-text-field>
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <span> Pilih Jenis Seragam :
                      <v-select
                        :items="itemsSeragam"
                        solo
                        item-text="NamaSeragam"
                        item-value="KodeSeragam"
                        v-model="jenis_seragam"
                        return-object
                      ></v-select>
                      </span>
                    </v-col>
                  </v-row>


                  <v-row>
                    <v-col cols="12" sm="6">
                      <span> No SJ :
                            <v-text-field
                              v-model="this.jenis_seragam.NoSJ"
                              solo
                              readonly
                            ></v-text-field>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <span> ID Seragam :
                        <v-text-field
                          v-model="this.jenis_seragam.KodeSeragam"
                          solo
                          readonly
                        ></v-text-field>
                        </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" class="shrink text-end">
                      <v-btn
                        color="primary"
                        block
                        :loading="btnLoading"
                        :disabled="!validBerhasil"
                        @click="submit()"
                        >submit</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row class="flex-column">
                  <h5 class="red--text">*) PASTIKAN NOMOR SJ DAN ID SERAGAM YANG DIINPUT SESUAI DENGAN NOTIFIKASI DI TELEGRAM</h5>
                  <h5 class="red--text">*) Isilah dengan lengkap dan tepat karena data yg diinput akan menjadi tanggal kebutuhan seragam Bapak/Ibu selanjutnya.</h5>
                  </v-row>
                  <v-row><br /></v-row>
                  <v-row>
                  <h5 class="red--text">*) Belum menerima notifikasi di Telegram? Silakan klik tombol dibawah ini</h5>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" class="shrink text-end">
                      <v-btn
                        color="primary"
                        block
                        :loading="btnLoadingNotif"
                        :disabled="!validNotif"
                        @click="sendNotif()"
                        >Kirim Notifikasi</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-form>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
// import Sidebar from "@/components/Sidebar.vue";
// import Toolbar from "@/components/Toolbar.vue";
import CNavbar from '../../components/CNavbar.vue'
import CToolbar from '../../components/CToolbar.vue'
import { mapGetters, mapActions } from "vuex";
const axios = require("axios");
export default {
  name: "Home",
  components: {
    CNavbar,
    CToolbar,
  },

  mounted() {
    // console.log(this.user)
    this.TOKEN = this.token
    this.vloading = true
    this.nama = this.user.data[0].NamaKaryawan;
    this.nik = this.user.data[0].NikKaryawan;
    // this.dataUnit()
    // this.showUserRole();
    // this.dataKaryawan();
    this.initialize();
    // this.dataUnit();
    // this.dataBagian();
  },

  data: () => ({
    // $baseurl: "http://localhost/",
    // $baseurl: "http://116.204.128.50:80/",
    // $baseurl: "https://ms.hrindomaret.com/",
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    modelKaryawan: null,
    cariKaryawan: null,
    NIK_NamaKaryawan: "",

    dialog: false,
    dialogKary: false,
    // dialogDelete: false,
    model: null,
    search: null,
    btnLoading: false,
    btnLoadingNotif: false,
    vloading: false,
    listSJ: false,
    // search: "",
    nik: "",
    nama: "",
    itemsSeragam: [],
    jenis_seragam: "",
    no_sj1: "",
    no_sj2: "",
    no_sj3: "",
    no_sj4: "",
    id_seragam: "",
    role: "",
    id_unit: "",
    id_bagian: [],
    id_cabang: [],
    unit: "",
    bagian: [],
    cabang: [],
    code: "",
    inputs: [
      {
        unit: "",
        cabang: "",
      },
    ],
    karyawan: [],

    headers: [
      // {
      //   text: "NIK",
      //   align: "start",
      //   sortable: true,
      //   value: "NIK",
      // },
      { text: "NIK", value: "NIK" },
      { text: "Nama", value: "NAMA" },
      { text: "Jenis Seragam", value: "ROLE" },
      { text: "Tanggal Serah Terima", value: "UNITROLE" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headers_list: [
      { text: "Karyawan", value: "Karyawan" },
      // { text: "NAMA", value: "NAMA" },
      { text: "Actions", value: "detail", sortable: false },
    ],

    loadingTable: false,

    showDepartement: false,
    itemsRole: [
      // {
      //   ID_ROLE: "Approval manager",
      //   ROLE: "Approval Manager",
      // },
      {
        ID_ROLE: "Verifikasi",
        ROLE: "Verifikasi",
      },
      {
        ID_ROLE: "Otorisasi Awal",
        ROLE: "Otorisasi Awal",
      },
      {
        ID_ROLE: "Otorisasi Akhir",
        ROLE: "Otorisasi Akhir",
      },
      {
        ID_ROLE: "Admin",
        ROLE: "Admin",
      },
    ],
    itemsTableSeragam: [],
    items_list: [],
    items_unit: [],
    items_bagian: [],
    items_cabang: [[]],
    editedIndex: -1,
    editedItem: {
      id_user: "",
      nik: "",
      nama: "",
      role: "",
      bagian: "",
    },

    chooseKar: {
      nik: "",
      nama: "",
    },
    defaultItem: {
      id_user: "",
      nik: "",
      nama: "",
      role: "",
      bagian: "",
    },
  }),

  computed: {
    ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      token:"token"
      // getDrawer: "drawer",
    }),
    
    // user: {
    //   get() {
    //     return this.getUser
    //   },
    //   set() {
    //     return this.setUser(val)
    //   }
    // },

    formTitle() {
      return this.editedIndex === -1 ? "New Pejabat" : "Edit Pejabat";
    },
    fields() {
      if (!this.modelKaryawan) return [];

      return Object.keys(this.modelKaryawan).map((key) => {
        return {
          key,
          value: this.modelKaryawan[key] || "n/a",
        };
      });
    },
    itemsKaryawan() {
      return this.entries.map((entry) => {
        const Description =
          entry.Description.length > this.descriptionLimit
            ? entry.Description.slice(0, this.descriptionLimit) + "..."
            : entry.Description;

        return Object.assign({}, entry, { Description });
      });
    },

    validAdmin() {
      return this.chooseKar.NIK && this.chooseKar.NAMA && this.role == "Admin";
    },
    validBasic() {
      let hasil = this.inputs
      let hasiltest = hasil.map((item) => item.unit);
      return this.chooseKar.NIK && this.chooseKar.NAMA && this.role != "Admin"
      && hasiltest[0] ;
    },

    validBerhasil() {
      return (
        this.nik != "" &&
        this.nama != "" &&
        this.jenis_seragam != "" &&
        this.jenis_seragam.NoSJ != "" &&
        this.jenis_seragam.KodeSeragam != ""&&
        this.jenis_seragam.TypeSJ != ""
      );
    },

    validNotif(){
      return(
        this.listSJ == true
      );
    }
    // validPICHO() {
    //   return (
    //     this.chooseKar.NIK &&
    //     this.chooseKar.NAMA &&
    //     this.rolePIC == "PIC HO" &&
    //     this.unit != "" &&
    //     this.bagian.length != 0
    //   );
    // },
    // validCabang() {
    //   return (
    //     this.chooseKar.NIK &&
    //     this.chooseKar.NAMA &&
    //     this.rolePIC == "HR Cabang" &&
    //     this.unit != "" &&
    //     this.cabang.length != 0
    //   );
    // },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
  },

  // created() {
  //   this.initialize();
  // },

  methods: {
    ...mapActions({
            getTransaksi:"getTransaksi"
        }),

    initialize() {
      const code = {
        code: 1,
        data:{
          nik: this.user.data[0].NikKaryawan,
        }
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: this.user.data[0].NikKaryawan,
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "Dev/Seragam/Transaksi", code, {
      //     headers,
      //   })
       this.getTransaksi(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // console.log(response)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
              this.vloading = false
            });
          } else if(result.message.length != 0){
            // // console.log(result.message);
            // this.itemsTableSeragam = result.message;
            this.itemsSeragam = result.message
            // console.log(this.itemsSeragam)
            this.vloading = false
            this.listSJ = true
        } else if (result.message.length == 0){
            this.itemsSeragam = result.message
            // console.log(this.itemsSeragam)
            this.vloading = false
            // this.listSJ = true
          }else{
            this.$swal({
              icon: "warning",
              title: "Maaf!",
              text: "Data tidak ditemukan",
            })
            this.vloading = false
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            this.vloading = false
            // this.snackbar=true;
          }
        });
    },

    submit(){
      // console.log(this.no_sj1);
      // console.log(this.no_sj2);
      // console.log(this.no_sj3);
      // console.log(this.no_sj4);
      let no_sj = this.no_sj1 +"/"+ this.no_sj2 +"/"+ this.no_sj3 +"/"+ this.no_sj4;
      // console.log(no_sj);
      this.$swal({
        icon: "info",
        title: "Apakah Anda Yakin?",
        text: "Data yang anda masukkan sudah sesuai",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Batal`,
        confirmButtonText: `Ya`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.btnLoading = true;
      // this.btnLoading= true
      const code = {
        code: 2,
        data:{
          no_sj: this.jenis_seragam.NoSJ,
          type_sj : this.jenis_seragam.TypeSJ,
          nik: this.user.data[0].NikKaryawan,
          kodeseragam: this.jenis_seragam.KodeSeragam
        }
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: this.user.data[0].NikKaryawan,
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "Dev/Seragam/Transaksi", code, {
      //     headers,
      //   })
      this.getTransaksi(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
              this.btnLoading = false
            });
          } else if(result.message.message == 'OK'){
            this.btnLoading = false
            this.$swal({
              icon: "success",
              title: "Sukses!",
              text: "Input bukti serah terima seragam berhasil",
            }).then(() => {
                  this.initialize();
                  window.location.reload();
                  this.clearForm();
                });
          }else{
            this.btnLoading = false
            this.$swal({
              icon: "warning",
              title: "Perhatian!",
              text: result.message.message
            })
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            this.vloading = false
            // this.snackbar=true;
          }
        });
        }
      })
    },

    sendNotif(){
      this.$swal({
        icon: "info",
        title: "Apakah Anda Yakin?",
        text: "Sistem akan mengirimkan notifikasi kembali melalui telegram anda",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Batal`,
        confirmButtonText: `Ya`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.btnLoadingNotif = true;
      // this.btnLoading= true
      const code = {
        code: 3,
        data:{
          nik: this.user.data[0].NikKaryawan
        }
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: this.user.data[0].NikKaryawan,
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "Dev/Seragam/Transaksi", code, {
      //     headers,
      //   })
      this.getTransaksi(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
              this.btnLoadingNotif = false
            });
          } else if(result.message.message == 'OK'){
            this.btnLoadingNotif = false
            this.$swal({
              icon: "success",
              title: "Sukses!",
              text: "Input bukti serah terima seragam berhasil dikirim",
            })
          }else{
            this.btnLoadingNotif = false
            this.$swal({
              icon: "warning",
              title: "Perhatian!",
              text: result.message.message
            })
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            this.btnLoadingNotif = false
            // this.snackbar=true;
          }
        });
        }
      })
    },

    editItem(item) {
      this.editedIndex = this.itemsTableSeragam.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.chooseKar.NIK = "";
      this.chooseKar.NAMA = "";
      this.NIK_NamaKaryawan = "";
      this.role = "";
      this.unit = [];
      this.cabang = [];
      // this.bagian = [];
      // this.clearBagianCabang;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    add() {
      this.inputs.push({
        unit: "",
        cabang: "",
      });
      // console.log(this.inputs);
    },

    remove(index) {
      this.inputs.splice(index, 1);
    },


    clearBagianCabang() {
      this.unit = "";
      this.bagian = [];
      this.cabang = [];
    },

    clearBagian() {
      this.unit = "";
      this.bagian = [];
    },

    clearCabang() {
      this.unit = "";
      this.cabang = [];
    },

    clearForm() {
      this.chooseKar.NIK = "";
      this.chooseKar.NAMA = "";
      this.role = "";
      this.unit = [];
      this.cabang = [];
      // this.bagian = [];
    },
  },
};
</script>
