<template>
  <div class="laporan">
    <c-toolbar />
    <c-navbar />
    <v-main>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2">
              <v-row justify="center">
                <v-img src="img/indomaret.png" aspect-ratio="3" max-width="250">
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Status Pengajuan Bantuan Pendidikan
              </h3>

              <form class="pa-4 mx-3">
                <v-dialog v-model="dialogGanjil" max-width="800px" persistent>
                  <v-card>
                    <v-card-title class="headline"
                      >Detail Nilai Mata Pelajaran Semester Ganjil</v-card-title
                    >
                    <v-card-text>
                      <v-alert class="mx-4" type="info">
                        <div>
                          Mohon input mata pelajaran sesuai urutan pada lembar
                          nilai di raport
                        </div>
                      </v-alert>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <h4>Mata Pelajaran</h4>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <h4>Nilai Pengetahuan</h4>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <h4>Nilai Keterampilan</h4>
                          </v-col>
                        </v-row>
                        <v-row v-for="(input, k) in inputsganjil" :key="k">
                          <v-col cols="12" sm="4" md="4">
                            <v-autocomplete
                              solo
                              v-model="input.mapelganjil"
                              :items="items_mapelganjil"
                              item-text="NAMA_MAPEL"
                              item-value="ID_MAPEL"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="primary"
                              v-show="k || (!k && inputsganjil.length > 1)"
                              @click="removeGanjil(k)"
                            >
                              <v-icon dark>
                                mdi-minus
                              </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="primary"
                              v-show="k == inputsganjil.length - 1"
                              @click="addGanjil(k)"
                            >
                              <v-icon dark>
                                mdi-plus
                              </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              solo
                              v-model="input.pengetahuanganjil"
                              v-on:keypress="isNumber($event)"
                              @change="updatePengetahuanGanjil"
                              :rules="inputRules"
                              :maxlength="3"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              solo
                              v-model="input.keterampilanganjil"
                              v-on:keypress="isNumber($event)"
                              @change="updateKeterampilanGanjil"
                              :rules="inputRules"
                              :maxlength="3"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <h4 style="text-align:right">
                              Jumlah Rata-Rata Nilai Mata Pelajaran
                            </h4>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              solo
                              disabled
                              placeholder="Hasil"
                              v-model="jmlnilaipengetahuanganjil"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              solo
                              disabled
                              placeholder="Hasil"
                              v-model="jmlnilaiketerampilanganjil"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="9" md="9">
                            <h4 style="text-align:right">
                              Rata-Rata Nilai Mata Pelajaran
                            </h4>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              solo
                              v-model="rata2ganjil"
                              disabled
                              placeholder="Hasil"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="cancelganjil">
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="saveganjil"
                        v-show="validGanjil"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogGenap" max-width="800px" persistent>
                  <v-card>
                    <v-card-title class="headline"
                      >Detail Nilai Mata Pelajaran Semester Genap</v-card-title
                    >
                    <v-card-text>
                      <v-alert class="mx-4" type="info">
                        <div>
                          Mohon input mata pelajaran sesuai urutan pada lembar
                          nilai di raport
                        </div>
                      </v-alert>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <h4>Mata Pelajaran</h4>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <h4>Nilai Pengetahuan</h4>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <h4>Nilai Keterampilan</h4>
                          </v-col>
                        </v-row>
                        <v-row v-for="(input, k) in inputsgenap" :key="k">
                          <v-col cols="12" sm="4" md="4">
                            <v-autocomplete
                              solo
                              v-model="input.mapelgenap"
                              :items="items_mapelgenap"
                              item-text="NAMA_MAPEL"
                              item-value="ID_MAPEL"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="primary"
                              v-show="k || (!k && inputsgenap.length > 1)"
                              @click="removeGenap(k)"
                            >
                              <v-icon dark>
                                mdi-minus
                              </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="primary"
                              v-show="k == inputsgenap.length - 1"
                              @click="addGenap(k)"
                            >
                              <v-icon dark>
                                mdi-plus
                              </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              solo
                              v-model="input.pengetahuangenap"
                              v-on:keypress="isNumber($event)"
                              @change="updatePengetahuanGenap"
                              :maxlength="3"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              solo
                              v-model="input.keterampilangenap"
                              v-on:keypress="isNumber($event)"
                              @change="updateKeterampilanGenap"
                              :maxlength="3"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <h4 style="text-align:right">
                              Jumlah Rata-Rata Nilai Mata Pelajaran
                            </h4>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              solo
                              disabled
                              placeholder="Hasil"
                              v-model="jmlnilaipengetahuangenap"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              solo
                              disabled
                              placeholder="Hasil"
                              v-model="jmlnilaiketerampilangenap"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="9" md="9">
                            <h4 style="text-align:right">
                              Rata-Rata Nilai Mata Pelajaran
                            </h4>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              solo
                              v-model="rata2genap"
                              disabled
                              placeholder="Hasil"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="cancelgenap">
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="savegenap"
                        v-show="validGenap"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogRevisi" max-width="1000px">
                  <v-card>
                    <v-form ref="form">
                      <v-card-title>
                        <span class="headline">Revisi Bantuan Pendidikan</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-overlay :value="overlayDialog">
                            <v-progress-circular
                              indeterminate
                              size="64"
                            ></v-progress-circular>
                          </v-overlay>
                          <v-simple-table dense class="pa-4 mx-3">
                            <tbody>
                              <tr>
                                <td style="width:25%">NIK</td>
                                <td style="width:1%">:</td>
                                <td colspan="2">
                                  {{ this.editedItem.NIK_GANJIL }}
                                </td>
                              </tr>
                              <tr>
                                <td>Nama</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.NAMA_KARYAWAN_GANJIL }}
                                </td>
                              </tr>
                              <tr>
                                <td>Unit</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.UNIT_NAME_GANJIL }}
                                </td>
                              </tr>
                              <tr>
                                <td>Cabang</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.CABANG_NAME_GANJIL }}
                                </td>
                              </tr>
                              <tr>
                                <td>Bagian</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.BAGIAN_NAME_GANJIL }}
                                </td>
                              </tr>
                              <tr>
                                <td>Jabatan</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.JABATAN_GANJIL }}
                                </td>
                              </tr>
                              <tr>
                                <td>Job Class</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.JOBCLASS_GANJIL }}
                                </td>
                              </tr>
                              <tr>
                                <td>Periode Bantuan Pendidikan</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.PERIODE_BEASISWA_GANJIL }}
                                </td>
                              </tr>
                              <tr>
                                <td>Penerima Bantuan Pendidikan</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.PENERIMA_BEASISWA_GANJIL }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.PENERIMA_BEASISWA_GANJIL ==
                                    'Anak Karyawan'
                                "
                              >
                                <td>Nama Anak Karyawan</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{
                                    this.editedItem.NAMA_ANAK_KARYAWAN_GANJIL
                                  }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.PENERIMA_BEASISWA_GANJIL
                                "
                              >
                                <td>
                                  Tingkat Pendidikan
                                </td>
                                <td>:</td>
                                <td colspan="2">
                                  {{
                                    this.editedItem.NAMA_TINGKAT_BEASISWA_GANJIL
                                  }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.PENERIMA_BEASISWA_GANJIL
                                "
                              >
                                <td>Usulan Bantuan Pendidikan</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.USULAN_BEASISWA_GANJIL }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.PENERIMA_BEASISWA_GANJIL
                                "
                              >
                                <td
                                  v-if="
                                    (permohonan == 'D' &&
                                      usulan == 'Naik Semester') ||
                                      (permohonan == 'E' &&
                                        usulan == 'Naik Semester')
                                  "
                                >
                                  IP Semester Ganjil
                                  <div class="caption">
                                    Contoh pengisian: <b>3.00</b> dari skala 4
                                  </div>
                                </td>
                                <td v-else>Nilai Rata2 Semester Ganjil</td>
                                <td>:</td>
                                <td>
                                  <v-text-field
                                    v-model="nilaiganjil"
                                    label="Nilai"
                                    solo
                                    :disabled="
                                      permohonan == 'A' ||
                                        permohonan == 'B' ||
                                        permohonan == 'C' ||
                                        (permohonan == 'D' &&
                                          usulan ==
                                            'Naik Jenjang Pendidikan') ||
                                        (permohonan == 'E' &&
                                          usulan ==
                                            'Naik Jenjang Pendidikan') ||
                                        permohonan == null
                                    "
                                    v-on:keypress="isNumber($event)"
                                    :maxlength="4"
                                    @change="updateGanjil"
                                  ></v-text-field>
                                </td>
                                <td>
                                  <v-btn
                                    small
                                    color="primary"
                                    block
                                    :disabled="
                                      (permohonan == 'D' &&
                                        usulan == 'Naik Semester') ||
                                        (permohonan == 'E' &&
                                          usulan == 'Naik Semester') ||
                                        (permohonan == 'E' &&
                                          usulan == 'Naik Jenjang Pendidikan' &&
                                          pendidikanSebelumnya == 'D') ||
                                        permohonan == null
                                    "
                                    @click="detailGanjil"
                                    >Input Nilai Detail</v-btn
                                  >
                                </td>
                                <td colspan="6">
                                  <div
                                    class="h1 red--text"
                                    v-if="
                                      (permohonan == 'D' &&
                                        usulan == 'Naik Semester') ||
                                        (permohonan == 'E' &&
                                          usulan == 'Naik Semester') ||
                                        (permohonan == 'E' &&
                                          usulan == 'Naik Jenjang Pendidikan' &&
                                          pendidikanSebelumnya == 'D')
                                    "
                                  >
                                    Nilai IP Semester Ganjil
                                  </div>
                                  <div class="red--text" v-else>
                                    Nilai Raport Semester Ganjil
                                  </div>
                                  <v-file-input
                                    :rules="rulesUpload"
                                    accept="application/pdf"
                                    label="Attach File"
                                    show-size
                                    ref="fileInput"
                                    v-model="filesTranskripGanjil"
                                  ></v-file-input>
                                  <div
                                    class="caption blue--text bold"
                                    v-show="this.namaPDFGanjil != ''"
                                  >
                                    {{ this.namaPDFGanjil }}
                                  </div>
                                  <div class="caption red--text">
                                    *Max File 1MB!
                                  </div>
                                  <div class="caption">Format: pdf</div>
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.PENERIMA_BEASISWA_GANJIL
                                "
                              >
                                <td
                                  v-if="
                                    (permohonan == 'D' &&
                                      usulan == 'Naik Semester') ||
                                      (permohonan == 'E' &&
                                        usulan == 'Naik Semester') ||
                                      (permohonan == 'E' &&
                                        usulan == 'Naik Jenjang Pendidikan' &&
                                        pendidikanSebelumnya == 'D')
                                  "
                                >
                                  Nilai IP Semester Genap
                                  <div class="caption">
                                    Contoh pengisian: <b>3.00</b> dari skala 4
                                  </div>
                                </td>
                                <td v-else>Nilai Raport Semester Genap</td>
                                <td>:</td>
                                <td>
                                  <v-text-field
                                    v-model="nilaigenap"
                                    label="Nilai"
                                    solo
                                    :disabled="
                                      permohonan == 'A' ||
                                        permohonan == 'B' ||
                                        permohonan == 'C' ||
                                        (permohonan == 'D' &&
                                          usulan ==
                                            'Naik Jenjang Pendidikan') ||
                                        (permohonan == 'E' &&
                                          usulan == 'Naik Jenjang Pendidikan' &&
                                          pendidikanSebelumnya == 'C') ||
                                        permohonan == null
                                    "
                                    v-on:keypress="isNumber($event)"
                                    :maxlength="4"
                                    @change="updateGenap"
                                  ></v-text-field>
                                </td>
                                <td>
                                  <v-btn
                                    small
                                    color="primary"
                                    block
                                    :disabled="
                                      (permohonan == 'D' &&
                                        usulan == 'Naik Semester') ||
                                        (permohonan == 'E' &&
                                          usulan == 'Naik Semester') ||
                                        (permohonan == 'E' &&
                                          usulan == 'Naik Jenjang Pendidikan' &&
                                          pendidikanSebelumnya == 'D') ||
                                        permohonan == null
                                    "
                                    @click="detailGenap"
                                    >Input Nilai Detail</v-btn
                                  >
                                </td>
                                <td colspan="6">
                                  <div
                                    class="h1 red--text"
                                    v-if="
                                      (permohonan == 'D' &&
                                        usulan == 'Naik Semester') ||
                                        (permohonan == 'E' &&
                                          usulan == 'Naik Semester') ||
                                        (permohonan == 'E' &&
                                          usulan == 'Naik Jenjang Pendidikan' &&
                                          pendidikanSebelumnya == 'D')
                                    "
                                  >
                                    Nilai IP Semester Genap
                                  </div>
                                  <div class="red--text" v-else>
                                    Nilai Raport Semester Genap
                                  </div>
                                  <v-file-input
                                    :rules="rulesUpload"
                                    accept="application/pdf"
                                    label="Attach File"
                                    show-size
                                    ref="fileInput"
                                    v-model="filesTranskripGenap"
                                  ></v-file-input>
                                  <div
                                    class="caption blue--text bold"
                                    v-show="this.namaPDFGenap != ''"
                                  >
                                    {{ this.namaPDFGenap }}
                                  </div>
                                  <div class="caption red--text">
                                    *Max File 1MB!
                                  </div>
                                  <div class="caption">Format: pdf</div>
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.PENERIMA_BEASISWA_GANJIL
                                "
                              >
                                <td
                                  v-if="
                                    (permohonan == 'D' &&
                                      usulan == 'Naik Semester') ||
                                      (permohonan == 'E' &&
                                        usulan == 'Naik Semester') ||
                                      (permohonan == 'E' &&
                                        usulan == 'Naik Jenjang Pendidikan' &&
                                        pendidikanSebelumnya == 'D')
                                  "
                                >
                                  IP Semester Total
                                </td>
                                <td v-else>Nilai Rata2 Semester Total</td>
                                <td>:</td>
                                <td>
                                  <v-text-field
                                    v-model="nilaitotal"
                                    label="Hasil"
                                    solo
                                    disabled
                                  ></v-text-field>
                                </td>
                                <td></td>
                                <td
                                  colspan="6"
                                  v-show="usulan == 'Naik Jenjang Pendidikan'"
                                >
                                  <div
                                    class="h1 red--text"
                                    v-if="permohonan == 'D'"
                                  >
                                    Upload Surat Keterangan Asli diterima di
                                    Akademi
                                  </div>
                                  <div
                                    class="h1 red--text"
                                    v-else-if="permohonan == 'E'"
                                  >
                                    Upload Surat Keterangan Asli diterima di
                                    Universitas
                                  </div>
                                  <div class="h1 red--text" v-else>
                                    Upload Surat Keterangan Asli diterima di
                                    Sekolah
                                  </div>
                                  <v-file-input
                                    :rules="rulesUpload"
                                    accept="application/pdf"
                                    label="Attach File"
                                    show-size
                                    ref="fileInput"
                                    v-model="filesDiterima"
                                  ></v-file-input>
                                  <div
                                    class="caption blue--text bold"
                                    v-show="this.namaPDFSuratDiterima != ''"
                                  >
                                    {{ this.namaPDFSuratDiterima }}
                                  </div>
                                  <div class="caption red--text">
                                    *Max File 1MB!
                                  </div>
                                  <div class="caption">Format: pdf</div>
                                </td>
                              </tr>
                              <tr v-show="usulan == 'Naik Jenjang Pendidikan'">
                                <td
                                  colspan="4"
                                  v-show="usulan == 'Naik Jenjang Pendidikan'"
                                >
                                  <div class="h1 red--text">
                                    Upload Surat Kelulusan
                                  </div>
                                  <v-file-input
                                    :rules="rulesUpload"
                                    accept="application/pdf"
                                    label="Attach File"
                                    show-size
                                    ref="fileInput"
                                    v-model="filesKelulusan"
                                  ></v-file-input>
                                  <div
                                    class="caption blue--text bold"
                                    v-show="this.namaPDFKelulusan != ''"
                                  >
                                    {{ this.namaPDFKelulusan }}
                                  </div>
                                  <div class="caption red--text">
                                    *Max File 1MB!
                                  </div>
                                  <div class="caption">Format: pdf</div>
                                </td>
                                <td
                                  colspan="5"
                                  v-show="usulan == 'Naik Jenjang Pendidikan'"
                                >
                                  <div class="h1 red--text">
                                    Upload Nilai Ijazah
                                  </div>
                                  <v-file-input
                                    :rules="rulesUpload"
                                    accept="application/pdf"
                                    label="Attach File"
                                    show-size
                                    ref="fileInput"
                                    v-model="filesIjazah"
                                  ></v-file-input>
                                  <div
                                    class="caption blue--text bold"
                                    v-show="this.namaPDFIjazah != ''"
                                  >
                                    {{ this.namaPDFIjazah }}
                                  </div>
                                  <div class="caption red--text">
                                    *Max File 1MB!
                                  </div>
                                  <div class="caption">Format: pdf</div>
                                </td>
                              </tr>
                              <tr
                                v-show="permohonan == 'D' || permohonan == 'E'"
                              >
                                <td
                                  colspan="4"
                                  v-show="
                                    permohonan == 'D' || permohonan == 'E'
                                  "
                                >
                                  <div class="h1 red--text">
                                    Upload Surat Akreditasi Program Studi
                                  </div>
                                  <v-file-input
                                    :rules="rulesUpload"
                                    accept="application/pdf"
                                    label="Attach File"
                                    show-size
                                    ref="fileInput"
                                    v-model="filesAkreditasiProdi"
                                  ></v-file-input>
                                  <div
                                    class="caption blue--text bold"
                                    v-show="this.namaPDFAkreditasiProdi != ''"
                                  >
                                    {{ this.namaPDFAkreditasiProdi }}
                                  </div>
                                  <div class="caption red--text">
                                    *Max File 1MB!
                                  </div>
                                  <div class="caption">Format: pdf</div>
                                </td>
                                <td
                                  colspan="5"
                                  v-show="
                                    permohonan == 'D' || permohonan == 'E'
                                  "
                                >
                                  <div
                                    class="h1 red--text"
                                    v-if="permohonan == 'D'"
                                  >
                                    Upload Surat Akreditasi Akademi
                                  </div>
                                  <div class="h1 red--text" v-else>
                                    Upload Surat Akreditasi Universitas
                                  </div>
                                  <v-file-input
                                    :rules="rulesUpload"
                                    accept="application/pdf"
                                    label="Attach File"
                                    show-size
                                    ref="fileInput"
                                    v-model="filesAkreditasiED"
                                  ></v-file-input>
                                  <div
                                    class="caption blue--text bold"
                                    v-show="this.namaPDFSuratAkreditasi != ''"
                                  >
                                    {{ this.namaPDFSuratAkreditasi }}
                                  </div>
                                  <div class="caption red--text">
                                    *Max File 1MB!
                                  </div>
                                  <div class="caption">Format: pdf</div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colspan="4"
                                  v-show="usulan == 'Naik Semester'"
                                >
                                  <div
                                    class="h1 red--text"
                                    v-if="permohonan == 'D'"
                                  >
                                    Upload Surat Aktif Kuliah dari Akademi
                                  </div>
                                  <div class="h1 red--text" v-else>
                                    Upload Surat Aktif Kuliah dari Universitas
                                  </div>
                                  <v-file-input
                                    :rules="rulesUpload"
                                    accept="application/pdf"
                                    label="Attach File"
                                    show-size
                                    ref="fileInput"
                                    v-model="filesAktifKuliah"
                                  ></v-file-input>
                                  <div
                                    class="caption blue--text bold"
                                    v-show="this.namaPDFAktifKuliah != ''"
                                  >
                                    {{ this.namaPDFAktifKuliah }}
                                  </div>
                                  <div class="caption red--text">
                                    *Max File 1MB!
                                  </div>
                                  <div class="caption">Format: pdf</div>
                                </td>
                                <td
                                  colspan="5"
                                  v-show="
                                    (permohonan == 'D' && postpone == 'Ya') ||
                                      (permohonan == 'E' && postpone == 'Ya')
                                  "
                                >
                                  <div
                                    class="h1 red--text"
                                    v-if="permohonan == 'D'"
                                  >
                                    Upload Surat Keterangan Postpone dari
                                    Akademi
                                  </div>
                                  <div class="h1 red--text" v-else>
                                    Upload Surat Keterangan Postpone dari
                                    Universitas
                                  </div>
                                  <v-file-input
                                    :rules="rulesUpload"
                                    accept="application/pdf"
                                    label="Attach File"
                                    show-size
                                    ref="fileInput"
                                    v-model="filesPostpone"
                                  ></v-file-input>
                                  <div
                                    class="caption blue--text bold"
                                    v-show="this.namaPDFPostpone != ''"
                                  >
                                    {{ this.namaPDFPostpone }}
                                  </div>
                                  <div class="caption red--text">
                                    *Max File 1MB!
                                  </div>
                                  <div class="caption">Format: pdf</div>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          :loading="btnLoading"
                          v-show="
                            validInput &&
                              validAnakKaryawan &&
                              validNaikJenjangPendidikan &&
                              validKampus &&
                              validNaikSemester &&
                              validPostpone &&
                              validNilai
                          "
                          @click="submitRevisi"
                        >
                          Submit Revisi
                        </v-btn>
                        <v-btn
                          color="red darken-1"
                          text
                          @click="dialogRevisi = false"
                        >
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
                <div class="my-2">
                  <div class="mt-10" v-show="dataTable">
                    <template>
                      <v-data-table
                        :headers="headers"
                        :items="itemsTableRevisi"
                        :search="search"
                        class="elevation-1"
                      >
                        <template v-slot:[`item.CREATEDDATE_GANJIL`]="{ item }">
                          {{ formatDate(item.CREATEDDATE_GANJIL) }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            small
                            rounded
                            class="mr-2"
                            color="green"
                            :disabled="disableRevisi(item)"
                            @click="bukaDialogRevisi(item)"
                            v-show="
                              item.STATUS_TRANSAKSI_DESKRIPSI_GANJIL ==
                                'Revisi Manager' ||
                                item.STATUS_TRANSAKSI_DESKRIPSI_GANJIL ==
                                  'Revisi Verifikasi' ||
                                item.STATUS_TRANSAKSI_DESKRIPSI_GANJIL ==
                                  'Revisi Otorisasi Awal' ||
                                item.STATUS_TRANSAKSI_DESKRIPSI_GANJIL ==
                                  'Revisi Otorisasi Akhir'
                            "
                          >
                            Revisi
                          </v-btn>
                        </template>
                        <template v-slot:top>
                          <v-toolbar flat>
                            <!-- <v-btn
                              color="success"
                              dark
                              class="mb-2"
                              @click="downloadExcel()"
                            >
                              Export Excel
                            </v-btn> -->

                            <!-- jarak judul -->
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <!-- search -->
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-toolbar>
                        </template>
                      </v-data-table>
                    </template>
                  </div>
                </div>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
// import Sidebar from "@/components/Sidebar.vue";
// import Toolbar from "@/components/Toolbar.vue";
import CNavbar from "../../components/CNavbar.vue";
import CToolbar from "../../components/CToolbar.vue";
import moment, { relativeTimeRounding } from "moment";
import { mapGetters, mapActions } from "vuex";
import XLSX from "xlsx";

export default {
  name: "Home",
  components: {
    CNavbar,
    CToolbar,
  },

  mounted() {
    this.TOKEN = this.token;
    this.dataTiketDanNik();
    this.tingkatPendidikan();
    this.notifBeasiswaSekolahUserAwal = this.user.data[0].NotifBeasiswaSekolahUserAwal;
    this.notifBeasiswaSekolahUserAkhir = this.user.data[0].NotifBeasiswaSekolahUserAkhir;
    this.notifBeasiswaInstitutUserAwal = this.user.data[0].NotifBeasiswaInstitutUserAwal;
    this.notifBeasiswaInstitutUserAkhir = this.user.data[0].NotifBeasiswaInstitutUserAkhir;
  },

  computed: {
    ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      token: "token",
      // getDrawer: "drawer",
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    computedDateFormatted() {
      // return this.dates.join(' ~ ');
      if (this.dates.length <= 0) {
        return "";
      } else {
        let date1 = this.formatDate(new Date(this.dates[0]));
        let date2 = this.formatDate(new Date(this.dates[1]));

        if (date2 < date1) {
          date2 = this.formatDate(new Date(this.dates[0]));
          date1 = this.formatDate(new Date(this.dates[1]));
        }
        const merge = [date1, date2];
        const temp = merge.join(" ~ ");
        return temp;
      }
    },

    // headers() {
    //   //set having unique values
    //   let s = new Set();
    //   var f;

    //   this.itemsTableMutasi.forEach((item) => {
    //     for (f in item) {
    //       //adding an existing value doesn't override the old one
    //       s.add(f);
    //     }
    //   });
    //   //respect the headers format required by Vuetify which
    //   // should has at least two fields (text, value)
    //   return Array.from(s).map((a) => {
    //     return {
    //       text: a.toUpperCase(),
    //       value: a,
    //     };
    //   });
    // },

    validGanjil() {
      // // console.log(this.inputs);
      let hasiltest = this.inputsganjil;
      let flag = true;
      for (var i = 0; i < hasiltest.length; i++) {
        let idmapel = hasiltest[i].mapelganjil;
        let pengetahuan = hasiltest[i].pengetahuanganjil;
        let keterampilan = hasiltest[i].keterampilanganjil;
        // // console.log(idmapel);
        flag = idmapel != null && pengetahuan != null && keterampilan != null;
        if (!flag) {
          break;
        }
      }
      return flag;
    },

    validGenap() {
      // // console.log(this.inputs);
      let hasiltest = this.inputsgenap;
      let flag = true;
      for (var i = 0; i < hasiltest.length; i++) {
        let idmapel = hasiltest[i].mapelgenap;
        let pengetahuan = hasiltest[i].pengetahuangenap;
        let keterampilan = hasiltest[i].keterampilangenap;
        // // console.log(pengetahuan)
        flag = idmapel != null && pengetahuan != null && keterampilan != null;
        if (!flag) {
          break;
        }
      }
      return flag;
    },

    validBerhasil() {
      return (
        this.date != null &&
        this.idsertifikasi != "" &&
        // this.idsertifikasi.length <= 26 &&
        this.idsertifikasi.length == 24 &&
        this.jenisvaksinasi != null &&
        this.realisasi != null &&
        // this.JawabanHasilVaksin == "1" &&
        this.files != null &&
        this.files.size <= 1000000
        // this.files.type == "image/png"
      );
    },
    validInput() {
      return (
        this.editedItem.NIK != "" &&
        this.editedItem.PERIODE_BEASISWA != "" &&
        this.editedItem.PENERIMA_BEASISWA != "" &&
        this.permohonan != "" &&
        this.usulan != "" &&
        this.nilaiganjil != "" &&
        this.nilaigenap != "" &&
        this.nilaitotal != "" &&
        this.filesTranskripGanjil != null &&
        this.filesTranskripGanjil.size / (1024 * 1024) <= 1 &&
        this.filesTranskripGenap != null &&
        this.filesTranskripGenap.size / (1024 * 1024) <= 1
      );
    },

    validNaikJenjangPendidikan() {
      if (
        (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "A") ||
        (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "B") ||
        (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "C")
      ) {
        return (
          this.filesDiterima != null &&
          this.filesDiterima.size / (1024 * 1024) <= 1 &&
          this.filesIjazah != null &&
          this.filesIjazah.size / (1024 * 1024) <= 1 &&
          this.filesKelulusan != null &&
          this.filesKelulusan.size / (1024 * 1024) <= 1
        );
      } else if (
        (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "D") ||
        (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "E")
      ) {
        return (
          this.filesDiterima != null &&
          this.filesDiterima.size / (1024 * 1024) <= 1 &&
          this.filesIjazah != null &&
          this.filesIjazah.size / (1024 * 1024) <= 1 &&
          this.filesKelulusan != null &&
          this.filesKelulusan.size / (1024 * 1024) <= 1 &&
          this.filesAkreditasiProdi != null &&
          this.filesAkreditasiProdi.size / (1024 * 1024) <= 1 &&
          this.filesAkreditasiED != null &&
          this.filesAkreditasiED.size / (1024 * 1024) <= 1
        );
      } else {
        return true;
      }
    },

    validAnakKaryawan() {
      if (this.penerima == "Anak Karyawan") {
        return this.penerima == "Anak Karyawan" && this.namaanak != "";
      } else {
        return true;
      }
    },

    // validDetail(){
    //     return(
    //     this.cekStatus == "Revisi Manager"||
    //     this.cekStatus == "Revisi Verifikasi"||
    //     this.cekStatus == "Revisi Otoritas Awal"||
    //     this.cekStatus == "Revisi Otoritas Akhir"
    //     )
    // },

    validKampus() {
      if (this.permohonan == "D" || this.permohonan == "E") {
        return (
          this.filesAkreditasiProdi != null &&
          this.filesAkreditasiProdi.size / (1024 * 1024) <= 1 &&
          this.filesAkreditasiED != null &&
          this.filesAkreditasiED.size / (1024 * 1024) <= 1
        );
      } else {
        return true;
      }
    },

    validNaikSemester() {
      if (
        (this.usulan == "Naik Semester" && this.permohonan == "D") ||
        (this.usulan == "Naik Semester" && this.permohonan == "E")
      ) {
        return (
          this.filesAktifKuliah != null &&
          this.filesAktifKuliah.size / (1024 * 1024) <= 1
        );
      } else {
        return true;
      }
    },

    validPostpone() {
      if (this.postpone == "Ya") {
        return (
          // this.semesterPostpone != "" &&
          // this.textPostpone != "" &&
          this.filesPostpone != null &&
          this.filesPostpone.size / (1024 * 1024) <= 1
        );
      } else {
        return true;
      }
    },

    validNilai() {
      if (
        (this.permohonan == "D" && this.usulan == "Naik Semester") ||
        (this.permohonan == "E" && this.usulan == "Naik Semester") ||
        (this.permohonan == "E" &&
          this.usulan == "Naik Jenjang Pendidikan" &&
          this.pendidikanSebelumnya == "D")
      ) {
        return (
          (parseFloat(this.nilaiganjil) <= parseFloat(4)) &&
          (parseFloat(this.nilaigenap) <= parseFloat(4)) &&
          (parseFloat(this.nilaitotal) <= parseFloat(4)) 
        );
      } else {
        return (
          this.inputsganjil[0].mapelganjil != null &&
          this.inputsganjil[0].pengetahuanganjil != null &
          this.inputsganjil[0].keterampilanganjil != null &
           this.inputsgenap[0].mapelgenap != null &&
          this.inputsgenap[0].pengetahuangenap != null &
          this.inputsgenap[0].keterampilangenap != null 
        )
      }
    },
  },

  data: () => ({
    // $baseurl: "http://localhost/",
    // $baseurl: "http://116.204.128.50:80/",
    // $baseurl: "https://ms.hrindomaret.com/",
    overlay: false,
    overlayDialog: false,
    dialogRevisi: false,
    dialogGanjil: false,
    dialogGenap: false,
    userRole: "",
    menu2: false,
    dates: [],
    btnLoading: false,
    dataTable: false,
    namaKary: "",
    nikKary: "",
    notiket: "",
    statustransaksi: "",
    permohonan: null,
    usulan: null,
    kelas: "",
    postpone: "",
    pendidikanSebelumnya: "",
    periodebulantahun: "",
    penerimabeasiswa: "",
    akreditasijurusan: "",
    akreditasikampus : "",
    plafond: "",
    search: null,
    filesIjazah: null,
    namaPDFIjazah: "",
    filesKelulusan: null,
    namaPDFKelulusan: "",
    filesTranskripGanjil: null,
    namaPDFGanjil: "",
    filesTranskripGenap: null,
    namaPDFGenap: "",
    filesDiterima: null,
    namaPDFSuratDiterima: "",
    filesAkreditasiProdi: null,
    namaPDFAkreditasiProdi: "",
    filesAkreditasiED: null,
    namaPDFSuratAkreditasi: "",
    filesPostpone: null,
    namaPDFPostpone: "",
    filesAktifKuliah: null,
    namaPDFAktifKuliah: "",
    nilaiganjil: "",
    nilaigenap: "",
    nilaitotal: "",
    jmlnilaiketerampilanganjil: "",
    jmlnilaipengetahuanganjil: "",
    rata2ganjil: "",
    jmlnilaiketerampilangenap: "",
    jmlnilaipengetahuangenap: "",
    rata2genap: "",
    cekStatus: "",
    mapelganjilarray: [],
    mapelgenaparray: [],
    pengetahuanganjilarray: [],
    pengetahuangenaparray: [],
    keterampilanganjilarray: [],
    keterampilangenaparray: [],
    inputsganjil: [
      {
        mapelganjil: null,
        pengetahuanganjil: null,
        keterampilanganjil: null,
      },
    ],

    inputsgenap: [
      {
        mapelgenap: null,
        pengetahuangenap: null,
        keterampilangenap: null,
      },
    ],
    headers: [
      { text: "NIK", value: "NIK_GANJIL" },
      { text: "Nama", value: "NAMA_KARYAWAN_GANJIL" },
      { text: "Periode", value: "PERIODE_BEASISWA_GANJIL" },
      { text: "Tanggal Pengajuan", value: "CREATEDDATE_GANJIL" },
      { text: "Status", value: "STATUS_TRANSAKSI_DESKRIPSI_GANJIL" },
      { text: "Keterangan", value: "KETERANGAN_REJECT_GANJIL" },
      { text: "Action", value: "actions", sortable: false },
    ],

    editedItem: {
      NIK: "",
      NAMA_KARYAWAN: "",
      UNIT_NAME: "",
      CABANG_NAME: "",
      BAGIAN_NAME: "",
      JABATAN: "",
      JOBCLASS: "",
      STATUS_TRANSAKSI_DESKRIPSI: "",
      PERIODE_BEASISWA: "",
      PENERIMA_BEASISWA: "",
      NAMA_ANAK_KARYAWAN: "",
      NAMA_TINGKAT_BEASISWA: "",
      USULAN_BEASISWA: "",
      NPSN_SEKOLAH: "",
      NAMA_SEKOLAH: "",
      AKREDITASI_JURUSAN: "",
      AKREDITASI_KAMPUS: "",
      TAHUN_MASUK: "",
      PLAFOND_BEASISWA: "",
      TAHUN_AJARAN_BARU: "",
      NILAI_GANJIL: "",
      NILAI_GENAP: "",
      NILAI_RATARATA: "",
      NILAI_PENGETAHUAN: "",
      NILAI_KETERAMPILAN: "",
      NILAI_PENGETAHUAN: "",
      NILAI_KETERAMPILAN: "",
      NAMA_MAPEL: "",
      NAMA_MAPEL: "",
      FILEBLOB_IJAZAH: "",
      // FILEPATH_RAPORT: "",
      FILEBLOB_RAPORT: "",
      FIEBLOB_SURATDITERIMA: "",
      EXTENSION_RAPORT: "",
      EXTENSION_IJAZAH: "",
      EXTENSION_SURATDITERIMA: "",
      NIK_MGR: "",
      NAMA_MGR: "",
      NIK_VERIF: "",
      NAMA_VERIF: "",
      NIK_OTORISASI_AWAL: "",
      NAMA_OTORISASI_AWAL: "",
      NIK_OTORISASI_AKHIR: "",
      NAMA_OTORISASI_AKHIR: "",
    },

    itemsTableRevisi: [],
    itemsTableDialog: [],
    itemsTingkatPendidikan: [],

    nikRules: [
      (v) => !!v || "NIK harus diisi",
      (v) => (v && v.length == 10) || "NIK Harus 10 Karakter",
    ],
    namaRules: [(v) => !!v || "Nama harus diisi"],
    ktpRules: [(v) => !!v || "No KTP harus diisi"],
    noHpRules: [(v) => !!v || "No HP harus diisi"],
    namaAtasanRules: [(v) => !!v || "Nama Atasan HP harus diisi"],
    nikAtasanRules: [
      (v) => !!v || "NIK Atasan HP harus diisi",
      // (v) => (v && v.length == 10) || "NIK Harus 10 Karakter",
    ],
    noHpAtasanRules: [(v) => !!v || "No HP Atasan harus diisi"],
    tglRules: [(v) => !!v || "Tanggal harus diisi"],
    hasilRules: [(v) => !!v || "Rencana vaksin harus diisi"],
    inputRules: [
      (value) =>
        /^[0-9.]*$/.test(value) || "Hanya angka dan titik yang diperbolehkan.",
    ],
    rulesUpload: [
      (v) => !v || v.size <= 1000000 || "Ukuran file maks 1 MB!",
      (v) => !!v || "File harus diisi",
      (v) => !v || v.type == "application/pdf" || "File harus pdf",
    ],
    rulesSertifikasi: [
      (v) => !!v || "ID Sertifikat harus diisi",
      // (v) => !v || v.length <= 26 || "Jumlah Karakter Melebihi 26!",
      (v) => !v || v.length == 24 || "ID Sertifikasi Harus 24 Karakter!",
      // (v) => !v || /(?=.*[A-Za-z0-9])/.test(v) || "Harus Mengandung Huruf dan Angka",
    ],
    rulesAlasan: [
      (v) => !!v || "Alasan harus diisi",
      (v) => !v || v.length <= 100 || "Jumlah Karakter Melebihi 100!",
    ],
  }),

  methods: {
    ...mapActions({
      getDataRevisiBeasiswa: "getDataRevisiBeasiswa",
      insertDataMstPlafondBeasiswa: "insertDataMstPlafondBeasiswa",
      insertDataMstMaPelBeasiswa: "insertDataMstMaPelBeasiswa",
    }),

    dataTiketDanNik() {
      // const userRole = sessionStorage.getItem("role");
      this.overlay = true;
      const axios = require("axios");
      const code = {
        nik: this.user.data[0].NikKaryawan,
        notiket: "",
        kategori: "Read",
        statustransaksi: "",
        usulanbeasiswa: "",
        kelas: "",
        tingkatbeasiswa: "",
        periodebulantahun: "",
        penerimabeasiswa: "",
        akreditasikampus: "",
        akreditasijurusan:"",
        plafond: "",
        postpone: "",
        nilaiganjil: "",
        nilaigenap: "",
        nilairata: "",
        idmapelganjil: "",
        idmapelgenap: "",
        nilaipengetahuanmapelganjil: "",
        nilaipengetahuanmapelgenap: "",
        nilaiketerampilanmapelganjil: "",
        nilaiketerampilanmapelgenap: "",
        semesterganjil: "",
        semestergenap: "",
        fileraport: "",
        fileipk: "",
        filekelulusan: "",
        fileijazah: "",
        filesuratditerima: "",
        filesuratakreditasi: "",
        fileakreditasiprogramstudi: "",
        filepostpone: "",
        fileaktifkuliah: "",
      };

      this.getDataRevisiBeasiswa(code)
        .then((response) => {
          const hasil = response.data;
          // console.log(hasil);
          // console.log(hasil.data.length);
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else if (hasil.data.length == 0) {
            // this.itemsTableRekap = hasil.data;
            this.overlay = false;
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Anda Tidak Memiliki Nomor Tiket Pengajuan",
            });
            // if(hasil.data.length < 0 ){
            //     this.$swal({
            //         icon:"info",
            //         title:"Info!",
            //         text:"Anda Tidak Memiliki Nomor Tiket Pengajuan"
            //     })
            //     this.overlay = false;
            // }else{
            // // this.notiket = hasil.data[0].NO_TIKET
            // // this.overlay = false

            // // this.filterRekap()
            // // this.filterDialog()
            // }

            // this.itemsTableMonitoring= hasil;

            // // console.log(hasil);
          } else {
            this.notiket = hasil.data[0].NO_TIKET;
            this.overlay = false;

            this.filterRekap();
            this.filterDialog();
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
          this.overlay = false;
        });
    },

    formatDate(value) {
      return moment(value).format("DD-MM-YYYY");
    },

    filterRekap() {
      this.overlay = true;

      const axios = require("axios");
      const code = {
        nik: "",
        notiket: this.notiket,
        kategori: "Read",
        statustransaksi: "",
        usulanbeasiswa: "",
        kelas: "",
        tingkatbeasiswa: "",
        periodebulantahun: "",
        plafond: "",
        postpone: "",
        nilaiganjil: "",
        nilaigenap: "",
        nilairata: "",
        idmapelganjil: "",
        idmapelgenap: "",
        nilaipengetahuanmapelganjil: "",
        nilaipengetahuanmapelgenap: "",
        nilaiketerampilanmapelganjil: "",
        nilaiketerampilanmapelgenap: "",
        semesterganjil: "",
        semestergenap: "",
        penerimabeasiswa: "",
        akreditasikampus: "",
        akreditasijurusan:"",
        fileraport: "",
        fileipk: "",
        filekelulusan: "",
        fileijazah: "",
        filesuratditerima: "",
        filesuratakreditasi: "",
        fileakreditasiprogramstudi: "",
        filepostpone: "",
        fileaktifkuliah: "",
      };

      this.getDataRevisiBeasiswa(code)
        .then((response) => {
          const hasil = response.data;
          //   console.log(hasil);
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else if (hasil.status == "Success") {
            if (hasil == null) {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Anda Tidak Memiliki Data Pengajuan",
              });
              this.overlay = false;
            } else {
              // console.log("AWS");

              this.itemsTableRevisi = hasil.data;

              this.inputsganjil = hasil.inputsganjil;
              this.updatePengetahuanGanjil();
              this.updateKeterampilanGanjil();
              // console.log(this.inputsganjil);

              this.inputsgenap = hasil.inputsgenap;
              this.updatePengetahuanGenap();
              this.updateKeterampilanGenap();

              // this.cekStatus = hasil.data.STATUS_TRANSAKSI_DESKRIPSI

              // console.log("KFWE");
              // // console.log(hasil);
              // this.overlay = false;
              this.dataTable = true;
            }
          } else {
            this.$swal({
              icon: "warning",
              title: "Maaf!",
              text: "Anda Tidak Memiliki Data Pengajuan",
            });
            this.overlay = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
          this.overlay = false;
        });
    },

    async filterDialog() {
      this.overlay = true;

      const axios = require("axios");
      const code = {
        nik: this.user.data[0].NikKaryawan,
        notiket: this.notiket,
        kategori: "Read",
        statustransaksi: "",
        usulanbeasiswa: "",
        kelas: "",
        tingkatbeasiswa: "",
        periodebulantahun: "",
        plafond: "",
        postpone: "",
        nilaiganjil: "",
        nilaigenap: "",
        nilairata: "",
        idmapelganjil: "",
        idmapelgenap: "",
        nilaipengetahuanmapelganjil: "",
        nilaipengetahuanmapelgenap: "",
        nilaiketerampilanmapelganjil: "",
        nilaiketerampilanmapelgenap: "",
        semesterganjil: "",
        semestergenap: "",
        penerimabeasiswa: "",
        akreditasikampus: "",
        akreditasijurusan:"",
        fileraport: "",
        fileipk: "",
        filekelulusan: "",
        fileijazah: "",
        filesuratditerima: "",
        filesuratakreditasi: "",
        fileakreditasiprogramstudi: "",
        filepostpone: "",
        fileaktifkuliah: "",
      };

      this.getDataRevisiBeasiswa(code)
        .then((response) => {
          const hasil = response.data;
          //   console.log(hasil);
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else if (hasil.status == "Success") {
            if (hasil == null) {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Anda Tidak Memiliki Data Pengajuan",
              });
              this.overlay = false;
            } else {
              // console.log("AWS");

              this.itemsTableDialog = hasil.data[0];

              // this.cekStatus = hasil.data.STATUS_TRANSAKSI_DESKRIPSI

              // // console.log(hasil);
              this.overlay = false;
              this.dataTable = true;
            }
          } else {
            this.$swal({
              icon: "warning",
              title: "Maaf!",
              text: "Anda Tidak Memiliki Data Pengajuan",
            });
            this.overlay = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
          this.overlay = false;
        });
    },

    bukaDialogRevisi(item) {
      this.editedIndex = this.itemsTableRevisi.indexOf(item);
      this.editedItem = Object.assign({}, item);

      console.log(this.inputsganjil.length, "ganjil");
      console.log(this.inputsgenap.length, "genap");
      console.log(this.inputsgenap, "genap");

      this.namaKary = this.editedItem.NAMA_KARYAWAN_GANJIL;
      this.statustransaksi = this.editedItem.STATUS_TRANSAKSI_DESKRIPSI_GANJIL;
      this.usulan = this.editedItem.USULAN_BEASISWA_GANJIL;
      this.kelas = this.editedItem.KELAS_GANJIL;
      this.permohonan = this.editedItem.TINGKAT_BEASISWA_GANJIL;
      this.postpone = this.editedItem.POSTPONE_GANJIL;
      this.pendidikanSebelumnya = this.editedItem.TINGKAT_SEBELUMNYA_GANJIL;
      this.nilaiganjil = this.editedItem.NILAI_GANJIL_GANJIL;
      this.nilaigenap = this.editedItem.NILAI_GENAP_GANJIL;
      this.nilaitotal = this.editedItem.NILAI_RATARATA_GANJIL;
      this.penerimabeasiswa = this.editedItem.PENERIMA_BEASISWA_GANJIL;
      this.akreditasikampus = this.editedItem.AKREDITASI_KAMPUS_GANJIL;
      this.akreditasijurusan = this.editedItem.AKREDITASI_JURUSAN_GANJIL;
      this.periodebulantahun = this.editedItem.PERIODE_PEMBERIAN_GANJIL;
      this.plafond = this.editedItem.PLAFOND_BEASISWA_GANJIL

      console.log(this.postpone);
      console.log(this.permohonan);
      console.log(this.akreditasikampus);
      console.log(this.akreditasijurusan);
      console.log(this.periodebulantahun);
      console.log(this.plafond);

      if (this.usulan == "Naik Kelas") {
        this.cekPDFGanjil(item);
        this.cekPDFGenap(item);
      } else if (this.usulan == "Naik Semester" && this.postpone == "Ya") {
        this.cekPDFGanjil(item);
        this.cekPDFGenap(item);
        this.cekPDFSuratAkreditasi(item);
        this.cekPDFAkreditasiProdi(item);
        this.cekPDFAktifKuliah(item);
        this.cekPDFPostpone(item);
      } else if (this.usulan == "Naik Semester" && this.postpone != "Ya") {
        this.cekPDFGanjil(item);
        this.cekPDFGenap(item);
        this.cekPDFSuratAkreditasi(item);
        this.cekPDFAkreditasiProdi(item);
        this.cekPDFAktifKuliah(item);
      } else if (
        (this.permohonan == "D" &&
          this.usulan == "Naik Jenjang Pendidikan" &&
          this.postpone == "Ya") ||
        (this.permohonan == "E" &&
          this.usulan == "Naik Jenjang Pendidikan" &&
          this.postpone == "Ya")
      ) {
        this.cekPDFGanjil(item);
        this.cekPDFGenap(item);
        this.cekPDFKelulusan(item);
        this.cekPDFIjazah(item);
        this.cekPDFSuratDiterima(item);
        this.cekPDFSuratAkreditasi(item);
        this.cekPDFAkreditasiProdi(item);
        this.cekPDFPostpone(item);
      } else if (
        (this.permohonan == "D" &&
          this.usulan == "Naik Jenjang Pendidikan" &&
          this.postpone != "Ya") ||
        (this.permohonan == "E" &&
          this.usulan == "Naik Jenjang Pendidikan" &&
          this.postpone != "Ya")
      ) {
        this.cekPDFGanjil(item);
        this.cekPDFGenap(item);
        this.cekPDFKelulusan(item);
        this.cekPDFIjazah(item);
        this.cekPDFSuratDiterima(item);
        this.cekPDFSuratAkreditasi(item);
        this.cekPDFAkreditasiProdi(item);
      } else {
        this.cekPDFGanjil(item);
        this.cekPDFGenap(item);
        this.cekPDFKelulusan(item);
        this.cekPDFIjazah(item);
        this.cekPDFSuratDiterima(item);
      }

      this.dialogRevisi = true;
    },

    disableRevisi(item) {
      if (
        item.STATUS_TRANSAKSI_DESKRIPSI_GANJIL == "Revisi Manager" ||
        item.STATUS_TRANSAKSI_DESKRIPSI_GANJIL == "Revisi Verifikasi" ||
        item.STATUS_TRANSAKSI_DESKRIPSI_GANJIL == "Revisi Otorisasi Awal"
      ) {
        if (
          (item.TINGKAT_BEASISWA_GANJIL == "A" ||
            item.TINGKAT_BEASISWA_GANJIL == "B" ||
            item.TINGKAT_BEASISWA_GANJIL == "C") &&
          this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Dibuka"
        ) {
          return false;
        } else if (
          (item.TINGKAT_BEASISWA_GANJIL == "D" ||
            item.TINGKAT_BEASISWA_GANJIL == "E") &&
          this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Dibuka"
        ) {
          return false;
        } else {
          return true;
        }
      } else if (
        item.STATUS_TRANSAKSI_DESKRIPSI_GANJIL == "Revisi Otorisasi Akhir"
      ) {
        if (
          (item.TINGKAT_BEASISWA_GANJIL == "A" ||
            item.TINGKAT_BEASISWA_GANJIL == "B" ||
            item.TINGKAT_BEASISWA_GANJIL == "C") &&
          this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Dibuka"
        ) {
          return false;
        } else if (
          (item.TINGKAT_BEASISWA_GANJIL == "D" ||
            item.TINGKAT_BEASISWA_GANJIL == "E") &&
          this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Dibuka"
        ) {
          return false;
        } else {
          return true;
        }
      }
    },

    cekPDFGanjil(item) {
      // console.log(item);
      this.editedIndex = this.itemsTableRevisi.indexOf(item);
      this.editedItem = Object.assign({}, item);

      let b64 = this.itemsTableDialog.FILEBLOB_RAPORT;
      // console.log(b64);
      const byteCharacters = atob(b64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // console.log(blob);

      const str = this.editedItem.FILEPATH_RAPORT_GANJIL;
      const words = str.split("/");
      // console.log(words[5]);
      let namaFile = words[5];

      this.namaPDFGanjil = namaFile;

      this.filesTranskripGanjil = blob;
    },

    cekPDFGenap(item) {
      // console.log(item);
      this.editedIndex = this.itemsTableRevisi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(editedIndex)
      // console.log(editedItem)

      let b64 = this.itemsTableDialog.FILEBLOB_IPK;
      // console.log(b64);
      const byteCharacters = atob(b64);
      // console.log(byteCharacters);
      const byteNumbers = new Array(byteCharacters.length);
      // console.log(byteNumbers);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // console.log(blob);

      const str = this.editedItem.FILEPATH_IPK_GANJIL;
      const words = str.split("/");
      // console.log(words[5]);
      let namaFile = words[5];

      this.namaPDFGenap = namaFile;

      this.filesTranskripGenap = blob;
    },

    cekPDFKelulusan(item) {
      // console.log(item);
      this.editedIndex = this.itemsTableRevisi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(editedIndex)
      // console.log(editedItem)

      let b64 = this.itemsTableDialog.FILEBLOB_KELULUSAN;
      // console.log(b64);
      if (b64 == null || b64 == "null" || b64 == "") {
        this.filesKelulusan = null;
        this.namaPDFKelulusan = "";
      } else {
        const byteCharacters = atob(b64);
        // console.log(byteCharacters);
        const byteNumbers = new Array(byteCharacters.length);
        // console.log(byteNumbers);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        // console.log(byteArray);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        // console.log(blob);

        const str = this.editedItem.FILEPATH_KELULUSAN_GANJIL;
        const words = str.split("/");
        // console.log(words[5]);
        let namaFile = words[5];

        this.namaPDFKelulusan = namaFile;

        this.filesKelulusan = blob;
      }
    },

    cekPDFIjazah(item) {
      // console.log(item);
      this.editedIndex = this.itemsTableRevisi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(editedIndex)
      // console.log(editedItem)

      let b64 = this.itemsTableDialog.FILEBLOB_IJAZAH;
      // console.log(b64);
      if (b64 == null || b64 == "null" || b64 == "") {
        this.filesIjazah = null;
        this.namaPDFIjazah = "";
      } else {
        const byteCharacters = atob(b64);
        // console.log(byteCharacters);
        const byteNumbers = new Array(byteCharacters.length);
        // console.log(byteNumbers);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        // console.log(byteArray);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        // console.log(blob);

        const str = this.editedItem.FILEPATH_IJAZAH_GANJIL;
        const words = str.split("/");
        // console.log(words[5]);
        let namaFile = words[5];

        this.namaPDFIjazah = namaFile;

        this.filesIjazah = blob;
      }
    },

    cekPDFSuratDiterima(item) {
      // console.log(item);
      this.editedIndex = this.itemsTableRevisi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(editedIndex)
      // console.log(editedItem)

      let b64 = this.itemsTableDialog.FILEBLOB_SURATDITERIMA;
      console.log(b64, "b64");

      if (b64 == null || b64 == "null" || b64 == "") {
        this.filesDiterima = null;
        this.namaPDFSuratDiterima = "";
      } else {
        const byteCharacters = atob(b64);
        // console.log(byteCharacters);
        const byteNumbers = new Array(byteCharacters.length);
        // console.log(byteNumbers);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        // console.log(byteArray);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        // console.log(blob);

        const str = this.editedItem.FILEPATH_SURATDITERIMA_GANJIL;
        const words = str.split("/");
        // console.log(words[5]);
        let namaFile = words[5];

        this.namaPDFSuratDiterima = namaFile;
        this.filesDiterima = blob;
      }
    },

    cekPDFSuratAkreditasi(item) {
      // console.log(item);
      this.editedIndex = this.itemsTableRevisi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(editedIndex)
      // console.log(editedItem)

      let b64 = this.itemsTableDialog.FILEBLOB_SURATAKREDITASI;
      // console.log(b64);
      if (b64 == null || b64 == "null" || b64 == "") {
        this.filesAkreditasiED = null;
        this.namaPDFSuratAkreditasi = "";
      } else {
        const byteCharacters = atob(b64);
        // console.log(byteCharacters);
        const byteNumbers = new Array(byteCharacters.length);
        // console.log(byteNumbers);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        // console.log(byteArray);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        // console.log(blob);

        const str = this.editedItem.FILEPATH_SURATAKREDITASI_GANJIL;
        const words = str.split("/");
        // console.log(words[5]);
        let namaFile = words[5];

        this.namaPDFSuratAkreditasi = namaFile;

        this.filesAkreditasiED = blob;
      }
    },

    cekPDFAkreditasiProdi(item) {
      // console.log(item);
      this.editedIndex = this.itemsTableRevisi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(editedIndex)
      // console.log(editedItem)

      let b64 = this.itemsTableDialog.FILEBLOB_AKREDITASIPROGRAMSTUDI;
      // console.log(b64);
      if (b64 == null || b64 == "null" || b64 == "") {
        this.filesAkreditasiProdi = null;
        this.namaPDFAkreditasiProdi = "";
      } else {
        const byteCharacters = atob(b64);
        // console.log(byteCharacters);
        const byteNumbers = new Array(byteCharacters.length);
        // console.log(byteNumbers);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        // console.log(byteArray);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        // console.log(blob);

        const str = this.editedItem.FILEPATH_AKREDITASIPROGRAMSTUDI_GANJIL;
        const words = str.split("/");
        // console.log(words[5]);
        let namaFile = words[5];

        this.namaPDFAkreditasiProdi = namaFile;

        this.filesAkreditasiProdi = blob;
      }
    },

    cekPDFAktifKuliah(item) {
      // console.log(item);
      this.editedIndex = this.itemsTableRevisi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(editedIndex)
      // console.log(editedItem)

      let b64 = this.itemsTableDialog.FILEBLOB_AKTIFKULIAH;
      // console.log(b64);
      if (b64 == null || b64 == "null" || b64 == "") {
        this.filesAktifKuliah = null;
        this.namaPDFAktifKuliah = "";
      } else {
        const byteCharacters = atob(b64);
        // console.log(byteCharacters);
        const byteNumbers = new Array(byteCharacters.length);
        // console.log(byteNumbers);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        // console.log(byteArray);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        // console.log(blob);

        const str = this.editedItem.FILEPATH_AKTIFKULIAH_GANJIL;
        const words = str.split("/");
        // console.log(words[5]);
        let namaFile = words[5];

        this.namaPDFAktifKuliah = namaFile;

        this.filesAktifKuliah = blob;
      }
    },

    cekPDFPostpone(item) {
      // console.log(item);
      this.editedIndex = this.itemsTableRevisi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(editedIndex)
      // console.log(editedItem)

      let b64 = this.itemsTableDialog.FILEBLOB_SURATPOSTPONE;
      // console.log(b64);
      if (b64 == null || b64 == "null" || b64 == "") {
        this.filesPostpone = null;
        this.namaPDFPostpone = "";
      } else {
        const byteCharacters = atob(b64);
        // console.log(byteCharacters);
        const byteNumbers = new Array(byteCharacters.length);
        // console.log(byteNumbers);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        // console.log(byteArray);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        // console.log(blob);

        const str = this.editedItem.FILEPATH_SURATPOSTPONE_GANJIL;
        const words = str.split("/");
        // console.log(words[5]);
        let namaFile = words[5];

        this.namaPDFPostpone = namaFile;

        this.filesPostpone = blob;
      }
    },

    tingkatPendidikan() {
      const code = {
        nik: this.user.data[0].NikKaryawan,
        status: "read",
        kategori: "",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // 'Access-Control-Allow-Origin': '*',
      // };
      // const axios = require("axios");
      // axios
      //   .post(
      //     this.$baseurl + "beasiswa/insertDataMstPlafondBeasiswa",
      //     code,
      //     { headers }
      //   )
      this.insertDataMstPlafondBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // // console.log(result.data);
            this.itemsTingkatPendidikan = result.data;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    rule(item) {
      // // console.log("test= "+this.penerima)
      if (this.penerima == "Karyawan") {
        return item != "A" && item != "B" && item != "C";
      } else {
        return true;
      }
    },

    updateGanjil() {
      let ganjil = parseFloat(this.nilaiganjil);
      // // console.log(ganjil);
      let genap = parseFloat(this.nilaigenap);
      let total = (ganjil + genap) / 2;
      let fix = total.toFixed(2);
      this.nilaitotal = fix;
    },
    updateGenap() {
      let ganjil = parseFloat(this.nilaiganjil);
      let genap = parseFloat(this.nilaigenap);
      let total = (ganjil + genap) / 2;
      let fix = total.toFixed(2);
      this.nilaitotal = fix;
    },

    updatePengetahuanGanjil() {
      // // console.log(this.inputs)
      let jmltotal = 0;
      // let jmlnilaiketerampilanganjil = 0;
      let hasil = this.inputsganjil;
      let hasiltest = hasil.map((item) => item.pengetahuanganjil);
      // // console.log(hasiltest);
      for (var i = 0; i < hasiltest.length; i++) {
        jmltotal += parseFloat(hasiltest[i]);
      }
      // // console.log(jmltotal / hasiltest.length);
      let rata = jmltotal / hasiltest.length;
      let fixed = rata.toFixed(2);
      this.jmlnilaipengetahuanganjil = fixed;
      let total =
        (parseFloat(this.jmlnilaipengetahuanganjil) +
          parseFloat(this.jmlnilaiketerampilanganjil)) /
        2;
      // // console.log("p "+total)
      let fixedTotal = total.toFixed(2);
      // // console.log("p "+fixedTotal)
      this.rata2ganjil = fixedTotal;
    },
    updateKeterampilanGanjil() {
      let jmltotal = 0;
      // let jmlnilaipengetahuanganjil = 0;
      let hasil = this.inputsganjil;
      let hasiltest = hasil.map((item) => item.keterampilanganjil);
      // // console.log(hasiltest);
      for (var i = 0; i < hasiltest.length; i++) {
        jmltotal += parseFloat(hasiltest[i]);
      }
      // // console.log(jmltotal / hasiltest.length);
      let rata = jmltotal / hasiltest.length;
      let fixed = rata.toFixed(2);
      this.jmlnilaiketerampilanganjil = fixed;

      let total =
        (parseFloat(this.jmlnilaipengetahuanganjil) +
          parseFloat(this.jmlnilaiketerampilanganjil)) /
        2;
      // // console.log(80.00+99.00)
      // // console.log("k "+total)
      let fixedTotal = total.toFixed(2);
      // // console.log("k " +fixedTotal)
      this.rata2ganjil = fixedTotal;
    },

    updatePengetahuanGenap() {
      // // console.log(this.inputs)
      let jmltotal = 0;
      // let jmlnilaiketerampilanganjil = 0;
      let hasil = this.inputsgenap;
      let hasiltest = hasil.map((item) => item.pengetahuangenap);
      // // console.log(hasiltest);
      for (var i = 0; i < hasiltest.length; i++) {
        jmltotal += parseFloat(hasiltest[i]);
      }
      // // console.log(jmltotal / hasiltest.length);
      let rata = jmltotal / hasiltest.length;
      let fixed = rata.toFixed(2);
      this.jmlnilaipengetahuangenap = fixed;
      let total =
        (parseFloat(this.jmlnilaipengetahuangenap) +
          parseFloat(this.jmlnilaiketerampilangenap)) /
        2;
      // // console.log("p "+total)
      let fixedTotal = total.toFixed(2);
      // // console.log("p "+fixedTotal)
      this.rata2genap = fixedTotal;
    },
    updateKeterampilanGenap() {
      let jmltotal = 0;
      // let jmlnilaipengetahuangenap = 0;
      let hasil = this.inputsgenap;
      let hasiltest = hasil.map((item) => item.keterampilangenap);
      // // console.log(hasiltest);
      for (var i = 0; i < hasiltest.length; i++) {
        jmltotal += parseFloat(hasiltest[i]);
      }
      // // console.log(jmltotal / hasiltest.length);
      let rata = jmltotal / hasiltest.length;
      let fixed = rata.toFixed(2);
      this.jmlnilaiketerampilangenap = fixed;

      let total =
        (parseFloat(this.jmlnilaipengetahuangenap) +
          parseFloat(this.jmlnilaiketerampilangenap)) /
        2;
      // // console.log(80.00+99.00)
      // // console.log("k "+total)
      let fixedTotal = total.toFixed(2);
      // // console.log("k " +fixedTotal)
      this.rata2genap = fixedTotal;
    },

    saveganjil() {
      console.log(this.inputsganjil);
      console.log(this.inputsganjil[0].mapelganjil);
      console.log(this.inputsganjil[0].pengetahuanganjil);
      console.log(this.inputsganjil[0].keterampilanganjil);
      let inputsganjil = this.inputsganjil;
      let mapelganjilarray1 = inputsganjil.map((item) => item.mapelganjil);
      let mapelganjilarray = JSON.stringify(mapelganjilarray1);
      // let mapelganjil = JSON.parse(mapelganjil2);
      // console.log(mapelganjilarray);
      // // let strArray = ["q", "w", "w", "w", "e", "i", "u", "r"];
      // let strArray = mapelganjilarray1;
      // let findDuplicates = mapelganjilarray.filter((el) => {
      //     return(mapelganjilarray.indexOf(el.item) != el.index)
      //   });

      // // console.log(findDuplicates(strArray)); // All duplicates
      // // console.log([...new Set(strArray)]); // Unique duplicates

      // const yourArray = [1, 1, 2, 3, 4, 5, 5];
      const yourArray = mapelganjilarray1;

      const yourArrayWithoutDuplicates = [...new Set(yourArray)];

      let duplicates = [...yourArray];
      yourArrayWithoutDuplicates.forEach((item) => {
        const i = duplicates.indexOf(item);
        duplicates = duplicates
          .slice(0, i)
          .concat(duplicates.slice(i + 1, duplicates.length));
      });

      // console.log(duplicates); //[ 1, 5 ]
      if (duplicates.length > 0) {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          text:
            "Mapel yang diinput tidak boleh sama karena data sebelumnya sudah ada",
        });
      } else {
        this.nilaiganjil = this.rata2ganjil;
        this.dialogGanjil = false;
        this.updateGanjil();
      }
    },
    cancelganjil() {
      this.dialogGanjil = false;
      // this.inputs = [];
    },

    savegenap() {
      console.log(this.inputsgenap);
      let inputsgenap = this.inputsgenap;
      let mapelgenaparray1 = inputsgenap.map((item) => item.mapelgenap);
      let mapelgenaparray = JSON.stringify(mapelgenaparray1);
      // let mapelganjil = JSON.parse(mapelganjil2);
      // console.log(mapelgenaparray);
      // // let strArray = ["q", "w", "w", "w", "e", "i", "u", "r"];
      // let strArray = mapelganjilarray1;
      // let findDuplicates = mapelganjilarray.filter((el) => {
      //     return(mapelganjilarray.indexOf(el.item) != el.index)
      //   });

      // // console.log(findDuplicates(strArray)); // All duplicates
      // // console.log([...new Set(strArray)]); // Unique duplicates

      // const yourArray = [1, 1, 2, 3, 4, 5, 5];
      const yourArray = mapelgenaparray1;

      const yourArrayWithoutDuplicates = [...new Set(yourArray)];

      let duplicates = [...yourArray];
      yourArrayWithoutDuplicates.forEach((item) => {
        const i = duplicates.indexOf(item);
        duplicates = duplicates
          .slice(0, i)
          .concat(duplicates.slice(i + 1, duplicates.length));
      });

      // console.log(duplicates); //[ 1, 5 ]
      if (duplicates.length > 0) {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          text:
            "Mapel yang diinput tidak boleh sama karena data sebelumnya sudah ada",
        });
      } else {
        this.nilaigenap = this.rata2genap;
        this.dialogGenap = false;
        this.updateGenap();
      }
    },
    cancelgenap() {
      this.dialogGenap = false;
      // this.inputs = [];
    },

    addGanjil() {
      this.inputsganjil.push({
        mapelganjil: null,
        pengetahuanganjil: null,
        keterampilanganjil: null,
      });
      // // console.log(this.inputs);
    },

    addGenap() {
      this.inputsgenap.push({
        mapelgenap: null,
        pengetahuangenap: null,
        keterampilangenap: null,
      });
      // // console.log(this.inputs);
    },

    removeGanjil(index) {
      this.inputsganjil.splice(index, 1);
      this.updatePengetahuanGanjil();
      this.updateKeterampilanGanjil();
    },

    removeGenap(index) {
      this.inputsgenap.splice(index, 1);
      this.updatePengetahuanGenap();
      this.updateKeterampilanGenap();
    },

    detailGanjil() {
      // const axios = require("axios");
      const code = {
        // nik: sessionStorage.getItem("nik"),
        status: "read",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };

      // // this.btnLoading = true;
      // axios
      //   .post(this.$baseurl + "beasiswa/insertDataMstMaPelBeasiswa", code, {
      //     headers,
      //   })
      this.insertDataMstMaPelBeasiswa(code)
        .then((response) => {
          // // console.log(response.data.SearchWorkerResult.length);
          const result = response.data;

          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.items_mapelganjil = result.data;
            // // console.log(result.data)
            // this.btnLoading = false;
            this.dialogGanjil = true;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.btnLoading = false;
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Karyawan Tidak Ditemukan",
          });
        });

      // this.dialogGanjil = true;
    },
    detailGenap() {
      // const axios = require("axios");
      const code = {
        nik: this.user.data[0].NikKaryawan,
        status: "read",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };

      // // this.btnLoading = true;
      // axios
      //   .post(this.$baseurl + "beasiswa/insertDataMstMaPelBeasiswa", code, {
      //     headers,
      //   })
      this.insertDataMstMaPelBeasiswa(code)
        .then((response) => {
          // // console.log(response.data.SearchWorkerResult.length);
          const result = response.data;

          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.items_mapelgenap = result.data;
            // // console.log(result.data)
            // this.btnLoading = false;
            this.dialogGenap = true;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.btnLoading = false;
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Karyawan Tidak Ditemukan",
          });
        });
    },

    submitRevisi() {
      const max = 4;
      // console.log(this.usulan,"tes")
      if (this.usulan == "Naik Semester") {
        if (parseFloat(this.nilaiganjil) > parseFloat(max)) {
          this.$swal({
            icon: "warning",
            title: "Maaf!",
            text: "Nilai IPK Semester Ganjil Tidak boleh melebihi 4.00",
          });
          return;
        }
        if (parseFloat(this.nilaigenap) > parseFloat(max)) {
          this.$swal({
            icon: "warning",
            title: "Maaf!",
            text: "Nilai IPK Semester Genap Tidak boleh melebihi 4.00",
          });
          return;
        }
      }
      let inputsganjil = this.inputsganjil;
      let mapelganjilarray1 = inputsganjil.map((item) => item.mapelganjil);
      let mapelganjilarray = JSON.stringify(mapelganjilarray1);
      // let mapelganjil = JSON.parse(mapelganjil2);
      // console.log(mapelganjilarray);
      let pengetahuanganjilarray1 = inputsganjil.map(
        (item) => item.pengetahuanganjil
      );
      let pengetahuanganjilarray = JSON.stringify(pengetahuanganjilarray1);
      // console.log(pengetahuanganjilarray);
      let keterampilanganjilarray1 = inputsganjil.map(
        (item) => item.keterampilanganjil
      );
      let keterampilanganjilarray = JSON.stringify(keterampilanganjilarray1);
      // console.log(keterampilanganjilarray);

      let inputsgenap = this.inputsgenap;
      let mapelgenaparray1 = inputsgenap.map((item) => item.mapelgenap);
      let mapelgenaparray = JSON.stringify(mapelgenaparray1);
      // console.log(mapelgenaparray);
      let pengetahuangenaparray1 = inputsgenap.map(
        (item) => item.pengetahuangenap
      );
      let pengetahuangenaparray = JSON.stringify(pengetahuangenaparray1);
      // console.log(pengetahuangenaparray);
      let keterampilangenaparray1 = inputsgenap.map(
        (item) => item.keterampilangenap
      );
      let keterampilangenaparray = JSON.stringify(keterampilangenaparray1);
      // console.log(keterampilangenaparray);

      this.btnLoading = true;
      this.overlayDialog = true;
      // const userRole = sessionStorage.getItem("role");

      const formData = new FormData();
      formData.set("nik", this.user.data[0].NikKaryawan);
      formData.set("notiket", this.notiket);
      formData.set("kategori", "Update");
      formData.set("statustransaksi", this.statustransaksi);
      formData.set("tingkatbeasiswa", this.permohonan);
      formData.set("usulanbeasiswa", this.usulan);
      formData.set("kelas", this.kelas);
      formData.set("periodebulantahun", "");
      formData.set("plafond", this.plafond);
      formData.set("postpone", this.postpone);
      formData.set("nilaiganjil", this.nilaiganjil);
      formData.set("nilaigenap", this.nilaigenap);
      formData.set("nilairata", this.nilaitotal);
      formData.set("penerimabeasiswa", this.penerimabeasiswa);
      formData.set("akreditasikampus", this.akreditasikampus);
      formData.set("akreditasijurusan", this.akreditasijurusan);
      formData.append("fileraport", this.filesTranskripGanjil);
      formData.append("fileipk", this.filesTranskripGenap);
      formData.append("fileijazah", this.filesIjazah);
      formData.append("filesuratditerima", this.filesDiterima);
      formData.append("filekelulusan", this.filesKelulusan);
      formData.append("fileakreditasiprogramstudi", this.filesAkreditasiProdi);
      formData.append("filesuratakreditasi", this.filesAkreditasiED);
      formData.append("filepostpone", this.filesPostpone);
      formData.append("fileaktifkuliah", this.filesAktifKuliah);
      formData.append("idmapelganjil", [mapelganjilarray]);
      formData.append("idmapelgenap", [mapelgenaparray]);
      formData.append("nilaipengetahuanmapelganjil", [pengetahuanganjilarray]);
      formData.append("nilaipengetahuanmapelgenap", [pengetahuangenaparray]);
      formData.append("nilaiketerampilanmapelganjil", [
        keterampilanganjilarray,
      ]);
      formData.append("nilaiketerampilanmapelgenap", [keterampilangenaparray]);
      formData.set("semesterganjil", "Ganjil");
      formData.set("semestergenap", "Genap");

      this.getDataRevisiBeasiswa(formData)
        .then((response) => {
          const hasil = response.data;
          const pesan = hasil.data[0].Pesan
          // console.log(hasil);
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else if (hasil.status == "Success") {
            // this.itemsTableRekap = hasil.data;

            this.$swal({
              icon: "success",
              title: "Sukses!",
              text: "Berhasil Merevisi Pengajuan",
            }).then(() => {
              window.location.reload();
            });

            this.btnLoading = false;
            // this.overlay = false
          } else {
            this.$swal({
              icon: "error",
              title: "Gagal!",
              text: pesan,
            });
            this.btnLoading = false;
            this.overlayDialog = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
          this.btnLoading = false;
          this.overlay = false;
        });
    },

    formatRp(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatDate(val) {
      const MyDate = new Date(val);

      const MyDateString =
        ("0" + MyDate.getDate()).slice(-2) +
        "-" +
        ("0" + (MyDate.getMonth() + 1)).slice(-2) +
        "-" +
        MyDate.getFullYear();
      return MyDateString;
    },

    showDate() {
      this.menu2 = false;
      // console.log(this.dates[0]);
      // console.log(this.dates[1]);
    },

    downloadExcel() {
      // var headings = [
      //   [
      //     "Unit/PT",
      //     "Cabang",
      //     "Direktorat",
      //     "Departemen",
      //     "Jumlah Karyawan",
      //     "Jumlah Karyawan Success",
      //     "% Success",
      //     "Jumlah Karyawan Outstanding",
      //     "% Outstanding",
      //     "Jumlah Karyawan Pending",
      //     "% Pending",
      //     "Jumlah Karyawan Failed",
      //     "% Failed",
      //     "Jumlah Karyawan None",
      //     "% None",
      //   ],
      // ];
      const wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(this.itemsTableMonitoring, {
        origin: "A2",
        // skipHeader: true,
      });
      // XLSX.utils.sheet_add_aoa(ws, headings); //heading: array of arrays

      XLSX.utils.book_append_sheet(wb, ws);

      XLSX.writeFile(wb, "Report Monitoring Pengajuan Beasiswa.xlsx");
    },
  },
};
</script>
