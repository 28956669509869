"use strict"

import firebaseApi from '@/api/firebaseMessage.js'
import store from "../index"

export default {
    actions: {
        messageFirebase(contex, payload) {
            return new Promise((resolve, reject) => {

                firebaseApi.notification(
                    payload
                ).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },

        setDataTokenFirebase(contex, payload) {
            return new Promise((resolve, reject) => {

                firebaseApi.setFirebaseToken(
                    payload
                ).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        getDataTokenFirebase(contex, payload) {
            return new Promise((resolve, reject) => {

                firebaseApi.getFirebaseToken(
                    payload
                ).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        }
    }
}