"use strict"

export default {
    state: {
        currentTokenFirebase: {},

    },
    mutations: {
        SET_TOKENFB(state, currentTokenFirebase) {
            state.currentTokenFirebase = currentTokenFirebase
        }
    },
    actions: {
        setTokenFireBase(context, payload) {
            context.commit("SET_TOKENFB", payload)
                // console.log(payload);
        },
        removeTokenFireBase(context) {
            context.commit("SET_TOKENFB", {})
        }
    },
    getters: {
        currentTokenFirebase: state => state.currentTokenFirebase,
    }
}