import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#4CAF50',
				secondary: '#FFC107',
				anchor: '#D500',
			},
		},
	},
})

export default new Vuetify({});