"use strict"

import axios from "axios"

const GET_DATA_REPORT = process.env.VUE_APP_API_BEASISWA_GET_DATA_REPORT
const GET_FILTER_REPORT = process.env.VUE_APP_API_BEASISWA_GET_FILTER_REPORT
const GET_DATA_KARYAWAN = process.env.VUE_APP_API_BEASISWA_GET_DATA_KARYAWAN
const GET_DATA_USER = process.env.VUE_APP_API_BEASISWA_GET_DATA_USER
const INSERT_DATA_MASTER_PLAFOND = process.env.VUE_APP_API_BEASISWA_INSERT_DATA_MASTER_PLAFOND
const INSERT_DATA_MASTER_USER = process.env.VUE_APP_API_BEASISWA_INSERT_DATA_MASTER_USER
const GET_INSTITUSI_PENDIDIKAN = process.env.VUE_APP_API_BEASISWA_GET_INSTITUSI_PENDIDIKAN
const INSERT_DATA_MASTER_MAPEL = process.env.VUE_APP_API_BEASISWA_INSERT_DATA_MASTER_MAPEL
const INSERT_DATA_PENGAJUAN = process.env.VUE_APP_API_BEASISWA_INSERT_DATA_PENGAJUAN
const GET_DATA_ANAK = process.env.VUE_APP_API_BEASISWA_GET_DATA_ANAK
const APPROVE_PENGAJUAN = process.env.VUE_APP_API_BEASISWA_APPROVE_PENGAJUAN
const GET_DATA_FILE = process.env.VUE_APP_API_BEASISWA_GET_DATA_FILE
const GET_DATA_ROLE = process.env.VUE_APP_API_BEASISWA_GET_DATA_ROLE
const INSERT_DATA_DETAIL_NILAI = process.env.VUE_APP_API_BEASISWA_INSERT_DATA_DETAIL_NILAI
const MUTASI_DATA_USER = process.env.VUE_APP_API_BEASISWA_MUTASI_DATA_USER
const REVISI_PENGAJUAN = process.env.VUE_APP_API_BEASISWA_REVISI_PENGAJUAN

export default {

    //Report Rekap dan Daftar Beasiswa
    getDataReportBeasiswa(data) {
        return axios.post(`${GET_DATA_REPORT}`, data, {
            withCredentials: true
        })
    },
    getFilterReportBeasiswa(data) {
        return axios.post(`${GET_FILTER_REPORT}`, data, {
            withCredentials: true
        })
    },

    //Pengajuan Beasiswa
    getDataKaryawanBeasiswa(data) {
        return axios.post(`${GET_DATA_KARYAWAN}`, data, {
            withCredentials: true
        })
    },
    getDataUserBeasiswa(data) {
        return axios.post(`${GET_DATA_USER}`, data, {
            withCredentials: true
        })
    },
    insertDataMstPlafondBeasiswa(data) {
        return axios.post(`${INSERT_DATA_MASTER_PLAFOND}`, data, {
            withCredentials: true
        })
    },
    insertDataMstUserBeasiswa(data) {
        return axios.post(`${INSERT_DATA_MASTER_USER}`, data, {
            withCredentials: true
        })
    },
    getInstPendidikanBeasiswa(data) {
        return axios.post(`${GET_INSTITUSI_PENDIDIKAN}`, data, {
            withCredentials: true
        })
    },
    insertDataMstMaPelBeasiswa(data) {
        return axios.post(`${INSERT_DATA_MASTER_MAPEL}`, data, {
            withCredentials: true
        })
    },
    insertDataPengajuanBeasiswa(data) {
        return axios.post(`${INSERT_DATA_PENGAJUAN}`, data, {
            withCredentials: true
        })
    },
    getDataAnakBeasiswa(data) {
        return axios.post(`${GET_DATA_ANAK}`, data, {
            withCredentials: true
        })
    },

    //Approval Beasiswa
    approveDataPengajuanBeasiswa(data) {
        return axios.post(`${APPROVE_PENGAJUAN}`, data, {
            withCredentials: true
        })
    },
    getDataFileBlobBeasiswa(data) {
        return axios.post(`${GET_DATA_FILE}`, data, {
            withCredentials: true
        })
    },
    getDataRoleBeasiswa(data) {
        return axios.post(`${GET_DATA_ROLE}`, data, {
            withCredentials: true
        })
    },
    insertDataDetailNilaiBeasiswa(data) {
        return axios.post(`${INSERT_DATA_DETAIL_NILAI}`, data, {
            withCredentials: true
        })
    },
    mutasiDataPejabatBeasiswa(data) {
        return axios.post(`${MUTASI_DATA_USER}`, data, {
            withCredentials: true
        })
    },
    revisiPengajuanBeasiswa(data) {
        return axios.post(`${REVISI_PENGAJUAN}`, data, {
            withCredentials: true
        })
    }
    //
}