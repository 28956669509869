"use strict"

import izinApi from "@/api/izin.js"
import store from "../index"
import { encrypt, decrypt } from '../encrypt';

export default {

    actions: {
        listIzin(context, payload) {
            return new Promise((resolve, reject) => {
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                // let token = store.getters.token
                izinApi.getIzin(payloadEncrypt).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        getProvinsiApi(context, payload) {
            return new Promise((resolve, reject) => {
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                let token = store.getters.token
                izinApi.getIzin(payloadEncrypt, token).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        getKabupatenApi(context, payload) {
            return new Promise((resolve, reject) => {
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                let token = store.getters.token
                izinApi.getIzin(payloadEncrypt, token).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        getFaskesApi(context, payload) {
            return new Promise((resolve, reject) => {
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                let token = store.getters.token
                izinApi.getIzin(payloadEncrypt).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        inputIzin(context, payload) {
            return new Promise((resolve, reject) => {
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                let token = store.getters.token
                izinApi.insertIzin(payloadEncrypt, token).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        inputIzinSakit(context, payload) {
            return new Promise((resolve, reject) => {
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                let token = store.getters.token
                izinApi.insertIzin(payloadEncrypt, token).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
    }
}