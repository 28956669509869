"use strict"

import scholarshipApi from "@/api/scholarship"
import store from "../index"

export default {
    state: {

    },
    mutations: {

    },
    actions: {
        // approveData(context, payload) {
        // 	return new Promise ((resolve, reject) => {
        // 		let token = store.getters.token
        // 		scholarshipApi.approve(payload, token)
        // 		.then(res => {
        // 			const data = res.data
        // 			const token = res.data.token

        // 			context.commit("SET_TOKEN", token)
        // 			resolve(data)
        // 		}).catch(err => {
        // 			reject(err)
        // 		})
        // 	})
        // },
        // getAuthenticated() {
        // 	return new Promise((resolve, reject) => {
        // 		let token = store.getters.token
        // 		authApi.authenticated(token).then(res =>{
        // 			// console.log(res)
        // 			const data = res.data

        // 			store.dispatch('setUser', data)
        // 			resolve(data)
        // 		}).catch(err => {
        // 			reject(err)
        // 		})
        // 	})
        // },

        setToken(context, payload) {
            // cookie.setCookie(payload)
            context.commit("SET_TOKEN", payload)
        },
        removeToken(context) {
            // cookie.removeCookie()
            context.commit("SET_TOKEN", null)
        },

        //Pengajuan Beasiswa

        getDataKaryawanBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.getDataKaryawanBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getDataUserBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.getDataUserBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        insertDataMstPlafondBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.insertDataMstPlafondBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        insertDataMstUserBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.insertDataMstUserBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getInstPendidikanBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.getInstPendidikanBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        insertDataMstMaPelBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.insertDataMstMaPelBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        insertDataPengajuanBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.insertDataPengajuanBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getDataAnakBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.getDataAnakBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },

        //Approval Beasiswa
        approveDataPengajuanBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.approveDataPengajuanBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getDataFileBlobBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.getDataFileBlobBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getDataRoleBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.getDataRoleBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        insertDataDetailNilaiBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.insertDataDetailNilaiBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },

        //Report Rekap dan Daftar Beasiswa
        getDataReportBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.getDataReportBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getFilterReportBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                    // console.log(payload);
                    // console.log(token);
                scholarshipApi.getFilterReportBeasiswa(payload, token)
                    // .then(res => {
                    // 	console.log(res)
                    // 	const data = res.data
                    // 	const token = res.data.token

                // 	// context.commit("SET_TOKEN", token)
                // 	resolve(data)
                // }).catch(err => {
                // 	reject(err)
                // })

                // scholarshipApi.getFilterReportBeasiswa({
                // 	'nik': payload.nik,
                // 	'idpt': payload.idpt,
                // 	'idcabang': payload.idcabang
                // })
                // axios
                // axios.post(this.$baseurl + "beasiswa/getFilterReportBeasiswa/Stag", payload, {
                //   headers,
                // })
                .then(res => {
                    // console.log(res)
                    const data = res
                        // const token = res.data.token

                    // context.commit("SET_TOKEN", token)
                    resolve(data)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getDataMutasiBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.mutasiDataPejabatBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },

        getDataRevisiBeasiswa(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                scholarshipApi.revisiPengajuanBeasiswa(payload, token)
                    .then(res => {
                        const data = res
                            // const token = res.data.token

                        // context.commit("SET_TOKEN", token)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
    },
    getters: {

    }
}