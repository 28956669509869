<template>
  <div>
    <c-toolbar />
    <c-navbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2"
              :loading="vloading">
              <template slot="progress">
                <v-progress-linear
                  color="green"
                  indeterminate
                ></v-progress-linear>
              </template>
              <br />
              <v-row justify="center">
                <v-img src="img/indomaret.png" aspect-ratio="3" max-width="250">
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Master Mata Pelajaran
              </h3>
              <!-- <v-alert class="mx-4" type="info">
                <div>
                  <b>Master mapel beasiswa periode 2021/2022 sudah ditutup</b>
                </div>
              </v-alert> -->

              <div class="pa-1 mx-4">
                <v-data-table
                  :headers="headers"
                  :items="itemsTableMatpel"
                  :search="search"
                  sort-by="nama"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-dialog v-model="dialog" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            New Mata Pelajaran
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="form">
                            <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                      v-model="editedItem.NAMA_MAPEL"
                                      label="Nama Mata Pelajaran"
                                      :rules="rulesMapel"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="close">
                                Cancel
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                                v-show="valid"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>

                      <!-- jarak judul -->
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <!-- search -->
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <!-- <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">
                      Refresh
                    </v-btn>
                  </template> -->
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
// import Sidebar from "@/components/Sidebar.vue";
// import Toolbar from "@/components/Toolbar.vue";
import CNavbar from "../../components/CNavbar.vue";
import CToolbar from "../../components/CToolbar.vue";
import { mapGetters, mapActions } from "vuex";
const axios = require("axios");

export default {
  name: "Home",
  components: {
    CNavbar,
    CToolbar,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: null,
    headers: [
      { text: "No", value: "NOMOR_URUT" },
      { text: "Nama Mata Pelajaran", value: "NAMA_MAPEL" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemsTableMatpel: [],
    // itemsTingkatPendidikan: [],
    tingkatpendidikan: null,
    rulesNama: [(v) => !!v || "Nama harus diisi"],
    rulesMapel: [
      // (v) => !v || v.length <= 2 || "Jumlah Melebihi 2 Karakter!",
      (v) => !!v || "Nama Mata Pelajaran harus diisi",
    ],
    editedIndex: -1,
    editedItem: {
      ID_MAPEL: "",
      NAMA_MAPEL: "",
    },
    defaultItem: {
      ID_MAPEL: "",
      NAMA_MAPEL: "",
    },
    vloading:false,
  }),

  // created() {
  //   // this.itemTingkatPendidikan();
  //   this.initialize();
  // },
  computed: {
    ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      token: "token",
      // getDrawer: "drawer",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? "New Mata Pelajaran"
        : "Edit Mata Pelajaran";
    },
    valid() {
      // let id_tp = this.editedItem.ID_TINGKATPENDIDIKAN;
      let mapel = this.editedItem.NAMA_MAPEL;
      return mapel != "";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  // created() {
  //   // this.initialize();
  // },

  mounted() {
    this.TOKEN = this.token;
    this.initialize();
    this.vloading = true
  },

  methods: {
    ...mapActions({
            insertDataMstMaPelBeasiswa:"insertDataMstMaPelBeasiswa",
        }),

    initialize() {
      const code = {
        nik: this.user.data[0].NikKaryawan,
        status: "read",
      };
      // const varToken = sessionStorage.getItem("token");
      // // console.log("token: "+this.TOKEN);
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // 'Access-Control-Allow-Origin': '*',
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/insertDataMstMaPelBeasiswa", code, {
      //     headers,
      //   })
      this.insertDataMstMaPelBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
              this.vloading = false
            });
          } else {
            // // console.log(result.message);
            this.itemsTableMatpel = result.data;
            this.vloading = false
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            this.vloading = false
            // this.snackbar=true;
          }
        });
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    isNumber(n) {
      let char = String.fromCharCode(n.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else n.preventDefault(); // If not match, don't add to input text
    },

    editItem(item) {
      this.editedIndex = this.itemsTableMatpel.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      // console.log(this.editedItem);
    },

    deleteItem(item) {
      this.$swal({
        icon: "warning",
        title: "Apakah Anda Yakin?",
        text: "Untuk Menghapus Mata Pelajaran",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Delete`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.editedIndex = this.itemsTableMatpel.indexOf(item);
          this.editedItem = Object.assign({}, item);
          // // console.log(this.editedItem);
          const ID_Mapel = this.editedItem.ID_MAPEL;
          const NIK = this.user.data[0].NikKaryawan;
          const deleteMapel = {
            nik: NIK,
            status: "delete",
            idmapel: ID_Mapel,
          };
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   Authorization: "Bearer" + this.TOKEN,
          //   // NIK: this.user.NikKaryawan,
          // };
          // axios
          //   .post(
          //     this.$baseurl + "beasiswa/insertDataMstMaPelBeasiswa",
          //     deleteMapel,
          //     {
          //       headers,
          //     }
          //   )
          this.insertDataMstMaPelBeasiswa(deleteMapel)
            .then((response) => {
              // // console.log(response.data.message.message);
              if (response.data.status == "Token is Expired") {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (response.data.status == "Success") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: response.data.data[0].Pesan,
                });
                this.initialize();
                this.deleteItemConfirm();
              } else {
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menghapus",
                });
              }
            })
            .catch((error) => {
              // console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menghapus",
              });
            });
        }
      });
    },

    deleteItemConfirm() {
      this.itemsTableMatpel.splice(this.editedIndex, 1);
      this.close();
    },

    close() {
      this.dialog = false;
      // this.$refs.form.resetValidation()
      // this.$refs.form.reset();
      this.editedItem.NAMA_MAPEL = "";
      // this.editedItem.PLAFOND = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onlyNumber($event) {
      //// console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      const ID_Mapel = this.editedItem.ID_MAPEL;
      const Mapel = this.editedItem.NAMA_MAPEL;
      // const ID_Plafond = this.editedItem.ID_PLAFOND;
      const NIK = this.user.data[0].NikKaryawan;
      // console.log(this.editedItem);
      if (this.editedIndex > -1) {
        // edit
        this.$swal({
          icon: "info",
          title: "Apakah Anda Yakin?",
          text: "Untuk Mengubah Mata Pelajaran",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          confirmButtonText: `Yes`,
        }).then((response) => {
          if (response.isConfirmed) {
            const editMapel = {
              nik: NIK,
              idmapel: ID_Mapel,
              namamapel: Mapel,
              status: "update",
            };
            // // console.log(editPlafond );
            // const varToken = sessionStorage.getItem("token");
            // const headers = {
            //   Authorization: "Bearer" + this.TOKEN,
            //   // NIK: this.user.NikKaryawan,
            // };
            // const axios = require("axios");
            // axios
            //   .post(
            //     this.$baseurl + "beasiswa/insertDataMstMaPelBeasiswa",
            //     editMapel,
            //     {
            //       headers,
            //     }
            //   )
            this.insertDataMstMaPelBeasiswa(editMapel)
              .then((response) => {
                // // console.log(response.data.message.message);
                if (response.data.status == "Token is Expired") {
                  this.$swal({
                    icon: "info",
                    title: "Info!",
                    text: "Session Anda Telah Habis",
                  }).then(() => {
                    sessionStorage.clear();
                    this.$router.push({ name: "Login" });
                  });
                } else if (response.data.status == "Success") {
                  this.$swal({
                    icon: "success",
                    title: "Berhasil",
                    text: response.data.data[0].Pesan,
                  });
                  this.initialize();
                } else {
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Mengubah, " + response.data.message,
                  });
                }
              })
              .catch((error) => {
                // console.log(error);
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Mengubah",
                });
              });
          }
        });
      } else {
        // add new
        // this.desserts.push(this.editedItem);
        this.$swal({
          icon: "info",
          title: "Apakah Anda Yakin?",
          text: "Untuk Menambah Mata Pelajaran",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          confirmButtonText: `Yes`,
        }).then((response) => {
          if (response.isConfirmed) {
            const addMapel = {
              nik: NIK,
              namamapel: Mapel,
              status: "create",
            };
            // // console.log(editVaksinasi);
            // const varToken = sessionStorage.getItem("token");
            // const headers = {
            //   Authorization: "Bearer" + this.TOKEN,
            //   // NIK: this.user.NikKaryawan,
            // };
            // const axios = require("axios");
            // axios
            //   .post(
            //     this.$baseurl + "beasiswa/insertDataMstMaPelBeasiswa",
            //     addMapel,
            //     {
            //       headers,
            //     }
            //   )
            this.insertDataMstMaPelBeasiswa(addMapel)
              .then((response) => {
                // console.log(response.data);
                if (response.data.status == "Token is Expired") {
                  this.$swal({
                    icon: "info",
                    title: "Info!",
                    text: "Session Anda Telah Habis",
                  }).then(() => {
                    sessionStorage.clear();
                    this.$router.push({ name: "Login" });
                  });
                } else if (response.data.status == "Success") {
                  this.$swal({
                    icon: "success",
                    title: "Berhasil",
                    text: response.data.data[0].Pesan,
                  });
                  this.initialize();
                } else {
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Menambahkan, " + response.data.message,
                  });
                }
              })
              .catch((error) => {
                // console.log(error);
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menambahkan",
                });
              });
          }
        });
      }
      this.close();
    },
  },
};
</script>
