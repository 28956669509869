import axios from "axios"

const PREFIX = process.env.VUE_APP_API_FIREBASE
const AUTH = process.env.VUE_APP_API_FIREBASE_AUTH

export default {
    notification(data) {
        axios.post("https://fcm.googleapis.com/fcm/send",
            data, {
                headers: {
                    Authorization: `${AUTH}`,
                    "Content-Type": 'application/json'
                }
                // withCredentials: true,
            })
    },

    setFirebaseToken(data) {
        return axios.post(`${PREFIX}`, data)
    },

    getFirebaseToken(data) {
        return axios.post(`${PREFIX}`, data)
    }
}