<template>
  <div>
    <c-toolbar />
    <c-navbar />
    <v-main>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2">
              <v-row justify="center">
                <v-img src="img/indomaret.png" aspect-ratio="3" max-width="250">
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Pengajuan Bantuan Pendidikan
              </h3>
              <!-- <v-alert class="mx-4" type="info">
                <div>
                  <b
                    >Info pembatasan penginputan per jenjang pendidikan, yaitu: </b
                  ><br />
                  <b>-Jenjang SD/SMP/SMA maksimal input tanggal 29 Juli 2022.</b
                  ><br />
                  <b
                    >-Jenjang Akademi/Universitas maksimal input tanggal 30
                    September 2022.</b
                  >
                </div>
              </v-alert> -->
              <template>
                <v-simple-table dense class="pa-4 mx-3">
                  <template v-slot:default>
                    <v-dialog
                      v-model="dialogPasangan"
                      max-width="650px"
                      persistent
                    >
                      <v-card>
                        <v-card-title class="headline">Question</v-card-title>
                        <v-card-text>
                          <v-spacer></v-spacer>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                Apakah Suami/Istri anda bekerja di Indomaret
                                Group?
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <v-radio-group v-model="choice" row>
                                  <v-radio label="Ya" value="Ya"></v-radio>
                                  <v-radio
                                    label="Tidak"
                                    value="Tidak"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-row>
                            <v-row v-show="choice == 'Ya'">
                              <v-col cols="12" sm="9" md="9">
                                <v-text-field
                                  v-model="NIK_NamaKaryawan"
                                  label="NIK / Nama Pasangan (Min 6 Karakter)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  @click="listKaryawan"
                                  :loading="btnLoading"
                                >
                                  Cari
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row v-show="choice == 'Ya'">
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  disabled
                                  v-model="NIK_Pasangan"
                                  label="NIK Pasangan"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  disabled
                                  v-model="Nama_Pasangan"
                                  label="Nama Pasangan"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <!-- <v-row v-show="choice == 'Ya'">
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="NIK_Pasangan"
                                  label="Input NIK Pasangan"
                                  v-on:keypress="isNumber($event)"
                                  :counter="10"
                                  :maxlength="10"
                                ></v-text-field>
                              </v-col>
                            </v-row> -->
                          </v-container>
                          <v-spacer></v-spacer>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="confirmPasangan"
                            v-show="validPasangan && validPasanganNIK"
                          >
                            Confirm
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogMgr" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >List Manager</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-data-table
                            :headers="headers_kary"
                            :items="itemsapprovalmgr"
                            class="elevation-1"
                          >
                            <template v-slot:[`item.detail`]="{ item }">
                              <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                @click="pilihMgr(item)"
                              >
                                Pilih
                              </v-btn>
                            </template>
                          </v-data-table>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogVerif" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >List Verifikasi</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-data-table
                            :headers="headers_kary"
                            :items="itemsverifikasi"
                            class="elevation-1"
                          >
                            <template v-slot:[`item.detail`]="{ item }">
                              <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                @click="pilihVerif(item)"
                              >
                                Pilih
                              </v-btn>
                            </template>
                          </v-data-table>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogOtoAwal" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >List Otorisasi Awal</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-data-table
                            :headers="headers_kary"
                            :items="itemsotorisasiawal"
                            class="elevation-1"
                          >
                            <template v-slot:[`item.detail`]="{ item }">
                              <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                @click="pilihOtoAwal(item)"
                              >
                                Pilih
                              </v-btn>
                            </template>
                          </v-data-table>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogOtoFinal" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >List Karyawan</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-data-table
                            :headers="headers_kary"
                            :items="itemsotorisasifinal"
                            class="elevation-1"
                          >
                            <template v-slot:[`item.detail`]="{ item }">
                              <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                @click="pilihOtoFinal(item)"
                              >
                                Pilih
                              </v-btn>
                            </template>
                          </v-data-table>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogKary" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >List Karyawan</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-data-table
                            :headers="headers_kary"
                            :items="items_kary"
                            class="elevation-1"
                          >
                            <template v-slot:[`item.detail`]="{ item }">
                              <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                @click="pilihKary(item)"
                              >
                                Pilih
                              </v-btn>
                            </template>
                          </v-data-table>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogSekolah" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >List Sekolah</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-data-table
                            :headers="headers_list"
                            :items="items_list"
                            class="elevation-1"
                          >
                            <template v-slot:[`item.detail`]="{ item }">
                              <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                @click="pilihSekolah(item)"
                              >
                                Pilih
                              </v-btn>
                            </template>
                          </v-data-table>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogJurusan" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >List Jurusan</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-data-table
                            :headers="headers_jurusan"
                            :items="items_jurusan"
                            class="elevation-1"
                          >
                            <template v-slot:[`item.detail`]="{ item }">
                              <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                @click="pilihJurusan(item)"
                              >
                                Pilih
                              </v-btn>
                            </template>
                          </v-data-table>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog
                      v-model="dialogGanjil"
                      max-width="800px"
                      persistent
                    >
                      <v-card>
                        <v-card-title class="headline"
                          >Detail Nilai Mata Pelajaran Semester
                          Ganjil</v-card-title
                        >
                        <v-card-text>
                          <v-alert class="mx-4" type="info">
                            <div>
                              Mohon input mata pelajaran sesuai urutan pada
                              lembar nilai di raport
                            </div>
                            <div>
                              Contoh pengisian: <b>70</b> dari skala 100
                            </div>
                          </v-alert>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <h4>Mata Pelajaran</h4>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <h4>Nilai Pengetahuan</h4>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <h4>Nilai Keterampilan</h4>
                              </v-col>
                            </v-row>
                            <v-row v-for="(input, k) in inputsganjil" :key="k">
                              <v-col cols="12" sm="4" md="4">
                                <v-autocomplete
                                  solo
                                  v-model="input.mapelganjil"
                                  :items="items_mapelganjil"
                                  item-text="NAMA_MAPEL"
                                  item-value="ID_MAPEL"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="1" md="1">
                                <v-btn
                                  class="mx-2"
                                  fab
                                  dark
                                  small
                                  color="primary"
                                  v-show="k || (!k && inputsganjil.length > 1)"
                                  @click="removeGanjil(k)"
                                >
                                  <v-icon dark>
                                    mdi-minus
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="1" md="1">
                                <v-btn
                                  class="mx-2"
                                  fab
                                  dark
                                  small
                                  color="primary"
                                  v-show="k == inputsganjil.length - 1"
                                  @click="addGanjil(k)"
                                >
                                  <v-icon dark>
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  solo
                                  v-model="input.pengetahuanganjil"
                                  v-on:keypress="isNumber($event)"
                                  @change="updatePengetahuanGanjil"
                                  :rules="inputRules"
                                  :maxlength="3"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  solo
                                  v-model="input.keterampilanganjil"
                                  v-on:keypress="isNumber($event)"
                                  @change="updateKeterampilanGanjil"
                                  :rules="inputRules"
                                  :maxlength="3"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <h4 style="text-align:right">
                                  Jumlah Rata-Rata Nilai Mata Pelajaran
                                </h4>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  solo
                                  disabled
                                  placeholder="Hasil"
                                  v-model="jmlnilaipengetahuanganjil"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  solo
                                  disabled
                                  placeholder="Hasil"
                                  v-model="jmlnilaiketerampilanganjil"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="9" md="9">
                                <h4 style="text-align:right">
                                  Rata-Rata Nilai Mata Pelajaran
                                </h4>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  solo
                                  v-model="rata2ganjil"
                                  disabled
                                  placeholder="Hasil"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="cancelganjil"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="saveganjil"
                            v-show="validGanjil"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      v-model="dialogGenap"
                      max-width="800px"
                      persistent
                    >
                      <v-card>
                        <v-card-title class="headline"
                          >Detail Nilai Mata Pelajaran Semester
                          Genap</v-card-title
                        >
                        <v-card-text>
                          <v-alert class="mx-4" type="info">
                            <div>
                              Mohon input mata pelajaran sesuai urutan pada
                              lembar nilai di raport
                            </div>
                            <div>
                              Contoh pengisian: <b>70</b> dari skala 100
                            </div>
                          </v-alert>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <h4>Mata Pelajaran</h4>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <h4>Nilai Pengetahuan</h4>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <h4>Nilai Keterampilan</h4>
                              </v-col>
                            </v-row>
                            <v-row v-for="(input, k) in inputsgenap" :key="k">
                              <v-col cols="12" sm="4" md="4">
                                <v-autocomplete
                                  solo
                                  v-model="input.mapelgenap"
                                  :items="items_mapelgenap"
                                  item-text="NAMA_MAPEL"
                                  item-value="ID_MAPEL"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="1" md="1">
                                <v-btn
                                  class="mx-2"
                                  fab
                                  dark
                                  small
                                  color="primary"
                                  v-show="k || (!k && inputsgenap.length > 1)"
                                  @click="removeGenap(k)"
                                >
                                  <v-icon dark>
                                    mdi-minus
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="1" md="1">
                                <v-btn
                                  class="mx-2"
                                  fab
                                  dark
                                  small
                                  color="primary"
                                  v-show="k == inputsgenap.length - 1"
                                  @click="addGenap(k)"
                                >
                                  <v-icon dark>
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  solo
                                  v-model="input.pengetahuangenap"
                                  v-on:keypress="isNumber($event)"
                                  @change="updatePengetahuanGenap"
                                  :rules="inputRules"
                                  :maxlength="3"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  solo
                                  v-model="input.keterampilangenap"
                                  v-on:keypress="isNumber($event)"
                                  @change="updateKeterampilanGenap"
                                  :rules="inputRules"
                                  :maxlength="3"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <h4 style="text-align:right">
                                  Jumlah Rata-Rata Nilai Mata Pelajaran
                                </h4>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  solo
                                  disabled
                                  placeholder="Hasil"
                                  v-model="jmlnilaipengetahuangenap"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  solo
                                  disabled
                                  placeholder="Hasil"
                                  v-model="jmlnilaiketerampilangenap"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="9" md="9">
                                <h4 style="text-align:right">
                                  Rata-Rata Nilai Mata Pelajaran
                                </h4>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  solo
                                  v-model="rata2genap"
                                  disabled
                                  placeholder="Hasil"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="cancelgenap"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="savegenap"
                            v-show="validGenap"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <tbody>
                      <!-- <tr v-for="item in desserts" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.calories }}</td>
                      </tr> -->
                      <tr>
                        <td style="width:15%">NIK</td>
                        <td style="width:2%">:</td>
                        <td colspan="7">{{ nik }}</td>
                      </tr>
                      <tr>
                        <td>Nama</td>
                        <td>:</td>
                        <td colspan="7">{{ nama }}</td>
                      </tr>
                      <tr>
                        <td>Unit</td>
                        <td>:</td>
                        <td colspan="7">{{ nama_pt }}</td>
                      </tr>
                      <tr>
                        <td>Cabang</td>
                        <td>:</td>
                        <td colspan="7">{{ nama_cabang }}</td>
                      </tr>
                      <tr>
                        <td>Bagian</td>
                        <td>:</td>
                        <td colspan="7">{{ nama_bagian }}</td>
                      </tr>
                      <tr>
                        <td>Jabatan</td>
                        <td>:</td>
                        <td colspan="7">{{ jabatan }}</td>
                      </tr>
                      <tr>
                        <td>Job Class</td>
                        <td>:</td>
                        <td colspan="7">{{ job_class }}</td>
                      </tr>
                      <tr>
                        <td>Periode Bantuan Pendidikan</td>
                        <td>:</td>
                        <td colspan="7">{{ periode }}</td>
                      </tr>
                      <tr>
                        <td>Penerima Bantuan Pendidikan</td>
                        <td>:</td>
                        <td colspan="2">
                          <v-radio-group
                            small
                            v-model="penerima"
                            row
                            :disabled="
                              status == 'Failed' || statusPasangan == '1'
                            "
                            @change="changePenerima"
                          >
                            <v-radio
                              label="Karyawan"
                              value="Karyawan"
                              :disabled="disabledKary"
                            ></v-radio>
                            <v-radio
                              label="Anak Karyawan"
                              value="Anak Karyawan"
                              @click="getAnak()"
                            ></v-radio>
                          </v-radio-group>
                          <!-- <v-radio-group
                            small
                            v-model="penerima"
                            row
                            
                            @change="changePenerima"
                          >
                            <v-radio
                              label="Karyawan"
                              value="Karyawan"
                            ></v-radio>
                            <v-radio
                              label="Anak Karyawan"
                              value="Anak Karyawan"
                              @click="getAnak()"
                            ></v-radio>
                          </v-radio-group> -->
                        </td>
                        <td
                          v-show="penerima == 'Anak Karyawan'"
                          style="width: 3%"
                        ></td>
                        <td v-show="penerima == 'Anak Karyawan'">
                          Nama Anak :
                        </td>
                        <td v-show="penerima == 'Anak Karyawan'" colspan="2">
                          <v-autocomplete
                            v-model="namaanak"
                            :items="itemsAnak"
                            dense
                            item-value="NAME"
                            item-text="NAME"
                            label="Silakan Pilih"
                            solo
                          ></v-autocomplete>
                        </td>
                        <td v-show="penerima == 'Anak Karyawan'">
                          <div class="caption red--text">
                            *Pastikan nama anak sudah sesuai dengan dokumen yang
                            diupload, apabila belum sesuai harap melakukan
                            pengkinian data terlebih dahulu
                          </div>
                        </td>
                      </tr>
                      <tr v-show="penerima">
                        <td>
                          Tingkat Pendidikan
                        </td>
                        <td>:</td>
                        <td colspan="7">
                          <v-radio-group
                            v-model="permohonan"
                            row
                            @change="getBeasiswa()"
                          >
                            <v-radio
                              v-for="item in itemsTingkatPendidikan"
                              :key="item.ID_TINGKATPENDIDIKAN"
                              :label="`${item.TINGKAT_PENDIDIKAN}`"
                              :value="item.ID_TINGKATPENDIDIKAN"
                              v-show="rule(item.ID_TINGKATPENDIDIKAN)"
                            ></v-radio>
                          </v-radio-group>
                        </td>
                      </tr>
                      <tr v-show="penerima">
                        <td>Usulan Bantuan Pendidikan</td>
                        <td>:</td>
                        <td colspan="2">
                          <v-radio-group
                            v-model="usulan"
                            row
                            @change="changeUsulan"
                          >
                            <v-radio
                              v-if="permohonan == 'D' || permohonan == 'E'"
                              label="Naik Semester"
                              value="Naik Semester"
                            ></v-radio>
                            <v-radio
                              v-else
                              label="Naik Kelas"
                              value="Naik Kelas"
                            ></v-radio>
                            <v-radio
                              v-show="penerima == 'Anak Karyawan'"
                              label="Naik Jenjang Pendidikan"
                              value="Naik Jenjang Pendidikan"
                            ></v-radio>
                          </v-radio-group>
                        </td>
                        <td
                          v-show="
                            usulan == 'Naik Jenjang Pendidikan' &&
                              permohonan != 'A'
                          "
                          colspan="2"
                        >
                          Pendidikan Sebelumnya :
                        </td>
                        <td
                          v-show="
                            usulan == 'Naik Jenjang Pendidikan' &&
                              permohonan != 'A'
                          "
                          colspan="3"
                        >
                          <v-radio-group
                            v-model="pendidikanSebelumnya"
                            @change="changePendidikanSebelumnya"
                            inline
                          >
                            <v-radio
                              v-if="permohonan == 'E'"
                              label="Akademi (D3)"
                              value="D"
                            ></v-radio>
                            <v-radio
                              v-if="permohonan == 'E'"
                              label="SMA / SMK"
                              value="C"
                            ></v-radio>
                            <v-radio
                              v-if="permohonan == 'D'"
                              label="SMA / SMK"
                              value="C"
                            ></v-radio>
                            <v-radio
                              v-if="permohonan == 'C'"
                              label="SMP"
                              value="B"
                            ></v-radio>
                            <v-radio
                              v-if="permohonan == 'B'"
                              label="SD"
                              value="A"
                            ></v-radio>
                          </v-radio-group>
                        </td>
                      </tr>
                      <tr v-show="penerima">
                        <td v-if="permohonan == 'D' || permohonan == 'E'">
                          Nama Kampus
                        </td>
                        <td v-else>Nama Sekolah</td>
                        <td>:</td>
                        <td colspan="3">
                          <!-- <v-autocomplete
                            v-model="namasekolah"
                            :items="items"
                            dense
                            label="Silakan Pilih"
                            solo
                          ></v-autocomplete> -->
                          <v-text-field
                            v-model="carisekolah"
                            label="Cari NPSN/Sekolah min 3 Karakter"
                            solo
                          >
                          </v-text-field>
                        </td>
                        <td>
                          <v-btn
                            color="primary"
                            block
                            @click="listSekolah"
                            :loading="btnLoading"
                            >Cari</v-btn
                          >
                        </td>
                        <td colspan="5">
                          <div class="caption">
                            Untuk cek NPSN bisa klik link berikut
                            <a
                              href="https://referensi.data.kemdikbud.go.id/"
                              target="_blank"
                              style="color:green"
                              >https://referensi.data.kemdikbud.go.id/</a
                            >
                          </div>
                        </td>
                      </tr>
                      <tr
                        v-show="
                          (penerima && permohonan == 'D') ||
                            (penerima && permohonan == 'E')
                        "
                      >
                        <td v-if="permohonan == 'D' || permohonan == 'E'">
                          Jurusan
                        </td>
                        <td>:</td>
                        <td colspan="3">
                          <v-text-field
                            v-model="carijurusan"
                            label="Cari Jurusan min 3 Karakter"
                            solo
                          >
                          </v-text-field>
                        </td>
                        <td>
                          <v-btn
                            color="primary"
                            block
                            @click="listJurusan"
                            :loading="btnLoading"
                            >Cari</v-btn
                          >
                        </td>
                      </tr>
                      <tr
                        v-show="
                          (permohonan == 'D' || permohonan == 'E') &&
                            pendidikanSebelumnya != 'C'
                        "
                      >
                        <td>Pernah Postpone Kuliah?</td>
                        <td>:</td>
                        <td colspan="2">
                          <v-radio-group
                            v-model="postpone"
                            row
                            @change="changePostpone"
                          >
                            <v-radio label="Ya, pernah" value="Ya"></v-radio>
                            <v-radio label="Tidak" value="Tidak"></v-radio>
                          </v-radio-group>
                        </td>
                        <td colspan="6" v-show="postpone == 'Ya'">
                          <div class="h1 red--text" v-if="permohonan == 'D'">
                            Upload Surat Keterangan Postpone dari Akademi
                          </div>
                          <div
                            class="h1 red--text"
                            v-else-if="permohonan == 'E'"
                          >
                            Upload Surat Keterangan Postpone dari Universitas
                          </div>
                          <v-file-input
                            :rules="rulesUpload"
                            truncate-length="50"
                            accept="application/pdf"
                            label="Attach File"
                            show-size
                            ref="fileInput"
                            v-model="filesPostpone"
                          ></v-file-input>
                          <div class="caption red--text">
                            *Max File 1MB!
                          </div>
                          <div class="caption">Format: pdf</div>
                        </td>
                      </tr>
                      <tr v-show="postpone == 'Ya'">
                        <td style="width: 2%">Berapa Semester Postpone?</td>
                        <td>:</td>
                        <td v-if="permohonan == 'D'">
                          <v-select
                            v-model="semesterPostpone"
                            :items="items_postponeAkademi"
                            dense
                            label="Pilih"
                            solo
                          ></v-select>
                        </td>
                        <td v-else>
                          <v-select
                            v-model="semesterPostpone"
                            :items="items_postponeUniv"
                            dense
                            label="Pilih"
                            solo
                          ></v-select>
                        </td>
                        <td style="width:12%">Postpone di Semester Berapa?</td>
                        <td>:</td>
                        <td colspan="3">
                          <v-text-field
                            v-model="textPostpone"
                            v-on:keypress="validasiPostpone($event)"
                            :rules="inputRulesPostpone"
                            dense
                            solo
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr v-show="penerima">
                        <td v-if="permohonan == 'D' || permohonan == 'E'">
                          Bantuan Pendidikan ini diajukan untuk Semester
                        </td>
                        <td v-else>
                          Bantuan Pendidikan ini diajukan untuk Kelas
                        </td>
                        <td>:</td>
                        <td>
                          <v-select
                            v-show="permohonan == 'A'"
                            v-model="grade"
                            :items="filterSD"
                            dense
                            label="Pilih"
                            solo
                          ></v-select>
                          <v-select
                            v-show="permohonan == 'B'"
                            v-model="grade"
                            :items="filterSMP"
                            dense
                            label="Pilih"
                            solo
                          ></v-select>
                          <v-select
                            v-show="permohonan == 'C'"
                            v-model="grade"
                            :items="filterSMA"
                            dense
                            label="Pilih"
                            solo
                          ></v-select>
                          <v-select
                            v-show="
                              permohonan == 'D' && penerima == 'Anak Karyawan'
                            "
                            v-model="grade"
                            :items="filterAkademi"
                            dense
                            label="Pilih"
                            solo
                          ></v-select>
                          <v-select
                            v-show="permohonan == 'D' && penerima == 'Karyawan'"
                            v-model="grade"
                            :items="items_kelasAkademiKary"
                            dense
                            label="Pilih"
                            solo
                          ></v-select>
                          <v-select
                            v-show="permohonan == 'E' && penerima == 'Karyawan'"
                            v-model="grade"
                            :items="items_kelasUniversitasKary"
                            dense
                            label="Pilih"
                            solo
                          ></v-select>
                          <v-select
                            v-show="
                              permohonan == 'E' && penerima == 'Anak Karyawan'
                            "
                            v-model="grade"
                            :items="filterUniversitas"
                            dense
                            label="Pilih"
                            solo
                          ></v-select>
                        </td>
                      </tr>

                      <tr v-show="permohonan == 'D' || permohonan == 'E'">
                        <td style="width: 2%">Akreditasi Kampus</td>
                        <td>:</td>
                        <td>
                          <v-select
                            v-model="akreditasikampus"
                            :items="itemsAkreditasiKampus"
                            dense
                            label="Pilih"
                            solo
                          ></v-select>
                        </td>
                        <td style="width:12%">Akreditasi Jurusan</td>
                        <td>:</td>
                        <td>
                          <v-select
                            v-model="akreditasijurusan"
                            :items="itemsAkreditasiJurusan"
                            dense
                            label="Pilih"
                            solo
                          ></v-select>
                        </td>
                      </tr>
                      <tr v-show="penerima">
                        <td
                          v-if="
                            permohonan == 'A' ||
                              permohonan == 'B' ||
                              permohonan == 'C'
                          "
                        >
                          Tahun Masuk Sekolah
                        </td>
                        <td v-else>Tahun Masuk Kuliah</td>
                        <td>:</td>
                        <td>
                          <!-- <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="tahunmasuk"
                                label="Pilih Tahun"
                                readonly
                                solo
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              type="year"
                              v-model="year"
                              @input="okDate"
                            ></v-date-picker>
                          </v-menu> -->
                          <!-- <v-select
                            v-model="tahunmasuk"
                            :items="itemsTahunMasuk"
                            dense
                            label="Pilih"
                            solo
                          ></v-select> -->
                          <!-- <v-date-picker v-model="tahunmasuk" type="month"></v-date-picker> -->
                          <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="tahunmasuk"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="tahunmasukFormatted"
                                label="Pilih Bulan dan Tahun"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="tahunmasuk"
                              type="month"
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(tahunmasuk)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </td>
                        <td>Besaran Bantuan Pendidikan</td>
                        <td>:</td>
                        <td colspan="4">Rp. {{ besaranbeasiswa }}/bulan</td>
                      </tr>
                      <tr v-show="penerima">
                        <td>Tahun Ajaran Baru</td>
                        <td>:</td>
                        <td colspan="7">
                          {{ new Date().getFullYear() }}
                        </td>
                      </tr>
                      <tr v-show="penerima">
                        <td
                          v-if="
                            (permohonan == 'D' && usulan == 'Naik Semester') ||
                              (permohonan == 'E' &&
                                usulan == 'Naik Semester') ||
                              (permohonan == 'E' &&
                                usulan == 'Naik Jenjang Pendidikan' &&
                                pendidikanSebelumnya == 'D')
                          "
                        >
                          IP Semester Ganjil
                          <div class="caption">
                            Contoh pengisian: <b>3.00</b> dari skala 4
                          </div>
                        </td>
                        <td v-else>Nilai Rata2 Semester Ganjil</td>
                        <td>:</td>
                        <td>
                          <v-text-field
                            v-model="nilaiganjil"
                            label="Nilai"
                            solo
                            :disabled="
                              permohonan == 'A' ||
                                permohonan == 'B' ||
                                permohonan == 'C' ||
                                (permohonan == 'D' &&
                                  usulan == 'Naik Jenjang Pendidikan') ||
                                (permohonan == 'E' &&
                                  usulan == 'Naik Jenjang Pendidikan' &&
                                  pendidikanSebelumnya == 'C') ||
                                permohonan == null
                            "
                            v-on:keypress="isNumber($event)"
                            :maxlength="4"
                            :rules="inputRules"
                            @change="updateGanjil"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-btn
                            small
                            color="primary"
                            block
                            :disabled="
                              (permohonan == 'D' &&
                                usulan == 'Naik Semester') ||
                                (permohonan == 'E' &&
                                  usulan == 'Naik Semester') ||
                                (permohonan == 'E' &&
                                  usulan == 'Naik Jenjang Pendidikan' &&
                                  pendidikanSebelumnya == 'D') ||
                                permohonan == null
                            "
                            @click="detailGanjil"
                            >Input Nilai Detail</v-btn
                          >
                        </td>
                        <td colspan="6">
                          <div
                            class="h1 red--text"
                            v-if="
                              (permohonan == 'D' &&
                                usulan == 'Naik Semester') ||
                                (permohonan == 'E' &&
                                  usulan == 'Naik Semester') ||
                                (permohonan == 'E' &&
                                  usulan == 'Naik Jenjang Pendidikan' &&
                                  pendidikanSebelumnya == 'D')
                            "
                          >
                            Nilai IP Semester Ganjil
                          </div>
                          <div class="red--text" v-else>
                            Nilai Raport Semester Ganjil
                          </div>
                          <v-file-input
                            :rules="rulesUpload"
                            truncate-length="50"
                            accept="application/pdf"
                            label="Attach File"
                            show-size
                            ref="fileInput"
                            v-model="filesTranskripGanjil"
                          ></v-file-input>
                          <div class="caption red--text">
                            *Max File 1MB!
                          </div>
                          <div class="caption">Format: pdf</div>
                        </td>
                      </tr>
                      <tr v-show="penerima">
                        <td
                          v-if="
                            (permohonan == 'D' && usulan == 'Naik Semester') ||
                              (permohonan == 'E' &&
                                usulan == 'Naik Semester') ||
                              (permohonan == 'E' &&
                                usulan == 'Naik Jenjang Pendidikan' &&
                                pendidikanSebelumnya == 'D')
                          "
                        >
                          Nilai IP Semester Genap
                          <div class="caption">
                            Contoh pengisian: <b>3.00</b> dari skala 4
                          </div>
                        </td>
                        <td v-else>Nilai Raport Semester Genap</td>
                        <td>:</td>
                        <td>
                          <v-text-field
                            v-model="nilaigenap"
                            label="Nilai"
                            solo
                            :disabled="
                              permohonan == 'A' ||
                                permohonan == 'B' ||
                                permohonan == 'C' ||
                                (permohonan == 'D' &&
                                  usulan == 'Naik Jenjang Pendidikan') ||
                                (permohonan == 'E' &&
                                  usulan == 'Naik Jenjang Pendidikan' &&
                                  pendidikanSebelumnya == 'C') ||
                                permohonan == null
                            "
                            v-on:keypress="isNumber($event)"
                            :maxlength="4"
                            :rules="inputRules"
                            @change="updateGenap"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-btn
                            small
                            color="primary"
                            block
                            :disabled="
                              (permohonan == 'D' &&
                                usulan == 'Naik Semester') ||
                                (permohonan == 'E' &&
                                  usulan == 'Naik Semester') ||
                                (permohonan == 'E' &&
                                  usulan == 'Naik Jenjang Pendidikan' &&
                                  pendidikanSebelumnya == 'D') ||
                                permohonan == null
                            "
                            @click="detailGenap"
                            >Input Nilai Detail</v-btn
                          >
                        </td>
                        <td colspan="6">
                          <div
                            class="h1 red--text"
                            v-if="
                              (permohonan == 'D' &&
                                usulan == 'Naik Semester') ||
                                (permohonan == 'E' &&
                                  usulan == 'Naik Semester') ||
                                (permohonan == 'E' &&
                                  usulan == 'Naik Jenjang Pendidikan' &&
                                  pendidikanSebelumnya == 'D')
                            "
                          >
                            Nilai IP Semester Genap
                          </div>
                          <div class="red--text" v-else>
                            Nilai Raport Semester Genap
                          </div>
                          <v-file-input
                            :rules="rulesUpload"
                            truncate-length="50"
                            accept="application/pdf"
                            label="Attach File"
                            show-size
                            ref="fileInput"
                            v-model="filesTranskripGenap"
                          ></v-file-input>
                          <div class="caption red--text">
                            *Max File 1MB!
                          </div>
                          <div class="caption">Format: pdf</div>
                        </td>
                      </tr>
                      <tr v-show="penerima">
                        <td
                          v-if="
                            (permohonan == 'D' && usulan == 'Naik Semester') ||
                              (permohonan == 'E' &&
                                usulan == 'Naik Semester') ||
                              (permohonan == 'E' &&
                                usulan == 'Naik Jenjang Pendidikan' &&
                                pendidikanSebelumnya == 'D')
                          "
                        >
                          IP Semester Total
                        </td>
                        <td v-else>Nilai Rata2 Semester Total</td>
                        <td>:</td>
                        <td>
                          <v-text-field
                            v-model="nilaitotal"
                            label="Hasil"
                            solo
                            disabled
                          ></v-text-field>
                        </td>
                        <td></td>
                        <td
                          colspan="6"
                          v-show="usulan == 'Naik Jenjang Pendidikan'"
                        >
                          <div class="h1 red--text" v-if="permohonan == 'D'">
                            Upload Surat Keterangan Asli diterima di Akademi
                          </div>
                          <div
                            class="h1 red--text"
                            v-else-if="permohonan == 'E'"
                          >
                            Upload Surat Keterangan Asli diterima di Universitas
                          </div>
                          <div class="h1 red--text" v-else>
                            Upload Surat Keterangan Asli diterima di Sekolah
                          </div>
                          <v-file-input
                            :rules="rulesUpload"
                            truncate-length="50"
                            accept="application/pdf"
                            label="Attach File"
                            show-size
                            ref="fileInput"
                            v-model="filesDiterima"
                          ></v-file-input>
                          <div class="caption red--text">
                            *Max File 1MB!
                          </div>
                          <div class="caption">Format: pdf</div>
                        </td>
                      </tr>
                      <tr v-show="usulan == 'Naik Jenjang Pendidikan'">
                        <td
                          colspan="4"
                          v-show="usulan == 'Naik Jenjang Pendidikan'"
                        >
                          <div class="h1 red--text">
                            Upload Surat Kelulusan
                          </div>
                          <v-file-input
                            :rules="rulesUpload"
                            truncate-length="50"
                            accept="application/pdf"
                            label="Attach File"
                            show-size
                            ref="fileInput"
                            v-model="filesKelulusan"
                          ></v-file-input>
                          <div class="caption red--text">
                            *Max File 1MB!
                          </div>
                          <div class="caption">Format: pdf</div>
                        </td>
                        <td
                          colspan="5"
                          v-show="usulan == 'Naik Jenjang Pendidikan'"
                        >
                          <div class="h1 red--text">
                            Upload Nilai Ijazah
                          </div>
                          <v-file-input
                            :rules="rulesUpload"
                            truncate-length="50"
                            accept="application/pdf"
                            label="Attach File"
                            show-size
                            ref="fileInput"
                            v-model="filesIjazah"
                          ></v-file-input>
                          <div class="caption red--text">
                            *Max File 1MB!
                          </div>
                          <div class="caption">Format: pdf</div>
                        </td>
                      </tr>
                      <tr v-show="permohonan == 'D' || permohonan == 'E'">
                        <td
                          colspan="4"
                          v-show="permohonan == 'D' || permohonan == 'E'"
                        >
                          <div class="h1 red--text">
                            Upload Surat Akreditasi Program Studi
                          </div>
                          <v-file-input
                            :rules="rulesUpload"
                            truncate-length="50"
                            accept="application/pdf"
                            label="Attach File"
                            show-size
                            ref="fileInput"
                            v-model="filesAkreditasiProdi"
                          ></v-file-input>
                          <div class="caption red--text">
                            *Max File 1MB!
                          </div>
                          <div class="caption">Format: pdf</div>
                        </td>
                        <td
                          colspan="5"
                          v-show="permohonan == 'D' || permohonan == 'E'"
                        >
                          <div class="h1 red--text" v-if="permohonan == 'D'">
                            Upload Surat Akreditasi Akademi
                          </div>
                          <div class="h1 red--text" v-else>
                            Upload Surat Akreditasi Universitas
                          </div>
                          <v-file-input
                            :rules="rulesUpload"
                            truncate-length="50"
                            accept="application/pdf"
                            label="Attach File"
                            show-size
                            ref="fileInput"
                            v-model="filesAkreditasiED"
                          ></v-file-input>
                          <div class="caption red--text">
                            *Max File 1MB!
                          </div>
                          <div class="caption">Format: pdf</div>
                        </td>
                      </tr>
                      <tr v-show="usulan == 'Naik Semester'">
                        <td colspan="4">
                          <div v-if="permohonan == 'D'" class="h1 red--text">
                            Upload Surat Aktif Kuliah dari Akademi
                          </div>
                          <div
                            v-else-if="permohonan == 'E'"
                            class="h1 red--text"
                          >
                            Upload Surat Aktif Kuliah dari Universitas
                          </div>
                          <v-file-input
                            :rules="rulesUpload"
                            truncate-length="50"
                            accept="application/pdf"
                            label="Attach File"
                            show-size
                            ref="fileInput"
                            v-model="filesAktifKuliah"
                          ></v-file-input>
                          <div class="caption red--text">
                            *Max File 1MB!
                          </div>
                          <div class="caption">Format: pdf</div>
                        </td>
                      </tr>
                      <tr v-show="penerima">
                        <td colspan="2">
                          Approval Manager
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-combobox
                                v-bind="attrs"
                                v-on="on"
                                ref="nik"
                                v-model="approvemgr"
                                dense
                                outlined
                                :items="itemsapprovalmgr"
                                item-text="Karyawan"
                                item-value="Karyawan"
                                hide-details="auto"
                                @update:search-input="setNikManager"
                                @change="getVerifikasi"
                              >
                              </v-combobox>
                            </template>
                            <!-- <template v-slot:activator="{ on, attrs }">
                              <v-autocomplete
                                v-model="approvemgr"
                                v-bind="attrs"
                                v-on="on"
                                @update:search-input="onSearchInput"
                                dense
                                outlined
                                :items="itemsapprovalmgr"
                                item-text="Karyawan"
                                item-value="Karyawan"
                                hide-details="auto"
                              ></v-autocomplete>
                            </template> -->
                            <span
                              >Silakan input minimal 5 karakter untuk
                              pencarian</span
                            >
                          </v-tooltip>
                        </td>
                        <td style="width:17%">
                          Verifikasi
                          <!-- <template>
                              <v-autocomplete
                                v-model="verifikasi"
                                :items="itemsverifikasi"
                                item-text="Karyawan"
                                item-value="Karyawan"
                                dense
                                solo
                                outlined
                                @change="getOtorisasiAwal"
                              ></v-autocomplete>
                          </template> -->
                          <v-autocomplete
                            v-model="verifikasi"
                            dense
                            outlined
                            :items="itemsverifikasi"
                            item-text="Karyawan"
                            item-value="Karyawan"
                            hide-details="auto"
                            @change="getOtorisasiAwal"
                          >
                          </v-autocomplete>
                        </td>
                        <td style="width:18%">
                          Otorisasi Awal
                          <v-autocomplete
                            v-model="otorisasiawal"
                            dense
                            outlined
                            :items="itemsotorisasiawal"
                            item-text="Karyawan"
                            item-value="Karyawan"
                            hide-details="auto"
                            @change="getOtorisasiFinal"
                          >
                          </v-autocomplete>
                        </td>
                        <td colspan="2" style="width:17%">
                          Otorisasi Final HR HO
                          <v-autocomplete
                            v-model="otorisasifinal"
                            dense
                            outlined
                            :items="itemsotorisasifinal"
                            item-text="Karyawan"
                            item-value="Karyawan"
                            hide-details="auto"
                          >
                          </v-autocomplete>
                        </td>
                        <td colspan="3"></td>
                      </tr>
                      <tr>
                        <center>
                          <v-btn
                            color="primary"
                            block
                            :loading="btnLoading"
                            @click="submit()"
                            v-show="
                              validInput &&
                                validAnakKaryawan &&
                                validKampus &&
                                validNaikJenjangPendidikan &&
                                validNaikSemester &&
                                validPendidikanSebelumnya &&
                                validPostpone &&
                                isItemSelected &&
                                isAutocompleteValid
                            "
                            >submit</v-btn
                          >
                        </center>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
// import Sidebar from "@/components/Sidebar.vue";
// import Toolbar from "@/components/Toolbar.vue";
import CNavbar from "../../components/CNavbar.vue";
import CToolbar from "../../components/CToolbar.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    CNavbar,
    CToolbar,
  },

  mounted() {
    // this.nama = sessionStorage.getItem("nama");
    // this.nik = sessionStorage.getItem("nik");
    // this.nama_pt = sessionStorage.getItem("nama_pt");
    // this.nama_cabang = sessionStorage.getItem("nama_cabang");
    // this.nama_bagian = sessionStorage.getItem("nama_bagian");
    // this.jabatan = sessionStorage.getItem("jabatan");
    // this.job_class = sessionStorage.getItem("job_class");
    this.nik = this.user.data[0].NikKaryawan;
    this.nama = this.user.data[0].NamaKaryawan;
    this.nama_pt = this.user.data[0].PT;
    this.nama_cabang = this.user.data[0].Lokasi;
    this.nama_bagian = this.user.data[0].Departemen;
    this.jabatan = this.user.data[0].Jabatan;
    this.job_class = this.user.data[0].JobClass;
    this.notifBeasiswaSekolah = this.user.data[0].NotifBeasiswaSekolah;
    this.notifBeasiswaInstitut = this.user.data[0].NotifBeasiswaInstitut;
    console.log("validessSekolah: " + this.user.data[0].NotifBeasiswaSekolah);
    console.log("validessInstitut: " + this.user.data[0].NotifBeasiswaInstitut);

    this.TOKEN = this.token;
    this.initialize();
    this.tingkatPendidikan();
    // this.getApproveManager();
    // this.getVerifikasi();
    // this.getOtorisasiAwal();
    // this.getOtorisasiFinal();
    // this.getBeasiswa();

    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },

  computed: {
    ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      token: "token",
      // getDrawer: "drawer",
    }),

    drawer: {
      get() {
        return this.getDrawer;
      },
      set(value) {
        this.setDrawer(value);
      },
    },

    tahunmasukFormatted() {
      return this.formatMonth(this.tahunmasuk);
    },

    filterApproveManager() {
      if (this.approvemgr.length >= 6) {
        this.getApproveManager;
      }
    },

    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    validPasangan() {
      return this.choice != "";
    },
    validPasanganNIK() {
      if (this.choice == "Ya") {
        return this.NIK_Pasangan != "" && this.NIK_Pasangan.length == 10;
      } else return true;
    },

    validGanjil() {
      // // console.log(this.inputs);
      let hasiltest = this.inputsganjil;
      let flag = true;
      for (var i = 0; i < hasiltest.length; i++) {
        let idmapel = hasiltest[i].mapelganjil;
        let pengetahuan = hasiltest[i].pengetahuanganjil;
        let keterampilan = hasiltest[i].keterampilanganjil;
        // // console.log(idmapel);
        flag = idmapel != null && pengetahuan != null && keterampilan != null;
        if (!flag) {
          break;
        }
      }
      return flag;
    },

    validGenap() {
      // // console.log(this.inputs);
      let hasiltest = this.inputsgenap;
      let flag = true;
      for (var i = 0; i < hasiltest.length; i++) {
        let idmapel = hasiltest[i].mapelgenap;
        let pengetahuan = hasiltest[i].pengetahuangenap;
        let keterampilan = hasiltest[i].keterampilangenap;
        // // console.log(pengetahuan)
        flag = idmapel != null && pengetahuan != null && keterampilan != null;
        if (!flag) {
          break;
        }
      }
      return flag;
    },

    validBerhasil() {
      return (
        this.date != null &&
        this.idsertifikasi != "" &&
        // this.idsertifikasi.length <= 26 &&
        this.idsertifikasi.length == 24 &&
        this.jenisvaksinasi != null &&
        this.realisasi != null &&
        // this.JawabanHasilVaksin == "1" &&
        this.files != null &&
        this.files.size / (1024 * 1024) <= 1
        // this.files.type == "image/png"
      );
    },
    validInput() {
      return (
        this.nik != "" &&
        this.periode != "" &&
        this.penerima != "" &&
        // this.namaanak != "" &&
        this.permohonan != null &&
        this.usulan != null &&
        // this.pendidikanSebelumnya != null &&
        this.carisekolah != null &&
        this.idsekolah != null &&
        this.grade != null &&
        this.nominal != "" &&
        // this.akreditasikampus != "" &&
        // this.akreditasijurusan != "" &&
        this.tahunmasuk != "" &&
        // this.tahunajaranbaru != "" &&
        this.nilaiganjil != "" &&
        this.nilaigenap != "" &&
        this.nilaitotal != "" &&
        // this.filesDiterima != null &&
        // this.filesDiterima.size <= 1000000 &&
        // this.filesIjazah != null &&
        // this.filesIjazah.size <= 1000000 &&
        this.filesTranskripGanjil != null &&
        this.filesTranskripGanjil.size / (1024 * 1024) <= 1 &&
        this.filesTranskripGenap != null &&
        this.filesTranskripGenap.size / (1024 * 1024) <= 1 &&
        // this.approvemgr != "" &&
        this.verifikasi != "" &&
        this.otorisasiawal != "" &&
        this.otorisasifinal != ""
      );
    },

    // validNaikJenjangKampus() {
    //   if (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "D" ||this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "E") {
    //     return (
    //       this.filesAkreditasiProdi != null &&
    //       this.filesAkreditasiProdi.size <= 1000000 &&
    //       this.filesAkreditasiED != null &&
    //       this.filesAkreditasiED.size <= 1000000

    //     );
    //   } else {
    //     return true
    //   }
    // },

    validNaikJenjangPendidikan() {
      if (
        (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "A") ||
        (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "B") ||
        (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "C")
      ) {
        return (
          this.filesDiterima != null &&
          this.filesDiterima.size / (1024 * 1024) <= 1 &&
          this.filesIjazah != null &&
          this.filesIjazah.size / (1024 * 1024) <= 1 &&
          this.filesKelulusan != null &&
          this.filesKelulusan.size / (1024 * 1024) <= 1
        );
      } else if (
        (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "D") ||
        (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan == "E")
      ) {
        return (
          this.filesDiterima != null &&
          this.filesDiterima.size / (1024 * 1024) <= 1 &&
          this.filesIjazah != null &&
          this.filesIjazah.size / (1024 * 1024) <= 1 &&
          this.filesKelulusan != null &&
          this.filesKelulusan.size / (1024 * 1024) <= 1 &&
          this.filesAkreditasiProdi != null &&
          this.filesAkreditasiProdi.size / (1024 * 1024) <= 1 &&
          this.filesAkreditasiED != null &&
          this.filesAkreditasiED.size / (1024 * 1024) <= 1
        );
      } else {
        return true;
      }
    },

    validNaikSemester() {
      if (
        (this.usulan == "Naik Semester" && this.permohonan == "D") ||
        (this.usulan == "Naik Semester" && this.permohonan == "E")
      ) {
        return (
          this.filesAktifKuliah != null &&
          this.filesAktifKuliah.size / (1024 * 1024) <= 1
        );
      } else {
        return true;
      }
    },

    validPendidikanSebelumnya() {
      if (this.usulan == "Naik Jenjang Pendidikan" && this.permohonan != "A") {
        return this.pendidikanSebelumnya != null;
      } else {
        return true;
      }
    },

    validPostpone() {
      if (this.postpone == "Ya") {
        return (
          this.semesterPostpone != "" &&
          this.textPostpone != "" &&
          this.filesPostpone != null &&
          this.filesPostpone.size / (1024 * 1024) <= 1
        );
      } else {
        return true;
      }
    },

    validAnakKaryawan() {
      if (this.penerima == "Anak Karyawan") {
        return this.penerima == "Anak Karyawan" && this.namaanak != "";
      } else {
        return true;
      }
    },

    validKampus() {
      if (
        (this.permohonan == "D" || this.permohonan == "E") &&
        this.pendidikanSebelumnya != "C"
      ) {
        return (
          (this.postpone != null) & (this.carijurusan != "") &&
          this.idjurusan != null &&
          this.akreditasikampus != "" &&
          this.akreditasijurusan != "" &&
          this.filesAkreditasiProdi != null &&
          this.filesAkreditasiProdi.size / (1024 * 1024) <= 1 &&
          this.filesAkreditasiED != null &&
          this.filesAkreditasiED.size / (1024 * 1024) <= 1
        );
      } else if (
        (this.permohonan == "D" || this.permohonan == "E") &&
        this.pendidikanSebelumnya == "C"
      ) {
        return (
          this.carijurusan != "" &&
          this.idjurusan != null &&
          this.akreditasikampus != "" &&
          this.akreditasijurusan != "" &&
          this.filesAkreditasiProdi != null &&
          this.filesAkreditasiProdi.size / (1024 * 1024) <= 1 &&
          this.filesAkreditasiED != null &&
          this.filesAkreditasiED.size / (1024 * 1024) <= 1
        );
      } else {
        return true;
      }
    },

    isItemSelected() {
      let show = false
      let temp = this.approvemgr
      let splitTemp = temp.split(' - ')
      console.log(splitTemp, 'split temp')
      console.log(splitTemp.length, 'split temp length')
      if(splitTemp.length > 1)
        show = true
      else
        show = false
      if(this.approvemgr != "" && show == true)
        return true
      else
        return false
      // return this.approvemgr != null
    },

    isAutocompleteValid() {
      return this.approvemgr && this.approvemgr.length >= 5;
    },

    disabledKary() {
      if (
        this.notifBeasiswaSekolah == "Pengajuan SD,SMP,SMA Dibuka" &&
        this.notifBeasiswaInstitut ==
          "Pengajuan Akademi dan Universitas Ditutup"
      ) {
        // console.log('Sekolah buka');
        return this.notifBeasiswaSekolah == "Pengajuan SD,SMP,SMA Dibuka";
      } else if (
        this.notifBeasiswaSekolah == "Pengajuan SD,SMP,SMA Dibuka" &&
        this.notifBeasiswaInstitut == "Pengajuan Akademi dan Universitas Dibuka"
      ) {
        // console.log('Sekolah Univ Buka');
        return (
          this.notifBeasiswaSekolah == "Pengajuan SD,SMP,SMA Ditutup" &&
          this.notifBeasiswaInstitut ==
            "Pengajuan Akademi dan Universitas Ditutup"
        );
      } else if (
        this.notifBeasiswaInstitut ==
          "Pengajuan Akademi dan Universitas Dibuka" &&
        this.notifBeasiswaSekolah == "Pengajuan SD,SMP,SMA Ditutup"
      ) {
        // console.log('Univ Buka');
        return (
          this.notifBeasiswaInstitut ==
          "Pengajuan Akademi dan Universitas Ditutup"
        );
      } else {
        // console.log('Sekolah dan Univ tutup');
        return (
          this.notifBeasiswaSekolah == "Pengajuan SD,SMP,SMA Dibuka" &&
          this.notifBeasiswaInstitut ==
            "Pengajuan Akademi dan Universitas Dibuka"
        );
      }
    },

    filterSD() {
      if (this.usulan == "Naik Jenjang Pendidikan") {
        return this.items_kelasSD.filter((item) => item === "1");
      } else {
        return this.items_kelasSD;
      }
    },

    filterSMP() {
      if (this.usulan == "Naik Jenjang Pendidikan") {
        return this.items_kelasSMP.filter((item) => item === "7");
      } else {
        return this.items_kelasSMP;
      }
    },

    filterSMA() {
      if (this.usulan == "Naik Jenjang Pendidikan") {
        return this.items_kelasSMA.filter((item) => item === "10");
      } else {
        return this.items_kelasSMA;
      }
    },

    filterAkademi() {
      if (this.usulan == "Naik Jenjang Pendidikan") {
        return this.items_kelasAkademi.filter((item) => item === "1");
      } else {
        return this.items_kelasAkademi;
      }
    },

    filterUniversitas() {
      if (this.usulan == "Naik Jenjang Pendidikan") {
        return this.items_kelasUniversitas.filter((item) => item === "1");
      } else {
        return this.items_kelasUniversitas;
      }
    },
  },

  // watch: {
  //   searchApproveMgr(val) {
  //     console.log(val + "mgr");
  //     if (val.length >= 6) {
  //       alert("tes");
  //       this.getApproveManager();
  //     }
  //   },
  //   search(newVal) {
  //     if (newVal && newVal.length >= 5) {
  //       this.debouncedSearch();
  //     } else {
  //       this.itemsapprovalmgr = [];
  //     }
  //   },
  //   searchOtorisasiAwal(val) {
  //     console.log(val + "otoawal");
  //     if (val.length >= 6) {
  //       this.getOtorisasiAwal();
  //     }
  //   },
  //   searchOtorisasiAkhir(val) {
  //     console.log(val + "otoakhir");
  //     if (val.length >= 6) {
  //       this.getOtorisasiFinal();
  //     }
  //   },
  // },

  data: () => ({
    btnLoading: false,
    overlay: false,
    align: "end",
    nik: "",
    nama: "",
    nama_pt: "",
    nama_cabang: "",
    nama_bagian: "",
    jabatan: "",
    job_class: "",
    periode: "",
    row: null,
    choice: "",
    NIK_Pasangan: "",
    Nama_Pasangan: "",
    permohonan: null,
    usulan: null,
    pendidikanSebelumnya: null,
    namasekolah: null,
    idsekolah: null,
    carisekolah: null,
    namajurusan: null,
    idjurusan: null,
    carijurusan: "",
    notifBeasiswaSekolah: "",
    notifBeasiswaInstitut: "",
    postpone: null,
    semesterPostpone: "",
    textPostpone: "",
    penerima: null,
    items: ["foo", "bar", "fizz", "buzz"],
    itemsAnak: [],
    itemsAkreditasiKampus: ["A", "B", "C", "-"],
    itemsAkreditasiJurusan: ["A", "B", "C", "-"],
    itemsTingkatPendidikan: [],
    itemsTahunMasuk: [],
    items_mapelganjil: [],
    items_mapelgenap: [],
    items_kelasSD: ["1", "2", "3", "4", "5", "6"],
    items_kelasSMP: ["7", "8", "9"],
    items_kelasSMA: ["10", "11", "12", "13"],
    items_kelasAkademi: ["1", "2", "3", "4", "5", "6"],
    items_kelasAkademiKary: ["3", "4", "5", "6"],
    items_kelasUniversitas: ["1", "2", "3", "4", "5", "6", "7", "8"],
    items_kelasUniversitasKary: ["3", "4", "5", "6", "7", "8"],
    items_postponeUniv: ["1", "2", "3", "4"],
    items_postponeAkademi: ["1", "2"],
    grade: "",
    namaanak: "",
    akreditasikampus: "",
    akreditasijurusan: "",
    tahunmasuk: "",
    besaranbeasiswa: "",
    nominal: "",
    status: "",
    statusPasangan: "",
    NIK_NamaKaryawan: "",
    approvemgr: "",
    verifikasi: "",
    otorisasiawal: "",
    otorisasifinal: "",
    dialogPasangan: false,
    dialogSekolah: false,
    dialogJurusan: false,
    dialogKary: false,
    dialogMgr: false,
    dialogVerif: false,
    dialogOtoAwal: false,
    dialogOtoFinal: false,
    dialogGanjil: false,
    dialogGenap: false,
    loading: false,
    files: null,
    filesIjazah: null,
    filesTranskripGanjil: null,
    filesTranskripGenap: null,
    filesDiterima: null,
    filesPostpone: null,
    filesAkreditasiProdi: null,
    filesAkreditasiED: null,
    filesKelulusan: null,
    filesAktifKuliah: null,
    date: null,
    year: null,
    headers_list: [
      { text: "NPSN", value: "EDUC_ID" },
      { text: "Sekolah", value: "EDUC_NAME" },
      { text: "Actions", value: "detail", sortable: false },
    ],
    headers_jurusan: [
      { text: "ID", value: "EDUC_ID" },
      { text: "Jurusan", value: "EDUC_NAME" },
      { text: "Actions", value: "detail", sortable: false },
    ],
    headers_kary: [
      { text: "Karyawan", value: "Karyawan" },
      // { text: "NAMA", value: "NAMA" },
      { text: "Actions", value: "detail", sortable: false },
    ],
    items_list: [],
    items_kary: [],
    itemsapprovalmgr: [],
    itemsverifikasi: [],
    itemsotorisasiawal: [],
    itemsotorisasifinal: [],
    search: "",
    nilaiganjil: "",
    nilaigenap: "",
    nilaitotal: "",
    jmlnilaiketerampilanganjil: "",
    jmlnilaipengetahuanganjil: "",
    rata2ganjil: "",
    jmlnilaiketerampilangenap: "",
    jmlnilaipengetahuangenap: "",
    rata2genap: "",
    mapelganjilarray: [],
    mapelgenaparray: [],
    pengetahuanganjilarray: [],
    pengetahuangenaparray: [],
    keterampilanganjilarray: [],
    keterampilangenaparray: [],
    // addRealisasiNone: "",
    dialog: false,
    desserts: [
      {
        name: "Frozen Yogurt",
        calories: 159,
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
      },
    ],
    inputsganjil: [
      {
        mapelganjil: null,
        pengetahuanganjil: null,
        keterampilanganjil: null,
      },
    ],

    inputsgenap: [
      {
        mapelgenap: null,
        pengetahuangenap: null,
        keterampilangenap: null,
      },
    ],

    nikRules: [
      (v) => !!v || "NIK harus diisi",
      (v) => (v && v.length == 10) || "NIK Harus 10 Karakter",
    ],
    namaRules: [(v) => !!v || "Nama harus diisi"],
    ktpRules: [(v) => !!v || "No KTP harus diisi"],
    noHpRules: [(v) => !!v || "No HP harus diisi"],
    namaAtasanRules: [(v) => !!v || "Nama Atasan HP harus diisi"],
    nikAtasanRules: [
      (v) => !!v || "NIK Atasan HP harus diisi",
      // (v) => (v && v.length == 10) || "NIK Harus 10 Karakter",
    ],
    noHpAtasanRules: [(v) => !!v || "No HP Atasan harus diisi"],
    tglRules: [(v) => !!v || "Tanggal harus diisi"],
    hasilRules: [(v) => !!v || "Rencana vaksin harus diisi"],
    inputRules: [
      (value) =>
        /^[0-9.]*$/.test(value) || "Hanya angka dan titik yang diperbolehkan.",
    ],
    inputRulesPostpone: [
      (value) =>
        /^[0-9,]*$/.test(value) || "Hanya angka dan koma yang diperbolehkan.",
    ],
    rulesUpload: [
      (v) => !v || v.size / (1024 * 1024) <= 1 || "Ukuran file maks 1 MB!",
      (v) => !!v || "File harus diisi",
      (v) => !v || v.type == "application/pdf" || "File harus pdf",
    ],
    rulesSertifikasi: [
      (v) => !!v || "ID Sertifikat harus diisi",
      // (v) => !v || v.length <= 26 || "Jumlah Karakter Melebihi 26!",
      (v) => !v || v.length == 24 || "ID Sertifikasi Harus 24 Karakter!",
      // (v) => !v || /(?=.*[A-Za-z0-9])/.test(v) || "Harus Mengandung Huruf dan Angka",
    ],
    rulesAlasan: [
      (v) => !!v || "Alasan harus diisi",
      (v) => !v || v.length <= 100 || "Jumlah Karakter Melebihi 100!",
    ],

    menu: false,
    modal: false,
    menu2: false,
    valid: true,
    itemsVaksin: [],
    itemJawab: [
      {
        id: "1",
        name: "Berhasil",
      },
      {
        id: "2",
        name: "Gagal",
      },
    ],
    // itemRealisasi: [
    //   {
    //     id: "1",
    //     name: "1",
    //   },
    //   {
    //     id: "2",
    //     name: "2",
    //   },
    // ],

    itemRealisasi: [],

    itemJadwalUlang: [
      {
        id: "None",
        name: "Jadwal Ulang",
      },
      {
        id: "Failed",
        name: "Tidak Jadwal Ulang",
      },
    ],
  }),

  // created() {
  //   this.initialize();
  // },

  // created() {
  //   this.debouncedSearch = this.$_.debounce(this.getApproveManager, 500);
  // },

  // watch: {
  //   menu(val) {
  //     val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
  //   },
  // },

  //  watch: {
  //   search: function(newVal) {
  //     if (newVal && newVal.length >= 5) {
  //       this.debouncedSearch();
  //     } else {
  //       this.itemsapprovalmgr = [];
  //     }
  //   },
  // },

  methods: {
    ...mapActions({
      getDataUserBeasiswa: "getDataUserBeasiswa",
      getDataKaryawanBeasiswa: "getDataKaryawanBeasiswa",
      insertDataMstPlafondBeasiswa: "insertDataMstPlafondBeasiswa",
      insertDataMstUserBeasiswa: "insertDataMstUserBeasiswa",
      getInstPendidikanBeasiswa: "getInstPendidikanBeasiswa",
      insertDataMstMaPelBeasiswa: "insertDataMstMaPelBeasiswa",
      insertDataPengajuanBeasiswa: "insertDataPengajuanBeasiswa",
      getDataAnakBeasiswa: "getDataAnakBeasiswa",
      insertDataMstMaPelBeasiswa: "insertDataMstMaPelBeasiswa",
      insertDataMstMaPelBeasiswa: "insertDataMstMaPelBeasiswa",
    }),

    initialize() {
      const code = {
        nik: this.user.data[0].NikKaryawan,
      };
      this.overlay = true;
      console.log("notifSekolah: " + this.notifBeasiswaSekolah);
      console.log("notifInstitut: " + this.notifBeasiswaInstitut);
      if (
        this.notifBeasiswaSekolah == "Pengajuan SD,SMP,SMA Ditutup" &&
        this.notifBeasiswaInstitut ==
          "Pengajuan Akademi dan Universitas Ditutup"
      ) {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          text: "Pengajuan Bantuan Pendidikan Sudah Ditutup",
          // text: result.data[0].FLAG,
        }).then(() => {
          this.overlay = false;
          this.status = "Failed";
        });
      }
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "bearer " +varToken,
      //   // 'Access-Control-Allow-Origin': '*',
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataKaryawanBeasiswa", code)
      this.getDataKaryawanBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
            this.overlay = false;
          } else if (result.status == "Success") {
            // this.dialogPasangan = true;
            this.periode = result.data[0].PERIODE;
            this.status = result.status;
            this.itemsTahunMasuk = result.tahunajaranbaru;
            // this.notifBeasiswaSekolah = result.notifBeasiswaSekolah;
            // this.notifBeasiswaInstitut = result.notifBeasiswaInstitut;
            this.overlay = false;

            // // console.log(result.tahunajaranbaru);
          } else {
            this.periode = result.data[0].PERIODE;
            this.status = result.status;
            this.overlay = false;
            this.$swal({
              icon: "warning",
              title: "Perhatian!",
              // text: "Pengajuan sudah ditutup, "+result.data[0].FLAG,
              text: result.data[0].FLAG,
            });
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            this.overlay = false;
            // this.snackbar=true;
          }
        });
    },

    listKaryawan() {
      const searchKary = {
        nik: "*" + this.NIK_NamaKaryawan,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };

      if (this.NIK_NamaKaryawan.length < 6) {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          text: "Pencarian minimal 6 karakter",
        });
      } else {
        this.btnLoading = true;
        // const axios = require("axios");
        // axios
        //   .post(this.$baseurl + "beasiswa/getDataUserBeasiswa", searchKary, {
        //     headers,
        //   })
        this.getDataUserBeasiswa(searchKary)
          .then((response) => {
            // // console.log(response.data.SearchWorkerResult.length);
            const result = response.data;

            if (result.status == "Token is Expired") {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else if (result.data.length > 0) {
              this.items_kary = result.data;
              // // console.log(result.data.Karyawan)
              this.btnLoading = false;
              this.dialogKary = true;
            } else {
              this.btnLoading = false;
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Karyawan Tidak Ditemukan",
              });
            }
          })
          .catch((error) => {
            // console.log(error);
            this.btnLoading = false;
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Karyawan Tidak Ditemukan",
            });
          });
      }
    },

    pilihMgr(item) {
      this.chooseMgr = Object.assign({}, item);
      this.approvemgr = this.chooseMgr.Karyawan;
      this.dialogMgr = false;
    },
    pilihVerif(item) {
      this.chooseVerif = Object.assign({}, item);
      console.log(this.chooseVerif);
      this.verifikasi = this.chooseVerif.Karyawan;
      this.dialogVerif = false;
    },
    pilihOtoAwal(item) {
      this.chooseOtoAwal = Object.assign({}, item);
      this.otorisasiawal = this.chooseOtoawal.Karyawan;
      this.dialogOtoAwal = false;
    },
    pilihOtoFinal(item) {
      this.chooseOtoFinal = Object.assign({}, item);
      this.otorisasifinal = this.chooseOtoFinal.Karyawan;
      this.dialogOtoFinal = false;
    },

    pilihKary(item) {
      this.chooseKar = Object.assign({}, item);
      // console.log(this.chooseKar);
      let panjang = this.chooseKar.Karyawan;
      let trim = panjang.trim();
      let NIK = trim.substr(0, 10);
      let NAMA = trim.substr(trim.indexOf("-") + 2);
      // // console.log(trim)
      // // console.log("'"+NIK+"'")
      // // console.log("'"+NAMA+"'")
      // this.NIK_NamaKaryawan = this.chooseKar.nik;
      this.chooseKar.NIK = NIK;
      this.chooseKar.NAMA = NAMA;
      this.NIK_Pasangan = NIK;
      this.Nama_Pasangan = NAMA;
      this.dialogKary = false;
      this.NIK_NamaKaryawan = "";
    },

    confirmPasangan() {
      if (this.choice == "Tidak") {
        this.dialogPasangan = false;
        this.status = 0;
      } else {
        const NIKPasangan = {
          nik: this.user.data[0].NikKaryawan,
          suamiistri: this.NIK_Pasangan,
        };
        // const varToken = sessionStorage.getItem("token");
        // const headers = {
        //   Authorization: "Bearer" + this.TOKEN,
        //   // 'Access-Control-Allow-Origin': '*',
        // };
        // const axios = require("axios");
        // axios
        //   .post(
        //     this.$baseurl + "beasiswa/getDataKaryawanBeasiswa",
        //     NIKPasangan,
        //     { headers }
        //   )
        this.getDataKaryawanBeasiswa(NIKPasangan)
          .then((response) => {
            // // console.log(response.data);
            const hasil = response.data;
            if (hasil.status == "Token is Expired") {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else if (hasil.status == "Success") {
              this.dialogPasangan = false;
              this.statusPasangan = "0";
            } else {
              this.$swal({
                icon: "warning",
                title: "Maaf!",
                text: hasil.data[0].FLAG,
              });
              this.btnLoading = false;
              this.dialogPasangan = false;
              this.statusPasangan = "1";
            }
          })
          .catch((error) => {
            this.btnLoading = false;
            // console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Server Error",
            });
            this.btnLoading = false;
          });
      }
    },

    onInput() {
      // Panggil fungsi ini saat input berubah untuk mengatur perilaku autocompletion
      if (this.approvemgr && this.selectedItem.length < 5) {
        this.itemsapprovalmgr = [];
      }
    },

    updateGanjil() {
      let ganjil = parseFloat(this.nilaiganjil);
      // // console.log(ganjil);
      let genap = parseFloat(this.nilaigenap);
      let total = (ganjil + genap) / 2;
      let fix = total.toFixed(2);
      if (
        /^[0-9.]+$/.test(this.nilaiganjil) &&
        /^[0-9.]+$/.test(this.nilaigenap)
      ) {
        this.nilaitotal = fix;
      } else {
        this.nilaitotal = "";
      }
    },
    updateGenap() {
      let ganjil = parseFloat(this.nilaiganjil);
      let genap = parseFloat(this.nilaigenap);
      let total = (ganjil + genap) / 2;
      let fix = total.toFixed(2);
      // this.nilaitotal = fix;
      if (
        /^[0-9.]+$/.test(this.nilaigenap) &&
        /^[0-9.]+$/.test(this.nilaiganjil)
      ) {
        this.nilaitotal = fix;
      } else {
        this.nilaitotal = "";
      }
    },

    updatePengetahuanGanjil() {
      // // console.log(this.inputs)
      let jmltotal = 0;
      // let jmlnilaiketerampilanganjil = 0;
      let hasil = this.inputsganjil;
      let hasiltest = hasil.map((item) => item.pengetahuanganjil);
      // // console.log(hasiltest);
      for (var i = 0; i < hasiltest.length; i++) {
        jmltotal += parseFloat(hasiltest[i]);
      }
      // // console.log(jmltotal / hasiltest.length);
      let rata = jmltotal / hasiltest.length;
      let fixed = rata.toFixed(2);
      this.jmlnilaipengetahuanganjil = fixed;
      let total =
        (parseFloat(this.jmlnilaipengetahuanganjil) +
          parseFloat(this.jmlnilaiketerampilanganjil)) /
        2;
      // // console.log("p "+total)
      let fixedTotal = total.toFixed(2);
      // // console.log("p "+fixedTotal)
      this.rata2ganjil = fixedTotal;
    },
    updateKeterampilanGanjil() {
      let jmltotal = 0;
      // let jmlnilaipengetahuanganjil = 0;
      let hasil = this.inputsganjil;
      let hasiltest = hasil.map((item) => item.keterampilanganjil);
      // // console.log(hasiltest);
      for (var i = 0; i < hasiltest.length; i++) {
        jmltotal += parseFloat(hasiltest[i]);
      }
      // // console.log(jmltotal / hasiltest.length);
      let rata = jmltotal / hasiltest.length;
      let fixed = rata.toFixed(2);
      this.jmlnilaiketerampilanganjil = fixed;

      let total =
        (parseFloat(this.jmlnilaipengetahuanganjil) +
          parseFloat(this.jmlnilaiketerampilanganjil)) /
        2;
      // // console.log(80.00+99.00)
      // // console.log("k "+total)
      let fixedTotal = total.toFixed(2);
      // // console.log("k " +fixedTotal)
      this.rata2ganjil = fixedTotal;
    },

    updatePengetahuanGenap() {
      // // console.log(this.inputs)
      let jmltotal = 0;
      // let jmlnilaiketerampilanganjil = 0;
      let hasil = this.inputsgenap;
      let hasiltest = hasil.map((item) => item.pengetahuangenap);
      // // console.log(hasiltest);
      for (var i = 0; i < hasiltest.length; i++) {
        jmltotal += parseFloat(hasiltest[i]);
      }
      // // console.log(jmltotal / hasiltest.length);
      let rata = jmltotal / hasiltest.length;
      let fixed = rata.toFixed(2);
      this.jmlnilaipengetahuangenap = fixed;
      let total =
        (parseFloat(this.jmlnilaipengetahuangenap) +
          parseFloat(this.jmlnilaiketerampilangenap)) /
        2;
      // // console.log("p "+total)
      let fixedTotal = total.toFixed(2);
      // // console.log("p "+fixedTotal)
      this.rata2genap = fixedTotal;
    },
    updateKeterampilanGenap() {
      let jmltotal = 0;
      // let jmlnilaipengetahuangenap = 0;
      let hasil = this.inputsgenap;
      let hasiltest = hasil.map((item) => item.keterampilangenap);
      // // console.log(hasiltest);
      for (var i = 0; i < hasiltest.length; i++) {
        jmltotal += parseFloat(hasiltest[i]);
      }
      // // console.log(jmltotal / hasiltest.length);
      let rata = jmltotal / hasiltest.length;
      let fixed = rata.toFixed(2);
      this.jmlnilaiketerampilangenap = fixed;

      let total =
        (parseFloat(this.jmlnilaipengetahuangenap) +
          parseFloat(this.jmlnilaiketerampilangenap)) /
        2;
      // // console.log(80.00+99.00)
      // // console.log("k "+total)
      let fixedTotal = total.toFixed(2);
      // // console.log("k " +fixedTotal)
      this.rata2genap = fixedTotal;
    },

    saveganjil() {
      let inputsganjil = this.inputsganjil;
      let mapelganjilarray1 = inputsganjil.map((item) => item.mapelganjil);
      let mapelganjilarray = JSON.stringify(mapelganjilarray1);
      // let mapelganjil = JSON.parse(mapelganjil2);
      // console.log(mapelganjilarray);
      // // let strArray = ["q", "w", "w", "w", "e", "i", "u", "r"];
      // let strArray = mapelganjilarray1;
      // let findDuplicates = mapelganjilarray.filter((el) => {
      //     return(mapelganjilarray.indexOf(el.item) != el.index)
      //   });

      // // console.log(findDuplicates(strArray)); // All duplicates
      // // console.log([...new Set(strArray)]); // Unique duplicates

      // const yourArray = [1, 1, 2, 3, 4, 5, 5];
      const yourArray = mapelganjilarray1;

      const yourArrayWithoutDuplicates = [...new Set(yourArray)];

      let duplicates = [...yourArray];
      yourArrayWithoutDuplicates.forEach((item) => {
        const i = duplicates.indexOf(item);
        duplicates = duplicates
          .slice(0, i)
          .concat(duplicates.slice(i + 1, duplicates.length));
      });

      // console.log(duplicates); //[ 1, 5 ]
      if (duplicates.length > 0) {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          text:
            "Mapel yang diinput tidak boleh sama karena data sebelumnya sudah ada",
        });
      } else {
        this.nilaiganjil = this.rata2ganjil;
        this.dialogGanjil = false;
        this.updateGanjil();
      }
    },
    cancelganjil() {
      this.dialogGanjil = false;
      // this.inputs = [];
    },

    savegenap() {
      let inputsgenap = this.inputsgenap;
      let mapelgenaparray1 = inputsgenap.map((item) => item.mapelgenap);
      let mapelgenaparray = JSON.stringify(mapelgenaparray1);
      // let mapelganjil = JSON.parse(mapelganjil2);
      // console.log(mapelgenaparray);
      // // let strArray = ["q", "w", "w", "w", "e", "i", "u", "r"];
      // let strArray = mapelganjilarray1;
      // let findDuplicates = mapelganjilarray.filter((el) => {
      //     return(mapelganjilarray.indexOf(el.item) != el.index)
      //   });

      // // console.log(findDuplicates(strArray)); // All duplicates
      // // console.log([...new Set(strArray)]); // Unique duplicates

      // const yourArray = [1, 1, 2, 3, 4, 5, 5];
      const yourArray = mapelgenaparray1;

      const yourArrayWithoutDuplicates = [...new Set(yourArray)];

      let duplicates = [...yourArray];
      yourArrayWithoutDuplicates.forEach((item) => {
        const i = duplicates.indexOf(item);
        duplicates = duplicates
          .slice(0, i)
          .concat(duplicates.slice(i + 1, duplicates.length));
      });

      // console.log(duplicates); //[ 1, 5 ]
      if (duplicates.length > 0) {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          text:
            "Mapel yang diinput tidak boleh sama karena data sebelumnya sudah ada",
        });
      } else {
        this.nilaigenap = this.rata2genap;
        this.dialogGenap = false;
        this.updateGenap();
      }
    },
    cancelgenap() {
      this.dialogGenap = false;
      // this.inputs = [];
    },

    okDate() {},

    pilihSekolah(item) {
      this.chooseSc = Object.assign({}, item);
      // // console.log(this.chooseKar.Karyawan);
      this.carisekolah = this.chooseSc.EDUC_NAME;
      this.idsekolah = this.chooseSc.EDUC_ID;
      // this.NIK_NamaKaryawan = this.chooseKar.nik;
      // this.chooseSc.NIK = NIK;
      // this.chooseKar.NAMA = NAMA;
      this.dialogSekolah = false;
      // this.NIK_NamaKaryawan = "";
    },

    pilihJurusan(item) {
      this.chooseJs = Object.assign({}, item);
      // // console.log(this.chooseKar.Karyawan);
      this.carijurusan = this.chooseJs.EDUC_NAME;
      this.idjurusan = this.chooseJs.EDUC_ID;
      // this.NIK_NamaKaryawan = this.chooseKar.nik;
      // this.chooseSc.NIK = NIK;
      // this.chooseKar.NAMA = NAMA;
      this.dialogJurusan = false;
      // this.NIK_NamaKaryawan = "";
    },

    tingkatPendidikan() {
      const code = {
        nik: this.user.data[0].NikKaryawan,
        status: "read",
        kategori: "",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // 'Access-Control-Allow-Origin': '*',
      // };
      // const axios = require("axios");
      // axios
      //   .post(
      //     this.$baseurl + "beasiswa/insertDataMstPlafondBeasiswa",
      //     code,
      //     { headers }
      //   )
      this.insertDataMstPlafondBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // // console.log(result.data);
            this.itemsTingkatPendidikan = result.data;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    searchMgr() {
      if (this.approvemgr.length >= 6) {
        this.getApproveManager();
      } else {
        console.log("min 3 character");
      }
    },
    searchVerif() {
      if (this.verifikasi.length >= 6) {
        this.getVerifikasi();
      } else {
        console.log("min 3 character");
      }
    },
    searchOtoAwal() {
      if (this.otorisasiawal.length >= 6) {
        this.getOtorisasiAwal();
      } else {
        console.log("min 3 character");
      }
    },
    searchOtoFinal() {
      if (this.otorisasifinal.length >= 6) {
        this.getOtorisasiFinal();
      } else {
        console.log("min 3 character");
      }
    },

    setNikManager($event) {
      this.approvemgr = $event;
      if (this.approvemgr != undefined) {
        if (this.approvemgr.length >= 5) {
          this.getApproveManager();
        }else{
          this.itemsapprovalmgr = [];
          this.approvemgr == "";
        }
      }
      // console.log("Search Input:", newSearch);
      // if (newSearch != undefined) {
      //   if (newSearch.length >= 5) {
      //     this.getApproveManager(newSearch);
      //   } else {
      //     this.itemsapprovalmgr = [];
      //     this.approvemgr = "";
      //   }
      // }
    },
    setNikVerifikasi(event) {
      this.verifikasi = event;
      if (this.verifikasi != undefined) {
        if (this.verifikasi.length >= 5) {
          this.getVerifikasi();
        }
      }
    },
    setNikOtorisasiAwal(event) {
      this.otorisasiawal = event;
      if (this.otorisasiawal != undefined) {
        if (this.otorisasiawal.length >= 5) {
          this.getOtorisasiAwal();
        }
      }
    },
    setNikOtorisasiFinal(event) {
      this.otorisasifinal = event;
      if (this.otorisasifinal != undefined) {
        if (this.otorisasifinal.length >= 5) {
          this.getOtorisasiFinal();
        }
      }
    },

    onSearchInput(newVal) {
      // Panggil fungsi ini saat input berubah untuk mengatur perilaku autocompletion
      if (newVal && newVal.length >= 5) {
        this.getApproveManager(newVal);
      } else {
        this.itemsapprovalmgr = [];
      }
    },

    getApproveManager() {
      // console.log("search: " + query);
      // this.approvemgr = newSearch;
      const code = {
        nik: this.approvemgr,
        niklogin: this.user.data[0].NikKaryawan,
        role: "",
        status: "read",
        email: "",
        unitrole: [],
        cabangrole: [],
        kategori: "Approval Manager",
        arrayobj_role: "",
      };
      // this.overlay = true;
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // 'Access-Control-Allow-Origin': '*',
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/insertDataMstUserBeasiswa", code, {
      //     headers,
      //   })
      this.insertDataMstUserBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // // console.log(result.data);
            // this.overlay = false;
            // this.dialogMgr = true
            this.itemsapprovalmgr = result.data;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    getVerifikasi() {
      console.log("value approve manager: " + this.approvemgr);
      this.loading = true;
      const code = {
        nik: "",
        niklogin: this.user.data[0].NikKaryawan,
        role: "",
        status: "read",
        email: "",
        unitrole: [],
        cabangrole: [],
        kategori: "Verifikasi",
        arrayobj_role: "",
      };
      // this.overlay = true;
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // 'Access-Control-Allow-Origin': '*',
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/insertDataMstUserBeasiswa", code, {
      //     headers,
      //   })
      this.insertDataMstUserBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // // console.log(result.data);
            // this.overlay = false;
            // this.dialogVerif = true
            this.itemsverifikasi = result.data;
            // console.log('data: 'result.data)
            // console.log('karyawan': result.data.Karyawan)
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getOtorisasiAwal() {
      const code = {
        nik: "",
        niklogin: this.user.data[0].NikKaryawan,
        role: "",
        status: "read",
        email: "",
        unitrole: [],
        cabangrole: [],
        kategori: "Otorisasi Awal",
        arrayobj_role: "",
      };
      // this.overlay = true;
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // 'Access-Control-Allow-Origin': '*',
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/insertDataMstUserBeasiswa", code, {
      //     headers,
      //   })
      this.insertDataMstUserBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // // console.log(result.data);
            // this.overlay = false;
            // this.dialogOtoAwal = true
            this.itemsotorisasiawal = result.data;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    getOtorisasiFinal() {
      const code = {
        nik: "",
        niklogin: this.user.data[0].NikKaryawan,
        role: "",
        status: "read",
        email: "",
        unitrole: [],
        cabangrole: [],
        kategori: "Otorisasi Akhir",
        arrayobj_role: "",
      };
      // this.overlay = true;
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // 'Access-Control-Allow-Origin': '*',
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/insertDataMstUserBeasiswa", code, {
      //     headers,
      //   })
      this.insertDataMstUserBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // // console.log(result.data);
            // this.overlay = false;
            // this.dialogOtoFinal = true
            this.itemsotorisasifinal = result.data;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    getBeasiswa() {
      // // console.log(this.permohonan)
      (this.pendidikanSebelumnya = null), (this.idsekolah = null);
      this.carisekolah = null;
      (this.akreditasikampus = ""),
        (this.akreditasijurusan = ""),
        (this.nilaiganjil = "");
      (this.inputsganjil = [
        {
          mapelganjil: null,
          pengetahuanganjil: null,
          keterampilanganjil: null,
        },
      ]),
        (this.inputsgenap = [
          {
            mapelgenap: null,
            pengetahuangenap: null,
            keterampilangenap: null,
          },
        ]),
        (this.nilaigenap = "");
      this.nilaitotal = "";
      this.usulan = null;
      this.postpone = null;
      this.filesIjazah = null;
      this.filesDiterima = null;
      this.filesKelulusan = null;
      this.filesAktifKuliah = null;
      // const axios = require("axios");
      const code = {
        nik: this.user.data[0].NikKaryawan,
        status: "read",
        kategori: "plafond",
        idtingkatpendidikan: this.permohonan,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      // };
      // axios
      //   .post(
      //     this.$baseurl + "beasiswa/insertDataMstPlafondBeasiswa",
      //     code,
      //     {
      //       headers,
      //     }
      //   )
      this.insertDataMstPlafondBeasiswa(code)
        .then((response) => {
          let hasil = response.data;
          // let anak = response.data.data.PengkinianDataResult
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // this.besaranbeasiswa = hasil.data[0].PLAFOND;
            const numb = hasil.data[0].PLAFOND;
            this.nominal = numb;
            const format = numb
              .toString()
              .split("")
              .reverse()
              .join("");
            const convert = format.match(/\d{1,3}/g);
            const rupiah = convert
              .join(".")
              .split("")
              .reverse()
              .join("");
            // // console.log(rupiah)
            this.besaranbeasiswa = rupiah;
          }
        })
        .catch((error) => {
          // console.log(error);
          alert("Tidak Dapat Mengambil data Plafond");
        });
    },

    rule(item) {
      console.log("rule: " + this.notifBeasiswaSekolah);

      if (this.penerima == "Karyawan") {
        return item != "A" && item != "B" && item != "C";
      } else if (
        this.penerima == "Anak Karyawan" &&
        this.notifBeasiswaSekolah == "Pengajuan SD,SMP,SMA Ditutup"
      ) {
        return item != "A" && item != "B" && item != "C";
      } else if (
        this.penerima == "Anak Karyawan" &&
        this.notifBeasiswaInstitut ==
          "Pengajuan Akademi dan Universitas Ditutup"
      ) {
        return item != "D" && item != "E";
      } else {
        return true;
      }
    },

    addGanjil() {
      this.inputsganjil.push({
        mapelganjil: null,
        pengetahuanganjil: null,
        keterampilanganjil: null,
      });
      // // console.log(this.inputs);
    },

    addGenap() {
      this.inputsgenap.push({
        mapelgenap: null,
        pengetahuangenap: null,
        keterampilangenap: null,
      });
      // // console.log(this.inputs);
    },

    removeGanjil(index) {
      this.inputsganjil.splice(index, 1);
      this.updatePengetahuanGanjil();
      this.updateKeterampilanGanjil();
    },

    removeGenap(index) {
      this.inputsgenap.splice(index, 1);
      this.updatePengetahuanGenap();
      this.updateKeterampilanGenap();
    },

    listSekolah() {
      // const axios = require("axios");
      const code = {
        deskripsi: this.carisekolah,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };

      if (this.carisekolah.length < 3) {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          text: "Pencarian minimal 3 karakter",
        });
      } else {
        this.btnLoading = true;
        // axios
        //   .post(this.$baseurl + "beasiswa/getInstPendidikanBeasiswa", code, {
        //     headers,
        //   })
        this.getInstPendidikanBeasiswa(code)
          .then((response) => {
            // // console.log(response.data.SearchWorkerResult.length);
            const result = response.data;

            if (result.status == "Token is Expired") {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else if (result.data.length > 0) {
              this.items_list = result.data;
              // // console.log(result.data)
              this.btnLoading = false;
              this.dialogSekolah = true;
            } else {
              this.btnLoading = false;
              this.$swal({
                icon: "error",
                title: "Maaf!",
                html: `<span>Sekolah Tidak Ditemukan</span><br><br> <span><small>Jika NPSN/Sekolah tidak ditemukan, silakan menghubungi HR HO untuk mendaftarkan sekolah tsb</small></span>`
              });
            }
          })
          .catch((error) => {
            // console.log(error);
            this.btnLoading = false;
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Karyawan Tidak Ditemukan",
            });
          });
      }
    },

    listJurusan() {
      // const axios = require("axios");
      const code = {
        deskripsi: "Jur" + this.carijurusan,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };

      if (this.carijurusan.length < 3) {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          text: "Pencarian minimal 3 karakter",
        });
      } else {
        this.btnLoading = true;
        // axios
        //   .post(this.$baseurl + "beasiswa/getInstPendidikanBeasiswa", code, {
        //     headers,
        //   })
        this.getInstPendidikanBeasiswa(code)
          .then((response) => {
            // // console.log(response.data.SearchWorkerResult.length);
            const result = response.data;

            if (result.status == "Token is Expired") {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else if (result.data.length > 0) {
              this.items_jurusan = result.data;
              // // console.log(result.data)
              this.btnLoading = false;
              this.dialogJurusan = true;
            } else {
              this.btnLoading = false;
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Jurusan Tidak Ditemukan",
              });
            }
          })
          .catch((error) => {
            // console.log(error);
            this.btnLoading = false;
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Karyawan Tidak Ditemukan",
            });
          });
      }
    },

    detailGanjil() {
      // const axios = require("axios");
      const code = {
        // nik: sessionStorage.getItem("nik"),
        status: "read",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };

      // // this.btnLoading = true;
      // axios
      //   .post(this.$baseurl + "beasiswa/insertDataMstMaPelBeasiswa", code, {
      //     headers,
      //   })
      this.insertDataMstMaPelBeasiswa(code)
        .then((response) => {
          // // console.log(response.data.SearchWorkerResult.length);
          const result = response.data;

          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.items_mapelganjil = result.data;
            // // console.log(result.data)
            // this.btnLoading = false;
            this.dialogGanjil = true;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.btnLoading = false;
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Karyawan Tidak Ditemukan",
          });
        });

      // this.dialogGanjil = true;
    },
    detailGenap() {
      // const axios = require("axios");
      const code = {
        nik: this.user.data[0].NikKaryawan,
        status: "read",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };

      // // this.btnLoading = true;
      // axios
      //   .post(this.$baseurl + "beasiswa/insertDataMstMaPelBeasiswa", code, {
      //     headers,
      //   })
      this.insertDataMstMaPelBeasiswa(code)
        .then((response) => {
          // // console.log(response.data.SearchWorkerResult.length);
          const result = response.data;

          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.items_mapelgenap = result.data;
            // // console.log(result.data)
            // this.btnLoading = false;
            this.dialogGenap = true;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.btnLoading = false;
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Karyawan Tidak Ditemukan",
          });
        });
    },

    // save(tahunmasuk) {
    //   this.$refs.menu.save(tahunmasuk);
    //   this.$refs.picker.activePicker = "YEAR";
    //   this.menu = false;
    // },

    submit() {
      const max = 4;
      // console.log(this.usulan,"tes")
      if (this.usulan == "Naik Semester") {
        if (parseFloat(this.nilaiganjil) > parseFloat(max)) {
          this.$swal({
            icon: "warning",
            title: "Maaf!",
            text: "Nilai IPK Semester Ganjil Tidak boleh melebihi 4.00",
          });
          return;
        }
        if (parseFloat(this.nilaigenap) > parseFloat(max)) {
          this.$swal({
            icon: "warning",
            title: "Maaf!",
            text: "Nilai IPK Semester Genap Tidak boleh melebihi 4.00",
          });
          return;
        }
      }
      // console.log(this.nik);
      // console.log(this.periode);
      // console.log(this.penerima);
      // console.log(this.namaanak);
      // console.log(this.permohonan);
      // console.log(this.usulan);
      // console.log(this.idsekolah);
      // console.log(this.carisekolah);
      // console.log(this.akreditasikampus);
      // console.log(this.akreditasijurusan);
      // console.log(this.nominal);
      // console.log(this.nilaiganjil);
      // console.log(this.nilaigenap);
      // console.log(this.nilaitotal);
      // console.log(this.filesDiterima);
      // console.log(this.filesIjazah);
      // console.log(this.filesTranskrip);

      let inputsganjil = this.inputsganjil;
      let mapelganjilarray1 = inputsganjil.map((item) => item.mapelganjil);
      let mapelganjilarray = JSON.stringify(mapelganjilarray1);
      // let mapelganjil = JSON.parse(mapelganjil2);
      // console.log(mapelganjilarray);
      let pengetahuanganjilarray1 = inputsganjil.map(
        (item) => item.pengetahuanganjil
      );
      let pengetahuanganjilarray = JSON.stringify(pengetahuanganjilarray1);
      // console.log(pengetahuanganjilarray);
      let keterampilanganjilarray1 = inputsganjil.map(
        (item) => item.keterampilanganjil
      );
      let keterampilanganjilarray = JSON.stringify(keterampilanganjilarray1);
      // console.log(keterampilanganjilarray);

      let inputsgenap = this.inputsgenap;
      let mapelgenaparray1 = inputsgenap.map((item) => item.mapelgenap);
      let mapelgenaparray = JSON.stringify(mapelgenaparray1);
      // console.log(mapelgenaparray);
      let pengetahuangenaparray1 = inputsgenap.map(
        (item) => item.pengetahuangenap
      );
      let pengetahuangenaparray = JSON.stringify(pengetahuangenaparray1);
      // console.log(pengetahuangenaparray);
      let keterampilangenaparray1 = inputsgenap.map(
        (item) => item.keterampilangenap
      );
      let keterampilangenaparray = JSON.stringify(keterampilangenaparray1);
      // console.log(keterampilangenaparray);

      // let approvalmgrnik = this.approvemgr[0]
      console.log("mgr1: " + this.approvemgr);
      let nikapprovemgr = this.approvemgr.substr(0, 10);
      console.log("mgr2: " + nikapprovemgr);
      console.log("ver1: " + this.verifikasi);
      console.log("otoawal1: " + this.otorisasiawal);
      console.log("otoakhir1: " + this.otorisasifinal);

      let nikverifikasi = this.verifikasi.substr(0, 10);

      let nikotorisasiawal = this.otorisasiawal.substr(0, 10);
      // console.log(nikotorisasiawal);

      let nikotorisasifinal = this.otorisasifinal.substr(0, 10);
      // console.log(nikotorisasifinal);

      // filesIjazah: null,
      // filesTranskripGanjil: null,
      // filesTranskripGenap: null,
      // filesDiterima: null,
      // filesPostpone: null,
      // filesAkreditasiProdi: null,
      // filesAkreditasiED: null,
      // filesKelulusan: null,
      // filesAktifKuliah: null,
      // if(this.filesIjazah == null){
      //   this.filesIjazah == "";
      // }
      // if(this.filesTranskripGanjil == null){
      //   this.filesTranskripGanjil == "";
      // }
      // if(this.filesTranskripGenap == null){
      //   this.filesTranskripGenap == "";
      // }
      // if(this.filesDiterima == null){
      //   this.filesDiterima == "";
      // }
      // if(this.filesPostpone == null){
      //   this.filesPostpone == "";
      // }
      // if(this.filesAkreditasiProdi == null){
      //   this.filesAkreditasiProdi == "";
      // }
      // if(this.filesAkreditasiED == null){
      //   this.filesAkreditasiED == "";
      // }
      // if(this.filesKelulusan == null){
      //   this.filesKelulusan == "";
      // }
      // if(this.filesAktifKuliah == null){
      //   this.filesAktifKuliah == "";
      // }

      this.$swal({
        icon: "info",
        title: "Apakah Anda Yakin?",
        text: "Untuk Mengajukan Bantuan Pendidikan",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Yes`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.btnLoading = true;
          this.overlay = true;

          const formData = new FormData();
          formData.set("nik", this.user.data[0].NikKaryawan);
          formData.set("periodebeasiswa", this.periode);
          formData.set("penerimabeasiswa", this.penerima);
          formData.set("namaanak", this.namaanak);
          formData.set("tingkatbeasiswa", this.permohonan);
          formData.set("usulanbeasiswa", this.usulan);
          formData.set("tingkatsebelum", this.pendidikanSebelumnya);
          formData.set("postpone", this.postpone);
          formData.set("smtpostpone", this.textPostpone);
          formData.set("jmlpostpone", this.semesterPostpone);
          formData.set("npsnsekolah", this.idsekolah);
          formData.set("namasekolah", this.carisekolah);
          formData.set("jurusan", this.carijurusan);
          formData.set("kelas", this.grade);
          formData.set("akreditasikampus", this.akreditasikampus);
          formData.set("akreditasijurusan", this.akreditasijurusan);
          formData.set("tahunmasuk", this.tahunmasuk);
          formData.set("tahunajaranbaru", new Date().getFullYear());
          formData.set("plafondbeasiswa", this.nominal);
          formData.set("nilaiganjil", this.nilaiganjil);
          formData.set("nilaigenap", this.nilaigenap);
          formData.set("nilairata", this.nilaitotal);
          formData.append("fileraport", this.filesTranskripGanjil);
          formData.set("flagreviewraport", 0);
          formData.append("fileipk", this.filesTranskripGenap);
          formData.set("flagreviewipk", 0);
          formData.append("fileijazah", this.filesIjazah);
          formData.set("flagreviewijazah", 0);
          formData.append("filesuratditerima", this.filesDiterima);
          formData.set("flagreviewsuratditerima", 0);
          formData.append("filepostpone", this.filesPostpone);
          formData.set("flagreviewpostpone", 0);
          formData.append("fileaktifkuliah", this.filesAktifKuliah);
          formData.set("flagreviewaktifkuliah", 0);
          formData.append("filekelulusan", this.filesKelulusan);
          formData.set("flagreviewkelulusan", 0);
          formData.append(
            "fileakreditasiprogramstudi",
            this.filesAkreditasiProdi
          );
          formData.set("flagreviewakreditasiprogramstudi", 0);
          formData.append("filesuratakreditasi", this.filesAkreditasiED);
          formData.set("flagreviewsuratakreditasi", 0);
          formData.set("nikmgr", nikapprovemgr);
          formData.set("nikverif", nikverifikasi);
          formData.set("nikotorisasiawal", nikotorisasiawal);
          formData.set("nikotorisasiakhir", nikotorisasifinal);
          formData.set("statustransaksi", 0);
          formData.append("idmapelganjil", [mapelganjilarray]);
          formData.append("idmapelgenap", [mapelgenaparray]);
          formData.append("nilaipengetahuanmapelganjil", [
            pengetahuanganjilarray,
          ]);
          formData.append("nilaipengetahuanmapelgenap", [
            pengetahuangenaparray,
          ]);
          formData.append("nilaiketerampilanmapelganjil", [
            keterampilanganjilarray,
          ]);
          formData.append("nilaiketerampilanmapelgenap", [
            keterampilangenaparray,
          ]);
          formData.set("semesterganjil", "Ganjil");
          formData.set("semestergenap", "Genap");

          // console.log(formData);
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   Authorization: "Bearer" + this.TOKEN,
          //   // NIK: sessionStorage.getItem("nik"),
          // };
          // const axios = require("axios");
          // axios
          //   .post(
          //     this.$baseurl + "beasiswa/insertDataPengajuanBeasiswa",
          //     formData,
          //     { headers }
          //   )
          this.insertDataPengajuanBeasiswa(formData)
            .then((response) => {
              // console.log(response.data);
              const hasil = response.data;
              if (hasil.status == "Token is Expired") {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (hasil.status == "Success") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: hasil.message,
                }).then(() => {
                  // this.initialize();
                  // window.location.reload();
                  this.clearForm();
                  this.$router.push({ name: "HomePage" });
                });
              } else if (hasil.status == "Warning") {
                this.$swal({
                  icon: "warning",
                  title: "Maaf!",
                  text:
                    "Gagal Menambahkan, Format Lampiran harus PDF dan ukuran File maximal 1MB!",
                });
                this.btnLoading = false;
                this.overlay = false;
              } else {
                this.$swal({
                  icon: "warning",
                  title: "Maaf!",
                  text: hasil.data[0].Pesan,
                });
                this.btnLoading = false;
                this.overlay = false;
              }
            })
            .catch((error) => {
              this.btnLoading = false;
              // console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menambahkan",
              });
              this.btnLoading = false;
              this.overlay = false;
            });
        }
      });
      // this.$refs.fileInput.value = null;
      // // console.log(this.$refs.fileInput.value)

      // this.$swal({
      //       icon: "success",
      //       title: "Berhasil!",
      //       text: "Data Rencana Vaksinasi Covid-19 berhasil disimpan",
      //     });
    },

    onKeyDown(evt) {
      if (this.realisasi.length <= 26) {
        if (evt.keyCode >= 48 && evt.keyCode <= 90) {
          evt.preventDefault();
          return;
        }
      }
    },

    formatMonth(tahunmasuk) {
      if (!tahunmasuk) return null;

      const [year, month] = tahunmasuk.split("-");
      return `${month}-${year}`;
    },

    getAnak() {
      // const axios = require("axios");
      const dataAnak = {
        nik: this.user.data[0].NikKaryawan,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: this.TOKEN,
      // };
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataAnakBeasiswa", dataAnak, {
      //     headers,
      //   })
      this.getDataAnakBeasiswa(dataAnak)
        .then((response) => {
          let hasil = response.data;
          // let anak = response.data.data.PengkinianDataResult
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // let data = hasil.data.PengkinianDataResult.map((item) => item.NAME);
            // // console.log(hasil.data.PengkinianDataResult);
            this.itemsAnak = hasil.data.Result;
          }
        })
        .catch((error) => {
          // console.log(error);
          alert("Tidak Dapat Mengambil data Anak");
        });
    },

    clearGagal() {
      this.date = null;
      this.alasan = "";
      this.files = null;
    },

    clearBerhasil() {
      this.date = null;
      if (this.vaksinasiKe == "1") {
        this.idsertifikasi = "";
      }

      this.jenisvaksinasi = null;
      this.files = null;
    },

    clearForm() {
      window.location.reload();
    },

    changePenerima() {
      this.permohonan = null;
      this.usulan = null;
      (this.inputsganjil = [
        {
          mapelganjil: null,
          pengetahuanganjil: null,
          keterampilanganjil: null,
        },
      ]),
        (this.inputsgenap = [
          {
            mapelgenap: null,
            pengetahuangenap: null,
            keterampilangenap: null,
          },
        ]),
        (this.pendidikanSebelumnya = null),
        (this.namaanak = "");
      this.postpone = null;
      this.filesIjazah = null;
      this.filesDiterima = null;
      this.filesKelulusan = null;
      this.nilaiganjil = "";
      this.nilaigenap = "";
      this.nilaitotal = "";
    },

    changePermohonan() {
      this.usulan = null;
      this.postpone = null;
      this.filesIjazah = null;
      this.filesDiterima = null;
      this.nilaiganjil = "";
      this.nilaigenap = "";
      this.nilaitotal = "";
    },

    changeUsulan() {
      (this.inputsganjil = [
        {
          mapelganjil: null,
          pengetahuanganjil: null,
          keterampilanganjil: null,
        },
      ]),
        (this.inputsgenap = [
          {
            mapelgenap: null,
            pengetahuangenap: null,
            keterampilangenap: null,
          },
        ]),
        (this.pendidikanSebelumnya = null),
        (this.idsekolah = null);
      this.carisekolah = null;
      (this.akreditasikampus = ""),
        (this.akreditasijurusan = ""),
        (this.filesIjazah = null);
      this.filesDiterima = null;
      this.filesKelulusan = null;
      this.filesAktifKuliah = null;
      this.nilaiganjil = "";
      this.nilaigenap = "";
      this.nilaitotal = "";
    },

    changePendidikanSebelumnya() {
      this.postpone = null;
    },

    changePostpone() {
      this.semesterPostpone = "";
      this.textPostpone = "";
      this.filesPostpone = null;
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    isNumber(n) {
      let char = String.fromCharCode(n.keyCode); // Get the character
      if (/^[0-9.]+$/.test(char)) return true;
      // Match with regex
      else n.preventDefault(); // If not match, don't add to input text
    },

    validasiPostpone(n) {
      let char = String.fromCharCode(n.keyCode); // Get the character
      if (/^[0-9,]+$/.test(char)) return true;
      // Match with regex
      else n.preventDefault(); // If not match, don't add to input text
    },
  },
};
</script>