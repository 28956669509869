<template>
  <div>
    <c-toolbar />
    <c-navbar />
    <v-main>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2" :loading="vloading">
              <template slot="progress">
                <v-progress-linear
                  color="green"
                  indeterminate
                ></v-progress-linear>
              </template>
              <br />
              <v-row justify="center">
                <v-img src="img/indomaret.png" aspect-ratio="3" max-width="250">
                </v-img>
              </v-row>
              <h3 class="text-center my-4">Approval Bantuan Pendidikan</h3>
              <v-alert class="mx-4" type="info">
                <div>
                  <b
                    >- Untuk Mengaktifkan Tombol Approve dan Reject Harap Klik
                    Tombol Lihat Detail Yang Ada Di Kolom Detail.</b
                  ><br />
                  <b>
                    Setelah Itu Checklist Kotak Di Samping Lihat Lampiran, Lalu
                    Klik Tombol OK.</b
                  >
                  <br />
                  <b
                    >- Silakan memilih Role terlebih dahulu, kemudian klik
                    tombol Filter.</b
                  >
                  <br />
                  <!-- <b>Info pembatasan persetujuan per jenjang pendidikan, yaitu: </b><br>
                  <b>1. Batas waktu persetujuan beasiswa untuk jenjang pendidikan SD/SMP/SMA sampai tanggal 5 Agustus 2022.</b><br>
                  <b>2. Jika tombol 'Lihat Detail' tidak bisa diklik (abu-abu), berarti sudah melebihi batas waktu yang ditentukan (baca poin 1).</b> -->
                </div>
              </v-alert>

              <div class="pa-1 mx-4">
                <v-dialog v-model="dialogDetail" max-width="1000px">
                  <v-card>
                    <v-form ref="form">
                      <v-card-title>
                        <span class="headline"
                          >Detail Pengajuan Bantuan Pendidikan</span
                        >
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-overlay :value="overlayDialog">
                            <v-progress-circular
                              indeterminate
                              size="64"
                            ></v-progress-circular>
                          </v-overlay>
                          <v-simple-table dense class="pa-4 mx-3">
                            <tbody>
                              <!-- <v-row>
                            <v-col cols="12" sm="4" md="4">
                              NIK
                            </v-col>
                            <v-col cols="12" sm="1" md="1">
                              :
                            </v-col>
                            <v-col cols="12" sm="7" md="7">
                              {{this.editedItem.NIK}}
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              NAMA
                            </v-col>
                            <v-col cols="12" sm="1" md="1">
                              :
                            </v-col>
                            <v-col cols="12" sm="7" md="7">
                              {{this.editedItem.NAMA_KARYAWAN}}
                            </v-col>
                          </v-row> -->
                              <tr>
                                <td style="width: 25%">NIK</td>
                                <td style="width: 1%">:</td>
                                <td>{{ this.editedItem.NIK }}</td>
                              </tr>
                              <tr>
                                <td>Nama</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.NAMA_KARYAWAN }}
                                </td>
                              </tr>
                              <tr>
                                <td>Unit</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.UNIT_NAME }}
                                </td>
                              </tr>
                              <tr>
                                <td>Cabang</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.CABANG_NAME }}
                                </td>
                              </tr>
                              <tr>
                                <td>Bagian</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.BAGIAN_NAME }}
                                </td>
                              </tr>
                              <tr>
                                <td>Jabatan</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.JABATAN }}
                                </td>
                              </tr>
                              <tr>
                                <td>Job Class</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.JOBCLASS }}
                                </td>
                              </tr>
                              <tr>
                                <td>Periode Bantuan Pendidikan</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.PERIODE_BEASISWA }}
                                </td>
                              </tr>
                              <tr>
                                <td>Penerima Bantuan Pendidikan</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.PENERIMA_BEASISWA }}
                                </td>
                              </tr>
                              <tr>
                                <td>Periode Pemberian</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.PERIODE_PEMBERIAN }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.NAMA_ANAK_KARYAWAN != null
                                "
                              >
                                <td>Nama Anak</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.NAMA_ANAK_KARYAWAN }}
                                </td>
                              </tr>
                              <tr>
                                <td>Tingkat Pendidikan</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.NAMA_TINGKAT_BEASISWA }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.USULAN_BEASISWA ==
                                    'Naik Jenjang Pendidikan' &&
                                    this.editedItem.TINGKAT_BEASISWA != 'A'
                                "
                              >
                                <td>Pendidikan Sebelumnya</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.NAMA_TINGKAT_SEBELUMNYA }}
                                </td>
                              </tr>
                              <tr>
                                <td>Usulan Bantuan Pendidikan</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.USULAN_BEASISWA }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  v-if="
                                    this.editedItem.TINGKAT_BEASISWA == 'D' ||
                                      this.editedItem.TINGKAT_BEASISWA == 'E'
                                  "
                                >
                                  Nama Kampus
                                </td>
                                <td v-else>Nama Sekolah</td>
                                <td>:</td>
                                <td>
                                  {{
                                    this.editedItem.NPSN_SEKOLAH +
                                      " - " +
                                      this.editedItem.NAMA_SEKOLAH
                                  }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.TINGKAT_BEASISWA == 'D' ||
                                    this.editedItem.TINGKAT_BEASISWA == 'E'
                                "
                              >
                                <td>Jurusan</td>
                                <td>:</td>
                                <td>
                                  {{
                                    this.editedItem.JURUSAN == null ||
                                    this.editedItem.JURUSAN == "null" ||
                                    this.editedItem.JURUSAN == ""
                                      ? "-"
                                      : this.editedItem.JURUSAN
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  v-if="
                                    this.editedItem.TINGKAT_BEASISWA == 'D' ||
                                      this.editedItem.TINGKAT_BEASISWA == 'E'
                                  "
                                >
                                  Bantuan Pendidikan ini untuk Semester
                                </td>
                                <td v-else>
                                  Bantuan Pendidikan ini untuk Kelas
                                </td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.KELAS }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  (this.editedItem.TINGKAT_BEASISWA == 'D' ||
                                    this.editedItem.TINGKAT_BEASISWA == 'E') &&
                                    this.editedItem.TINGKAT_SEBELUMNYA != 'C'
                                "
                              >
                                <td>Pernah Postpone Kuliah?</td>
                                <td>:</td>
                                <td>
                                  {{
                                    this.editedItem.POSTPONE == null ||
                                    this.editedItem.POSTPONE == "null" ||
                                    this.editedItem.POSTPONE == ""
                                      ? "-"
                                      : this.editedItem.POSTPONE
                                  }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.POSTPONE == 'Ya' &&
                                    (this.editedItem.TINGKAT_BEASISWA == 'D' ||
                                      this.editedItem.TINGKAT_BEASISWA ==
                                        'E') &&
                                    this.editedItem.TINGKAT_SEBELUMNYA != 'C'
                                "
                              >
                                <td>Berapa Semester Postpone?</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.JML_SMT_POSTPONE }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.POSTPONE == 'Ya' &&
                                    (this.editedItem.TINGKAT_BEASISWA == 'D' ||
                                      this.editedItem.TINGKAT_BEASISWA ==
                                        'E') &&
                                    this.editedItem.TINGKAT_SEBELUMNYA != 'C'
                                "
                              >
                                <td>Postpone di Semester Berapa?</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.SMT_POSTPONE }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.TINGKAT_BEASISWA == 'D' ||
                                    this.editedItem.TINGKAT_BEASISWA == 'E'
                                "
                              >
                                <td>Akreditasi Kampus</td>
                                <td>:</td>
                                <td>
                                  {{
                                    this.editedItem.AKREDITASI_KAMPUS == null ||
                                    this.editedItem.AKREDITASI_KAMPUS ==
                                      "null" ||
                                    this.editedItem.AKREDITASI_KAMPUS == ""
                                      ? "-"
                                      : this.editedItem.AKREDITASI_KAMPUS
                                  }}
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.TINGKAT_BEASISWA == 'D' ||
                                    this.editedItem.TINGKAT_BEASISWA == 'E'
                                "
                              >
                                <td>Akreditasi Jurusan</td>
                                <td>:</td>
                                <td>
                                  {{
                                    this.editedItem.AKREDITASI_JURUSAN ==
                                      null ||
                                    this.editedItem.AKREDITASI_JURUSAN ==
                                      "null" ||
                                    this.editedItem.AKREDITASI_JURUSAN == ""
                                      ? "-"
                                      : this.editedItem.AKREDITASI_JURUSAN
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Tahun Masuk</td>
                                <td>:</td>
                                <td>
                                  {{ tahunmasukFormatted }}
                                </td>
                              </tr>
                              <tr>
                                <td>Tahun Ajaran Baru</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.TAHUN_AJARAN_BARU }}
                                </td>
                              </tr>
                              <tr>
                                <td>Besaran Bantuan Pendidikan</td>
                                <td>:</td>
                                <td>
                                  Rp.
                                  {{
                                    formatRp(this.editedItem.PLAFOND_BEASISWA)
                                  }}/bulan
                                </td>
                              </tr>
                              <tr>
                                <td>Nilai Semester Ganjil</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.NILAI_GANJIL }}
                                </td>
                                <td
                                  v-if="
                                    this.editedItem.TINGKAT_BEASISWA == 'A' ||
                                      this.editedItem.TINGKAT_BEASISWA == 'B' ||
                                      this.editedItem.TINGKAT_BEASISWA == 'C' ||
                                      (this.editedItem.TINGKAT_BEASISWA ==
                                        'D' &&
                                        this.editedItem.USULAN_BEASISWA ==
                                          'Naik Jenjang Pendidikan') ||
                                      (this.editedItem.TINGKAT_BEASISWA ==
                                        'E' &&
                                        this.editedItem.USULAN_BEASISWA ==
                                          'Naik Jenjang Pendidikan' &&
                                        this.editedItem.TINGKAT_SEBELUMNYA ==
                                          'C')
                                  "
                                >
                                  <v-btn
                                    small
                                    color="primary"
                                    block
                                    @click="clickGanjil"
                                    >Detail Nilai</v-btn
                                  >
                                </td>
                                <td v-else></td>
                              </tr>
                              <tr>
                                <td>Nilai Semester Genap</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.NILAI_GENAP }}
                                </td>
                                <td
                                  v-if="
                                    this.editedItem.TINGKAT_BEASISWA == 'A' ||
                                      this.editedItem.TINGKAT_BEASISWA == 'B' ||
                                      this.editedItem.TINGKAT_BEASISWA == 'C' ||
                                      (this.editedItem.TINGKAT_BEASISWA ==
                                        'D' &&
                                        this.editedItem.USULAN_BEASISWA ==
                                          'Naik Jenjang Pendidikan') ||
                                      (this.editedItem.TINGKAT_BEASISWA ==
                                        'E' &&
                                        this.editedItem.USULAN_BEASISWA ==
                                          'Naik Jenjang Pendidikan' &&
                                        this.editedItem.TINGKAT_SEBELUMNYA ==
                                          'C')
                                  "
                                >
                                  <v-btn
                                    small
                                    color="primary"
                                    block
                                    @click="clickGenap"
                                    >Detail Nilai</v-btn
                                  >
                                </td>
                                <td v-else></td>
                              </tr>
                              <tr>
                                <td>Nilai Rata2 Semester</td>
                                <td>:</td>
                                <td>
                                  {{ this.editedItem.NILAI_RATARATA }}
                                </td>
                              </tr>
                              <tr>
                                <td>Lampiran Nilai Raport Ganjil</td>
                                <td>:</td>
                                <!-- <td>
                                  <a
                                    href="#"
                                    @click="clickLampiranRaport"
                                    style="color:green"
                                    >Lihat Lampiran</a
                                  >
                                </td> -->
                                <td>
                                  <v-btn
                                    href="#"
                                    small
                                    @click="getDataPDFGanjil"
                                    style="color: green"
                                    >Lihat Lampiran Nilai Raport Ganjil
                                  </v-btn>
                                </td>
                                <td>
                                  <v-checkbox
                                    v-model="checkGanjil"
                                    :disabled="
                                      editedItem.FILEPATH_RAPORT == 'null' ||
                                        editedItem.FILEPATH_RAPORT == null ||
                                        editedItem.FILEPATH_RAPORT == ''
                                    "
                                  ></v-checkbox>
                                </td>
                              </tr>
                              <tr>
                                <td>Lampiran Nilai Raport Genap</td>
                                <td>:</td>
                                <!-- <td>
                                  <a
                                    href="#"
                                    @click="clickLampiranRaport"
                                    style="color:green"
                                    >Lihat Lampiran</a
                                  >
                                </td> -->
                                <td>
                                  <v-btn
                                    href="#"
                                    small
                                    @click="getDataPDFGenap"
                                    style="color: green"
                                    >Lihat Lampiran Nilai Raport Genap
                                  </v-btn>
                                </td>
                                <td>
                                  <v-checkbox
                                    v-model="checkGenap"
                                    :disabled="
                                      editedItem.FILEPATH_IPK == 'null' ||
                                        editedItem.FILEPATH_IPK == null ||
                                        editedItem.FILEPATH_IPK == ''
                                    "
                                  ></v-checkbox>
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.USULAN_BEASISWA ==
                                    'Naik Jenjang Pendidikan'
                                "
                              >
                                <td>Lampiran Ijazah</td>
                                <td>:</td>
                                <!-- <td>
                                  <a
                                    href="#"
                                    @click="clickLampiranIjazah"
                                    style="color:green"
                                    >Lihat Lampiran</a
                                  >
                                </td> -->
                                <td>
                                  <v-btn
                                    href="#"
                                    small
                                    @click="getDataPDFIjazah"
                                    style="color: green"
                                    >Lihat Lampiran Ijazah
                                  </v-btn>
                                </td>
                                <td>
                                  <v-checkbox
                                    v-model="checkIjazah"
                                    :disabled="
                                      editedItem.FILEPATH_IJAZAH == 'null' ||
                                        editedItem.FILEPATH_IJAZAH == null ||
                                        editedItem.FILEPATH_IJAZAH == ''
                                    "
                                  ></v-checkbox>
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.USULAN_BEASISWA ==
                                    'Naik Jenjang Pendidikan'
                                "
                              >
                                <td>Lampiran Surat Diterima Sekolah</td>
                                <td>:</td>
                                <!-- <td>
                                  <a
                                    href="#"
                                    @click="clickLampiranSuratDiterima"
                                    style="color:green"
                                    >Lihat Lampiran</a
                                  >
                                </td> -->
                                <td>
                                  <v-btn
                                    href="#"
                                    small
                                    @click="getDataPDFSuratDiterima"
                                    style="color: green"
                                    >Lihat Lampiran Surat Diterima Sekolah
                                  </v-btn>
                                </td>
                                <td>
                                  <v-checkbox
                                    v-model="checkSuratDiterima"
                                    :disabled="
                                      editedItem.FILEPATH_SURATDITERIMA ==
                                        'null' ||
                                        editedItem.FILEPATH_SURATDITERIMA ==
                                          null ||
                                        editedItem.FILEPATH_SURATDITERIMA == ''
                                    "
                                  ></v-checkbox>
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.USULAN_BEASISWA ==
                                    'Naik Jenjang Pendidikan'
                                "
                              >
                                <td>Lampiran Surat Kelulusan</td>
                                <td>:</td>
                                <!-- <td>
                                  <a
                                    href="#"
                                    @click="clickLampiranSuratDiterima"
                                    style="color:green"
                                    >Lihat Lampiran</a
                                  >
                                </td> -->
                                <td>
                                  <v-btn
                                    href="#"
                                    small
                                    @click="getDataPDFKelulusan"
                                    style="color: green"
                                    >Lihat Lampiran Surat Kelulusan
                                  </v-btn>
                                </td>
                                <td>
                                  <v-checkbox
                                    v-model="checkKelulusan"
                                    :disabled="
                                      editedItem.FILEPATH_KELULUSAN == 'null' ||
                                        editedItem.FILEPATH_KELULUSAN == null ||
                                        editedItem.FILEPATH_KELULUSAN == ''
                                    "
                                  ></v-checkbox>
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.TINGKAT_BEASISWA == 'D' ||
                                    this.editedItem.TINGKAT_BEASISWA == 'E'
                                "
                              >
                                <td>Lampiran Surat Akreditasi Program Studi</td>
                                <td>:</td>
                                <!-- <td>
                                  <a
                                    href="#"
                                    @click="clickLampiranSuratDiterima"
                                    style="color:green"
                                    >Lihat Lampiran</a
                                  >
                                </td> -->
                                <td>
                                  <v-btn
                                    href="#"
                                    small
                                    @click="getDataPDFAkreditasiProdi"
                                    style="color: green"
                                    >Lihat Lampiran Surat Akreditasi Program
                                    Studi
                                  </v-btn>
                                </td>
                                <td>
                                  <v-checkbox
                                    v-model="checkAkreditasiProdi"
                                    :disabled="
                                      editedItem.FILEPATH_AKREDITASIPROGRAMSTUDI ==
                                        'null' ||
                                        editedItem.FILEPATH_AKREDITASIPROGRAMSTUDI ==
                                          null ||
                                        editedItem.FILEPATH_AKREDITASIPROGRAMSTUDI ==
                                          ''
                                    "
                                  ></v-checkbox>
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.TINGKAT_BEASISWA == 'D' ||
                                    this.editedItem.TINGKAT_BEASISWA == 'E'
                                "
                              >
                                <td>
                                  Lampiran Surat Akreditasi Akademi /
                                  Universitas
                                </td>

                                <td>:</td>
                                <!-- <td>
                                  <a
                                    href="#"
                                    @click="clickLampiranSuratDiterima"
                                    style="color:green"
                                    >Lihat Lampiran</a
                                  >
                                </td> -->
                                <td>
                                  <v-btn
                                    href="#"
                                    small
                                    @click="getDataPDFSuratAkreditasi"
                                    style="color: green"
                                    >Lihat Lampiran Surat Akreditasi
                                  </v-btn>
                                </td>
                                <td>
                                  <v-checkbox
                                    v-model="checkAkreditasi"
                                    :disabled="
                                      editedItem.FILEPATH_SURATAKREDITASI ==
                                        'null' ||
                                        editedItem.FILEPATH_SURATAKREDITASI ==
                                          null ||
                                        editedItem.FILEPATH_SURATAKREDITASI ==
                                          ''
                                    "
                                  ></v-checkbox>
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  this.editedItem.USULAN_BEASISWA ==
                                    'Naik Semester'
                                "
                              >
                                <td>Lampiran Surat Aktif Kuliah dari Kampus</td>

                                <td>:</td>
                                <td>
                                  <v-btn
                                    href="#"
                                    small
                                    @click="getDataPDFSuratAktifKuliah"
                                    style="color: green"
                                    >Lihat Lampiran Surat Aktif Kuliah
                                  </v-btn>
                                </td>
                                <td>
                                  <v-checkbox
                                    v-model="checkAktifKuliah"
                                    :disabled="
                                      editedItem.FILEPATH_AKTIFKULIAH ==
                                        'null' ||
                                        editedItem.FILEPATH_AKTIFKULIAH ==
                                          null ||
                                        editedItem.FILEPATH_AKTIFKULIAH == ''
                                    "
                                  ></v-checkbox>
                                </td>
                              </tr>
                              <tr
                                v-show="
                                  (this.editedItem.TINGKAT_BEASISWA == 'D' &&
                                    this.editedItem.POSTPONE == 'Ya') ||
                                    (this.editedItem.TINGKAT_BEASISWA == 'E' &&
                                      this.editedItem.POSTPONE == 'Ya')
                                "
                              >
                                <td>
                                  Lampiran Surat Keterangan Postpone dari Kampus
                                </td>

                                <td>:</td>
                                <td>
                                  <v-btn
                                    href="#"
                                    small
                                    @click="getDataPDFSuratKetPostpone"
                                    style="color: green"
                                    >Lihat Lampiran Surat Keterangan Postpone
                                  </v-btn>
                                </td>
                                <td>
                                  <v-checkbox
                                    v-model="checkPostpone"
                                    :disabled="
                                      editedItem.FILEPATH_SURATPOSTPONE ==
                                        'null' ||
                                        editedItem.FILEPATH_SURATPOSTPONE ==
                                          null ||
                                        editedItem.FILEPATH_SURATPOSTPONE == ''
                                    "
                                  ></v-checkbox>
                                </td>
                              </tr>
                              <tr>
                                <td>Approval Manager</td>
                                <td>:</td>
                                <td>
                                  {{
                                    this.editedItem.NIK_MGR +
                                      " - " +
                                      this.editedItem.NAMA_MGR
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Verifikasi</td>
                                <td>:</td>
                                <td>
                                  {{
                                    this.editedItem.NIK_VERIF +
                                      " - " +
                                      this.editedItem.NAMA_VERIF
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Otorisasi Awal</td>
                                <td>:</td>
                                <td>
                                  {{
                                    this.editedItem.NIK_OTORISASI_AWAL +
                                      " - " +
                                      this.editedItem.NAMA_OTORISASI_AWAL
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Otorisasi Final</td>
                                <td>:</td>
                                <td>
                                  {{
                                    this.editedItem.NIK_OTORISASI_AKHIR +
                                      " - " +
                                      this.editedItem.NAMA_OTORISASI_AKHIR
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" text @click="editPengajuan">
                          EDIT
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="saveFlag">
                          OK
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogGanjil" max-width="800px">
                  <v-card>
                    <v-form ref="form">
                      <v-card-title>
                        <span class="headline"
                          >Detail Nilai Semester Ganjil</span
                        >
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-simple-table dense class="pa-4 mx-3">
                            <tbody>
                              <tr>
                                <td>Nama Mapel</td>
                                <td>Nilai Pengetahuan</td>
                                <td>Nilai Keterampilan</td>
                                <td>Semester</td>
                              </tr>
                              <tr
                                v-for="item in arrayGanjil"
                                :key="item.ID_MAPEL"
                              >
                                <td>{{ item.NAMA_MAPEL }}</td>
                                <td>{{ item.NILAI_PENGETAHUAN }}</td>
                                <td>{{ item.NILAI_KETERAMPILAN }}</td>
                                <td>{{ item.SEMESTER }}</td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeGanjil">
                          OK
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogGenap" max-width="800px">
                  <v-card>
                    <v-form ref="form">
                      <v-card-title>
                        <span class="headline"
                          >Detail Nilai Semester Genap</span
                        >
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-simple-table dense class="pa-4 mx-3">
                            <tbody>
                              <tr>
                                <td>Nama Mapel</td>
                                <td>Nilai Pengetahuan</td>
                                <td>Nilai Keterampilan</td>
                                <td>Semester</td>
                              </tr>
                              <tr
                                v-for="item in this.arrayGenap"
                                :key="item.ID_MAPEL"
                              >
                                <td>{{ item.NAMA_MAPEL }}</td>
                                <td>{{ item.NILAI_PENGETAHUAN }}</td>
                                <td>{{ item.NILAI_KETERAMPILAN }}</td>
                                <td>{{ item.SEMESTER }}</td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeGenap">
                          OK
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogEdit" max-width="600px" persistent>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5"
                        >Edit Permohonan Bantuan Pendidikan</span
                      >
                    </v-card-title>
                    <v-card-text>
                      <v-simple-table dense class="pa-4 mx-3">
                        <tbody>
                          <tr>
                            <td style="width: 15%">Tingkat Pendidikan</td>
                            <td style="width: 1%">:</td>
                            <td colspan="2">
                              <v-radio-group
                                v-model="tingkatBeasiswaEdit"
                                column
                                @change="getPlafond()"
                              >
                                <v-radio
                                  v-for="item in itemsTingkatPendidikan"
                                  :key="item.ID_TINGKATPENDIDIKAN"
                                  :label="`${item.TINGKAT_PENDIDIKAN}`"
                                  :value="item.ID_TINGKATPENDIDIKAN"
                                  v-show="rule(item.ID_TINGKATPENDIDIKAN)"
                                ></v-radio>
                              </v-radio-group>
                            </td>
                          </tr>
                          <tr>
                            <td>Usulan Bantuan Pendidikan</td>
                            <td>:</td>
                            <td>
                              <v-radio-group
                                v-model="usulanBeasiswaEdit"
                                column
                                @change="changeUsulanBeasiswaEdit()"
                              >
                                <v-radio
                                  v-if="
                                    tingkatBeasiswaEdit == 'D' ||
                                      tingkatBeasiswaEdit == 'E'
                                  "
                                  label="Naik Semester"
                                  value="Naik Semester"
                                ></v-radio>
                                <v-radio
                                  v-else
                                  label="Naik Kelas"
                                  value="Naik Kelas"
                                ></v-radio>
                                <v-radio
                                  v-show="
                                    penerimaBeasiswaEdit == 'Anak Karyawan'
                                  "
                                  label="Naik Jenjang Pendidikan"
                                  value="Naik Jenjang Pendidikan"
                                ></v-radio>
                              </v-radio-group>
                            </td>
                          </tr>
                          <tr>
                            <td v-if="tingkatBeasiswaEdit == 'D' || tingkatBeasiswaEdit == 'E'">
                              Semester
                            </td>
                            <td v-else>
                              Kelas
                            </td>
                            <td>:</td>
                            <td>
                              <v-select
                                v-show="tingkatBeasiswaEdit == 'A'"
                                v-model="kelasBeasiswaEdit"
                                :items="filterSD"
                                dense
                                label="Pilih"
                                solo
                              ></v-select>
                              <v-select
                                v-show="tingkatBeasiswaEdit == 'B'"
                                v-model="kelasBeasiswaEdit"
                                :items="filterSMP"
                                dense
                                label="Pilih"
                                solo
                              ></v-select>
                              <v-select
                                v-show="tingkatBeasiswaEdit == 'C'"
                                v-model="kelasBeasiswaEdit"
                                :items="filterSMA"
                                dense
                                label="Pilih"
                                solo
                              ></v-select>
                              <v-select
                                v-show="
                                  tingkatBeasiswaEdit == 'D' &&
                                    penerimaBeasiswaEdit == 'Anak Karyawan'
                                "
                                v-model="kelasBeasiswaEdit"
                                :items="filterAkademi"
                                dense
                                label="Pilih"
                                solo
                              ></v-select>
                              <v-select
                                v-show="
                                  tingkatBeasiswaEdit == 'D' && penerimaBeasiswaEdit == 'Karyawan'
                                "
                                v-model="kelasBeasiswaEdit"
                                :items="items_kelasAkademiKary"
                                dense
                                label="Pilih"
                                solo
                              ></v-select>
                              <v-select
                                v-show="
                                  tingkatBeasiswaEdit == 'E' && penerimaBeasiswaEdit == 'Karyawan'
                                "
                                v-model="kelasBeasiswaEdit"
                                :items="items_kelasUniversitasKary"
                                dense
                                label="Pilih"
                                solo
                              ></v-select>
                              <v-select
                                v-show="
                                  tingkatBeasiswaEdit == 'E' &&
                                    penerimaBeasiswaEdit == 'Anak Karyawan'
                                "
                                v-model="kelasBeasiswaEdit"
                                :items="filterUniversitas"
                                dense
                                label="Pilih"
                                solo
                              ></v-select>
                            </td>
                          </tr>
                          <tr>
                            <td>Besaran Bantuan</td>
                            <td>:</td>
                            <td>Rp. {{ plafondBeasiswa }}/bulan</td>
                          </tr>
                          <tr
                            v-show="
                              tingkatBeasiswaEdit == 'D' ||
                                tingkatBeasiswaEdit == 'E'
                            "
                          >
                            <td>Periode Pemberian</td>
                            <td>:</td>
                            <v-radio-group
                              v-model="periodePemberianEdit"
                              column
                            >
                              <v-radio
                                label="1 Tahun"
                                value="1 Tahun"
                              ></v-radio>
                              <v-radio
                                label="6 Bulan"
                                value="6 Bulan"
                              ></v-radio>
                            </v-radio-group>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="cancelEdit">
                        Cancel
                      </v-btn>
                      <v-btn
                        v-show="
                          this.tingkatBeasiswaEdit != '' &&
                            this.penerimaBeasiswaEdit != '' &&
                            this.usulanBeasiswaEdit != '' &&
                            this.kelasBeasiswaEdit != ''
                        "
                        color="blue darken-1"
                        :disabled="dialogLoading"
                        :loading="dialogLoading"
                        text
                        @click="saveEdit"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="600px">
                  <v-card>
                    <!-- <v-overlay :value="overlayDialog">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay> -->
                    <v-form ref="form">
                      <v-card-title>
                        <span class="headline">Keterangan</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-overlay :value="overlayDialogReject">
                            <v-progress-circular
                              indeterminate
                              size="64"
                            ></v-progress-circular>
                          </v-overlay>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-textarea
                                v-model="alasan"
                                label="Alasan"
                                v-on:keypress="keterangan($event)"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveReject"
                          v-show="validSaveReject"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogRevisi" max-width="600px">
                  <v-card>
                    <!-- <v-overlay :value="overlayDialogRevisi">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay> -->
                    <v-form ref="form">
                      <v-card-title>
                        <span class="headline">Keterangan Revisi</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-overlay :value="overlayDialogRevisi">
                            <v-progress-circular
                              indeterminate
                              size="64"
                            ></v-progress-circular>
                          </v-overlay>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-textarea
                                v-model="alasanRevisi"
                                label="Alasan Revisi"
                                v-on:keypress="keterangan($event)"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeRevisi">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveRevisi"
                          v-show="validSaveRevisi"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="dialogLoading"
                  hide-overlay
                  persistent
                  width="300"
                >
                  <v-card color="primary" dark>
                    <v-card-text>
                      Please wait
                      <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                      ></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-card>
                  <form class="pa-4 mx-3">
                    <div class="my-2">
                      <v-row>
                        <v-col class="d-flex" cols="12" sm="3">
                          <v-autocomplete
                            :items="items_unit"
                            label="Unit"
                            v-model="unit"
                            item-text="PT"
                            item-value="ID_PT"
                            @change="dataCabang"
                            return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                          <v-autocomplete
                            :items="items_cabang"
                            label="Cabang"
                            v-model="cabang"
                            item-text="CABANG"
                            item-value="ID_CABANG"
                            return-object
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                          <v-autocomplete
                            :items="items_role"
                            label="Role"
                            v-model="role"
                            item-text="ROLE"
                            item-value="ROLE"
                            return-object
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="6" sm="2" class="shrink text-end">
                          <v-btn
                            color="primary"
                            block
                            :loading="btnLoading"
                            :disabled="validFilter"
                            @click="filterApproval"
                            >Filter
                          </v-btn>

                          <!-- <v-btn
                            color="primary"
                            block
                            :loading="btnLoading"
                            disabled
                            @click="filterApproval"
                            >Filter</v-btn
                          > -->
                        </v-col>
                        <v-col cols="6" sm="2" class="text-start">
                          <span class="ml-2 caption blue--text"
                            >*Silakan memilih Role terlebih dahulu, kemudian
                            klik tombol Filter</span
                          >
                        </v-col>
                      </v-row>

                      <v-card-title>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-if="this.selected.length > 0"
                          @click="multiApprove"
                        >
                          Approve Sesuai Checklist
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="itemsTable"
                        :search="search"
                        :single-select="singleSelect"
                        show-select
                        item-key="NO_TIKET"
                        :items-per-page="20"
                        class="elevation-1"
                        :footer-props="footerProps"
                        @update:items-per-page="getItemPerPage"
                      >
                        <!-- <template v-slot:[`header.data-table-select`]="{ item }"></template> -->
                        <template v-slot:[`item.data-table-select`]="{ item }">
                          <!-- {{item.CHECK_FLAG != 'TRUE' ? disabled : enable}} -->
                          <v-checkbox
                            :disabled="disableCheckbox(item)"
                            class="pa-0 ma-0"
                            :ripple="false"
                            v-model="selected"
                            :value="item"
                          >
                          </v-checkbox>
                        </template>
                        <template v-slot:[`item.STATUS_TRANSAKSI`]="{ item }">
                          {{
                            item.STATUS_TRANSAKSI == 0
                              ? "Draft"
                              : item.STATUS_TRANSAKSI == 1
                              ? "Approve Manager"
                              : item.STATUS_TRANSAKSI == 2
                              ? "Approve Verifikasi"
                              : item.STATUS_TRANSAKSI == 3
                              ? "Approve Otorisasi Awal"
                              : item.STATUS_TRANSAKSI == 4
                              ? "Approve Otorisasi Final"
                              : item.STATUS_TRANSAKSI == 10
                              ? "Reject Manager"
                              : item.STATUS_TRANSAKSI == 20
                              ? "Reject Verifikasi"
                              : item.STATUS_TRANSAKSI == 30
                              ? "Reject Otorisasi Awal"
                              : item.STATUS_TRANSAKSI == 40
                              ? "Reject Otorisasi Final"
                              : "Null"
                          }}
                        </template>
                        <template v-slot:[`item.CREATEDDATE`]="{ item }">
                          {{ formatDate(item.CREATEDDATE) }}
                        </template>
                        <template v-slot:[`item.REVISI_CREATEDDATE`]="{ item }">
                          {{
                            item.REVISI_CREATEDDATE == null ||
                            item.REVISI_CREATEDDATE == "" ||
                            item.REVISI_CREATEDDATE == "null"
                              ? "-"
                              : formatDate(item.REVISI_CREATEDDATE)
                          }}
                        </template>
                        <template
                          v-slot:[`item.actions`]="{ item }"
                          v-if="!this.selected.length > 0"
                        >
                          <v-btn
                            small
                            class="mr-2"
                            color="green"
                            @click="approve(item)"
                            :disabled="disableCheckbox(item)"
                          >
                            Approve
                          </v-btn>
                          <v-btn
                            small
                            class="mr-2"
                            color="orange"
                            @click="revisi(item)"
                            :disabled="disableRevisi(item)"
                          >
                            Revisi
                          </v-btn>
                          <v-btn
                            small
                            color="red"
                            @click="reject(item)"
                            :disabled="disableCheckbox(item)"
                          >
                            Reject
                          </v-btn>
                        </template>
                        <template v-slot:[`item.details`]="{ item }">
                          <v-btn
                            small
                            color="primary"
                            block
                            @click="detail(item)"
                            :disabled="disableLihatDetail(item)"
                            >Lihat Detail</v-btn
                          >
                          <!-- <v-btn
                            small
                            color="primary"
                            block
                            @click="detail(item)"
                            >Lihat Detail</v-btn
                          > -->
                          <!-- <v-icon small @click="detail(item)">
                            mdi-clipboard-text
                          </v-icon> -->
                        </template>
                      </v-data-table>
                    </div>
                  </form>
                </v-card>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
// import Sidebar from "@/components/Sidebar.vue";
// import Toolbar from "@/components/Toolbar.vue";
import CNavbar from "../../components/CNavbar.vue";
import CToolbar from "../../components/CToolbar.vue";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    CNavbar,
    CToolbar,
  },

  mounted() {
    if (sessionStorage.getItem("pejabat") == "1") {
      if (localStorage.getItem("reloaded")) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem("reloaded");
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem("reloaded", "1");
        location.reload();
      }
    }
    // if (this.user.data[0].NikKaryawan == "1988000268") {
    //   this.initialize();
    // }
    this.notifBeasiswaSekolahUserAwal = this.user.data[0].NotifBeasiswaSekolahUserAwal;
    this.notifBeasiswaSekolahUserAkhir = this.user.data[0].NotifBeasiswaSekolahUserAkhir;
    this.notifBeasiswaInstitutUserAwal = this.user.data[0].NotifBeasiswaInstitutUserAwal;
    this.notifBeasiswaInstitutUserAkhir = this.user.data[0].NotifBeasiswaInstitutUserAkhir;
    // this.role = this.user.data[0].RoleBeasiswa.ROLE;
    this.dataUnit();
    this.dataRole();
    this.TOKEN = this.token;
    this.vloading = true;
  },

  data() {
    return {
      // $baseurl: "http://localhost/",
      // $baseurl: "http://116.204.128.50:80/",
      // $baseurl: "https://ms.hrindomaret.com/",
      singleSelect: false,
      tooltipText:
        "Silakan memilih Role terlebih dahulu, kemudian klik tombol Filter",
      search: "",
      selected: [],
      alasan: "",
      alasanRevisi: "",
      idsekolah: null,
      besaranPlafond: "",
      statustransaksiText: "",
      pernahPostpone: "",
      notifBeasiswaSekolahUserAwal: "",
      notifBeasiswaSekolahUserAkhir: "",
      notifBeasiswaInstitutUserAwal: "",
      notifBeasiswaInstitutUserAkhir: "",
      tingkat: "",
      tingkatBeasiswa: "",
      tingkatBeasiswaEdit: "",
      penerimaBeasiswaEdit: "",
      usulanBeasiswaEdit: "",
      kelasBeasiswaEdit: "",
      plafondBeasiswaEdit: "",
      headers_list: [
        { text: "NPSN", value: "EDUC_ID" },
        { text: "Sekolah", value: "EDUC_NAME" },
        { text: "Actions", value: "detail", sortable: false },
      ],
      headers_jurusan: [
        { text: "ID", value: "EDUC_ID" },
        { text: "Jurusan", value: "EDUC_NAME" },
        { text: "Actions", value: "detail", sortable: false },
      ],
      rulesUpload: [
        (v) => !v || v.size <= 1000000 || "Ukuran file maks 1 MB!",
        (v) => !!v || "File harus diisi",
        (v) => !v || v.type == "application/pdf" || "File harus pdf",
      ],
      filesPostpone: null,
      namaPDFSuratPostpone: "",
      dialog: false,
      dialogRevisi: false,
      dialogDetail: false,
      dialogEdit: false,
      dialogLoading: false,
      dialogLampiranRaport: false,
      dialogLampiranIjazah: false,
      dialogLampiranSuratDiterima: false,
      dialogSekolah: false,
      dialogJurusan: false,
      dialogGanjil: false,
      dialogGenap: false,
      btnLoading: false,
      vloading: false,
      overlay: false,
      overlayDialog: false,
      overlayDialogRevisi: false,
      overlayDialogReject: false,
      checkGanjil: false,
      checkGenap: false,
      checkIjazah: false,
      checkKelulusan: false,
      checkSuratDiterima: false,
      checkAkreditasiProdi: false,
      checkAkreditasi: false,
      checkPostpone: false,
      checkAktifKuliah: false,
      filesTranskripGanjil: null,
      filesTranskripGenap: null,
      filesDiterima: null,
      filesAkreditasiProdi: null,
      filesAkreditasiKampus: null,
      filesKelulusan: null,
      filesIjazah: null,
      filesPostpone: null,
      filesAktifKuliah: null,
      fileblob_raport: null,
      fileblob_ipk: null,
      fileblob_kelulusan: null,
      fileblob_ijazah: null,
      fileblob_suratditerima: null,
      fileblob_suratakreditasi: null,
      fileblob_akreditasiprogramstudi: null,
      fileblob_suratpostpone: null,
      fileblob_aktifkuliah: null,
      inputsganjil: [],
      inputsgenap: [],
      mapelganjilarray: [],
      mapelgenaparray: [],
      pengetahuanganjilarray: [],
      pengetahuangenaparray: [],
      keterampilanganjilarray: [],
      keterampilangenaparray: [],
      items_kelasSD: ["1", "2", "3", "4", "5", "6"],
      items_kelasSMP: ["7", "8", "9"],
      items_kelasSMA: ["10", "11", "12", "13"],
      items_kelasAkademi: ["1", "2", "3", "4", "5", "6"],
      items_kelasAkademiKary: ["3", "4", "5", "6"],
      items_kelasUniversitas: ["1", "2", "3", "4", "5", "6", "7", "8"],
      items_kelasUniversitasKary: ["3", "4", "5", "6", "7", "8"],
      items_postponeUniv: ["1", "2", "3", "4"],
      items_postponeAkademi: ["1", "2"],

      // pagination: {
      //   rowsPerPage: 20
      // },
      footerProps: { "items-per-page-options": [20, 40, 60, 100] },
      // unit: "",
      // cabang: "",
      unit: {
        ID_PT: "All",
        PT: "All",
      },
      cabang: {
        ID_CABANG: "All",
        CABANG: "All",
      },
      role: "",
      headers: [
        {
          text: "No Tiket",
          align: "start",
          value: "NO_TIKET",
        },
        { text: "NIK Karyawan", value: "NIK" },
        { text: "Nama Karyawan", value: "NAMA_KARYAWAN" },
        { text: "Tanggal Pengajuan", value: "CREATEDDATE" },
        { text: "Tingkat Pendidikan", value: "NAMA_TINGKAT_BEASISWA" },
        { text: "Status", value: "STATUS_TRANSAKSI" },
        { text: "Tanggal Revisi", value: "REVISI_CREATEDDATE" },
        { text: "Alasan Revisi", value: "KETERANGAN_REJECT" },
        { text: "Detail", value: "details", sortable: false },
        { text: "Action", value: "actions", sortable: false },
      ],
      editedItem: {
        NIK: "",
        NAMA_KARYAWAN: "",
        UNIT_NAME: "",
        CABANG_NAME: "",
        BAGIAN_NAME: "",
        JABATAN: "",
        JOBCLASS: "",
        PERIODE_BEASISWA: "",
        PENERIMA_BEASISWA: "",
        NAMA_ANAK_KARYAWAN: "",
        NAMA_TINGKAT_BEASISWA: "",
        TINGKAT_BEASISWA: "",
        USULAN_BEASISWA: "",
        NPSN_SEKOLAH: "",
        NAMA_SEKOLAH: "",
        KELAS: "",
        JURUSAN: "",
        AKREDITASI_JURUSAN: "",
        AKREDITASI_KAMPUS: "",
        TAHUN_MASUK: "",
        PLAFOND_BEASISWA: "",
        TAHUN_AJARAN_BARU: "",
        NILAI_GANJIL: "",
        NILAI_GENAP: "",
        NILAI_RATARATA: "",
        FILEBLOB_IJAZAH: "",
        // FILEPATH_RAPORT: "",
        FILEBLOB_RAPORT: "",
        FIEBLOB_SURATDITERIMA: "",
        EXTENSION_RAPORT: "",
        EXTENSION_IJAZAH: "",
        EXTENSION_SURATDITERIMA: "",
        NIK_MGR: "",
        NAMA_MGR: "",
        NIK_VERIF: "",
        NAMA_VERIF: "",
        NIK_OTORISASI_AWAL: "",
        NAMA_OTORISASI_AWAL: "",
        NIK_OTORISASI_AKHIR: "",
        NAMA_OTORISASI_AKHIR: "",
      },
      itemsTableApproval: [],
      itemsTingkatPendidikan: [],
      itemsAnak: [],
      items_unit: [],
      items_cabang: [],
      items_role: [],
      items_blob: [],
      items_cekblob: [],
      arrayGanjil: [],
      arrayGenap: [],
      // desserts: [
      //   {
      //     name: "2015169897",
      //     calories: "Dimas Rizky Romadhon",
      //     fat: "1",
      //     carbs: "20-04-2021",
      //     protein: "Sleman",
      //     iron: "Approve Reject",
      //   },
      // ],
    };
  },

  computed: {
    ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      token: "token",
      // getDrawer: "drawer",
    }),

    itemsTable() {
      return this.itemsTableApproval.map((x) => ({
        ...x,
        isSelectable: this.isCheckFlagTrue(x),
      }));
    },

    validSaveReject() {
      return this.alasan != "";
    },
    validSaveRevisi() {
      return this.alasanRevisi != "";
    },
    validFilter() {
      return this.role == "";
    },
    validButton() {
      return (
        this.checkGanjil != false && this.checkGenap != false
        // this.checkIjazah == false &&
        // this.checkKelulusan == false &&
        // this.checkSuratDiterima == false &&
        // this.checkAkreditasiProdi == false &&
        // this.checkAkreditasi == false
      );
    },
    // headers() {
    //   //set having unique values
    //   let s = new Set();
    //   var f;

    //   this.itemsTableApproval.forEach((item) => {
    //     for (f in item) {
    //       //adding an existing value doesn't override the old one
    //       s.add(f);
    //     }
    //   });
    //   //respect the headers format required by Vuetify which
    //   // should has at least two fields (text, value)
    //   return Array.from(s).map((a) => {
    //     return {
    //       text: a.toUpperCase(),
    //       value: a,
    //     };
    //   });
    // },

    // formatRpEdit(value) {
    //   console.log(this.editedItem,"tes")
    //   if(this.editedItem.PERIODE_PEMBERIAN == '6 Bulan'){
    //     console.log("value: "+value)
    //     let set = value/2
    //     return set.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    //   }else{
    //     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    //   }

    // },

    plafondBeasiswa() {
      let value = this.plafondBeasiswaEdit;
      let value2 = this.editedItem.PERIODE_PEMBERIAN;
      console.log(value, "value");
      console.log(value2, "value2");
      // if (this.editedItem.PERIODE_PEMBERIAN == "6 Bulan") {
      //   let halved = value / 2;
      //   this.besaranPlafond = halved;
      //   return halved.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      // } else {
      //   this.besaranPlafond = value;
      //   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      // }
      this.besaranPlafond = value;
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    tahunmasukFormatted() {
      return this.formatMonth(this.editedItem.TAHUN_MASUK);
    },

    filterSD() {
      if (this.usulanBeasiswaEdit == "Naik Jenjang Pendidikan") {
        return this.items_kelasSD.filter((item) => item === "1");
      } else {
        return this.items_kelasSD;
      }
    },

    filterSMP() {
      if (this.usulanBeasiswaEdit == "Naik Jenjang Pendidikan") {
        return this.items_kelasSMP.filter((item) => item === "7");
      } else {
        return this.items_kelasSMP;
      }
    },

    filterSMA() {
      if (this.usulanBeasiswaEdit == "Naik Jenjang Pendidikan") {
        return this.items_kelasSMA.filter((item) => item === "10");
      } else {
        return this.items_kelasSMA;
      }
    },

    filterAkademi() {
      if (this.usulanBeasiswaEdit == "Naik Jenjang Pendidikan") {
        return this.items_kelasAkademi.filter((item) => item === "1");
      } else {
        return this.items_kelasAkademi;
      }
    },

    filterUniversitas() {
      if (this.usulanBeasiswaEdit == "Naik Jenjang Pendidikan") {
        return this.items_kelasUniversitas.filter((item) => item === "1");
      } else {
        return this.items_kelasUniversitas;
      }
    },
  },

  created() {
    if (this.user.data[0].NikKaryawan == "1988000268") {
      console.log(this.user.data[0].RoleBeasiswa[0].ROLE);
      this.role = this.user.data[0].RoleBeasiswa[0];
      this.initialize();
    }
    // this.role = this.user.data[0].RoleBeasiswa[0];
    // this.initialize();
  },

  methods: {
    ...mapActions({
      getDataUserBeasiswa: "getDataUserBeasiswa",
      getDataKaryawanBeasiswa: "getDataKaryawanBeasiswa",
      insertDataMstPlafondBeasiswa: "insertDataMstPlafondBeasiswa",
      insertDataMstUserBeasiswa: "insertDataMstUserBeasiswa",
      getInstPendidikanBeasiswa: "getInstPendidikanBeasiswa",
      insertDataMstMaPelBeasiswa: "insertDataMstMaPelBeasiswa",
      insertDataPengajuanBeasiswa: "insertDataPengajuanBeasiswa",
      getDataAnakBeasiswa: "getDataAnakBeasiswa",
      insertDataMstMaPelBeasiswa: "insertDataMstMaPelBeasiswa",
      getDataRevisiBeasiswa: "getDataRevisiBeasiswa",

      approveDataPengajuanBeasiswa: "approveDataPengajuanBeasiswa",
      getDataFileBlobBeasiswa: "getDataFileBlobBeasiswa",
      getDataRoleBeasiswa: "getDataRoleBeasiswa",
      insertDataDetailNilaiBeasiswa: "insertDataDetailNilaiBeasiswa",
      approveDataPengajuanBeasiswa: "approveDataPengajuanBeasiswa",
    }),
    initialize() {
      // this.vloading = true
      this.btnLoading = true;
      const code = {
        nik: this.user.data[0].NikKaryawan,
        idpt: this.unit.ID_PT,
        idcabang: this.cabang.ID_CABANG,
        role: this.role.ROLE,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      // // // console.log(code);
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataUserBeasiswa", code, {
      //     headers,
      //   })
      this.getDataUserBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
            // this.vloading = false
            this.btnLoading = false;
          } else {
            // // console.log(result.message);
            let hasil = result.data;
            // console.log(hasil)
            this.btnLoading = false;
            this.itemsTableApproval = hasil;
            this.tingkat = hasil.TINGKAT_BEASISWA;
            this.selected = [];
            // this.checkGanjil = result.data.FLAG_REVIEW.RAPORT
            // let hasiltest = hasil.map((item) => {
            //   return {
            //     "No Tiket": item.NO_TIKET,
            //     "NIK Karyawan": item.NIK,
            //     "Nama Karyawan": item.NAMA_KARYAWAN,
            //     "Tanggal Pengajuan": item.CREATEDDATE,
            //     "Status": item.STATUS_TRANSAKSI
            //   };
            // });
            // this.itemsTableApproval = hasiltest;
            // console.log(hasiltest);
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.vloading = false
            this.btnLoading = false;
            // this.snackbar=true;
          }
        });
    },

    isCheckFlagTrue(item) {
      console.log(this.role.ROLE, "ROLE");
      if (
        this.role.ROLE == "Manager" ||
        this.role.ROLE == "Verifikasi" ||
        this.role.ROLE == "Otorisasi Awal"
      ) {
        if (
          (this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAwal == "") &&
          (this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAwal == "")
        ) {
          // console.log("ditutup semua");
          return false;
        } else if (
          (this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAwal == "") &&
          this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Dibuka"
        ) {
          // console.log("awal ditutup");
          return (
            (item.CHECK_FLAG == "TRUE" &&
              this.role.ROLE != "Otorisasi Akhir" &&
              item.TINGKAT_BEASISWA == "D") ||
            (item.CHECK_FLAG == "TRUE" &&
              this.role.ROLE != "Otorisasi Akhir" &&
              item.TINGKAT_BEASISWA == "E")
          );
        } else if (
          this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Dibuka" &&
          (this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAwal == "")
        ) {
          // console.log("akhir ditutup");
          // console.log(item.CHECK_FLAG, "flag");
          // console.log(this.role.ROLE, "role");
          // console.log(item.TINGKAT_BEASISWA, "tingkat");
          return (
            item.CHECK_FLAG == "TRUE" &&
            (this.role.ROLE == "Manager" ||
              this.role.ROLE == "Verifikasi" ||
              this.role.ROLE == "Otorisasi Awal") &&
            (item.TINGKAT_BEASISWA == "A" ||
              item.TINGKAT_BEASISWA == "B" ||
              item.TINGKAT_BEASISWA == "C")
          );
        } else {
          // console.log("dibuka semua");
          return item.CHECK_FLAG == "TRUE";
        }
      } else if (this.role.ROLE == "Otorisasi Akhir") {
        if (
          (this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAkhir == "") &&
          (this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAkhir == "")
        ) {
          return false;
        } else if (
          (this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAkhir == "") &&
          this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Dibuka"
        ) {
          return (
            (item.CHECK_FLAG == "TRUE" &&
              this.role.ROLE == "Otorisasi Akhir" &&
              item.TINGKAT_BEASISWA == "D") ||
            (item.CHECK_FLAG == "TRUE" &&
              this.role.ROLE == "Otorisasi Akhir" &&
              item.TINGKAT_BEASISWA == "E")
          );
        } else if (
          this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Dibuka" &&
          (this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAkhir == "")
        ) {
          return (
            (item.CHECK_FLAG == "TRUE" &&
              this.role.ROLE == "Otorisasi Akhir" &&
              item.TINGKAT_BEASISWA == "A") ||
            (item.CHECK_FLAG == "TRUE" &&
              this.role.ROLE == "Otorisasi Akhir" &&
              item.TINGKAT_BEASISWA == "B") ||
            (item.CHECK_FLAG == "TRUE" &&
              this.role.ROLE == "Otorisasi Akhir" &&
              item.TINGKAT_BEASISWA == "C")
          );
        } else {
          return item.CHECK_FLAG == "TRUE";
        }
      }
    },

    disableCheckbox(item) {
      console.log(this.role.ROLE, "ROLE");
      if (
        this.role.ROLE == "Manager" ||
        this.role.ROLE == "Verifikasi" ||
        this.role.ROLE == "Otorisasi Awal"
      ) {
        if (
          (this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAwal == "") &&
          (this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAwal == "")
        ) {
          // console.log("ditutup semua");
          return true;
        } else if (
          (this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAwal == "") &&
          this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Dibuka"
        ) {
          // console.log("awal ditutup");
          return (
            (this.role.ROLE != "Otorisasi Akhir" &&
              (item.TINGKAT_BEASISWA == "A" ||
                item.TINGKAT_BEASISWA == "B" ||
                item.TINGKAT_BEASISWA == "C")) ||
            (item.TINGKAT_BEASISWA == "D" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "E" && item.CHECK_FLAG != "TRUE")
          );
        } else if (
          this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Dibuka" &&
          (this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAwal == "")
        ) {
          // console.log("akhir ditutup");
          console.log(item.CHECK_FLAG, "flag");
          console.log(this.role.ROLE, "role");
          console.log(item.TINGKAT_BEASISWA, "tingkat");
          return (
            (this.role.ROLE != "Otorisasi Akhir" &&
              (item.TINGKAT_BEASISWA == "D" || item.TINGKAT_BEASISWA == "E")) ||
            (item.TINGKAT_BEASISWA == "A" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "B" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "C" && item.CHECK_FLAG != "TRUE")
          );
        } else {
          // console.log("dibuka semua");
          return item.CHECK_FLAG != "TRUE";
        }
      } else if (this.role.ROLE == "Otorisasi Akhir") {
        if (
          (this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAkhir == "") &&
          (this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAkhir == "")
        ) {
          return true;
        } else if (
          (this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAkhir == "") &&
          this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Dibuka"
        ) {
          return (
            (this.role.ROLE == "Otorisasi Akhir" &&
              (item.TINGKAT_BEASISWA == "A" ||
                item.TINGKAT_BEASISWA == "B" ||
                item.TINGKAT_BEASISWA == "C")) ||
            (item.TINGKAT_BEASISWA == "D" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "E" && item.CHECK_FLAG != "TRUE")
          );
        } else if (
          this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Dibuka" &&
          (this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAkhir == "")
        ) {
          return (
            (this.role.ROLE == "Otorisasi Akhir" &&
              (item.TINGKAT_BEASISWA == "D" || item.TINGKAT_BEASISWA == "E")) ||
            (item.TINGKAT_BEASISWA == "A" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "B" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "C" && item.CHECK_FLAG != "TRUE")
          );
        } else {
          return item.CHECK_FLAG != "TRUE";
        }
      }
    },

    disableRevisi(item) {
      console.log(this.role.ROLE, "ROLE");
      if (
        this.role.ROLE == "Manager" ||
        this.role.ROLE == "Verifikasi" ||
        this.role.ROLE == "Otorisasi Awal"
      ) {
        if (
          (this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAwal == "") &&
          (this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAwal == "")
        ) {
          // console.log("ditutup semua");
          return true;
        } else if (
          (this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAwal == "") &&
          this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Dibuka"
        ) {
          // console.log("sekolah ditutup");
          return (
            (this.role.ROLE != "Otorisasi Akhir" &&
              (item.TINGKAT_BEASISWA == "A" ||
                item.TINGKAT_BEASISWA == "B" ||
                item.TINGKAT_BEASISWA == "C")) ||
            (item.TINGKAT_BEASISWA == "D" && item.CHECK_FLAG == "TRUE") ||
            (item.TINGKAT_BEASISWA == "E" && item.CHECK_FLAG == "TRUE")
          );
        } else if (
          this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Dibuka" &&
          (this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAwal == "")
        ) {
          // console.log("kuliah ditutup");
          // console.log(item.CHECK_FLAG, "flag");
          // console.log(this.role.ROLE, "role");
          // console.log(item.TINGKAT_BEASISWA, "tingkat");
          return (
            (this.role.ROLE != "Otorisasi Akhir" &&
              (item.TINGKAT_BEASISWA == "D" || item.TINGKAT_BEASISWA == "E")) ||
            (item.TINGKAT_BEASISWA == "A" && item.CHECK_FLAG == "TRUE") ||
            (item.TINGKAT_BEASISWA == "B" && item.CHECK_FLAG == "TRUE") ||
            (item.TINGKAT_BEASISWA == "C" && item.CHECK_FLAG == "TRUE")
          );
        } else {
          // console.log("dibuka semua");
          return item.CHECK_FLAG == "TRUE";
        }
      } else if (this.role.ROLE == "Otorisasi Akhir") {
        if (
          (this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAkhir == "") &&
          (this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAkhir == "")
        ) {
          return true;
        } else if (
          (this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAkhir == "") &&
          this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Dibuka"
        ) {
          return (
            (this.role.ROLE == "Otorisasi Akhir" &&
              (item.TINGKAT_BEASISWA == "A" ||
                item.TINGKAT_BEASISWA == "B" ||
                item.TINGKAT_BEASISWA == "C")) ||
            (item.TINGKAT_BEASISWA == "D" && item.CHECK_FLAG == "TRUE") ||
            (item.TINGKAT_BEASISWA == "E" && item.CHECK_FLAG == "TRUE")
          );
        } else if (
          this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Dibuka" &&
          (this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAkhir == "")
        ) {
          return (
            (this.role.ROLE == "Otorisasi Akhir" &&
              (item.TINGKAT_BEASISWA == "D" || item.TINGKAT_BEASISWA == "E")) ||
            (item.TINGKAT_BEASISWA == "A" && item.CHECK_FLAG == "TRUE") ||
            (item.TINGKAT_BEASISWA == "B" && item.CHECK_FLAG == "TRUE") ||
            (item.TINGKAT_BEASISWA == "C" && item.CHECK_FLAG == "TRUE")
          );
        } else {
          return item.CHECK_FLAG == "TRUE";
        }
      }
    },

    disableLihatDetail(item) {
      if (
        this.role.ROLE == "Manager" ||
        this.role.ROLE == "Verifikasi" ||
        this.role.ROLE == "Otorisasi Awal"
      ) {
        if (
          (this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAwal == "") &&
          (this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAwal == "")
        ) {
          // console.log("ditutup semua");
          return true;
        } else if (
          (this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAwal == "") &&
          this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Dibuka"
        ) {
          // console.log("awal ditutup");
          return (
            this.role.ROLE != "Otorisasi Akhir" &&
            (item.TINGKAT_BEASISWA == "A" ||
              item.TINGKAT_BEASISWA == "B" ||
              item.TINGKAT_BEASISWA == "C")
          );
        } else if (
          this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Dibuka" &&
          (this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAwal == "")
        ) {
          // console.log("akhir ditutup");
          // console.log(item.CHECK_FLAG, "flag");
          // console.log(this.role.ROLE, "role");
          // console.log(item.TINGKAT_BEASISWA, "tingkat");
          return (
            this.role.ROLE != "Otorisasi Akhir" &&
            (item.TINGKAT_BEASISWA == "D" || item.TINGKAT_BEASISWA == "E")
          );
        } else {
          // console.log("dibuka semua");
          return false;
        }
      } else if (this.role.ROLE == "Otorisasi Akhir") {
        console.log(this.notifBeasiswaSekolahUserAkhir);
        console.log(this.notifBeasiswaInstitutUserAkhir);
        if (
          (this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAkhir == "") &&
          (this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAkhir == "")
        ) {
          // console.log("ditutup semua");
          return true;
        } else if (
          (this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Ditutup" ||
            this.notifBeasiswaSekolahUserAkhir == "") &&
          this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Dibuka"
        ) {
          // console.log("sekolah ditutup");
          return (
            this.role.ROLE == "Otorisasi Akhir" &&
            (item.TINGKAT_BEASISWA == "A" ||
              item.TINGKAT_BEASISWA == "B" ||
              item.TINGKAT_BEASISWA == "C")
          );
        } else if (
          this.notifBeasiswaSekolahUserAkhir ==
            "Persetujuan Akhir SD,SMP,SMA Dibuka" &&
          (this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Ditutup" ||
            this.notifBeasiswaInstitutUserAkhir == "")
        ) {
          // console.log("kuliah ditutup");
          return (
            this.role.ROLE == "Otorisasi Akhir" &&
            (item.TINGKAT_BEASISWA == "D" || item.TINGKAT_BEASISWA == "E")
          );
        } else {
          // console.log("dibuka semua");
          return false;
        }
      }
    },

    disableApproval(item) {
      if (
        (this.notifBeasiswaSekolahUserAwal ==
          "Persetujuan Awal SD,SMP,SMA Dibuka" ||
          this.notifBeasiswaSekolahUserAwal == "") &&
        (this.notifBeasiswaSekolahUserAkhir ==
          "Persetujuan Akhir SD,SMP,SMA Ditutup" ||
          this.notifBeasiswaSekolahUserAkhir == "") &&
        (this.notifBeasiswaInstitutUserAwal ==
          "Persetujuan Awal Akademi dan Universitas Dibuka" ||
          this.notifBeasiswaInstitutUserAwal == "") &&
        (this.notifBeasiswaInstitutUserAkhir ==
          "Persetujuan Akhir Akademi dan Universitas Ditutup" ||
          this.notifBeasiswaInstitutUserAkhir == "")
      ) {
        // console.log("Awal Buka");
        if (this.role.ROLE == "Otorisasi Akhir") {
          return (
            item.TINGKAT_BEASISWA == "A" ||
            item.TINGKAT_BEASISWA == "B" ||
            item.TINGKAT_BEASISWA == "C" ||
            item.TINGKAT_BEASISWA == "D" ||
            item.TINGKAT_BEASISWA == "E"
          );
        } else {
          return (
            (item.TINGKAT_BEASISWA == "A" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "B" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "C" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "D" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "E" && item.CHECK_FLAG != "TRUE")
          );
        }
      } else if (
        (this.notifBeasiswaSekolahUserAwal ==
          "Persetujuan Awal SD,SMP,SMA Ditutup" ||
          this.notifBeasiswaSekolahUserAwal == "") &&
        (this.notifBeasiswaSekolahUserAkhir ==
          "Persetujuan Akhir SD,SMP,SMA Dibuka" ||
          this.notifBeasiswaSekolahUserAkhir == "") &&
        (this.notifBeasiswaInstitutUserAwal ==
          "Persetujuan Awal Akademi dan Universitas Ditutup" ||
          this.notifBeasiswaInstitutUserAwal == "") &&
        (this.notifBeasiswaInstitutUserAkhir ==
          "Persetujuan Akhir Akademi dan Universitas Dibuka" ||
          this.notifBeasiswaInstitutUserAkhir == "")
      ) {
        // console.log("Akhir Buka");
        if (this.role.ROLE != "Otorisasi Akhir") {
          return (
            item.TINGKAT_BEASISWA == "A" ||
            item.TINGKAT_BEASISWA == "B" ||
            item.TINGKAT_BEASISWA == "C" ||
            item.TINGKAT_BEASISWA == "D" ||
            item.TINGKAT_BEASISWA == "E"
          );
        } else {
          return (
            (item.TINGKAT_BEASISWA == "A" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "B" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "C" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "D" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "E" && item.CHECK_FLAG != "TRUE")
          );
        }
      } else if (
        (this.notifBeasiswaSekolahUserAwal ==
          "Persetujuan Awal SD,SMP,SMA Dibuka" ||
          this.notifBeasiswaSekolahUserAwal == "") &&
        (this.notifBeasiswaSekolahUserAkhir ==
          "Persetujuan Akhir SD,SMP,SMA Dibuka" ||
          this.notifBeasiswaSekolahUserAkhir == "") &&
        (this.notifBeasiswaInstitutUserAwal ==
          "Persetujuan Awal Akademi dan Universitas Ditutup" ||
          this.notifBeasiswaInstitutUserAwal == "") &&
        (this.notifBeasiswaInstitutUserAkhir ==
          "Persetujuan Akhir Akademi dan Universitas Ditutup" ||
          this.notifBeasiswaInstitutUserAkhir == "")
      ) {
        // console.log("Sekolah Awal Buka");
        // console.log(item.TINGKAT_BEASISWA);
        // console.log(this.role.ROLE);
        return (
          item.TINGKAT_BEASISWA == "D" ||
          item.TINGKAT_BEASISWA == "E" ||
          (item.TINGKAT_BEASISWA == "A" && item.CHECK_FLAG != "TRUE") ||
          (item.TINGKAT_BEASISWA == "B" && item.CHECK_FLAG != "TRUE") ||
          (item.TINGKAT_BEASISWA == "C" && item.CHECK_FLAG != "TRUE")
        );
      } else if (
        (this.notifBeasiswaSekolahUserAkhir ==
          "Persetujuan Akhir SD,SMP,SMA Dibuka" ||
          this.notifBeasiswaInstitutUserAkhir == "") &&
        (this.notifBeasiswaSekolahUserAwal ==
          "Persetujuan Awal SD,SMP,SMA Ditutup" ||
          this.notifBeasiswaSekolahUserAwal == "") &&
        (this.notifBeasiswaInstitutUserAwal ==
          "Persetujuan Awal Akademi dan Universitas Ditutup" ||
          this.notifBeasiswaInstitutUserAwal == "") &&
        (this.notifBeasiswaInstitutUserAkhir ==
          "Persetujuan Akhir Akademi dan Universitas Ditutup" ||
          this.notifBeasiswaInstitutUserAkhir == "")
      ) {
        // console.log("sekolah akhir buka");
        return (
          (item.TINGKAT_BEASISWA == "A" &&
            item.CHECK_FLAG != "TRUE" &&
            this.role.ROLE != "Otorisasi Akhir") ||
          (item.TINGKAT_BEASISWA == "B" &&
            item.CHECK_FLAG != "TRUE" &&
            this.role.ROLE != "Otorisasi Akhir") ||
          (item.TINGKAT_BEASISWA == "C" &&
            item.CHECK_FLAG != "TRUE" &&
            this.role.ROLE != "Otorisasi Akhir") ||
          item.TINGKAT_BEASISWA == "D" ||
          item.TINGKAT_BEASISWA == "E"
        );
      } else if (
        this.notifBeasiswaSekolahUserAkhir ==
          "Persetujuan Akhir SD,SMP,SMA Ditutup" ||
        (this.notifBeasiswaSekolahUserAkhir == "" &&
          this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Ditutup") ||
        (this.notifBeasiswaSekolahUserAwal == "" &&
          this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Dibuka") ||
        (this.notifBeasiswaInstitutUserAwal == "" &&
          this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Dibuka") ||
        this.notifBeasiswaInstitutUserAkhir == ""
      ) {
        // console.log("Univ Awal Buka");
        return (
          item.TINGKAT_BEASISWA == "A" ||
          item.TINGKAT_BEASISWA == "B" ||
          item.TINGKAT_BEASISWA == "C" ||
          (item.TINGKAT_BEASISWA == "D" && item.CHECK_FLAG != "TRUE") ||
          (item.TINGKAT_BEASISWA == "E" && item.CHECK_FLAG != "TRUE")
        );
      } else if (
        this.notifBeasiswaSekolahUserAkhir ==
          "Persetujuan Akhir SD,SMP,SMA Ditutup" ||
        (this.notifBeasiswaSekolahUserAkhir == "" &&
          this.notifBeasiswaSekolahUserAwal ==
            "Persetujuan Awal SD,SMP,SMA Ditutup") ||
        (this.notifBeasiswaSekolahUserAwal == "" &&
          this.notifBeasiswaInstitutUserAwal ==
            "Persetujuan Awal Akademi dan Universitas Ditutup") ||
        (this.notifBeasiswaInstitutUserAwal == "" &&
          this.notifBeasiswaInstitutUserAkhir ==
            "Persetujuan Akhir Akademi dan Universitas Dibuka") ||
        this.notifBeasiswaIntitusUserAkhir
      ) {
        // console.log("Univ Akhir Buka");
        if (
          item.TINGKAT_BEASISWA == "A" ||
          item.TINGKAT_BEASISWA == "B" ||
          item.TINGKAT_BEASISWA == "C"
        ) {
          return (
            item.TINGKAT_BEASISWA == "A" ||
            item.TINGKAT_BEASISWA == "B" ||
            item.TINGKAT_BEASISWA == "C"
          );
        } else if (
          (item.TINGKAT_BEASISWA == "D" &&
            this.role.ROLE == "Otorisasi Akhir") ||
          (item.TINGKAT_BEASISWA == "E" && this.role.ROLE == "Otorisasi Akhir")
        ) {
          return (
            (item.TINGKAT_BEASISWA == "D" &&
              item.CHECK_FLAG != "TRUE" &&
              this.role.ROLE != "Otorisasi Akhir") ||
            (item.TINGKAT_BEASISWA == "E" &&
              item.CHECK_FLAG != "TRUE" &&
              this.role.ROLE != "Otorisasi Akhir")
          );
        } else {
          return (
            item.TINGKAT_BEASISWA == "A" ||
            item.TINGKAT_BEASISWA == "B" ||
            item.TINGKAT_BEASISWA == "C" ||
            (item.TINGKAT_BEASISWA == "D" && item.CHECK_FLAG != "TRUE") ||
            (item.TINGKAT_BEASISWA == "E" && item.CHECK_FLAG != "TRUE")
          );
        }
      } else {
        // console.log("Tutup All");
        return (
          item.TINGKAT_BEASISWA == "A" ||
          item.TINGKAT_BEASISWA == "B" ||
          item.TINGKAT_BEASISWA == "C" ||
          item.TINGKAT_BEASISWA == "D" ||
          item.TINGKAT_BEASISWA == "E"
        );
      }
    },

    formatDate(value) {
      return moment(value).format("DD-MM-YYYY");
    },

    formatRp(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatMonth(tahunmasuk) {
      if (!tahunmasuk) return null;

      const [year, month] = tahunmasuk.split("-");
      return `${month}-${year}`;
    },

    // formatRpEdit(value) {
    //   if(this.editedItem.PERIODE_PEMBERIAN == '6 Bulan'){
    //     console.log("value: "+value)
    //     let set = value/2
    //     return set.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    //   }else{
    //     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    //   }

    // },

    keterangan(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9 . A-Za-z]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.alasan = "";
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },

    closeRevisi() {
      this.dialogRevisi = false;
      this.$refs.form.reset();
      this.alasanRevisi = "";
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },

    closeDetail() {
      this.dialogDetail = false;
    },
    // closeRevisi(){
    //   this.dialogRevisi = false;
    // },
    closeReject() {
      this.dialog = false;
      this.$refs.form.reset();
      this.alasan = "";
    },
    closeLampiranRaport() {
      this.dialogLampiranRaport = false;
    },
    closeLampiranIjazah() {
      this.dialogLampiranIjazah = false;
    },
    closeLampiranSuratDiterima() {
      this.dialogLampiranSuratDiterima = false;
    },
    closeGanjil() {
      this.dialogGanjil = false;
    },
    closeGenap() {
      this.dialogGenap = false;
    },

    // selectAll(event) {
    //   console.log("event "+event)
    //   console.log("status "+event.status)
    //   if (event.status == true) {
    //     alert('selected all')
    //   } else {
    //     alert('deselected all')
    //   }
    // },

    getItemPerPage(val) {
      console.log(val);
    },

    approve(item) {
      const axios = require("axios");
      this.$swal({
        icon: "info",
        title: "Apakah Anda Yakin?",
        text: "Untuk Menyetujui Pengajuan Bantuan Pendidikan",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Yes`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.overlay = true;
          this.editedIndex = this.itemsTableApproval.indexOf(item);
          this.editedItem = Object.assign({}, item);
          // // console.log(this.editedItem);
          const approve = {
            nik: [this.editedItem.NIK],
            niklogin: this.user.data[0].NikKaryawan,
            notiket: [this.editedItem.NO_TIKET],
            statustransaksi: "1",
            keteranganreject: "",
            role: this.role.ROLE,
          };
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   Authorization: "Bearer" + this.TOKEN,
          //   // NIK: sessionStorage.getItem("nik"),
          // };
          // // console.log(approve);
          // axios
          //   .post(
          //     this.$baseurl + "beasiswa/approveDataPengajuanBeasiswa",
          //     approve,
          //     {
          //       headers,
          //     }
          //   )
          this.approveDataPengajuanBeasiswa(approve)
            .then((response) => {
              // console.log(response.data);
              const hasil = response.data;
              if (hasil.status == "Token is Expired") {
                this.overlay = false;
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (hasil.status == "Success") {
                this.overlay = false;
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: hasil.data[0].Pesan,
                }).then(() => {
                  this.initialize();
                  // window.location.reload();
                  // this.clearForm();
                });
              } else {
                if (hasil.data == "Success Approve") {
                  this.overlay = false;
                  this.$swal({
                    icon: "success",
                    title: "Berhasil",
                    text: hasil.data,
                  }).then(() => {
                    this.initialize();
                    // window.location.reload();
                    // this.clearForm();
                  });
                } else {
                  this.overlay = false;
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Menyetujui, " + hasil.message,
                  });
                }
              }
            })
            .catch((error) => {
              // console.log(error);
              this.overlay = false;
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menyetujui",
              });
            });
        }
      });
    },

    revisi(item) {
      this.editedIndex = this.itemsTableApproval.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRevisi = true;
    },

    saveRevisi() {
      // this.editedIndex = this.itemsTableApproval.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // const axios = require("axios");
      this.$swal({
        icon: "info",
        title: "Apakah Anda Yakin?",
        text: "Untuk Merevisi Pengajuan Bantuan Pendidikan",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Yes`,
      }).then((response) => {
        if (response.isConfirmed) {
          // this.dialogRevisi = false;
          this.overlayDialogRevisi = true;
          // this.editedIndex = this.itemsTableApproval.indexOf(item);
          // this.editedItem = Object.assign({}, item);
          // // console.log(this.editedItem);
          const approve = {
            nik: [this.editedItem.NIK],
            niklogin: this.user.data[0].NikKaryawan,
            notiket: [this.editedItem.NO_TIKET],
            statustransaksi: "2",
            keteranganreject: this.alasanRevisi,
            role: this.role.ROLE,
          };
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   Authorization: "Bearer" + this.TOKEN,
          //   // NIK: sessionStorage.getItem("nik"),
          // };
          // // console.log(approve);
          // axios
          //   .post(
          //     this.$baseurl + "beasiswa/approveDataPengajuanBeasiswa",
          //     approve,
          //     {
          //       headers,
          //     }
          //   )
          this.approveDataPengajuanBeasiswa(approve)
            .then((response) => {
              // console.log(response.data);
              const hasil = response.data;
              if (hasil.status == "Token is Expired") {
                this.overlayDialogRevisi = false;
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (hasil.status == "Success") {
                this.overlayDialogRevisi = false;
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: hasil.data[0].Pesan,
                }).then(() => {
                  this.initialize();
                  // window.location.reload();
                  this.closeRevisi();
                  // this.clearForm();
                });
              } else {
                if (hasil.data == "Success Revisi") {
                  this.overlayDialogRevisi = false;
                  this.$swal({
                    icon: "success",
                    title: "Berhasil",
                    text: hasil.data,
                  }).then(() => {
                    this.initialize();
                    // window.location.reload();
                    this.closeRevisi();
                    // this.clearForm();
                  });
                } else {
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Merevisi, " + hasil.message,
                  });
                  this.overlayDialogRevisi = false;
                }
              }
            })
            .catch((error) => {
              // console.log(error);

              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Merevisi",
              });
              this.overlayDialogRevisi = false;
            });
        }
      });
    },

    saveFlag() {
      // this.editedIndex = this.itemsTableApproval.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // const axios = require("axios");
      this.$swal({
        icon: "info",
        title: "Apakah Anda Yakin?",
        text: "Untuk Menyimpan Check Lampiran",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Yes`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.overlayDialog = true;
          const approve = {
            nik: [this.editedItem.NIK],
            niklogin: this.user.data[0].NikKaryawan,
            notiket: [this.editedItem.NO_TIKET],
            statustransaksi: "3",
            keteranganreject: "",
            role: "",
            flagraport: this.checkGanjil == true ? 1 : 0,
            flagipk: this.checkGenap == true ? 1 : 0,
            flagkelulusan: this.checkKelulusan == true ? 1 : 0,
            flagijazah: this.checkIjazah == true ? 1 : 0,
            flagsuratditerima: this.checkSuratDiterima == true ? 1 : 0,
            flagsuratakreditasi: this.checkAkreditasi == true ? 1 : 0,
            flagakreditasiprogramstudi:
              this.checkAkreditasiProdi == true ? 1 : 0,
            flagaktifkuliah: this.checkAktifKuliah == true ? 1 : 0,
            flagpostpone: this.checkPostpone == true ? 1 : 0,
          };
          this.approveDataPengajuanBeasiswa(approve)
            .then((response) => {
              // console.log(response.data);
              const hasil = response.data;
              if (hasil.status == "Token is Expired") {
                this.overlay = false;
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (hasil.status == "Success") {
                this.overlayDialog = false;
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: hasil.data[0].Pesan,
                }).then(() => {
                  this.initialize();
                  // this.selected = item.length != 0 ? item : [];
                  this.closeDetail();
                  // window.location.reload();
                  // this.clearForm();
                });
              } else {
                this.overlayDialog = false;
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menyimpan, " + hasil.message,
                });
                this.overlayDialog = false;
              }
            })
            .catch((error) => {
              // console.log(error);
              this.overlayDialog = false;
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menyimpan",
              });
              this.overlayDialog = false;
            });
        }
      });
    },

    editPengajuan() {
      // this.dialogDetail = false;
      this.tingkatBeasiswaEdit = this.editedItem.TINGKAT_BEASISWA;
      this.penerimaBeasiswaEdit = this.editedItem.PENERIMA_BEASISWA;
      this.usulanBeasiswaEdit = this.editedItem.USULAN_BEASISWA;
      this.kelasBeasiswaEdit = this.editedItem.KELAS;
      this.plafondBeasiswaEdit = this.editedItem.PLAFOND_BEASISWA;
      this.periodePemberianEdit = this.editedItem.PERIODE_PEMBERIAN;
      this.dialogEdit = true;
      this.tingkatPendidikan();
      // this.getPlafondEdit();
      // this.getRevisi();
    },

    cancelEdit() {
      this.dialogEdit = false;
      // this.initialize();
      // this.tingkatPendidikan();
      // this.getPlafond();
      // this.getRevisi();
      // this.dialogDetail = false;
    },

    saveEdit() {
      this.dialogLoading = true;
      this.submitEdit();
    },

    reject(item) {
      this.editedIndex = this.itemsTableApproval.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    detail(item) {
      this.editedIndex = this.itemsTableApproval.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(this.editedItem);
      // this.overlay = true;

      this.checkGanjil =
        this.editedItem.FLAG_REVIEW_RAPORT == "1" ? true : false;
      this.checkGenap = this.editedItem.FLAG_REVIEW_IPK == "1" ? true : false;
      this.checkKelulusan =
        this.editedItem.FLAG_REVIEW_KELULUSAN == "1" ? true : false;
      this.checkIjazah =
        this.editedItem.FLAG_REVIEW_IJAZAH == "1" ? true : false;
      this.checkSuratDiterima =
        this.editedItem.FLAG_REVIEW_SURATDITERIMA == "1" ? true : false;
      this.checkAkreditasiProdi =
        this.editedItem.FLAG_REVIEW_AKREDITASIPROGRAMSTUDI == "1"
          ? true
          : false;
      this.checkAkreditasi =
        this.editedItem.FLAG_REVIEW_SURATAKREDITASI == "1" ? true : false;
      this.checkAktifKuliah =
        this.editedItem.FLAG_REVIEW_AKTIFKULIAH == "1" ? true : false;
      this.checkPostpone =
        this.editedItem.FLAG_REVIEW_SURATPOSTPONE == "1" ? true : false;
      this.dialogDetail = true;

      // this.cekFileBlob();

      // const axios = require("axios");
      // const getDetail = {
      //   notiket: this.editedItem.NO_TIKET,
      // };
      // // axios
      // //   .post(this.$baseurl + "beasiswa/getDataFileBlobBeasiswa", getDetail)
      // this.getDataFileBlobBeasiswa(getDetail)
      //   .then((response) => {
      //     // // console.log(response.data.GetAllUnitResult);
      //     const hasil = response.data;
      //     // // console.log(hasil.data)
      //     this.items_cekblob = hasil.data[0];
      //     this.overlay = false;
      //     this.dialogDetail = true;
      //   })
      //   .catch((error) => {
      //     // console.log(error);
      //     this.$swal({
      //       icon: "error",
      //       title: "Maaf!",
      //       text: "Detail Tidak Ditemukan",
      //     });
      //     this.overlay = false;
      //   });
    },

    lampiranRapot(item) {
      this.editedIndex = this.itemsTableApproval.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(this.editedItem);
      this.dialogLampiranRapot = true;
    },

    saveReject(item) {
      // const axios = require("axios");
      const noTiket = this.editedItem.NO_TIKET;
      const nikapprove = this.editedItem.NIK;

      this.$swal({
        icon: "warning",
        title: "Apakah Anda Yakin?",
        text: "Untuk Menolak Pengajuan Bantuan Pendidikan",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Yes`,
        footer: `<font color="#ff0000">PERHATIAN!! APABILA ANDA MELAKUKAN REJECT,<br> MAKA KARYAWAN <b> TIDAK DAPAT</b> MENGAJUKAN <br>KEMBALI BANTUAN PENDIDIKAN DI PERIODE INI</font>.`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.overlayDialogReject = true;

          // this.editedIndex = this.itemsTableApproval.indexOf(item);
          // this.editedItem = Object.assign({}, item);
          // console.log(this.editedItem);
          const reject = {
            nik: [nikapprove],
            niklogin: this.user.data[0].NikKaryawan,
            notiket: [noTiket],
            statustransaksi: "0",
            keteranganreject: this.alasan,
            role: this.role.ROLE,
          };
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   Authorization: "Bearer" + this.TOKEN,
          //   // NIK: sessionStorage.getItem("nik"),
          // };
          // // console.log(reject);
          // axios
          //   .post(
          //     this.$baseurl + "beasiswa/approveDataPengajuanBeasiswa",
          //     reject,
          //     {
          //       headers,
          //     }
          //   )
          this.approveDataPengajuanBeasiswa(reject)
            .then((response) => {
              // // console.log(response.data.message.message);
              const hasil = response.data;
              if (hasil.status == "Token is Expired") {
                this.overlayDialogReject = false;
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (hasil.status == "Success") {
                this.overlayDialogReject = false;
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: hasil.data[0].Pesan,
                }).then(() => {
                  this.initialize();
                  // window.location.reload();
                  this.closeReject();
                  // this.clearForm();
                });
              } else {
                if (hasil.data == "Success Reject") {
                  this.overlayDialogReject = false;
                  this.$swal({
                    icon: "success",
                    title: "Berhasil",
                    text: hasil.data,
                  }).then(() => {
                    this.initialize();
                    // window.location.reload();
                    this.closeReject();
                    // this.clearForm();
                  });
                } else {
                  this.overlayDialogReject = false;
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Menolak, " + hasil.message,
                  });
                }
              }
            })
            .catch((error) => {
              // console.log(error);
              this.overlayDialogReject = false;
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menolak",
              });
            });
        }
      });
    },

    multiApprove() {
      const axios = require("axios");
      this.$swal({
        icon: "info",
        title: "Apakah Anda Yakin?",
        text: "Untuk Menyetujui Pengajuan Bantuan Pendidikan",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Yes`,
      }).then((response) => {
        if (response.isConfirmed) {
          // this.editedIndex = this.itemsTableRencana.indexOf(item);
          // this.editedItem = Object.assign({}, item);
          // // console.log(this.selected);
          this.overlay = true;
          const tempNoTiket = this.selected.map((item) => item.NO_TIKET);
          const tempNIK = this.selected.map((item) => item.NIK);
          // // console.log(tempNoTiket)
          const multiApprove = {
            nik: tempNIK,
            niklogin: this.user.data[0].NikKaryawan,
            notiket: tempNoTiket,
            statustransaksi: "1",
            keteranganreject: "",
            role: this.role.ROLE,
          };
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   Authorization: "Bearer" + this.TOKEN,
          //   // NIK: sessionStorage.getItem("nik"),
          // };
          // // console.log(multiApprove);
          // axios
          //   .post(
          //     this.$baseurl + "beasiswa/approveDataPengajuanBeasiswa",
          //     multiApprove,
          //     {
          //       headers,
          //     }
          //   )
          this.approveDataPengajuanBeasiswa(multiApprove)
            .then((response) => {
              // console.log(response.data);
              const hasil = response.data;
              if (hasil.status == "Token is Expired") {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (hasil.status == "Success") {
                this.overlay = false;
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: hasil.data[0].Pesan,
                }).then(() => {
                  this.initialize();
                  // window.location.reload();
                  // this.clearForm();
                });
              } else {
                console.log("hasil: " + hasil);
                console.log("data: " + hasil.data);
                if (hasil.data == "Success Approve") {
                  this.overlay = false;
                  this.$swal({
                    icon: "success",
                    title: "Berhasil",
                    text: hasil.data,
                  }).then(() => {
                    this.initialize();
                    // window.location.reload();
                    // this.clearForm();
                  });
                } else {
                  this.overlay = false;
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Menyetujui, " + hasil.message,
                  });
                }
              }
            })
            .catch((error) => {
              // console.log(error);
              this.overlay = false;
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menyetujui " + response.data,
              });
            });
        }
      });
    },

    getAnak() {
      // const axios = require("axios");
      const dataAnak = {
        nik: this.user.data[0].NikKaryawan,
      };
      this.getDataAnakBeasiswa(dataAnak)
        .then((response) => {
          let hasil = response.data;
          // let anak = response.data.data.PengkinianDataResult
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // let data = hasil.data.PengkinianDataResult.map((item) => item.NAME);
            // // console.log(hasil.data.PengkinianDataResult);
            this.itemsAnak = hasil.data.Result;
          }
        })
        .catch((error) => {
          // console.log(error);
          alert("Tidak Dapat Mengambil data Anak");
        });
    },

    tingkatPendidikan() {
      const code = {
        nik: this.user.data[0].NikKaryawan,
        status: "read",
        kategori: "",
      };
      this.insertDataMstPlafondBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // // console.log(result.data);
            this.itemsTingkatPendidikan = result.data;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
          }
        });
    },

    getPlafond() {
      console.log(this.usulanBeasiswaEdit, "usulan edit");
      this.usulanBeasiswaEdit = "";
      this.kelasBeasiswaEdit = "";
      const code = {
        nik: this.user.data[0].NikKaryawan,
        status: "read",
        kategori: "plafond",
        idtingkatpendidikan: this.tingkatBeasiswaEdit,
      };
      this.insertDataMstPlafondBeasiswa(code)
        .then((response) => {
          let hasil = response.data;
          // let anak = response.data.data.PengkinianDataResult
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.plafondBeasiswaEdit = hasil.data[0].PLAFOND;
          }
        })
        .catch((error) => {
          // console.log(error);
          alert("Tidak Dapat Mengambil data Plafond");
        });
    },

    changeUsulanBeasiswaEdit(){
      this.kelasBeasiswaEdit = "";
    },

    getRevisi() {
      const code = {
        nik: this.user.data[0].NikKaryawan,
        notiket: this.editedItem.NO_TIKET,
        kategori: "Read",
        penerimabeasiswa: this.editedItem.PENERIMA_BEASISWA,
        akreditasikampus: this.editedItem.AKREDITASI_KAMPUS,
        akreditasijurusan: this.editedItem.AKREDITASI_JURUSAN,
        statustransaksi: "",
        usulanbeasiswa: "",
        kelas: "",
        tingkatbeasiswa: "",
        periodebulantahun: "",
        plafond: "",
        postpone: "",
        nilaiganjil: "",
        nilaigenap: "",
        nilairata: "",
        idmapelganjil: "",
        idmapelgenap: "",
        nilaipengetahuanmapelganjil: "",
        nilaipengetahuanmapelgenap: "",
        nilaiketerampilanmapelganjil: "",
        nilaiketerampilanmapelgenap: "",
        semesterganjil: "",
        semestergenap: "",
        fileraport: "",
        fileipk: "",
        filekelulusan: "",
        fileijazah: "",
        filesuratditerima: "",
        filesuratakreditasi: "",
        fileakreditasiprogramstudi: "",
        filepostpone: "",
        fileaktifkuliah: "",
      };

      this.getDataRevisiBeasiswa(code)
        .then((response) => {
          const hasil = response.data;
          //   console.log(hasil);
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else if (hasil.status == "Success") {
            if (hasil == null) {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Anda Tidak Memiliki Data Pengajuan",
              });
              // this.dialogLoading = false;
            } else {
              // console.log("hasil getRevisi: "+hasil.data[0]);
              // let hasil = hasil.data[0];
              this.fileblob_raport = hasil.data[0].FILEBLOB_RAPORT;
              this.fileblob_ipk = hasil.data[0].FILEBLOB_IPK;

              this.inputsganjil = hasil.inputsganjil;
              // let mapelganjilarray1 = inputsganjil.map((item) => item.mapelganjil);
              // this.mapelganjilarray = JSON.stringify(mapelganjilarray1);
              // let pengetahuanganjilarray1 = inputsganjil.map((item) => item.pengetahuanganjil);
              // this.pengetahuanganjilarray = JSON.stringify(pengetahuanganjilarray1);
              // let keterampilanganjilarray1 = inputsganjil.map((item) => item.keterampilanganjil);
              // this.keterampilanganjilarray = JSON.stringify(keterampilanganjilarray1);
              this.inputsgenap = hasil.inputsgenap;
              // let mapelgenaparray1 = inputsgenap.map((item) => item.mapelgenap);
              // this.mapelgenaparray = JSON.stringify(mapelgenaparray1);
              // let pengetahuangenaparray1 = inputsgenap.map((item) => item.pengetahuangenap);
              // this.pengetahuangenaparray = JSON.stringify(pengetahuangenaparray1);
              // let keterampilangenaparray1 = inputsgenap.map((item) => item.keterampilangenap);
              // this.keterampilangenaparray = JSON.stringify(keterampilangenaparray1);
              this.cekPDFGanjil();
              this.cekPDFGenap();
              if (hasil.data[0].FILEBLOB_KELULUSAN != null) {
                this.fileblob_kelulusan = hasil.data[0].FILEBLOB_KELULUSAN;
                this.cekPDFKelulusan();
              }
              if (hasil.data[0].FILEBLOB_IJAZAH != null) {
                this.fileblob_ijazah = hasil.data[0].FILEBLOB_IJAZAH;
                this.cekPDFIjazah();
              }
              if (hasil.data[0].FIEBLOB_SURATDITERIMA != null) {
                this.fileblob_suratditerima =
                  hasil.data[0].FILEBLOB_SURATDITERIMA;
                this.cekPDFSuratDiterima();
              }
              if (hasil.data[0].FILEBLOB_SURATAKREDITASI != null) {
                this.fileblob_suratakreditasi =
                  hasil.data[0].FILEBLOB_SURATAKREDITASI;
                this.cekPDFSuratAkreditasi();
              }
              if (hasil.data[0].FILEBLOB_AKREDITASIPROGRAMSTUDI != null) {
                this.fileblob_akreditasiprogramstudi =
                  hasil.data[0].FILEBLOB_AKREDITASIPROGRAMSTUDI;
                this.cekPDFAkreditasiProdi();
              }
              if (hasil.data[0].FILEBLOB_SURATPOSTPONE != null) {
                this.fileblob_suratpostpone =
                  hasil.data[0].FILEBLOB_SURATPOSTPONE;
                this.cekPDFPostpone();
              }
              if (hasil.data[0].FILEBLOB_AKTIFKULIAH != null) {
                this.fileblob_aktifkuliah = hasil.data[0].FILEBLOB_AKTIFKULIAH;
                this.cekPDFAktifKuliah();
              }
            }
          } else {
            this.$swal({
              icon: "warning",
              title: "Maaf!",
              text: "Gagal Get Data Pengajuan",
            });
            // this.dialogLoading = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
          this.dialogLoading = false;
        });
    },

    submitEdit() {
      // let pernahPostpone = this.editedItem.POSTPONE;
      console.log(this.editedItem.POSTPONE, "postpone");
      if (
        this.editedItem.POSTPONE == null ||
        this.editedItem.POSTPONE == "null" ||
        this.editedItem.POSTPONE == ""
      ) {
        this.pernahPostpone = "Tidak";
      } else {
        this.pernahPostpone = this.editedItem.POSTPONE;
      }
      console.log(this.pernahPostpone, "pernah postpone");

      let inputsganjil = this.inputsganjil;
      let mapelganjilarray1 = inputsganjil.map((item) => item.mapelganjil);
      let mapelganjilarray = JSON.stringify(mapelganjilarray1);
      let pengetahuanganjilarray1 = inputsganjil.map(
        (item) => item.pengetahuanganjil
      );
      let pengetahuanganjilarray = JSON.stringify(pengetahuanganjilarray1);
      let keterampilanganjilarray1 = inputsganjil.map(
        (item) => item.keterampilanganjil
      );
      let keterampilanganjilarray = JSON.stringify(keterampilanganjilarray1);
      let inputsgenap = this.inputsgenap;
      let mapelgenaparray1 = inputsgenap.map((item) => item.mapelgenap);
      let mapelgenaparray = JSON.stringify(mapelgenaparray1);
      let pengetahuangenaparray1 = inputsgenap.map(
        (item) => item.pengetahuangenap
      );
      let pengetahuangenaparray = JSON.stringify(pengetahuangenaparray1);
      let keterampilangenaparray1 = inputsgenap.map(
        (item) => item.keterampilangenap
      );
      let keterampilangenaparray = JSON.stringify(keterampilangenaparray1);

      if (this.editedItem.STATUS_TRANSAKSI == "0") {
        this.statustransaksiText = "Draft";
      } else if (this.editedItem.STATUS_TRANSAKSI == "1") {
        this.statustransaksiText = "Approve Manager";
      } else if (this.editedItem.STATUS_TRANSAKSI == "11") {
        this.statustransaksiText = "Revisi Manager";
      } else if (this.editedItem.STATUS_TRANSAKSI == "10") {
        this.statustransaksiText = "Reject Manager";
      } else if (this.editedItem.STATUS_TRANSAKSI == "2") {
        this.statustransaksiText = "Approve Verifikasi";
      } else if (this.editedItem.STATUS_TRANSAKSI == "21") {
        this.statustransaksiText = "Revisi Verifikasi";
      } else if (this.editedItem.STATUS_TRANSAKSI == "20") {
        this.statustransaksiText = "Reject Verifikasi";
      } else if (this.editedItem.STATUS_TRANSAKSI == "3") {
        this.statustransaksiText = "Approve Otorisasi Awal";
      } else if (this.editedItem.STATUS_TRANSAKSI == "31") {
        this.statustransaksiText = "Revisi Otorisasi Awal";
      } else if (this.editedItem.STATUS_TRANSAKSI == "30") {
        this.statustransaksiText = "Reject Otorisasi Awal";
      } else if (this.editedItem.STATUS_TRANSAKSI == "4") {
        this.statustransaksiText = "Approve Otorisasi Akhir";
      } else if (this.editedItem.STATUS_TRANSAKSI == "41") {
        this.statustransaksiText = "Revisi Otorisasi Akhir";
      } else if (this.editedItem.STATUS_TRANSAKSI == "40") {
        this.statustransaksiText = "Reject Otorisasi Akhir";
      } else {
        this.statustransaksiText = "";
      }

      const formData = new FormData();
      formData.set("nik", this.user.data[0].NikKaryawan);
      formData.set("notiket", this.editedItem.NO_TIKET);
      formData.set("kategori", "Update");
      formData.set("statustransaksi", this.statustransaksiText);
      formData.set("tingkatbeasiswa", this.tingkatBeasiswaEdit);
      formData.set("usulanbeasiswa", this.usulanBeasiswaEdit);
      formData.set("kelas", this.kelasBeasiswaEdit);
      formData.set("periodebulantahun", this.periodePemberianEdit);
      formData.set("penerimabeasiswa", this.editedItem.PENERIMA_BEASISWA);
      formData.set("akreditasikampus", this.editedItem.AKREDITASI_KAMPUS);
      formData.set("akreditasijurusan", this.editedItem.AKREDITASI_JURUSAN);
      formData.set("plafond", this.besaranPlafond);
      formData.set("postpone", this.pernahPostpone);
      formData.set("nilaiganjil", this.editedItem.NILAI_GANJIL);
      formData.set("nilaigenap", this.editedItem.NILAI_GENAP);
      formData.set("nilairata", this.editedItem.NILAI_RATARATA);
      formData.append("fileraport", this.filesTranskripGanjil);
      formData.append("fileipk", this.filesTranskripGenap);
      formData.append("fileijazah", this.filesIjazah);
      formData.append("filesuratditerima", this.filesDiterima);
      formData.append("filekelulusan", this.filesKelulusan);
      formData.append("fileakreditasiprogramstudi", this.filesAkreditasiProdi);
      formData.append("filesuratakreditasi", this.filesAkreditasiKampus);
      formData.append("filepostpone", this.filesPostpone);
      formData.append("fileaktifkuliah", this.filesAktifKuliah);
      formData.append("idmapelganjil", [mapelganjilarray]);
      formData.append("idmapelgenap", [mapelgenaparray]);
      formData.append("nilaipengetahuanmapelganjil", [pengetahuanganjilarray]);
      formData.append("nilaipengetahuanmapelgenap", [pengetahuangenaparray]);
      formData.append("nilaiketerampilanmapelganjil", [
        keterampilanganjilarray,
      ]);
      formData.append("nilaiketerampilanmapelgenap", [keterampilangenaparray]);
      formData.set("semesterganjil", "Ganjil");
      formData.set("semestergenap", "Genap");

      // console.log(formData, "hasil");

      this.getDataRevisiBeasiswa(formData)
        .then((response) => {
          const hasil = response.data;
          //   console.log(hasil);
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else if (hasil.status == "Success") {
            this.$swal({
              icon: "success",
              title: "Sukses!",
              text: "Berhasil Edit Pengajuan",
            }).then(() => {
              // window.location.reload();
              this.initialize();
            });

            this.dialogLoading = false;
            this.dialogDetail = false;
            this.dialogEdit = false;
            // this.overlay = false
          } else {
            this.$swal({
              icon: "warning",
              title: "Maaf!",
              text: "Gagal Edit Pengajuan",
            });
            // this.dialogLoading = false;
            this.dialogLoading = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
          this.dialogLoading = false;
        });
    },

    rule(item) {
      // // console.log("test= "+this.penerima)
      if (this.editedItem.PENERIMA_BEASISWA == "Karyawan") {
        return item != "A" && item != "B" && item != "C";
      } else {
        return true;
      }
    },

    cekPDFGanjil() {
      let b64 = this.fileblob_raport;
      // console.log(b64);
      const byteCharacters = atob(b64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // console.log(blob);

      this.filesTranskripGanjil = blob;
    },

    cekPDFGenap() {
      let b64 = this.fileblob_ipk;
      // console.log(b64);
      const byteCharacters = atob(b64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // console.log(blob);

      this.filesTranskripGenap = blob;
    },

    cekPDFKelulusan() {
      let b64 = this.fileblob_kelulusan;
      // console.log(b64);
      const byteCharacters = atob(b64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // console.log(blob);

      this.filesKelulusan = blob;
    },

    cekPDFIjazah() {
      let b64 = this.fileblob_ijazah;
      // console.log(b64);
      const byteCharacters = atob(b64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // console.log(blob);

      this.filesIjazah = blob;
    },

    cekPDFSuratDiterima() {
      let b64 = this.fileblob_suratditerima;
      // console.log(b64);
      const byteCharacters = atob(b64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // console.log(blob);

      this.filesDiterima = blob;
    },

    cekPDFSuratAkreditasi() {
      let b64 = this.fileblob_suratakreditasi;
      // console.log(b64);
      const byteCharacters = atob(b64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // console.log(blob);

      this.filesAkreditasiKampus = blob;
    },

    cekPDFAkreditasiProdi() {
      let b64 = this.fileblob_akreditasiprogramstudi;
      // console.log(b64);
      const byteCharacters = atob(b64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // console.log(blob);

      this.filesAkreditasiProdi = blob;
    },

    cekPDFPostpone() {
      let b64 = this.fileblob_suratpostpone;
      // console.log(b64);
      const byteCharacters = atob(b64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // console.log(blob);

      this.filesPostpone = blob;
    },

    cekPDFAktifKuliah() {
      let b64 = this.fileblob_aktifkuliah;
      // console.log(b64);
      const byteCharacters = atob(b64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // console.log(blob);

      this.filesAktifKuliah = blob;
    },

    cekFileBlob() {
      if (
        this.editedItem.FILEPATH_KELULUSAN == "null" ||
        this.editedItem.FILEPATH_KELULUSAN == NULL ||
        this.editedItem.FILEPATH_KELULUSAN == ""
      ) {
        this.editedItem.FILEPATH_KELULUSAN == "";
      }
      if (
        this.editedItem.FILEPATH_IJAZAH == "null" ||
        this.editedItem.FILEPATH_IJAZAH == NULL ||
        this.editedItem.FILEPATH_IJAZAH == ""
      ) {
        this.editedItem.FILEPATH_IJAZAH == "";
      }
      if (
        this.editedItem.FILEPATH_SURATDITERIMA == "null" ||
        this.editedItem.FILEPATH_SURATDITERIMA == NULL ||
        this.editedItem.FILEPATH_SURATDITERIMA == ""
      ) {
        this.editedItem.FILEPATH_SURATDITERIMA == "";
      }

      const getDetail = {
        notiket: this.editedItem.NO_TIKET,
        pathraport: this.editedItem.FILEPATH_RAPORT,
        pathipk: this.editedItem.FILEPATH_IPK,
        pathkelulusan: this.editedItem.FILEPATH_KELULUSAN,
        pathijazah: this.editedItem.FILEPATH_IJAZAH,
        pathsuratditerima: this.editedItem.FILEPATH_SURATDITERIMA,
        pathsuratakreditasi: this.editedItem.FILEPATH_SURATAKREDITASI,
        pathakreditasiprogramstudi: this.editedItem
          .FILEPATH_AKREDITASIPROGRAMSTUDI,
        pathaktifkuliah: this.editedItem.FILEPATH_AKTIFKULIAH,
        pathpostpone: this.editedItem.FILEPATH_SURATPOSTPONE,
      };
      this.dialogLoading = true;

      this.getDataFileBlobBeasiswa(getDetail)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          console.log(hasil.data);
          this.items_cekblob = hasil.data[0];
          console.log("cekblob: " + this.items_cekblob);
          // this.overlayDialog = false;

          let b64 = this.items_cekblob.FILEBLOB_RAPORT;
          console.log("b64: " + b64);
          const byteCharacters = atob(b64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          var objectUrl = URL.createObjectURL(blob);
          this.dialogLoading = false;
          window.open(objectUrl);
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objectUrl), 100;
          });
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Lampiran Tidak Ditemukan",
          });
          this.dialogLoading = false;
        });
    },

    getDataPDFGanjil() {
      const getDetail = {
        notiket: this.editedItem.NO_TIKET,
        pathraport: this.editedItem.FILEPATH_RAPORT,
        pathipk: "",
        pathkelulusan: "",
        pathijazah: "",
        pathsuratditerima: "",
        pathsuratakreditasi: "",
        pathakreditasiprogramstudi: "",
        pathaktifkuliah: "",
        pathpostpone: "",
      };
      this.overlayDialog = true;

      this.getDataFileBlobBeasiswa(getDetail)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // // console.log(hasil.data)
          this.items_cekblob = hasil.data[0];
          console.log("cekblob: " + this.items_cekblob);
          this.overlayDialog = false;

          let b64 = this.items_cekblob.FILEBLOB_RAPORT;
          console.log("b64: " + b64);
          const byteCharacters = atob(b64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objectUrl), 100;
          });
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Lampiran Tidak Ditemukan",
          });
          this.overlayDialog = false;
        });
    },

    getDataPDFGenap() {
      const getDetail = {
        notiket: this.editedItem.NO_TIKET,
        pathraport: "",
        pathipk: this.editedItem.FILEPATH_IPK,
        pathkelulusan: "",
        pathijazah: "",
        pathsuratditerima: "",
        pathsuratakreditasi: "",
        pathakreditasiprogramstudi: "",
        pathaktifkuliah: "",
        pathpostpone: "",
      };
      this.overlayDialog = true;

      this.getDataFileBlobBeasiswa(getDetail)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // // console.log(hasil.data)
          this.items_cekblob = hasil.data[0];
          this.overlayDialog = false;

          let b64 = this.items_cekblob.FILEBLOB_IPK;
          const byteCharacters = atob(b64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objectUrl), 100;
          });
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Lampiran Tidak Ditemukan",
          });
          this.overlayDialog = false;
        });
    },

    getDataPDFKelulusan() {
      const getDetail = {
        notiket: this.editedItem.NO_TIKET,
        pathraport: "",
        pathipk: "",
        pathkelulusan: this.editedItem.FILEPATH_KELULUSAN,
        pathijazah: "",
        pathsuratditerima: "",
        pathsuratakreditasi: "",
        pathakreditasiprogramstudi: "",
        pathaktifkuliah: "",
        pathpostpone: "",
      };
      this.overlayDialog = true;

      this.getDataFileBlobBeasiswa(getDetail)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // // console.log(hasil.data)
          this.items_cekblob = hasil.data[0];
          this.overlayDialog = false;

          let b64 = this.items_cekblob.FILEBLOB_KELULUSAN;
          const byteCharacters = atob(b64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objectUrl), 100;
          });
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Lampiran Tidak Ditemukan",
          });
          this.overlayDialog = false;
        });
    },

    getDataPDFAkreditasiProdi() {
      const getDetail = {
        notiket: this.editedItem.NO_TIKET,
        pathraport: "",
        pathipk: "",
        pathkelulusan: "",
        pathijazah: "",
        pathsuratditerima: "",
        pathsuratakreditasi: "",
        pathakreditasiprogramstudi: this.editedItem
          .FILEPATH_AKREDITASIPROGRAMSTUDI,
        pathaktifkuliah: "",
        pathpostpone: "",
      };
      this.overlayDialog = true;

      this.getDataFileBlobBeasiswa(getDetail)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // // console.log(hasil.data)
          this.items_cekblob = hasil.data[0];
          this.overlayDialog = false;

          let b64 = this.items_cekblob.FILEBLOB_AKREDITASIPROGRAMSTUDI;
          const byteCharacters = atob(b64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objectUrl), 100;
          });
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Lampiran Tidak Ditemukan",
          });
          this.overlayDialog = false;
        });
    },

    getDataPDFIjazah() {
      const getDetail = {
        notiket: this.editedItem.NO_TIKET,
        pathraport: "",
        pathipk: "",
        pathkelulusan: "",
        pathijazah: this.editedItem.FILEPATH_IJAZAH,
        pathsuratditerima: "",
        pathsuratakreditasi: "",
        pathakreditasiprogramstudi: "",
        pathaktifkuliah: "",
        pathpostpone: "",
      };
      this.overlayDialog = true;

      this.getDataFileBlobBeasiswa(getDetail)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // // console.log(hasil.data)
          this.items_cekblob = hasil.data[0];
          this.overlayDialog = false;

          let b64 = this.items_cekblob.FILEBLOB_IJAZAH;
          const byteCharacters = atob(b64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objectUrl), 100;
          });
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Lampiran Tidak Ditemukan",
          });
          this.overlayDialog = false;
        });
    },

    getDataPDFSuratDiterima() {
      const getDetail = {
        notiket: this.editedItem.NO_TIKET,
        pathraport: "",
        pathipk: "",
        pathkelulusan: "",
        pathijazah: "",
        pathsuratditerima: this.editedItem.FILEPATH_SURATDITERIMA,
        pathsuratakreditasi: "",
        pathakreditasiprogramstudi: "",
        pathaktifkuliah: "",
        pathpostpone: "",
      };
      this.overlayDialog = true;

      this.getDataFileBlobBeasiswa(getDetail)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // // console.log(hasil.data)
          this.items_cekblob = hasil.data[0];
          this.overlayDialog = false;

          let b64 = this.items_cekblob.FILEBLOB_SURATDITERIMA;
          const byteCharacters = atob(b64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objectUrl), 100;
          });
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Lampiran Tidak Ditemukan",
          });
          this.overlayDialog = false;
        });
    },

    getDataPDFSuratAkreditasi() {
      const getDetail = {
        notiket: this.editedItem.NO_TIKET,
        pathraport: "",
        pathipk: "",
        pathkelulusan: "",
        pathijazah: "",
        pathsuratditerima: "",
        pathsuratakreditasi: this.editedItem.FILEPATH_SURATAKREDITASI,
        pathakreditasiprogramstudi: "",
        pathaktifkuliah: "",
        pathpostpone: "",
      };
      this.overlayDialog = true;

      this.getDataFileBlobBeasiswa(getDetail)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // // console.log(hasil.data)
          this.items_cekblob = hasil.data[0];
          this.overlayDialog = false;

          let b64 = this.items_cekblob.FILEBLOB_SURATAKREDITASI;
          const byteCharacters = atob(b64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objectUrl), 100;
          });
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Lampiran Tidak Ditemukan",
          });
          this.overlayDialog = false;
        });
    },

    getDataPDFSuratAktifKuliah() {
      const getDetail = {
        notiket: this.editedItem.NO_TIKET,
        pathraport: "",
        pathipk: "",
        pathkelulusan: "",
        pathijazah: "",
        pathsuratditerima: "",
        pathsuratakreditasi: "",
        pathakreditasiprogramstudi: "",
        pathaktifkuliah: this.editedItem.FILEPATH_AKTIFKULIAH,
        pathpostpone: "",
      };
      this.overlayDialog = true;

      this.getDataFileBlobBeasiswa(getDetail)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // // console.log(hasil.data)
          this.items_cekblob = hasil.data[0];
          this.overlayDialog = false;

          let b64 = this.items_cekblob.FILEBLOB_AKTIFKULIAH;
          const byteCharacters = atob(b64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objectUrl), 100;
          });
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Lampiran Tidak Ditemukan",
          });
          this.overlayDialog = false;
        });
    },

    getDataPDFSuratKetPostpone() {
      const getDetail = {
        notiket: this.editedItem.NO_TIKET,
        pathraport: "",
        pathipk: "",
        pathkelulusan: "",
        pathijazah: "",
        pathsuratditerima: "",
        pathsuratakreditasi: "",
        pathakreditasiprogramstudi: "",
        pathaktifkuliah: "",
        pathpostpone: this.editedItem.FILEPATH_SURATPOSTPONE,
      };
      this.overlayDialog = true;

      this.getDataFileBlobBeasiswa(getDetail)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // // console.log(hasil.data)
          this.items_cekblob = hasil.data[0];
          this.overlayDialog = false;

          let b64 = this.items_cekblob.FILEBLOB_SURATPOSTPONE;
          const byteCharacters = atob(b64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objectUrl), 100;
          });
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Lampiran Tidak Ditemukan",
          });
          this.overlayDialog = false;
        });
    },

    clearForm() {
      window.location.reload();
      // this.alasan = "";
    },

    dataUnit() {
      // const getUnit = {nik: sessionStorage.getItem("nik")}
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      const code = {
        nik: this.user.data[0].NikKaryawan,
        idpt: "",
        idcabang: "",
      };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataUserBeasiswa", code, {
      //     headers,
      //   })
      this.getDataUserBeasiswa(code)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          this.items_unit = hasil.data;
          this.dataCabang();
          this.vloading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Unit Tidak Ditemukan",
          });
          this.vloading = false;
        });
    },

    dataCabang() {
      // // console.log(JSON.stringify(this.inputs))
      // this.dialog = true;
      // this.dialog = false;
      let hasil = this.unit.ID_PT;
      //  let hasiltest = hasil.map((item) => item.unit);
      // console.log(hasil);
      const getCabang = {
        nik: this.user.data[0].NikKaryawan,
        idpt: hasil,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataUserBeasiswa", getCabang, {
      //     headers,
      //   })
      this.getDataUserBeasiswa(getCabang)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // // console.log(hasil.data)
          this.items_cabang = hasil.data;
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Cabang Tidak Ditemukan",
          });
        });
    },

    dataRole() {
      // // console.log(JSON.stringify(this.inputs))
      // this.dialog = true;
      // this.dialog = false;
      // let hasil = this.unit.ID_PT;
      //  let hasiltest = hasil.map((item) => item.unit);
      // // console.log(hasil);
      const getRole = {
        nik: this.user.data[0].NikKaryawan,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataRoleBeasiswa", getRole, {
      //     headers,
      //   })
      this.getDataRoleBeasiswa(getRole)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // // console.log(hasil.data)
          this.items_role = hasil.data;
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Role Tidak Ditemukan",
          });
        });
    },

    filterApproval() {
      // console.log(this.unit.ID_PT);
      // console.log(this.cabang.ID_CABANG);
      console.log(this.role.ROLE, "ROLE");
      if (this.role.ROLE != "") {
        this.initialize();
      } else {
        this.$swal({
          icon: "warning",
          title: "Maaf!",
          text: "Anda Harus Memilih Role Terlebih Dahulu",
        });
      }
    },

    clickLampiranRaport() {
      this.dialogLampiranRaport = true;
      // const getRaport = {
      //   notiket: this.editedItem.NO_TIKET
      // };
      // // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer " + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataFileBlobBeasiswa", getRaport, {
      //     headers,
      //   })
      //   .then((response) => {
      //     // // console.log(response.data.GetAllUnitResult);
      //     const hasil = response.data;
      //     // // console.log(hasil.data)
      //     this.items_blob = hasil.data[0];
      //     this.dialogLampiranRaport = true;
      //   })
      //   .catch((error) => {
      //     // console.log(error);
      //     this.$swal({
      //       icon: "error",
      //       title: "Maaf!",
      //       text: "File Tidak Ditemukan",
      //     });
      //   });
    },
    clickLampiranIjazah() {
      this.dialogLampiranIjazah = true;
      // const getIjazah = {
      //   notiket: this.editedItem.NO_TIKET
      // };
      // // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer " + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataFileBlobBeasiswa", getIjazah, {
      //     headers,
      //   })
      //   .then((response) => {
      //     // // console.log(response.data.GetAllUnitResult);
      //     const hasil = response.data;
      //     // // console.log(hasil.data)
      //     this.items_blob = hasil.data[0];
      //     this.dialogLampiranIjazah = true;
      //   })
      //   .catch((error) => {
      //     // console.log(error);
      //     this.$swal({
      //       icon: "error",
      //       title: "Maaf!",
      //       text: "File Tidak Ditemukan",
      //     });
      //   });
    },
    clickLampiranSuratDiterima() {
      this.dialogLampiranSuratDiterima = true;
      // const getSurat = {
      //   notiket: this.editedItem.NO_TIKET
      // };
      // // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer " + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataFileBlobBeasiswa", getSurat, {
      //     headers,
      //   })
      //   .then((response) => {
      //     // // console.log(response.data.GetAllUnitResult);
      //     const hasil = response.data;
      //     // // console.log(hasil.data)
      //     this.items_blob = hasil.data[0];
      //     this.dialogLampiranSuratDiterima = true;
      //   })
      //   .catch((error) => {
      //     // console.log(error);
      //     this.$swal({
      //       icon: "error",
      //       title: "Maaf!",
      //       text: "File Tidak Ditemukan",
      //     });
      //   });
    },
    clickGanjil() {
      const getGanjil = {
        notiket: this.editedItem.NO_TIKET,
        semester: "ganjil",
      };
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      // const axios = require("axios");
      // axios
      //   .post(
      //     this.$baseurl + "beasiswa/insertDataDetailNilaiBeasiswa",
      //     getGanjil,
      //     {
      //       headers,
      //     }
      //   )
      this.insertDataDetailNilaiBeasiswa(getGanjil)
        .then((response) => {
          if (response.data.status == "Success") {
            this.arrayGanjil = response.data.data;
            // // console.log(this.arrayGanjil)
            this.dialogGanjil = true;
          } else {
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: response.data.message,
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Detail Nilai Ganjil Tidak Ditemukan",
          });
        });
    },
    clickGenap() {
      const getGenap = {
        notiket: this.editedItem.NO_TIKET,
        semester: "genap",
      };
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      // const axios = require("axios");
      // axios
      //   .post(
      //     this.$baseurl + "beasiswa/insertDataDetailNilaiBeasiswa",
      //     getGenap,
      //     {
      //       headers,
      //     }
      //   )
      this.insertDataDetailNilaiBeasiswa(getGenap)
        .then((response) => {
          if (response.data.status == "Success") {
            this.arrayGenap = response.data.data;
            // // console.log(this.arrayGanjil)
            this.dialogGenap = true;
          } else {
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: response.data.message,
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Detail Nilai Genap Tidak Ditemukan",
          });
        });
    },
  },
};
</script>
<style>
/* Custom CSS to show tooltip on disabled button */
.disabled-button .v-tooltip__content {
  opacity: 1;
  pointer-events: auto;
}
</style>
