<template>
  <div>
    <c-toolbar />
    <c-navbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2"
              :loading="vloading">
              <template slot="progress">
                <v-progress-linear
                  color="green"
                  indeterminate
                ></v-progress-linear>
              </template>
              <br />
              <v-row justify="center">
                <v-img
                  src="img/indomaret.png"
                  aspect-ratio="3"
                  max-width="250"
                >
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Master Pejabat
              </h3>
              <!-- <v-alert class="mx-4" type="info">
                <div>
                  <b>Master pejabat beasiswa periode 2021/2022 sudah ditutup</b>
                </div>
              </v-alert> -->

              <div class="pa-1 mx-4">
                <v-data-table
                  :headers="headers"
                  :items="itemsTablePejabat"
                  :search="search"
                  sort-by="nama"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-dialog v-model="dialog" max-width="700px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            New Pejabat
                          </v-btn>
                        </template>
                        <v-card elevation="10">
                          <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                  <v-col cols="12" sm="9" md="9">
                                    <v-text-field
                                      v-model="NIK_NamaKaryawan"
                                      label="NIK / Nama Karyawan (Min 6 Karakter)"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="3">
                                    <v-btn
                                      color="primary"
                                      dark
                                      class="mb-2"
                                      @click="listKaryawan"
                                      :loading="btnLoading"
                                    >
                                      Cari
                                    </v-btn>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="chooseKar.NIK"
                                      label="NIK"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="chooseKar.NAMA"
                                      label="Nama"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row v-for="(inputrole, indexRole) in input_role" :key="indexRole">
                                  <v-col cols="12" sm="6" md="6">
                                    <v-autocomplete
                                      :items="itemsRole"
                                      item-text="ROLE"
                                      item-value="ID_ROLE"
                                      label="Role"
                                      v-model="inputrole.role"
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="1" md="1">
                                    <v-btn
                                      class="mx-2"
                                      fab
                                      dark
                                      small
                                      color="primary"
                                      @click="remove_role(indexRole)"
                                      v-show="indexRole || (!indexRole && input_role.length > 1)"
                                    >
                                      <v-icon dark>
                                        mdi-minus
                                      </v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="1" md="1">
                                    <v-btn
                                      class="mx-2"
                                      fab
                                      dark
                                      small
                                      color="primary"
                                      @click="add_role(indexRole)"
                                      v-show="indexRole == input_role.length - 1"
                                    >
                                      <v-icon dark>
                                        mdi-plus
                                      </v-icon>
                                    </v-btn>
                                  </v-col>
                                <!-- </v-row> -->
                                  <v-row v-for="(input, k) in input_role[indexRole].arrayobj_unitcabang" :key="k">
                                    <v-col cols="12" sm="5" md="5">
                                      <v-autocomplete
                                        v-show="
                                          input_role[indexRole].role == 'Verifikasi' ||
                                          input_role[indexRole].role == 'Otorisasi Awal' ||
                                          input_role[indexRole].role == 'Otorisasi Akhir'
                                        "
                                        :items="items_unit"
                                        item-text="PT"
                                        item-value="ID_PT"
                                        label="Unit"
                                        v-model="input.unit"
                                        @change="dataCabang(indexRole,k)"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="5" md="5">
                                      <v-autocomplete
                                        v-show="
                                          input_role[indexRole].role == 'Verifikasi' ||
                                          input_role[indexRole].role == 'Otorisasi Awal' ||
                                          input_role[indexRole].role == 'Otorisasi Akhir'
                                        "
                                        :items="items_cabang[k]"
                                        item-text="CABANG"
                                        item-value="ID_CABANG"
                                        label="Cabang"
                                        v-model="input.cabang"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="1" md="1" 
                                    v-show="input_role[indexRole].role == 'Verifikasi' ||
                                            input_role[indexRole].role == 'Otorisasi Awal' ||
                                            input_role[indexRole].role == 'Otorisasi Akhir'">
                                      <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        small
                                        color="primary"
                                        @click="remove(indexRole,k)"
                                        v-show="k || (!k && input_role[indexRole].arrayobj_unitcabang.length > 1)"
                                      >
                                        <v-icon dark>
                                          mdi-minus
                                        </v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col cols="12" sm="1" md="1" 
                                      v-show="input_role[indexRole].role == 'Verifikasi' ||
                                      input_role[indexRole].role == 'Otorisasi Awal' ||
                                      input_role[indexRole].role == 'Otorisasi Akhir'">
                                      <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        small
                                        color="primary"
                                        @click="add(indexRole)"
                                        v-show="k == input_role[indexRole].arrayobj_unitcabang.length - 1"
                                      >
                                        <v-icon dark>
                                          mdi-plus
                                        </v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="save"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-dialog v-model="dialogKary" max-width="500px">
                        <v-card>
                          <v-card-title class="headline"
                            >List Karyawan</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-data-table
                              :headers="headers_list"
                              :items="items_list"
                              class="elevation-1"
                            >
                              <template v-slot:[`item.detail`]="{ item }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  @click="pilihKary(item)"
                                >
                                  Pilih
                                </v-btn>
                              </template>
                            </v-data-table>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <!-- jarak judul -->
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <!-- search -->
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-toolbar>
                  </template>

                  <!-- <v-tooltip bottom> -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <!-- <v-tooltip slot="append" bottom> -->
                    <!-- <v-icon small class="mr-2" @click="detailItem(item)">
                      mdi-view-list
                    </v-icon> -->
                    <!-- <span>Tooltip</span>
                      </v-tooltip> -->
                    <!-- <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon> -->
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <!-- <span>Tooltip</span>
                      </v-tooltip> -->
                  <!-- <template v-slot:no-data>
                    <h3>No Data</h3>
                    <v-btn color="primary" @click="initialize">
                      Refresh
                    </v-btn>
                  </template> -->
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
// import Sidebar from "@/components/Sidebar.vue";
// import Toolbar from "@/components/Toolbar.vue";
import CNavbar from '../../components/CNavbar.vue'
import CToolbar from '../../components/CToolbar.vue'
import { mapGetters, mapActions } from "vuex";
const axios = require("axios");
export default {
  name: "Home",
  components: {
    CNavbar,
    CToolbar,
  },

  mounted() {
    this.TOKEN = this.token
    this.vloading = true
    // this.name = this.user.NAMA;
    // this.nik = this.user.NIK;
    this.dataUnit()
    // this.showUserRole();
    // this.dataKaryawan();
    this.initialize();
    // this.dataUnit();
    // this.dataBagian();
  },

  data: () => ({
    // $baseurl: "http://localhost/",
    // $baseurl: "http://116.204.128.50:80/",
    // $baseurl: "https://ms.hrindomaret.com/",
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    modelKaryawan: null,
    cariKaryawan: null,
    NIK_NamaKaryawan: "",

    dialog: false,
    dialogKary: false,
    // dialogDelete: false,
    model: null,
    search: null,
    btnLoading: false,
    vloading: false,
    // search: "",
    nik: "",
    nama: "",
    role: "",
    id_unit: "",
    id_bagian: [],
    id_cabang: [],
    unit: "",
    bagian: [],
    cabang: [],
    code: "",
    inputs: [
      {
        unit: "",
        cabang: "",
      },
    ],
    input_role: [
      {
        role: "",
        arrayobj_unitcabang: [
          {
            unit: "",
            cabang: ""
          }
        ]
      },
    ],
    karyawan: [],

    headers: [
      // {
      //   text: "NIK",
      //   align: "start",
      //   sortable: true,
      //   value: "NIK",
      // },
      { text: "NIK", value: "NIK" },
      { text: "Nama Pejabat", value: "NAMA" },
      { text: "Role", value: "ROLE" },
      { text: "Unit", value: "UNITROLE" },
      { text: "Cabang", value: "CABANGROLE" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headers_list: [
      { text: "Karyawan", value: "Karyawan" },
      // { text: "NAMA", value: "NAMA" },
      { text: "Actions", value: "detail", sortable: false },
    ],

    loadingTable: false,

    showDepartement: false,
    itemsRole: [
      // {
      //   ID_ROLE: "Approval manager",
      //   ROLE: "Approval Manager",
      // },
      {
        ID_ROLE: "Verifikasi",
        ROLE: "Verifikasi",
      },
      {
        ID_ROLE: "Otorisasi Awal",
        ROLE: "Otorisasi Awal",
      },
      {
        ID_ROLE: "Otorisasi Akhir",
        ROLE: "Otorisasi Akhir",
      },
      {
        ID_ROLE: "Admin",
        ROLE: "Admin",
      },
    ],
    itemsTablePejabat: [],
    items_list: [],
    items_unit: [],
    items_bagian: [],
    items_cabang: [[]],
    editedIndex: -1,
    editedItem: {
      id_user: "",
      nik: "",
      nama: "",
      role: "",
      bagian: "",
    },

    chooseKar: {
      nik: "",
      nama: "",
    },
    defaultItem: {
      id_user: "",
      nik: "",
      nama: "",
      role: "",
      bagian: "",
    },
    emailKaryawan: ""
  }),

  computed: {
    ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      token:"token"
      // getDrawer: "drawer",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Pejabat" : "Edit Pejabat";
    },
    fields() {
      if (!this.modelKaryawan) return [];

      return Object.keys(this.modelKaryawan).map((key) => {
        return {
          key,
          value: this.modelKaryawan[key] || "n/a",
        };
      });
    },
    itemsKaryawan() {
      return this.entries.map((entry) => {
        const Description =
          entry.Description.length > this.descriptionLimit
            ? entry.Description.slice(0, this.descriptionLimit) + "..."
            : entry.Description;

        return Object.assign({}, entry, { Description });
      });
    },

    validAdmin() {
      return this.chooseKar.NIK && this.chooseKar.NAMA && this.role == "Admin";
    },
    validBasic() {
      let hasil = this.inputs
      let hasiltest = hasil.map((item) => item.unit);
      return this.chooseKar.NIK && this.chooseKar.NAMA && this.role != "Admin"
      && hasiltest[0] ;
    },
    // validPICHO() {
    //   return (
    //     this.chooseKar.NIK &&
    //     this.chooseKar.NAMA &&
    //     this.rolePIC == "PIC HO" &&
    //     this.unit != "" &&
    //     this.bagian.length != 0
    //   );
    // },
    // validCabang() {
    //   return (
    //     this.chooseKar.NIK &&
    //     this.chooseKar.NAMA &&
    //     this.rolePIC == "HR Cabang" &&
    //     this.unit != "" &&
    //     this.cabang.length != 0
    //   );
    // },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
  },

  // created() {
  //   this.initialize();
  // },

  methods: {
    ...mapActions({
            getDataUserBeasiswa:"getDataUserBeasiswa",
            getDataKaryawanBeasiswa:"getDataKaryawanBeasiswa",
            insertDataMstPlafondBeasiswa:"insertDataMstPlafondBeasiswa",
            insertDataMstUserBeasiswa:"insertDataMstUserBeasiswa",
            
        }),
    initialize() {
      const code = {
        nik: "",
        niklogin: this.user.data[0].NikKaryawan,
        role: "",
        status: "read",
        email: "",
        unitrole: [],
        cabangrole: [],
        kategori: "",
        arrayobj_role:"",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: this.user.NikKaryawan,
      // };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/insertDataMstUserBeasiswa", code, {
      //     headers,
      //   })
      this.insertDataMstUserBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
              this.vloading = false
            });
          } else {
            // // console.log(result.message);
            this.itemsTablePejabat = result.data;
            this.vloading = false
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            this.vloading = false
            // this.snackbar=true;
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.itemsTablePejabat.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.$swal({
        icon: "warning",
        title: "Apakah Anda Yakin?",
        text: "Untuk Menghapus Pejabat",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Delete`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.editedIndex = this.itemsTablePejabat.indexOf(item);
          this.editedItem = Object.assign({}, item);
          // // console.log(this.editedItem);
          const NIK_LOGIN = this.user.data[0].NikKaryawan;
          const deleteUser = {
            nik: this.editedItem.NIK,
            niklogin: NIK_LOGIN,
            role: this.editedItem.ROLE,
            status: "delete",
            email: "",
            unitrole: this.editedItem.ID_UNITROLE,
            cabangrole: this.editedItem.ID_CABANGROLE,
            kategori: "",
            arrayobj_role:"",
          };
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   Authorization: "Bearer" + this.TOKEN,
          //   // NIK: this.user.NikKaryawan,
          // };
          // axios
          //   .post(
          //     this.$baseurl + "beasiswa/insertDataMstUserBeasiswa",
          //     deleteUser,
          //     {
          //       headers,
          //     }
          //   )
          this.insertDataMstUserBeasiswa(deleteUser)
            .then((response) => {
              // // console.log(response.data.result.message);
              const hasil = response.data;
              if (hasil.status == "Token is Expired") {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (hasil.status == "Success") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: hasil.data[0].Pesan,
                });
                this.initialize();
              } else {
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menghapus " + hasil.message,
                });
              }
            })
            .catch((error) => {
              // console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menghapus",
              });
            });
        }
      });

      // this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.chooseKar.NIK = "";
      this.chooseKar.NAMA = "";
      this.NIK_NamaKaryawan = "";
      this.role = "";
      this.unit = [];
      this.cabang = [];
      // this.bagian = [];
      // this.clearBagianCabang;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    add(index) {
      console.log(this.input_role[index],"masuk sini")
      this.input_role[index].arrayobj_unitcabang.push({
        unit: "",
        cabang: "",
      });
      // console.log(this.inputs);
    },
    add_role() {
      // var temp = []
      // temp.role = ""
      // temp.unit = ""
      // temp.cabang = ""
      console.log("masuk sini 2")
      this.input_role.push({
        role: "",
        arrayobj_unitcabang: [ 
          {
            unit: "",
            cabang: ""
          } 
        ]
        // temp
      });
      // console.log(this.inputs);
    },
    

    remove(i,index) {
      this.input_role[i].arrayobj_unitcabang.splice(index, 1);
    },
    remove_role(index) {
      this.input_role.splice(index, 1);
    },

    save() {
      let test = this.inputs;
      console.log(test,"tes")
      let hasilunit = test.map((item) => item.unit);
      let hasilcabang = test.map((item) => item.cabang);

      let result_role = this.input_role;
      console.log(result_role,"tes2")
      let hasilrole = result_role.map((item) => item.role);
      // // console.log(JSON.stringify(this.inputs));
      // // console.log(JSON.stringify(hasilunit));
      // // console.log(JSON.stringify(hasilcabang));
      let unit = hasilunit;
      let cabang = hasilcabang;
      let role = hasilrole;
      // console.log(hasilunit);
      // console.log(hasilcabang);
      const NIK = this.chooseKar.NIK;
      // const NAMA = this.chooseKar.NAMA;
      // const role = this.role;
      // const id_unit = this.unit.AliasUnit;
      // const unit = this.unit.NamaUnit;
      // const id_cabang = this.cabang.AREAID;
      // const cabang = this.cabang;
      // const bagian = this.bagian;
      // const bagian = this.bagian.NAME;
      // let temp_id_bagian = this.bagian.map(
      //   (item) => item.OMOPERATINGUNITNUMBER
      // );
      // let temp_bagian = this.bagian.map((item) => item.NAME);
      // let temp_id_cabang = this.cabang.map((item) => item.AREAID);
      // let temp_cabang = this.cabang.map((item) => item.AREANAME);

      // let tempBagian = this.bagian.map(item => item.OMOPERATINGUNITNUMBER)
      // const tempBagian = this.bagian.map((item) => item.OMOPERATINGUNITNUMBER);
      // // console.log(tempBagian)
      const NIK_LOGIN = this.user.data[0].NikKaryawan;
      console.log(this.user.data[0])
      // const data = {
      //   nik: "*"+this.NIK_NamaKaryawan,
      // }
      // this.getDataUserBeasiswa(data)
      this.$swal({
        icon: "info",
        title: "Apakah Anda Yakin?",
        text: "Untuk Menambah User",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Yes`,
      }).then((response) => {
        if (response.isConfirmed) {
          const addUserAdmin = {
            nik: NIK,
            niklogin: NIK_LOGIN,
            role: role,
            status: "create",
            email: "",
            unitrole: [],
            cabangrole: [],
            kategori: "",
            arrayobj_role:"",
          };

          const addUserNonAdmin = {
            nik: NIK,
            niklogin: NIK_LOGIN,
            role: '',
            status: "Create",
            email: '',
            unitrole: '',
            cabangrole: '',
            kategori: "",
            arrayobj_role: this.input_role,
          };
          // const addUserHRCabang = {
          //   code: "1",
          //   data: {
          //     NIK: NIK,
          //     NAMA: NAMA,
          //     ROLE: rolePIC,
          //     ID_UNIT: id_unit,
          //     ID_CABANG: ";" + temp_id_cabang + ";",
          //     ID_BAGIAN: "",
          //     UNIT: unit,
          //     CABANG: ";" + temp_cabang + ";",
          //     BAGIAN: "",
          //     Creator_editor: NIK_LOGIN,
          //   },
          // };
          // // console.log(addUserHRCabang);
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   Authorization: "Bearer" + this.TOKEN,
          //   // NIK: this.user.NikKaryawan,
          // };
          // const axios = require("axios");
          // // console.log(this.rolePIC);
          if (role == "Admin") {
          // axios
          //   .post(
          //     this.$baseurl + "beasiswa/insertDataMstUserBeasiswa",
          //     addUserAdmin,
          //     {
          //       headers,
          //     }
          //   )
          this.insertDataMstUserBeasiswa(addUserAdmin)
            .then((response) => {
              // // console.log(response.data.result.message);
              const hasil = response.data;
              if (hasil.status == "Token is Expired") {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (hasil.status == "Success") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: hasil.data[0].Pesan,
                }).then(() => {
                  this.initialize();
                  window.location.reload();
                  this.clearForm();
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menambahkan, " + hasil.message,
                });
              }
            })
            .catch((error) => {
              // console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menambahkan",
              });
            });
          } else {
            // // console.log(addUserPICHO);
            // axios
            // .post(
            //   this.$baseurl + "beasiswa/insertDataMstUserBeasiswa",
            //   addUserNonAdmin,
            //   {
            //     headers,
            //   }
            // )
            this.insertDataMstUserBeasiswa(addUserNonAdmin)
            .then((response) => {
              // // console.log(response.data.result.message);
              const hasil = response.data;
              if (hasil.status == "Token is Expired") {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (hasil.status == "Success") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: hasil.data[0].Pesan,
                }).then(() => {
                  this.initialize();
                  window.location.reload();
                  this.clearForm();
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menambahkan, " + hasil.message,
                });
              }
            })
            .catch((error) => {
              // console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menambahkan",
              });
            });
          }
        }
      });

      // if (rolePIC == "PIC HO") {
      //   this.$swal({
      //     icon: "info",
      //     title: "Apakah Anda Yakin?",
      //     text: "Untuk Menambah User PIC HO",
      //     showCancelButton: true,
      //     confirmButtonColor: "#3085d6",
      //     cancelButtonColor: "#d33",
      //     cancelButtonText: `Cancel`,
      //     confirmButtonText: `Yes`,
      //   }).then((response) => {
      //     if (response.isConfirmed) {
      //       const addUserPICHO = {
      //         code: "1",
      //         data: {
      //           NIK: this.chooseKar.NIK,
      //           NAMA: this.chooseKar.NAMA,
      //           ROLE: rolePIC,
      //           UNIT: this.unit,
      //           CABANG: "",
      //           BAGIAN: ";" + this.bagian + ";",
      //           Creator_editor: NIK,
      //         },
      //       };

      //       const varToken = sessionStorage.getItem("token");
      //       const headers = {
      //         JWT: varToken,
      //         NIK: this.user.NikKaryawan,
      //       };
      //       const axios = require("axios");
      //       // console.log(this.rolePIC);

      //       axios
      //         .post(this.$baseurl + "api/v1/MasterUser", addUserPICHO, {
      //           headers,
      //         })
      //         .then((response) => {
      //           // console.log(response.data.result.message);
      //           if (response.data.Message) {
      //             this.$swal({
      //               icon: "info",
      //               title: "Info!",
      //               text: "Session Anda Telah Habis",
      //             }).then(() => {
      //               sessionStorage.clear();
      //               this.$router.push({ name: "Login" });
      //             });
      //           } else if (response.data.result.message == "OK") {
      //             this.$swal({
      //               icon: "success",
      //               title: "Berhasil",
      //               text: "User Telah Ditambahkan",
      //             }).then(() => {
      //               this.initialize();
      //               // window.location.reload();
      //               this.clearForm();
      //             });
      //           } else {
      //             this.$swal({
      //               icon: "error",
      //               title: "Maaf!",
      //               text:
      //                 "Gagal Menambahkan, " + response.data.result.message,
      //             });
      //           }
      //         })
      //         .catch((error) => {
      //           // console.log(error);
      //           this.$swal({
      //             icon: "error",
      //             title: "Maaf!",
      //             text: "Gagal Menambahkan",
      //           });
      //         });
      //     }
      //   });
      // } else if(rolePIC == "HR Cabang"){
      //   this.$swal({
      //     icon: "info",
      //     title: "Apakah Anda Yakin?",
      //     text: "Untuk Menambah User HR Cabang",
      //     showCancelButton: true,
      //     confirmButtonColor: "#3085d6",
      //     cancelButtonColor: "#d33",
      //     cancelButtonText: `Cancel`,
      //     confirmButtonText: `Yes`,
      //   }).then((response) => {
      //     if (response.isConfirmed) {
      //       const addUserCabang = {
      //         code: "1",
      //         data: {
      //           NIK: this.chooseKar.NIK,
      //           NAMA: this.chooseKar.NAMA,
      //           ROLE: rolePIC,
      //           UNIT: this.unit,
      //           CABANG: ";" + this.cabang + ";",
      //           BAGIAN: "",
      //           Creator_editor: NIK,
      //         },
      //       };

      //       const varToken = sessionStorage.getItem("token");
      //       const headers = {
      //         JWT: varToken,
      //         NIK: this.user.NikKaryawan,
      //       };
      //       const axios = require("axios");
      //       // console.log(this.rolePIC);

      //       axios
      //         .post(this.$baseurl + "api/v1/MasterUser", addUserCabang, {
      //           headers,
      //         })
      //         .then((response) => {
      //           // console.log(response.data.result.message);
      //           if (response.data.Message) {
      //             this.$swal({
      //               icon: "info",
      //               title: "Info!",
      //               text: "Session Anda Telah Habis",
      //             }).then(() => {
      //               sessionStorage.clear();
      //               this.$router.push({ name: "Login" });
      //             });
      //           } else if (response.data.result.message == "OK") {
      //             this.$swal({
      //               icon: "success",
      //               title: "Berhasil",
      //               text: "User Telah Ditambahkan",
      //             }).then(() => {
      //               this.initialize();
      //               // window.location.reload();
      //               this.clearForm();
      //             });
      //           } else {
      //             this.$swal({
      //               icon: "error",
      //               title: "Maaf!",
      //               text:
      //                 "Gagal Menambahkan, " + response.data.result.message,
      //             });
      //           }
      //         })
      //         .catch((error) => {
      //           // console.log(error);
      //           this.$swal({
      //             icon: "error",
      //             title: "Maaf!",
      //             text: "Gagal Menambahkan",
      //           });
      //         });
      //     }
      //   });
      // } else{
      //   this.$swal({
      //     icon: "info",
      //     title: "Apakah Anda Yakin?",
      //     text: "Untuk Menambah User ETD",
      //     showCancelButton: true,
      //     confirmButtonColor: "#3085d6",
      //     cancelButtonColor: "#d33",
      //     cancelButtonText: `Cancel`,
      //     confirmButtonText: `Yes`,
      //   }).then((response) => {
      //     if (response.isConfirmed) {
      //       // // console.log(this.rolePIC)
      //       const addUserETD = {
      //         code: "1",
      //         data: {
      //           NIK: this.chooseKar.NIK,
      //           NAMA: this.chooseKar.NAMA,
      //           ROLE: rolePIC,
      //           UNIT: "",
      //           CABANG: "",
      //           BAGIAN: "",
      //           Creator_editor: NIK,
      //         },
      //       };

      //       const varToken = sessionStorage.getItem("token");
      //       const headers = {
      //         JWT: varToken,
      //         NIK: this.user.NikKaryawan,
      //       };
      //       const axios = require("axios");
      //       // console.log(this.rolePIC);

      //       axios
      //         .post(this.$baseurl + "api/v1/MasterUser", addUserETD, {
      //           headers,
      //         })
      //         .then((response) => {
      //           // console.log(response.data.result.message);
      //           if (response.data.Message) {
      //             this.$swal({
      //               icon: "info",
      //               title: "Info!",
      //               text: "Session Anda Telah Habis",
      //             }).then(() => {
      //               sessionStorage.clear();
      //               this.$router.push({ name: "Login" });
      //             });
      //           } else if (response.data.result.message == "OK") {
      //             this.$swal({
      //               icon: "success",
      //               title: "Berhasil",
      //               text: "User Telah Ditambahkan",
      //             }).then(() => {
      //               this.initialize();
      //               // window.location.reload();
      //               this.clearForm();
      //             });
      //           } else {
      //             this.$swal({
      //               icon: "error",
      //               title: "Maaf!",
      //               text:
      //                 "Gagal Menambahkan, " + response.data.result.message,
      //             });
      //           }
      //         })
      //         .catch((error) => {
      //           // console.log(error);
      //           this.$swal({
      //             icon: "error",
      //             title: "Maaf!",
      //             text: "Gagal Menambahkan",
      //           });
      //         });
      //     }
      //   });
      // }
      // }
      this.close();
    },

    dataKaryawan() {
      // axios
      //   .post("https://hrindomaret.com/api/covidform/getUserHO", {})
      //   .then((response) => {
      //     this.karyawan = response.data;
      //     // console.log(this.karyawan);
      //   })
      //   .catch((error) => {
      //     // console.log(error)
      //     alert("Tidak Dapat Mengambil Karyawan");
      //   });
    },

    listKaryawan() {
      const searchKary = {
        nik: "*"+this.NIK_NamaKaryawan,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: this.user.NikKaryawan,
      // };

      if (this.NIK_NamaKaryawan.length < 6) {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          text: "Pencarian minimal 6 karakter",
        });
      } else {
        this.btnLoading = true;
        // axios
        //   .post(this.$baseurl + "beasiswa/getDataUserBeasiswa", searchKary, {
        //     headers,
        //   })
        this.getDataUserBeasiswa(searchKary)
          .then((response) => {
            // // console.log(response.data.SearchWorkerResult.length);
            const result = response.data;

            if (result.status == "Token is Expired") {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else if (result.data.length > 0) {
              this.items_list = result.data;
              console.log(result.data,"dataKaryawan")
              this.btnLoading = false;
              this.dialogKary = true;
            } else {
              this.btnLoading = false;
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Karyawan Tidak Ditemukan",
              });
            }
          })
          .catch((error) => {
            // console.log(error);
            this.btnLoading = false;
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Karyawan Tidak Ditemukan",
            });
          });
      }
    },

    pilihKary(item) {
      // console.log(item)
      this.chooseKar = Object.assign({}, item);
      // // console.log(this.chooseKar.Karyawan);
      let panjang = this.chooseKar.Karyawan;
      let trim = panjang.trim();
      let NIK = trim.substr(0, 10);
      let NAMA = trim.substr(trim.indexOf("-") + 2);
      // // console.log(trim)
      // // console.log("'"+NIK+"'")
      // // console.log("'"+NAMA+"'")
      // this.NIK_NamaKaryawan = this.chooseKar.nik;
      this.chooseKar.NIK = NIK;
      this.chooseKar.NAMA = NAMA;
      this.dialogKary = false;
      this.NIK_NamaKaryawan = "";
      this.emailKaryawan = item.EmailKaryawan
    },

    dataUnit() {
      // const getUnit = {nik: this.user.NikKaryawan}
      // const varToken = sessionStorage.getItem("token");
      const headers = {
        Authorization: "Bearer" + this.TOKEN,
        // NIK: this.user.NikKaryawan,
      };
      const code = {
        nik: "*20151",
        idpt: "",
        idcabang: ""
      };
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataUserBeasiswa", code, {
      //     headers,
      //   })
      this.getDataUserBeasiswa(code)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // console.log(hasil, "hasilUnit")
          this.items_unit = hasil.data;
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Unit Tidak Ditemukan",
          });
        });
    },

    dataCabang(index,k) {
      
      // // console.log(JSON.stringify(this.inputs))
      let hasil = this.input_role[index].arrayobj_unitcabang[k]
      console.log(hasil,"ini hasilnya")
    //  let hasiltest = hasil.map((item) => item.unit);
    //  // console.log(hasiltest[0])
      const getCabang = {
        nik: "*20151",
        idpt: hasil.unit,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: this.user.NikKaryawan,
      // };
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataUserBeasiswa", getCabang, {
      //     headers,
      //   })
        this.getDataUserBeasiswa(getCabang)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          
          const hasil = response.data;
          // console.log(hasil.data)
          this.items_cabang[k] = hasil.data;
          // console.log(this.items_cabang[k],"itemCabang")
          // console.log(k, "index")
          // console.log(this.items_unit, "unit")
          this.add(index);
          this.remove(index,k+1);
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Cabang Tidak Ditemukan",
          });
        });
    },

    // dataBagian() {
    //   const getBagian = {
    //     level: "5,6",
    //     dept_area: "00",
    //   };
    //   const varToken = sessionStorage.getItem("token");
    //   const headers = {
    //     JWT: varToken,
    //     // NIK: this.user.NikKaryawan,
    //   };
    //   axios
    //     .post(
    //       this.$baseurl +
    //         "api/v1/DVC/Utility/Company/GetDepartemenByLevelDeptArea",
    //       getBagian,
    //       {
    //         headers,
    //       }
    //     )
    //     .then((response) => {
    //       const hasil = response.data.GetDepartemenByLevelDeptAreaResult;
    //       this.items_bagian = hasil;
    //     })
    //     .catch((error) => {
    //       // console.log(error);
    //       this.$swal({
    //         icon: "error",
    //         title: "Maaf!",
    //         text: "Bagian Tidak Ditemukan",
    //       });
    //     });
    // },

    clearBagianCabang() {
      this.unit = "";
      this.bagian = [];
      this.cabang = [];
    },

    clearBagian() {
      this.unit = "";
      this.bagian = [];
    },

    clearCabang() {
      this.unit = "";
      this.cabang = [];
    },

    clearForm() {
      this.chooseKar.NIK = "";
      this.chooseKar.NAMA = "";
      this.role = "";
      this.unit = [];
      this.cabang = [];
      // this.bagian = [];
    },
  },
};
</script>
