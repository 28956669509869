"use strict"

import izinApi from "@/api/izin.js"
import store from "../index"
import { encrypt, decrypt } from '../encrypt';

export default {

    actions: {
        riwayatIzin(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                izinApi.getIzin(payloadEncrypt, token).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        getPendingIzin(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                izinApi.getIzin(payloadEncrypt).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        getDetailPendingIzin(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                izinApi.getIzin(payloadEncrypt).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        getImageIzinSakit(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                izinApi.getIzin(payloadEncrypt).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        approveIzinApi(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                izinApi.approveRejectIzin(payloadEncrypt, token).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        rejectIzinApi(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                var isEncrypt = process.env.VUE_APP_ENCRYPT;
                var payloadEncrypt = isEncrypt == 'false' ? payload : encrypt(payload)
                izinApi.approveRejectIzin(payloadEncrypt, token).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
    }
}