<template>
  <div>
    <c-toolbar />
    <c-navbar />
    <v-main>
      <v-responsive aspect-ratio="16/9">
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="12">
              <v-card class="mx-auto elevation-11 py-4 px-2">
                <v-row justify="center">
                  <v-img
                    src="img/indomaret.png"
                    aspect-ratio="3"
                    max-width="300"
                    position="end"
                  ></v-img>
                </v-row>

                <h1 class="text-center my-4">Riwayat Presensi Karyawan</h1>

                <v-col cols="12" sm="4">
                  <v-menu
                    ref="date1"
                    v-model="date1"
                    transition="scale-transition"
                    :close-on-content-click="false"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="tanggalAwal"
                        label="Pilih Tanggal Awal"
                        v-bind="attrs"
                        v-on="on"
                        hint="YYYY-MM-DD format"
                        persistent-hint
                        :rules="[() => !!tanggalAwal || 'This field is required']"
                        prepend-icon="mdi-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.startDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      no-title
                      @input="date1 = false"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false"
                        >Batal
                      </v-btn>
                      <v-btn text color="primary">OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-menu
                    ref="date2"
                    v-model="date2"
                    transition="scale-transition"
                    :close-on-content-click="false"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="tanggalAkhir"
                        label="Pilih Tanggal Akhir"
                        v-bind="attrs"
                        v-on="on"
                        hint="YYYY-MM-DD format"
                        persistent-hint
                        :rules="[() => !!tanggalAkhir || 'This field is required']"
                        prepend-icon="mdi-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.endDate"
                      :max="maxDate"
                      no-title
                      @input="date2 = false"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false"
                        >Batal</v-btn
                      >
                      <v-btn text color="primary">OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  sm="6"
                  align="center"
                  justify="space-around"
                  class="ml-2"
                >
                  <v-row>
                    <v-checkbox
                      v-if="itemsBawahan.length > 0"
                      label="Lihat Bawahan"
                      v-model="checked"
                    ></v-checkbox>
                    <v-checkbox
                      v-else
                      label="Lihat Bawahan"
                      v-model="checked"
                      disabled
                    ></v-checkbox>
                  </v-row>
                  <v-autocomplete
                    v-model="select"
                    @input="select = $event || ''"
                    :disabled="!checked"
                    :items="itemsBawahan"
                    :loading="isLoading"
                    item-value="NIK"
                    :filter="customFilter"
                    hide-no-data
                    hide-selected
                    clearable
                    label="Daftar bawahan"
                    placeholder="Cari bawahan dengan nik/nama"
                    prepend-icon="mdi-account-search"
                    @click:clear="handleClear"
                    return-object
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.NIK }} - {{ data.item.NAMA | gantiNama }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.NIK }} - {{ data.item.NAMA | gantiNama }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <div class="d-flex flex-row">
                  <v-btn
                    color="primary"
                    elevation="2"
                    :loading="isLoading"
                    class="mx-2"
                    :disabled="this.select.NIK === 'All'"
                    @click="getPresensi"
                  >
                    Submit
                  </v-btn>
                  <v-btn
                    color="#2E7D32"
                    @click="exportToExcel('xlsx')"
                    elevation="2"
                    :loading="isLoading"
                    class="mx-2 white--text"
                    :disabled="this.select.NIK !== 'All'"
                  >
                    Cetak Rincian
                    <v-icon>
                     mdi-microsoft-excel
                    </v-icon>
                  </v-btn>
                </div>

                <v-card class="mt-2 pt-2" outlined>
                  <v-row>
                    <v-col cols="12">
                      <v-card-title>
                        Laporan Presensi Karyawan

                        <v-spacer></v-spacer>
                        <v-col cols="4" sm="4">
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-card-title>

                      <div v-if="showTable">
                        <!-- <v-row align="center" justify="center" no-gutters>
                          <v-col sm="4">
                            <span class="subtitle-2"
                              >NIK Karyawan:
                              {{
                                this.select.NIK != "" &&
                                this.select.NIK != undefined
                                  ? this.select.NIK
                                  : this.nik
                              }}</span
                            >
                          </v-col>
                        </v-row>
                        <v-row
                          align="center"
                          justify="center"
                          no-gutters
                          class="mb-2"
                        >
                          <v-col sm="4">
                            <span class="subtitle-2"
                              >Nama Karyawan:
                              {{
                                this.select.NAMA != "" &&
                                this.select.NAMA != undefined
                                  ? this.select.NAMA
                                  : this.nama
                              }}</span
                            >
                          </v-col>
                        </v-row> -->
                        <v-divider class="mx-auto"></v-divider>
                        <v-data-table
                          :headers="headers"
                          :items="rows_table"
                          :search="search"
                          :loading="isLoading"
                          class="elevation-1 "
                          hide-default-header
                        >
                          <template #header="{ }">
                            <thead class="v-data-table-header">
                              <tr>
                                <th v-for="(h,i) in headers" :key="i" class="text-center parent-header td-border-style" :rowspan="h.children?1:2" :colspan="h.children?h.children.length:1">
                                  {{ h.text }}
                                </th>
                              </tr>
                              <tr>
                                <th v-for="(h1,i1) in getSubHeader(headers)" :key="i1" class="text-center child-header td-border-style">
                                  {{ h1.text }}
                                </th>
                              </tr>
                            </thead>
                          </template>
                          <template #item="props">
                            <tr>
                              <td v-for="(c,ci) in getRows(props.item)" :key="ci" class="text-center">
                                {{ c }}
                              </td>
                            </tr>
                          </template>
                          <!-- <template slot="items" slot-scope="props">
                            <td v-if="shownColumns.no">{{ props.item.$no }}</td>
                          </template> -->
                        </v-data-table>
                      </div>

                      <div v-else>
                        <v-card :loading="loadingHasil">
                          <v-card-text
                            v-show="!loadingHasil"
                            class="text-center font-italic error--text"
                            >Please Completely field the form to show the
                            table</v-card-text
                          >
                          <template slot="progress">
                            <v-card-text class="text-center">
                              <v-progress-circular
                                color="primary"
                                indeterminate
                              ></v-progress-circular>
                            </v-card-text>
                          </template>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </v-main>
  </div>
</template>

<style scoped>
  /* .td-border-style {
    border-top: thin solid rgba(0, 0, 0, 0.12);
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-right: thin solid rgba(0, 0, 0, 0.12);
  } */
  /* .v-data-table-header .td {
      padding: 12px 8px;
      border-color: rgba(0,0,0,0.12);
      border-style: solid;
      border-left-width: 0px;
      border-right-width: 1px;
      border-top-width: 0px;
      border-bottom-width: 1px;
    }
    .v-data-table-header .th {
      border-color: rgba(0,0,0,0.12);
      border-style: solid;
      border-left-width: 0px;
      border-right-width: 1px;
      border-top-width: 0px;
      border-bottom-width: 1px;
    }
  .v-data-table table {
    border: solid 1px #a1a1a1;
    border-radius: 5px;
  } */
  .v-data-table table thead tr th {
    font-weight: bold;
    padding: 0px 8px;
    border-color: rgba(0,0,0,0.12);
    border-style: solid;
    border-left-width: 0px;
    border-right-width: 1px;
    border-top-width: 0px;
    border-bottom-width: 1px;
  }
/* 
  .v-data-table table tbody tr td .v-btn {
    margin-right: 0px !important;
  }

  .v-data-table .v-data-table-header__sort-badge {
    font-size: 10px;
  }

  .v-data-table.theme--dark tr th {
      color: #fff !important;
  }

  .v-data-table.theme--light table thead tr th{
      color: black;
  }
  .v-data-table.theme--light table thead tr td {
    color: black;
  } */
</style>

<script>
import Axios from "axios";
import { mapActions, mapGetters } from "vuex";
import CToolbar from "@/components/CToolbar.vue";

import CNavbar from "@/components/CNavbar.vue";
import _ from "lodash";
import XLSX from "xlsx";

export default {
  name: "Riwayat-Presensi",
  components: {
    CToolbar,
    CNavbar,
  },

  data() {
    return {
      search: "",
      headers: [
        { text: "No", value: "no" },
        { text: "NIP", value: "nik" },
        { text: "Nama", value: "nama" },
        { text: "Tanggal", value: "tanggalAbsen" },
        { text: "Aktual", divider: true, children: [
          { text: "Kode Absen", value: "kodeAbsen" },
          { text: "Shift", value: "idShift" },
          { text: "Status", value: "description" },
          { text: "Jam In", value: "jamIn" },
          { text: "Jam Out", value: "jamOut" }
        ]}
      ],
      btnLoading: false,
      startDate: "",
      endDate: "",
      results: [],
      rows_table: [],
      nik: "",
      nama: "",
      select: "",
      checked: false,
      itemsBawahan: [],

      showFieldDay: false,
      date1: false,
      date2: false,
      isLoading: false,
      loadingHasil: false,
      showTable: false,

      formData: {
        startDate: "",
        endDate: "",
      },
    };
  },

  mounted() {
    (this.nik = this.user.data[0].NikKaryawan),
      (this.nama = this.user.data[0].NamaKaryawan),
      this.getBawahan();
    // this.selectedNIK = {
    //   NIK: null,
    //   NAMA: null
    // }
  },

  filters: {
    gantiNama(str) {
      const arr = str.toLowerCase().split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    },
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),

    maxDate() {
      if (
        this.formData.startDate != null &&
        this.formData.startDate != "" &&
        this.formData.startDate != undefined
      ) {
        // if(new Date(this.formData.startDate).toISOString().substr(0,10) == new Date().toISOString().substr(0,10)) {
        //   return new Date().toISOString().substr(0, 10);
        // }

        let max = new Date(this.formData.startDate);
        let valueDate = max.setDate(max.getDate() + 6);
        let currDate = new Date();
        let valueCurrDate = currDate.setDate(currDate.getDate());

        if (valueDate >= valueCurrDate)
          return new Date().toISOString().substr(0, 10);
        else return new Date(valueDate).toISOString().substr(0, 10);
      }
    },

    tanggalAwal() {
      console.log("tanggalMulai: " + this.formData.startDate);
      if (this.formData.startDate == "") {
        return "";
      } else {
        let startDate = this.testReformatDate(
          new Date(this.formData.startDate)
        );
        return startDate;
      }
    },

    tanggalAkhir() {
      console.log("tanggalAkhir: " + this.formData.endDate);
      let timeDiff = Math.abs(
        new Date(this.formData.endDate).getTime() -
          new Date(this.formData.startDate).getTime()
      );
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (
        this.formData.endDate >= this.formData.startDate ||
        (this.formData.endDate == "" && this.formData.startDate != "")
      ) {
        if (this.formData.endDate == "") {
          return "";
        } else if (diffDays > 6) {
          this.$swal({
            icon: "warning",
            title: "Perhatian!",
            html: `Periode presensi yang dapat dipilih maksimal hanya 7 hari!`,
          });
        } else {
          let endDate = this.testReformatDate(new Date(this.formData.endDate));
          return endDate;
        }
      } else {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          html: `Tanggal akhir harus lebih besar dari tanggal awal!`,
        });
      }
    },

    computedHeaders() {
      let bp = this.$vuetify.breakpoint;
      let vm = this;
      return this.headers.filter((h) => {
        if (!h.hide) return true;
        let hide = h.hide;
        // console.log('hide: ' + hide)
        if (!Array.isArray(hide)) hide = [hide];
        return !hide.find((e) => {
          if (typeof e === "string") return bp[e];
          if (typeof e === "function") return e(vm);
        });
      });
    },
    shownColumns() {
      let m = {};
      this.computedHeaders.forEach((e) => (m[e.id] = true));
      return m;
    },
  },

  methods: {
    ...mapActions({
      presensiKaryawan: "presensiKaryawan",
      daftarBawahan: "daftarBawahan",
    }),

    exportToExcel(type, fn, dl) {
      let data = {
        data: {
          module: "report",
          code: "1",
          report: {
            action: "presensi_periode",
            tanggalMulai: this.formData.startDate,
            tanggalAkhir: this.formData.endDate,
            nikAtasan: this.nik,
          },
        },
        code: "2",
        nik: this.nik
      };
      if (
        data.data.report.nik == "" ||
        data.data.report.tanggalMulai == "" ||
        data.data.report.tanggalAkhir == ""
      ) {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          html: `Harap isi form dengan lengkap!`,
        });
      }
      else {
        this.menu = false;
        this.isLoading = true;
        this.presensiKaryawan(data)
          .then((response) => {
            this.results = response;
            this.isLoading = false;

            const rows_sorted = this.results.sort((a, b) => {
              return a.nik - b.nik || a.tanggalAbsen - b.tanggalAbsen;
            })
            let num = 0;
            const rows_result = rows_sorted.map((row) => ({
              no: num +=1,
              nik: row.nik,
              nama: row.nama,
              tanggalAbsen: row.tanggalAbsen,
              kodeAbsen: row.kodeAbsen,
              kodeShift: row.idShift,
              description: row.description,
              jamMasuk: row.jamIn,
              jamKeluar: row.jamOut,
            }));
            //Had to create a new workbook and then add the header
            const wb = XLSX.utils.book_new();

            // width for each columns
            const wscols = [
              {wch:2}, 
              {wch:15}, 
              {wch:50}, 
              {wch:20},
              {wch:10},
              {wch:10},
              {wch:18},
              {wch:10},
              {wch:10}
            ];
            const ws = XLSX.utils.json_to_sheet([]);
            ws["!cols"] = wscols;

            XLSX.utils.sheet_add_aoa(ws, [['No.', 'NIP', 'Nama', 'Tanggal Absen', 'Kode Absen', 'Kode Shift', 'Keterangan', 'Jam Masuk', 'Jam Keluar']]);

            //Starting in the second row to avoid overriding and skipping headers
            XLSX.utils.sheet_add_json(ws, rows_result, { origin: 'A2', skipHeader: true });
            // XLSX.utils.sheet_add_json(ws, this.results);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            XLSX.writeFile(wb, `Report Presensi Bawahan - ${this.nik}.xlsx`);
          })
          .catch((error) => {
            alert("Data Error");
            this.isLoading = false;
            // alert(error);
            // console.log(error);
          });
      }
    },

    getSubHeader(headers) {
      let result = [];
      headers
        .filter((i) => i.children)
        .forEach((v) => {
          result = result.concat(v.children);
        });
      return result;
    },

    getRows(rows) {
      const result = {};
      _.forEach(rows, (i, key) => {
        if (i.children) {
          _.forEach(i.children, (i1, key1) => {
            result["c" + key1] = i1;
          });
        } else result[key] = i;
      });
      return result;
    },

    handleClear() {
      this.showTable = false;
    },

    customFilter(item, queryText, itemText) {
      const textOne = item.NIK.toLowerCase();
      const textTwo = item.NAMA.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    getPresensi() {
      const nikBawahan = this.select.NIK;
      let data = {
        data: {
          module: "report",
          code: "1",
          report: {
            action: "presensi_periode",
            nik: nikBawahan ? nikBawahan : this.nik,
            tanggalMulai: this.formData.startDate,
            tanggalAkhir: this.formData.endDate,
          },
        },
        code: "2",
        nik: this.nik
      };
      if (
        data.data.report.nik == "" ||
        data.data.report.tanggalMulai == "" ||
        data.data.report.tanggalAkhir == ""
      ) {
        this.$swal({
          icon: "warning",
          title: "Perhatian!",
          html: `Harap isi form dengan lengkap!`,
        });
      } 
      else {
        this.menu = false;
        this.isLoading = true;
        this.presensiKaryawan(data)
        .then((response) => {
          this.results = response;

          const rows_sorted = this.results.sort((a, b) => {
              return a.nik - b.nik || a.tanggalAbsen - b.tanggalAbsen;
          })
          let num = 0;
          this.rows_table = rows_sorted.map((row) => ({
            no: num +=1,
            nik: row.nik,
            nama: row.nama,
            tanggalAbsen: row.tanggalAbsen,
            kodeAbsen: row.kodeAbsen,
            kodeShift: row.idShift,
            description: row.description,
            jamMasuk: row.jamIn,
            jamKeluar: row.jamOut,
          }));
          this.showTable = true;
          this.isLoading = false;
        })
        .catch((error) => {
          alert("Data Error");
          this.isLoading = false;
          // alert(error);
          // console.log(error);
        });
      }
    },

    getBawahan() {
      let data = {
        param: {
          code: "4",
          action: "bawahan-1",
          find: "[kalo butuh masukin nik/nama, kalo ga kosongin]",
          atasan: this.nik,
        },
        code: "1",
        nik: this.nik
      };

      this.daftarBawahan(data)
        .then((response) => {
          this.itemsBawahan = response;
          if(this.itemsBawahan.length > 0){
          this.itemsBawahan.sort().reverse();
          }
          else{
            this.$swal({
                        title:"Perhatian!",
                        icon:"warning",
                        html:`Data Bawahan Tidak Ada`
                    })
          }
        })
        .catch((error) => {
          const errorMessage = error;
          // alert("Data Error");
          alert(errorMessage);
          // console.log(errorMessage);
        });
    },

    testReformatDate(val) {
      let tempDate = new Date(val);
      return `${tempDate.getFullYear()}-${(
        "0" +
        (tempDate.getMonth() + 1)
      ).slice(-2)}-${("0" + tempDate.getDate()).slice(-2)}`;
    },

    // getDaysArray (start, end) {
    //   let arr=[];
    //   for(let i=0; i < 6; i++){
    //       arr.push(start);
    //       start.setDate(start.getDate() + 1)
    //       console.log(arr[i])
    //   }
    //   return arr;
    // },

    // allowedDates(val) {
    //   let dateArray = this.getDaysArray(new Date(this.formData.startDate), new Date(this.formData.startDate));
    //   return dateArray.map((v)=> new Date(v).toISOString().substr(0,10));
    // },
  },

  watch: {
    select: function(value) {
      console.log("select value: ", value.NIK); // select value
      console.log(typeof value); //select type
      this.showTable = false;
    },

    checked: function() {
      if (this.checked == false) {
        this.select = "";
      }
    },
  },

  // watch: {
  //   search (val) {
  //       // Items have already been loaded
  //       if (this.itemsBawahan.length > 0) return

  //       // Items have already been requested
  //       if (this.isLoading) return

  //       this.isLoading = true

  //       // Lazily load input items
  //       let data = {
  //         param: {
  //           code: "4",
  //           action: "bawahan-1",
  //           find: "[kalo butuh masukin nik/nama, kalo ga kosongin]",
  //           atasan: this.nik
  //         },
  //         code:"1"
  //       }

  //       this.daftarBawahan(data)
  //         .then(res => {
  //           const { NIK } = res
  //           this.itemsBawahan = NIK
  //         })
  //         .catch(err => {
  //           console.log(err)
  //         })
  //         .finally(() => (this.isLoading = false))
  //     },
  // }
};
</script>
