import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/axios'
import vuetify from './plugins/vuetify';
import VueSweetalert2 from 'vue-sweetalert2'
import VDigitalTimePicker from 'v-digital-time-picker'
import browserDetect from "vue-browser-detect-plugin";
import * as firebase from "firebase/app";
import 'firebase/messaging'

Vue.config.productionTip = false

//!Live API Variable Global
// Vue.prototype.$baseurl = process.env.VUE_APP_API_URL;
// //!API Alamat
// Vue.prototype.$alamaturl = "https://hrindomaret.com/api/HRMS/PengkinianData/Dev";

Vue.use(VueSweetalert2);
Vue.use(VDigitalTimePicker)
Vue.use(browserDetect);

firebase.initializeApp({
    apiKey: "AIzaSyBjp2plRXkKcUZeXHJbMkyVs3Td0AgZFCQ",
    authDomain: "notifidm.firebaseapp.com",
    projectId: "notifidm",
    storageBucket: "notifidm.appspot.com",
    messagingSenderId: "60807709657",
    appId: "1:60807709657:web:df5270f963fb7346864fc8",
    measurementId: "G-MTHDSH5G6P"
});

// navigator.serviceWorker
//     .register("../public/firebase-messaging-sw.js")
//     .then((registration) => {
//         const messaging = firebase.getMessaging();
//         messaging.useServiceWorker(registration);
//     })
//     .catch((err) => {
//         console.log(err);
//     });

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')