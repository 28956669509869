var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-toolbar'),_c('c-navbar'),_c('v-main',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto elevation-5 py-4 px-2",attrs:{"loading":_vm.vloading}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"green","indeterminate":""}})],1),_c('br'),_c('v-row',{attrs:{"justify":"center"}},[_c('v-img',{attrs:{"src":"img/indomaret.png","aspect-ratio":"3","max-width":"250"}})],1),_c('h3',{staticClass:"text-center my-4"},[_vm._v(" Master Besaran Plafond Bantuan Pendidikan ")]),_c('div',{staticClass:"pa-1 mx-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsTablePlafond,"search":_vm.search,"sort-by":"nama"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New Plafond ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.itemsTingkatPendidikan,"disabled":_vm.editedIndex > -1,"item-text":"TINGKAT_PENDIDIKAN","item-value":"ID_TINGKATPENDIDIKAN","label":"Tingkat Pendidikan"},model:{value:(_vm.editedItem.ID_TINGKATPENDIDIKAN),callback:function ($$v) {_vm.$set(_vm.editedItem, "ID_TINGKATPENDIDIKAN", $$v)},expression:"editedItem.ID_TINGKATPENDIDIKAN"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Besaran Plafond (Rp)","rules":_vm.rulesPlafond},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.editedItem.PLAFOND),callback:function ($$v) {_vm.$set(_vm.editedItem, "PLAFOND", $$v)},expression:"editedItem.PLAFOND"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.valid),expression:"valid"}],attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.PLAFOND",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" Rp. "+_vm._s(_vm.rupiah(item.PLAFOND))+" ")])]}}],null,true)})],1)],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }