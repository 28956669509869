import cookies from "js-cookie"
import VueCookies from 'vue-cookies'


export default {

    getCookie() {
        return cookies.get("ess-online") || null
    },

    setCookie(token) {
        let newDate = new Date()
        newDate.toLocaleString('id', {
            timeZone: 'Asia/Jakarta'
        })

        let inFifteenMinutes = new Date(newDate.getTime() + 8 * 60 * 10 * 1000);


        let tempToken = token
        cookies.set("ess-online", tempToken, {
            expires: inFifteenMinutes
        }, )

    },


    removeCookie() {
        cookies.remove("ess-online")
        window.location.reload
    }
}