<template>
    <div>

    <c-toolbar/>
    <c-navbar/>

    <v-main>
        <v-responsive aspect-ratio="16/9">
        <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm ="12">

            <v-card class="mx-auto elevation-11 py-4 px-2"
            :loading="vloading">
            <template slot="progress">
            <v-progress-linear
            color="deep-purple"
            height="20"
            indeterminate
            ></v-progress-linear>
            </template>

                <span></span>
                <v-row justify="center">
                <v-img src="img/Indomaret.png" aspect-ratio="3" max-width="300" position="end"></v-img>

                </v-row>

                <h2 class="text-center my-4">Form Pengkinian Alamat</h2>


            <v-row justify="center">
            <v-dialog
            v-model="popupInfo"
            persistent
            max-width="600px"
            >
            <v-card>
            <v-card-title class="text-h5">
                Pengumuman!
        </v-card-title>
        <v-card-text>
            <span class="font-weight-bold"> PENTING </span>Selesaikan Pengkinian Alamat Anda sampai muncul <span class="font-weight-bold"> NOMOR TIKET </span> (setelah klik Tombol <span class="font-weight-bold"> SAVE DATA ALAMAT </span>) agar seluruh data yang diinput tersimpan. Hal itu harus diselesaikan pada Hari yang Sama! Jika pengkinian Anda dilanjutkan besok, maka Anda akan <span class="font-weight-bold">menginput ulang seluruh data dari awal</span>. Terima Kasih
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="popupInfo = false"
        >
            OK
        </v-btn>  
        </v-card-actions>
        </v-card>
    </v-dialog>
    </v-row>


            <v-stepper v-model="e1" class="fill-height">
            <v-stepper-header>
            
            <v-stepper-step
            :complete="e1 > 1"
            step="1"
            >
            Alamat KTP
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
            :complete="e1 > 2"
            step="2"
            >
            Alamat Domisili
            </v-stepper-step>

            
            </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content
            step="1"
            
            >

            <v-row>
                <v-col cols="12" sm="6">
            <v-card disabled
            :loading="vloading">
                    <v-card-title>
                        Alamat KTP Lama
                    </v-card-title>

                    
                    <v-form >
                        <div>
                            <v-row>
                                <v-col cols="12" sm="12">
                                <span> Alamat* <v-textarea v-model="alamatKtp.lama"
                                solo
                                readonly
                                auto-grow
                                >
                                </v-textarea>
                                </span>
                                </v-col>
                            

                                <v-col cols="3" sm="3">
                                <span> RT* <v-text-field v-model="RT.lama"
                                solo
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="3" sm="3">
                                <span> RW* <v-text-field v-model="RW.lama"
                                solo
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Provinsi* <v-text-field v-model="provinsiKtp.lama"
                                
                                solo
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kota* <v-text-field v-model="kotaKtp.lama"
                                solo
                                
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kecamatan* <v-text-field v-model="kecamatanKtp.lama"
                                solo
                                
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kelurahan* <v-text-field v-model="kelurahanKtp.lama"
                                solo
                                
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kode Pos* <v-text-field v-model="kodePosKtp.lama"
                                solo
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Status Kepemilikan* <v-text-field v-model="statusKepemilikanKtp.lama"
                                solo
                                
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <!-- <v-col cols="6" sm="6">
                                <span> Alamat Domisili Sama Dengan Alamat KTP  <v-checkbox
                                v-model="AlamatDomisiliCheck"
                                :label="`Sama Dengan KTP`"
                                ></v-checkbox>
                                </span>
                                </v-col> -->

                            </v-row>
                        </div>
                    </v-form>
                    

                </v-card>
                </v-col>

                <v-col cols="12" sm="6">
                <v-card>
                    <v-card-title>
                        Alamat KTP Baru
                    </v-card-title>

                    
                    
                    <v-form>
                        <div>
                            <v-row>
                                <v-col cols="12" sm="12">
                                <span> Alamat* <v-textarea v-model="alamatKtp.baru"
                                solo
                                auto-grow
                                @keyup="uppercase" 
                                class="alamatKtp.baru"
                                v-on:keypress="isAlamat($event)"
                                >
                                </v-textarea>
                                </span>
                                </v-col>
                            

                                <v-col cols="3" sm="3">
                                <span> RT* <v-text-field v-model="RT.baru"
                                solo
                                class="text-uppercase"
                                v-on:keypress="isNumber($event)"
                                counter
                                maxlength="3"
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="3" sm="3">
                                <span> RW* <v-text-field v-model="RW.baru"
                                solo
                                class="text-uppercase"
                                v-on:keypress="isNumber($event)"
                                counter
                                maxlength="3"
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Provinsi* <v-autocomplete v-model="provinsiKtp.baru"
                                :items="masterProvinsi"
                                item-text="NAME"
                                item-value="STATERECID"
                                solo
                                
                                @change="getKotaChangeData"
                                return-object
                                >
                                </v-autocomplete>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kota* <v-autocomplete v-model="kotaKtp.baru"
                                solo
                                :items="masterKota"
                                item-text="NAME"
                                item-value="COUNTYRECID"
                                @change="getKecamatanChangeData"
                                return-object
                                >
                                </v-autocomplete>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kecamatan* <v-autocomplete v-model="kecamatanKtp.baru"
                                solo
                                :items="masterKecamatan"
                                item-text="DESCRIPTION"
                                item-value="CITYRECID"
                                @change="getKelurahanChangeData"
                                return-object
                                >
                                </v-autocomplete>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kelurahan* <v-autocomplete v-model="kelurahanKtp.baru"
                                solo
                                :items="masterKelurahan"
                                item-text="DESCRIPTION"
                                item-value="DISTRICTRECID"
                                return-object
                                >
                                </v-autocomplete>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kode Pos* <v-text-field v-model="kodePosKtp.baru"
                                solo
                                :rules="kodePostRule"
                                class="text-uppercase"
                                v-on:keypress="isNumber($event)"
                                counter
                                minlength="5"
                                maxlength="5"
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Status Kepemilikan* <v-select v-model="statusKepemilikanKtp.baru"
                                solo
                                :items="masterStatusKepemilikan"
                                item-text="STATUSKEPEMILIKAN"
                                item-value="STATUSKEPEMILIKAN"
                                
                                >
                                </v-select>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Alamat Domisili Sama Dengan Alamat KTP  <v-checkbox
                                v-model="AlamatDomisiliCheck"
                                :label="`Sama Dengan KTP`"
                                ></v-checkbox>
                                </span>
                                </v-col>

                            </v-row>
                        </div>
                    </v-form>

                </v-card>

                </v-col>
            </v-row>
            
            <br>
            <v-row justify="end" >
            
            <v-btn
            color="primary"
            large
            
            v-show="AlamatDomisiliCheck == false"
            :disabled ="!validateInputKTP"
            @click="saveAlamatKTPData "
            >
            Selanjutnya Isi Alamat Domisili
            </v-btn>

            <v-btn
            color="primary"
            large
            
            v-show="AlamatDomisiliCheck == true"
            :disabled ="!validateInputKTP"
            @click="saveAlamatKTPDomisiliData"
            >
            Save Data Alamat
            </v-btn>
            </v-row>
            <br>

            </v-stepper-content>


            <v-stepper-content step="2">

            <v-row>
                <v-col cols="12" sm= "6">

                <v-card disabled>
                        <v-card-title>
                            Alamat Domisili Lama
                        </v-card-title>

                    <v-form>
                        <div>
                            <v-row>

                                <v-col cols="12" sm="12">
                                <span> Alamat* <v-textarea v-model="alamatDomisili.lama"
                                solo
                                auto-grow
                                readonly
                                >
                                </v-textarea>
                                </span>
                                </v-col>

                                <v-col cols="3" sm="3">
                                <span> RT* <v-text-field v-model="RTDomisili.lama"
                                solo
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="3" sm="3">
                                <span> RW* <v-text-field v-model="RWDomisili.lama"
                                solo
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Provinsi* <v-text-field v-model="provinsiDomisili.lama"
                                solo
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kota* <v-text-field v-model="kotaDomisili.lama"
                                solo
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kecamatan* <v-text-field v-model="kecamatanDomisili.lama"
                                solo
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kelurahan* <v-text-field v-model="kelurahanDomisili.lama"
                                solo
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kode Pos* <v-text-field v-model="kodePosDomisili.lama"
                                solo
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Status Kepemilikan* <v-text-field v-model="statusKepemilikanDomisili.lama"
                                solo
                                readonly
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <!-- <v-col cols="6" sm="6">
                                <span> Alamat Domisili Sama Dengan Alamat KTP  <v-checkbox
                                v-model="AlamatDomisiliCheck"
                                :label="`Sama Dengan KTP`"
                                ></v-checkbox>
                                </span>
                                </v-col> -->

                            </v-row>
                        </div>
                    </v-form>

                    </v-card>
            </v-col>

            <v-col cols="12" sm="6">
                <v-card>
                        <v-card-title>
                            Alamat Domisili Baru
                        </v-card-title>

                    <v-form>
                        <div>
                            <v-row>

                                <v-col cols="12" sm="12">
                                <span> Alamat* <v-textarea v-model="alamatDomisili.baru"
                                solo
                                auto-grow
                                @keyup="uppercase" 
                                class="alamatDomisili.baru"
                                v-on:keypress="isAlamat($event)"
                                >
                                </v-textarea>
                                </span>
                                </v-col>

                                <v-col cols="3" sm="3">
                                <span> RT* <v-text-field v-model="RTDomisili.baru"
                                solo
                                class="text-uppercase"
                                v-on:keypress="isNumber($event)"
                                counter
                                maxlength="3"
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="3" sm="3">
                                <span> RW* <v-text-field v-model="RWDomisili.baru"
                                solo
                                class="text-uppercase"
                                v-on:keypress="isNumber($event)"
                                counter
                                maxlength="3"
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Provinsi* <v-autocomplete v-model="provinsiDomisili.baru"
                                :items="masterProvinsiDomisili"
                                item-text="NAME"
                                item-value="STATERECID"
                                solo
                                @change="getKotaDomisliChangeData"
                                return-object
                                >
                                </v-autocomplete>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kota* <v-autocomplete v-model="kotaDomisili.baru"
                                solo
                                :items="masterKotaDomisili"
                                item-text="NAME"
                                item-value="COUNTYRECID"
                                @change="getKecamatanDomisiliChangeData"
                                return-object
                                >
                                </v-autocomplete>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kecamatan* <v-autocomplete v-model="kecamatanDomisili.baru"
                                solo
                                :items="masterKecamatanDomisili"
                                item-text="DESCRIPTION"
                                item-value="CITYRECID"
                                @change="getKelurahanDomisiliChangeData"
                                return-object
                                >
                                </v-autocomplete>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kelurahan* <v-autocomplete v-model="kelurahanDomisili.baru"
                                solo
                                :items="masterKelurahanDomisili"
                                item-text="DESCRIPTION"
                                item-value="DISTRICTRECID"
                                return-object
                                >
                                </v-autocomplete>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Kode Pos* <v-text-field v-model="kodePosDomisili.baru"
                                solo
                                :rules="kodePostRule"
                                class="text-uppercase"
                                v-on:keypress="isNumber($event)"
                                counter
                                minlength="5"
                                maxlength="5"
                                >
                                </v-text-field>
                                </span>
                                </v-col>

                                <v-col cols="6" sm="6">
                                <span> Status Kepemilikan* <v-select v-model="statusKepemilikanDomisili.baru"
                                solo
                                :items="masterStatusKepemilikan"
                                item-text="STATUSKEPEMILIKAN"
                                item-value="STATUSKEPEMILIKAN"
                                
                                >
                                </v-select>
                                </span>
                                </v-col>

                                <!-- <v-col cols="6" sm="6">
                                <span> Alamat Domisili Sama Dengan Alamat KTP  <v-checkbox
                                v-model="AlamatDomisiliCheck"
                                :label="`Sama Dengan KTP`"
                                ></v-checkbox>
                                </span>
                                </v-col> -->

                            </v-row>
                        </div>
                    </v-form>

                    </v-card>

            </v-col>

            </v-row>

            <br>
            <v-row justify="end">
            <v-btn
            color="primary"
            large
            right
            :disabled ="!validateInputDomisili"
            @click="saveAlamatDomisiliData"
            >
            Save Data Alamat
            </v-btn>

            <v-btn 
            large
            right
            color="secondary"
            @click="e1 = 1">
            Cancel
            </v-btn>
            </v-row>
            <br>

            </v-stepper-content>

            </v-stepper-items>
            </v-stepper>

            </v-card>
                </v-col>
                
            </v-row>

        </v-container>
        </v-responsive>
    </v-main>

    </div>
</template>


<script>
import axios from "axios";
import { required, maxLength } from "vuelidate/lib/validators"
import { validationMixin } from "vuelidate";
import CToolbar from "@/components/CToolbar.vue";

import { mapActions, mapGetters } from "vuex"
import moment from 'moment'
import CNavbar from "@/components/CNavbar.vue"

    export default{
        name: "PengkinianAlamat",
        components:{
            CNavbar,
            CToolbar
        },
        
        data() {
            return {

                kodePostRule:[
                    v => v && v.length >= 5 || 'Min 5 digit' ,
                    v => v && v.length < 6 || 'Maks 5 digit'
                ],


                AlamatDomisiliCheck: false,
                vloading:false,
                popupInfo: false,

                e1: 1,

                nikLogin:"",
                person:"",
                tempPerson:"",

                noTiketStatus:"",
                tiket:"",
                nikStatus:"",

                data:"",

                RT:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    draft:"",
                },

                RW:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    draft:"",
                },

                
                RTDomisili:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    draft:"",
                },

                RWDomisili:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    draft:"",
                },

                alamatKtp:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    draft:"",
                },
                alamatDomisili:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    idDraft:"",
                    draft:"",
                },
                provinsiKtp:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    idDraft:"",
                    draft:"",
                },
                provinsiDomisili:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    idDraft:"",
                    draft:"",
                },
                kotaKtp:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    idDraft:"",
                    draft:"",
                },
                kotaDomisili:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    idDraft:"",
                    draft:"",
                },
                kecamatanKtp:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    idDraft:"",
                    draft:"",
                },
                kecamatanDomisili:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    idDraft:"",
                    draft:"",
                },
                kelurahanKtp:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    idDraft:"",
                    draft:"",
                },
                kelurahanDomisili:{
                    baru:"",
                    nama:"",
                    lama:"",
                    id: "",
                    idDraft:"",
                    draft:"",
                },
                kodePosKtp: {
                    baru:"",
                    lama:"",
                    id: "",
                    idDraft:"",
                    draft:"",
                },
                kodePosDomisili: {
                    baru:"",
                    lama:"",
                    id: "",
                    draft:"",
                },
                statusKepemilikanKtp: {
                    baru:"",
                    lama:"",
                    id: "",
                    draft:"",
                },
                statusKepemilikanDomisili: {
                    baru:"",
                    lama:"",
                    id: "",
                    draft:"",
                },

                masterProvinsi:[],
                masterKota:[],
                masterKecamatan:[],
                masterKelurahan:[],
                masterProvinsiDomisili:[],
                masterKotaDomisili:[],
                masterKecamatanDomisili:[],
                masterKelurahanDomisili:[],
                masterStatusKepemilikan:[],
                
                input:"",
                resKTP:"",
                resDOM:"",
                resKTPDOM:"",
                

                
            }
        },

        mounted(){
            // this.loadData()
					this.data = this.user.data[0].NikKaryawan
					this.nikLogin = this.data
					this.vloading = true
					this.TOKEN = this.token
					this.cekStatus()
        },

  computed:{
    ...mapGetters({
			user: "user",
			hasAllAccess: "hasAllAccess",
			token:"token",
		}),
		
    validateInputKTP(){
			if (this.nikStatus =="ACCEPTABLE" ) {
        return(
            this.alamatKtp.baru != ""&&
            this.RT.baru != ""&&
            this.RW.baru != ""&&
            this.provinsiKtp.baru != ""&&
            this.kotaKtp.baru != ""&&
            this.kecamatanKtp.baru != ""&&
            this.kelurahanKtp.baru != ""&&
            this.kodePosKtp.baru != ""&&
            this.statusKepemilikanKtp.baru != ""&&
            this.kodePostRule
				)
				
			} else if(this.nikStatus == "DRAFT") {
        return(
            this.alamatKtp.baru != ""&&
            this.RT.baru != ""&&
            this.RW.baru != ""&&
            this.provinsiKtp.baru != null&&
            this.kotaKtp.baru != null&&
            this.kecamatanKtp.baru != null&&
            this.kelurahanKtp.baru != null&&
            this.kodePosKtp.baru != ""&&
            this.statusKepemilikanKtp.baru != null&&
            this.kodePostRule
        )
			} else return true
    },

    validateInputDomisili(){
			if(this.nikStatus == "ACCEPTABLE") {
        return(
            this.alamatDomisili.baru != ""&&
            this.RTDomisili.baru != ""&&
            this.RWDomisili.baru != ""&&
            this.provinsiDomisili.baru != ""&&
            this.kotaDomisili.baru != ""&&
            this.kecamatanDomisili.baru != ""&&
            this.kelurahanDomisili.baru != ""&&
            this.kodePosDomisili.baru != ""&&
            this.statusKepemilikanDomisili.baru != ""&&
            this.kodePostRule
				)
			} else if(this.nikStatus == "DRAFT") {
        return(
            this.alamatDomisili.baru != ""&&
            this.RTDomisili.baru != ""&&
            this.RWDomisili.baru != ""&&
            this.provinsiDomisili.baru != null&&
            this.kotaDomisili.baru != null&&
            this.kecamatanDomisili.baru != null&&
            this.kelurahanDomisili.baru != null&&
            this.kodePosDomisili.baru != ""&&
            this.statusKepemilikanDomisili.baru != null&&
            this.kodePostRule
				)
			} else return true
    }

        },


        watch: {
        steps (val) {
        if (this.e1 > val) {
            this.e1 = val
        }
        },
    },

        methods: {
        nextStep (n) {
        if (n === this.steps) {
            this.e1 = 1
        } else {
            this.e1 = n + 1
        }
        },

        uppercase() {
        this.alamatKtp.baru = this.alamatKtp.baru.toUpperCase();
        this.alamatDomisili.baru = this.alamatDomisili.baru.toUpperCase();
    },
        
        isAlamat(e) {
         let char = String.fromCharCode(e.keyCode); // Get the character
        if(/^[0-9 . A-Za-z]+$/.test(char)) return true; // Match with regex 
        else e.preventDefault(); // If not match, don't add to input text
        },

        isNumber(n) {
         let char = String.fromCharCode(n.keyCode); // Get the character
        if(/^[0-9]+$/.test(char)) return true; // Match with regex 
        else n.preventDefault(); // If not match, don't add to input text
        },

        ...mapActions({
            cekStatusApi:"cekStatusApi",
            getDetailApi:"getDetailApi",
            getAlamatApi:"getAlamatApi",
            getProvinsi :"getProvinsi",
            getKota:"getKota",
            getKotaChange:"getKotaChange",
            getKecamatan : "getKecamatan",
            getKecamatanChange:"getKecamatanChange",
            getKelurahan : "getKelurahan",
            getKelurahanChange: "getKelurahanChange",
            getProvinsiDomisili: "getProvinsiDomisili",
            getKotaDomisli: "getKotaDomisli",
            getKotaDomisliChange:"getKotaDomisliChange",
            getKecamatanDomisili:"getKecamatanDomisili",
            getKecamatanDomisiliChange:"getKecamatanDomisiliChange",
            getKelurahanDomisili:"getKelurahanDomisili",
            getKelurahanDomisiliChange:"getKelurahanDomisiliChange",
            getStatusKepemilikan:"getStatusKepemilikan",
            saveAlamatKTPDomisili:"saveAlamatKTPDomisili",
            saveAlamatKTP:"saveAlamatKTP",
            saveAlamatDomisili:"saveAlamatDomisili",
        }),

        loadData(){
					this.data = Object.assign({}, this.user.data[0])
        },

        cekStatus() {
						let data ={
							Data: {
								type: "Master-Transaksi",
								code: "1",
								nik: this.nikLogin,
							}
						}
           const code = data

            this.cekStatusApi(code)
            .then((response)=>{
                let data = response
                // console.log(response);
								this.nikStatus = data.Status
								// console.log(this.nikStatus)

                if (data.Status == "ACCEPTABLE") {

									this.getDataAlamat()
									this.getProvinsiData()
									this.getStatusKepemilikanData()
								
									this.vloading = false
                                    this.popupInfo = true

                } else if(data.Status == "DRAFT" && data.Result == "OK !") {
									let statusTiket = data.NoTicket
									this.noTiketStatus = statusTiket
									this.tiket = statusTiket

									this.getDataAlamat()
									this.getDetail()
									// this.getProvinsiData()
									this.getStatusKepemilikanData()

                                    this.vloading = false
                                    this.popupInfo = true

                }else if(data.Status == "DRAFT"){    
                	let statusTiket = data.NoTicket
                	this.noTiketStatus = statusTiket
                	this.tiket = statusTiket

									this.getDataAlamat()
									this.getDetail()
									// this.getProvinsiData()
									this.getStatusKepemilikanData()

                                    this.vloading = false
                                    this.popupInfo = true

                } else if(data.Status == "FAILED") {
                    this.vloading = false
                    this.popupInfo = false
                    this.$swal({
                    title:"PERHATIAN!",
                    icon: "warning",
                    html:`${data.Result}`
                    }).then(()=>{

                    this.$router.push({ name: "HomePage" })
                  })
                } else if(data.Status == "REVISI") {
									let statusTiket = data.NoTicket
									this.noTiketStatus = statusTiket
									this.tiket = statusTiket

                this.vloading = false
                this.popupInfo = true
                    this.$swal({
                    title:"PERHATIAN!",
                    icon: "warning",
                    html:`${data.Result}`
                    }).then(()=>{

                    this.getDataAlamat()
                    this.getDetail()
                    // this.getProvinsiData()
                    this.getStatusKepemilikanData()
                    })

                }else{
                    this.vloading = false
                    this.popupInfo = false
                    this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`${data.Message}`
                    }).then(()=>{
                        this.$router.push({ name: "HomePage" })
                    })
                }
            }).catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`${error}`
										})
										// console.log(error)
            })
        },

        getDetail(){
            let data ={
		        	Data: {
								type: "Alamat-Transaksi",
								code: "3",
								no_ticket: this.noTiketStatus
							}
            }
           this.getDetailApi(data)
            .then((response)=>{
								let data = response.Result[0]
								// console.log(response)

                this.vloading = false

                this.alamatKtp.draft = data.PRIMARYADDRESS
                this.RT.draft = data.PRIMARYNEIGHBOURHOODNUMBER
                this.RW.draft = data.PRIMARYWARDNUMBER
                this.provinsiKtp.draft = data.PRIMARYSTATENAME
                this.provinsiKtp.idDraft= data.PRIMARYSTATERECID
                this.kotaKtp.draft = data.PRIMARYCOUNTYNAME
                this.kotaKtp.idDraft= data.PRIMARYCOUNTYRECID
                this.kecamatanKtp.draft = data.PRIMARYCITYNAME
                this.kecamatanKtp.idDraft= data.PRIMARYCITYRECID
                this.kelurahanKtp.draft = data.PRIMARYDISTRICTNAME
                this.kelurahanKtp.idDraft= data.PRIMARYDISTRICTRECID
                this.kodePosKtp.draft = data.PRIMARYZIPCODE
                this.statusKepemilikanKtp.draft = data.PRIMARYOWNERSHIPSTATUSID

                this.alamatDomisili.draft = data.SECONDARYADDRESS
                this.RTDomisili.draft = data.SECONDARYNEIGHBOURHOODNUMBER
                this.RWDomisili.draft = data.SECONDARYWARDNUMBER
                this.provinsiDomisili.draft = data.SECONDARYSTATENAME
                this.provinsiDomisili.idDraft= data.SECONDARYSTATERECID
                this.kotaDomisili.draft = data.SECONDARYCOUNTYNAME
                this.kotaDomisili.idDraft= data.SECONDARYCOUNTYRECID
                this.kecamatanDomisili.draft = data.SECONDARYCITYNAME
                this.kecamatanDomisili.idDraft= data.SECONDARYCITYRECID
                this.kelurahanDomisili.draft = data.SECONDARYDISTRICTNAME
                this.kelurahanDomisili.idDraft= data.SECONDARYDISTRICTRECID
                this.kodePosDomisili.draft = data.SECONDARYZIPCODE
                this.statusKepemilikanDomisili.draft = data.SECONDARYOWNERSHIPSTATUSID

                this.person = data.PERSON
                

                this.alamatKtp.baru = this.alamatKtp.draft
                this.RT.baru = this.RT.draft
                this.RW.baru = this.RW.draft
                this.provinsiKtp.baru = this.provinsiKtp.idDraft
                this.kotaKtp.baru = this.kotaKtp.idDraft
                this.kecamatanKtp.baru = this.kecamatanKtp.idDraft
                this.kelurahanKtp.baru = this.kelurahanKtp.idDraft
                this.provinsiKtp.nama = this.provinsiKtp.draft
                this.kotaKtp.nama = this.kotaKtp.draft
                this.kecamatanKtp.nama = this.kecamatanKtp.draft
                this.kelurahanKtp.nama = this.kelurahanKtp.draft
                this.kodePosKtp.baru = this.kodePosKtp.draft
                this.statusKepemilikanKtp.baru = this.statusKepemilikanKtp.draft

                

                this.alamatDomisili.baru = this.alamatDomisili.draft
                this.RTDomisili.baru = this.RTDomisili.draft
                this.RWDomisili.baru = this.RWDomisili.draft
                this.provinsiDomisili.baru = this.provinsiDomisili.idDraft
                this.kotaDomisili.baru = this.kotaDomisili.idDraft
                this.kecamatanDomisili.baru = this.kecamatanDomisili.idDraft
                this.kelurahanDomisili.baru = this.kelurahanDomisili.idDraft
                this.provinsiDomisili.nama = this.provinsiDomisili.draft
                this.kotaDomisili.nama = this.kotaDomisili.draft
                this.kecamatanDomisili.nama = this.kecamatanDomisili.draft
                this.kelurahanDomisili.nama = this.kelurahanDomisili.draft
                this.kodePosDomisili.baru = this.kodePosDomisili.draft
                this.statusKepemilikanDomisili.baru = this.statusKepemilikanDomisili.draft

                this.getProvinsiData()
                this.getKotaData() 
                this.getKecamatanData()
                this.getKelurahanData()
                this.getProvinsiDomisiliData()
                this.getKotaDomisliData()
                this.getKecamatanDomisiliData()
                this.getKelurahanDomisiliData()
                this.getStatusKepemilikanData()

            }).catch((error)=>{
                this.$swal({
                        title:"Perhatian!",
                        icon:"warning",
                        html:`Data Draft Belum Lengkap`
                    })
            })

        },

        getDataAlamat(){
            let data = {
							Data: {
								type: "Alamat",
								code: "1",
								nik: this.nikLogin
							}
            }
            this.getAlamatApi(data)
            .then((response) => {
								let data = response.Result
								// console.log(data[0].PERSON + "  IWE");
                        this.tempPerson = data[0].PERSON

                    this.tempPerson = data[0].PERSON

                if (data[0].LOCATIONNAME == "KTP" && data[0].ISPRIMARY == 1) {
                    
                
                this.alamatKtp.lama = data[0].STREET
                this.RT.lama = data[0].NEIGHBOURHOODNUMBER
                this.RW.lama = data[0].WARDNUMBER
                this.provinsiKtp.lama = data[0].STATENAME
                this.provinsiKtp.id = data[0].STATERECID
                this.kotaKtp.lama = data[0].COUNTYNAME
                this.kotaKtp.id = data[0].COUNTYRECID
                this.kecamatanKtp.lama = data[0].CITYNAME
                this.kecamatanKtp.id = data[0].CITYRECID
                this.kelurahanKtp.lama = data[0].DISTRICTNAME
                this.kelurahanKtp.id = data[0].DISTRICTRECID
                this.kodePosKtp.lama = data[0].ZIPCODE
                this.statusKepemilikanKtp.lama = data[0].OWNERSHIPSTATUSID

                this.person = data[0].PERSON
                console.log(this.provinsiKtp.id )
                
                if(data.length > 1 && data[1].ISPRIMARY == 0){
                    this.alamatDomisili.lama = data[1].STREET
                this.RTDomisili.lama = data[1].NEIGHBOURHOODNUMBER
                this.RWDomisili.lama = data[1].WARDNUMBER
                this.provinsiDomisili.lama = data[1].STATENAME
                this.provinsiDomisili.id = data[1].STATERECID
                this.kotaDomisili.lama = data[1].COUNTYNAME
                this.kotaDomisili.id = data[1].COUNTYRECID
                this.kecamatanDomisili.lama = data[1].CITYNAME
                this.kecamatanDomisili.id = data[1].CITYRECID
                this.kelurahanDomisili.lama = data[1].DISTRICTNAME
                this.kelurahanDomisili.id = data[1].DISTRICTRECID
                this.kodePosDomisili.lama = data[1].ZIPCODE
                this.statusKepemilikanDomisili.lama = data[1].OWNERSHIPSTATUSID

                console.log(this.alamatDomisili.lama + "DomKTP")
                }
                
                
                
                } else if(data[0].LOCATIONNAME == "KTP" && data[0].ISPRIMARY == 0){
                this.alamatKtp.lama = ""
                this.RT.lama = ""
                this.RW.lama = ""
                this.provinsiKtp.lama = ""
                this.provinsiKtp.id = ""
                this.kotaKtp.lama = ""
                this.kotaKtp.id = ""
                this.kecamatanKtp.lama = ""
                this.kecamatanKtp.id = ""
                this.kelurahanKtp.lama = ""
                this.kelurahanKtp.id = ""
                this.kodePosKtp.lama = ""
                this.statusKepemilikanKtp.lama = ""

                this.person = data[0].PERSON
                console.log(this.alamatKtp.lama + "KTP")
                
                if(data.length > 1 && data[1].ISPRIMARY == 0){
                    this.alamatDomisili.lama = data[1].STREET
                this.RTDomisili.lama = data[1].NEIGHBOURHOODNUMBER
                this.RWDomisili.lama = data[1].WARDNUMBER
                this.provinsiDomisili.lama = data[1].STATENAME
                this.provinsiDomisili.id = data[1].STATERECID
                this.kotaDomisili.lama = data[1].COUNTYNAME
                this.kotaDomisili.id = data[1].COUNTYRECID
                this.kecamatanDomisili.lama = data[1].CITYNAME
                this.kecamatanDomisili.id = data[1].CITYRECID
                this.kelurahanDomisili.lama = data[1].DISTRICTNAME
                this.kelurahanDomisili.id = data[1].DISTRICTRECID
                this.kodePosDomisili.lama = data[1].ZIPCODE
                this.statusKepemilikanDomisili.lama = data[1].OWNERSHIPSTATUSID

                console.log(this.alamatDomisili.lama + "DomKTP")
                }
                }
                else if (data[0].LOCATIONNAME == "Domisili" && data[0].ISPRIMARY == 0) {
                    
                this.alamatDomisili.lama = data[0].STREET
                this.RTDomisili.lama = data[0].NEIGHBOURHOODNUMBER
                this.RWDomisili.lama = data[0].WARDNUMBER
                this.provinsiDomisili.lama = data[0].STATENAME
                this.provinsiDomisili.id = data[0].STATERECID
                this.kotaDomisili.lama = data[0].COUNTYNAME
                this.kotaDomisili.id = data[0].COUNTYRECID
                this.kecamatanDomisili.lama = data[0].CITYNAME
                this.kecamatanDomisili.id = data[0].CITYRECID
                this.kelurahanDomisili.lama = data[0].DISTRICTNAME
                this.kelurahanDomisili.id = data[0].DISTRICTRECID
                this.kodePosDomisili.lama = data[0].ZIPCODE
                this.statusKepemilikanDomisili.lama = data[0].OWNERSHIPSTATUSID

                if(data.length > 1 && data[1].ISPRIMARY == 1){
                this.alamatKtp.lama = data[1].STREET
                this.RT.lama = data[1].NEIGHBOURHOODNUMBER
                this.RW.lama = data[1].WARDNUMBER
                this.provinsiKtp.lama = data[1].STATENAME
                this.provinsiKtp.id = data[1].STATERECID
                this.kotaKtp.lama = data[1].COUNTYNAME
                this.kotaKtp.id = data[1].COUNTYRECID
                this.kecamatanKtp.lama = data[1].CITYNAME
                this.kecamatanKtp.id = data[1].CITYRECID
                this.kelurahanKtp.lama = data[1].DISTRICTNAME
                this.kelurahanKtp.id = data[1].DISTRICTRECID
                this.kodePosKtp.lama = data[1].ZIPCODE
                this.statusKepemilikanKtp.lama = data[1].OWNERSHIPSTATUSID
                }
                
                    this.person = data[0].PERSON
                    // console.log(data[0].PERSON);
                    
                    // console.log(data[1].PERSON);

                } else if(data[0].LOCATIONNAME == "Domisili" && data[0].ISPRIMARY == 1){
                this.alamatDomisili.lama = ""
                this.RTDomisili.lama = ""
                this.RWDomisili.lama = ""
                this.provinsiDomisili.lama = ""
                this.provinsiDomisili.id = ""
                this.kotaDomisili.lama = ""
                this.kotaDomisili.id = ""
                this.kecamatanDomisili.lama = ""
                this.kecamatanDomisili.id = ""
                this.kelurahanDomisili.lama = ""
                this.kelurahanDomisili.id = ""
                this.kodePosDomisili.lama = ""
                this.statusKepemilikanDomisili.lama = ""

                if(data.length > 1 && data[1].ISPRIMARY == 1){
                this.alamatKtp.lama = data[1].STREET
                this.RT.lama = data[1].NEIGHBOURHOODNUMBER
                this.RW.lama = data[1].WARDNUMBER
                this.provinsiKtp.lama = data[1].STATENAME
                this.provinsiKtp.id = data[1].STATERECID
                this.kotaKtp.lama = data[1].COUNTYNAME
                this.kotaKtp.id = data[1].COUNTYRECID
                this.kecamatanKtp.lama = data[1].CITYNAME
                this.kecamatanKtp.id = data[1].CITYRECID
                this.kelurahanKtp.lama = data[1].DISTRICTNAME
                this.kelurahanKtp.id = data[1].DISTRICTRECID
                this.kodePosKtp.lama = data[1].ZIPCODE
                this.statusKepemilikanKtp.lama = data[1].OWNERSHIPSTATUSID
                }
                
                    this.person = data[0].PERSON
                }
                else if(data[0].LOCATIONNAME== "KTP dan Domisili" && data[0].ISPRIMARY == 1 ){
                
                this.alamatKtp.lama = data[0].STREET
                this.RT.lama = data[0].NEIGHBOURHOODNUMBER
                this.RW.lama = data[0].WARDNUMBER
                this.provinsiKtp.lama = data[0].STATENAME
                this.provinsiKtp.id = data[0].STATERECID
                this.kotaKtp.lama = data[0].COUNTYNAME
                this.kotaKtp.id = data[0].COUNTYRECID
                this.kecamatanKtp.lama = data[0].CITYNAME
                this.kecamatanKtp.id = data[0].CITYRECID
                this.kelurahanKtp.lama = data[0].DISTRICTNAME
                this.kelurahanKtp.id = data[0].DISTRICTRECID
                this.kodePosKtp.lama = data[0].ZIPCODE
                this.statusKepemilikanKtp.lama = data[0].OWNERSHIPSTATUSID
                this.AlamatDomisiliCheck = true;
                
                if(data.length > 1 && data[1].ISPRIMARY == 0){
                this.alamatDomisili.lama = data[1].STREET
                this.RTDomisili.lama = data[1].NEIGHBOURHOODNUMBER
                this.RWDomisili.lama = data[1].WARDNUMBER
                this.provinsiDomisili.lama = data[1].STATENAME
                this.provinsiDomisili.id = data[1].STATERECID
                this.kotaDomisili.lama = data[1].COUNTYNAME
                this.kotaDomisili.id = data[1].COUNTYRECID
                this.kecamatanDomisili.lama = data[1].CITYNAME
                this.kecamatanDomisili.id = data[1].CITYRECID
                this.kelurahanDomisili.lama = data[1].DISTRICTNAME
                this.kelurahanDomisili.id = data[1].DISTRICTRECID
                this.kodePosDomisili.lama = data[1].ZIPCODE
                this.statusKepemilikanDomisili.lama = data[1].OWNERSHIPSTATUSID
                }

                    this.person = data[0].PERSON
                    // console.log(data[0].PERSON);
                    
                    
                    // console.log(data[1].PERSON);

                }else if(data[0].LOCATIONNAME== "KTP dan Domisili" && data[0].ISPRIMARY == 0 ){
                    this.alamatDomisili.lama = data[0].STREET
                this.RTDomisili.lama = data[0].NEIGHBOURHOODNUMBER
                this.RWDomisili.lama = data[0].WARDNUMBER
                this.provinsiDomisili.lama = data[0].STATENAME
                this.provinsiDomisili.id = data[0].STATERECID
                this.kotaDomisili.lama = data[0].COUNTYNAME
                this.kotaDomisili.id = data[0].COUNTYRECID
                this.kecamatanDomisili.lama = data[0].CITYNAME
                this.kecamatanDomisili.id = data[0].CITYRECID
                this.kelurahanDomisili.lama = data[0].DISTRICTNAME
                this.kelurahanDomisili.id = data[0].DISTRICTRECID
                this.kodePosDomisili.lama = data[0].ZIPCODE
                this.statusKepemilikanDomisili.lama = data[0].OWNERSHIPSTATUSID
                this.AlamatDomisiliCheck = false

                if(data.length > 1 && data[1].ISPRIMARY == 1){
                this.alamatKtp.lama = data[1].STREET
                this.RT.lama = data[1].NEIGHBOURHOODNUMBER
                this.RW.lama = data[1].WARDNUMBER
                this.provinsiKtp.lama = data[1].STATENAME
                this.provinsiKtp.id = data[1].STATERECID
                this.kotaKtp.lama = data[1].COUNTYNAME
                this.kotaKtp.id = data[1].COUNTYRECID
                this.kecamatanKtp.lama = data[1].CITYNAME
                this.kecamatanKtp.id = data[1].CITYRECID
                this.kelurahanKtp.lama = data[1].DISTRICTNAME
                this.kelurahanKtp.id = data[1].DISTRICTRECID
                this.kodePosKtp.lama = data[1].ZIPCODE
                this.statusKepemilikanKtp.lama = data[1].OWNERSHIPSTATUSID
                }
                
                    this.person = data[0].PERSON
                }
                else{
                this.alamatKtp.lama = data[0].STREET
                this.RT.lama = data[0].NEIGHBOURHOODNUMBER
                this.RW.lama = data[0].WARDNUMBER
                this.provinsiKtp.lama = data[0].STATENAME
                this.provinsiKtp.id = data[0].STATERECID
                this.kotaKtp.lama = data[0].COUNTYNAME
                this.kotaKtp.id = data[0].COUNTYRECID
                this.kecamatanKtp.lama = data[0].CITYNAME
                this.kecamatanKtp.id = data[0].CITYRECID
                this.kelurahanKtp.lama = data[0].DISTRICTNAME
                this.kelurahanKtp.id = data[0].DISTRICTRECID
                this.kodePosKtp.lama = data[0].ZIPCODE
                this.statusKepemilikanKtp.lama = data[0].OWNERSHIPSTATUSID

                this.alamatDomisili.lama = data[0].STREET
                this.RTDomisili.lama = data[0].NEIGHBOURHOODNUMBER
                this.RWDomisili.lama = data[0].WARDNUMBER
                this.provinsiDomisili.lama = data[0].STATENAME
                this.provinsiDomisili.id = data[0].STATERECID
                this.kotaDomisili.lama = data[0].COUNTYNAME
                this.kotaDomisili.id = data[0].COUNTYRECID
                this.kecamatanDomisili.lama = data[0].CITYNAME
                this.kecamatanDomisili.id = data[0].CITYRECID
                this.kelurahanDomisili.lama = data[0].DISTRICTNAME
                this.kelurahanDomisili.id = data[0].DISTRICTRECID
                this.kodePosDomisili.lama = data[0].ZIPCODE
                this.statusKepemilikanDomisili.lama = data[0].OWNERSHIPSTATUSID

                    this.person = data[0].PERSON
                }
                

                if(this.nikStatus == "ACCEPTABLE"){
                console.log(data[0].LOCATIONNAME + " " + "AWAL")
                console.log(data[0].ISPRIMARY + " " + "AWAL")

                    if(data[0].LOCATIONNAME == "KTP" && data[0].ISPRIMARY == 1 ){
									this.alamatKtp.baru = this.alamatKtp.lama
									this.RT.baru = this.RT.lama
									this.RW.baru = this.RW.lama
									this.provinsiKtp.baru = this.provinsiKtp.id
									this.kotaKtp.baru = this.kotaKtp.id
									this.kecamatanKtp.baru = this.kecamatanKtp.id
									this.kelurahanKtp.baru = this.kelurahanKtp.id
									this.provinsiKtp.nama = this.provinsiKtp.lama
									this.kotaKtp.nama = this.kotaKtp.lama
									this.kecamatanKtp.nama = this.kecamatanKtp.lama
									this.kelurahanKtp.nama = this.kelurahanKtp.lama
									this.kodePosKtp.baru = this.kodePosKtp.lama
									this.statusKepemilikanKtp.baru = this.statusKepemilikanKtp.lama

                                    this.getKotaData()
                                    this.getKecamatanData()
                                    this.getKelurahanData()

                                    console.log(this.alamatKtp.baru + "Acept 1")

                        if(data.length > 1 && data[1].ISPRIMARY == 1 && (data[1].LOCATIONNAME == "Domisili"|| data[1].LOCATIONNAME == "KTP dan Domisili" )){
                                    this.alamatDomisili.baru = ""
									this.RTDomisili.baru = ""
									this.RWDomisili.baru = ""
									this.provinsiDomisili.baru = ""
									this.kotaDomisili.baru = ""
									this.kecamatanDomisili.baru = ""
									this.kelurahanDomisili.baru = ""
									this.provinsiDomisili.nama = ""
									this.kotaDomisili.nama = ""
									this.kecamatanDomisili.nama = ""
									this.kelurahanDomisili.nama = ""
									this.kodePosDomisili.baru = ""
									this.statusKepemilikanDomisili.baru = ""
                                    console.log(this.alamatDomisili.baru + " " + "DOMISILI 1")

                        }else if(data.length > 1 && data[1].ISPRIMARY == 0 && (data[1].LOCATIONNAME == "Domisili" || data[1].LOCATIONNAME == "KTP dan Domisili")){
                        
									this.alamatDomisili.baru = this.alamatDomisili.lama
									this.RTDomisili.baru = this.RTDomisili.lama
									this.RWDomisili.baru = this.RWDomisili.lama
									this.provinsiDomisili.baru = this.provinsiDomisili.id
									this.kotaDomisili.baru = this.kotaDomisili.id
									this.kecamatanDomisili.baru = this.kecamatanDomisili.id
									this.kelurahanDomisili.baru = this.kelurahanDomisili.id
									this.provinsiDomisili.nama = this.provinsiDomisili.lama
									this.kotaDomisili.nama = this.kotaDomisili.lama
									this.kecamatanDomisili.nama = this.kecamatanDomisili.lama
									this.kelurahanDomisili.nama = this.kelurahanDomisili.lama
									this.kodePosDomisili.baru = this.kodePosDomisili.lama
									this.statusKepemilikanDomisili.baru = this.statusKepemilikanDomisili.lama

                                    this.getKotaDomisliData()
                                    this.getKecamatanDomisiliData()
                                    this.getKelurahanDomisiliData()
                                    console.log(this.alamatDomisili.baru + "" + "DOMISILI 0")
                                    
                        }


                    }else if(data[0].LOCATIONNAME == "KTP" && data[0].ISPRIMARY == 0 ){
                                    this.alamatKtp.baru = ""
									this.RT.baru =""
									this.RW.baru = ""
									this.provinsiKtp.baru = ""
									this.kotaKtp.baru = ""
									this.kecamatanKtp.baru = ""
									this.kelurahanKtp.baru = ""
									this.provinsiKtp.nama = ""
									this.kotaKtp.nama = ""
									this.kecamatanKtp.nama = ""
									this.kelurahanKtp.nama = ""
									this.kodePosKtp.baru = ""
									this.statusKepemilikanKtp.baru = ""
                            
                            if(data.length > 1 && data[1].ISPRIMARY == 1 && (data[1].LOCATIONNAME == "Domisili" || data[1].LOCATIONNAME == "KTP dan Domisili")){
                                    this.alamatDomisili.baru = ""
									this.RTDomisili.baru = ""
									this.RWDomisili.baru = ""
									this.provinsiDomisili.baru = ""
									this.kotaDomisili.baru = ""
									this.kecamatanDomisili.baru = ""
									this.kelurahanDomisili.baru = ""
									this.provinsiDomisili.nama = ""
									this.kotaDomisili.nama = ""
									this.kecamatanDomisili.nama = ""
									this.kelurahanDomisili.nama = ""
									this.kodePosDomisili.baru = ""
									this.statusKepemilikanDomisili.baru = ""
                                    console.log(this.alamatDomisili.baru + " " + "DOMISILI 1")

                        }else if(data.length > 1 && data[1].ISPRIMARY == 0 && (data[1].LOCATIONNAME == "Domisili" || data[1].LOCATIONNAME == "KTP dan Domisili")){
                        
									this.alamatDomisili.baru = this.alamatDomisili.lama
									this.RTDomisili.baru = this.RTDomisili.lama
									this.RWDomisili.baru = this.RWDomisili.lama
									this.provinsiDomisili.baru = this.provinsiDomisili.id
									this.kotaDomisili.baru = this.kotaDomisili.id
									this.kecamatanDomisili.baru = this.kecamatanDomisili.id
									this.kelurahanDomisili.baru = this.kelurahanDomisili.id
									this.provinsiDomisili.nama = this.provinsiDomisili.lama
									this.kotaDomisili.nama = this.kotaDomisili.lama
									this.kecamatanDomisili.nama = this.kecamatanDomisili.lama
									this.kelurahanDomisili.nama = this.kelurahanDomisili.lama
									this.kodePosDomisili.baru = this.kodePosDomisili.lama
									this.statusKepemilikanDomisili.baru = this.statusKepemilikanDomisili.lama

                                    this.getKotaDomisliData()
                                    this.getKecamatanDomisiliData()
                                    this.getKelurahanDomisiliData()
                                    console.log(this.alamatDomisili.baru + "" + "DOMISILI 0")
                                    
                        }

                                    console.log(this.alamatKtp.baru + "Acept 0")

                    }else if(data[0].LOCATIONNAME == "Domisili" && data[0].ISPRIMARY == 0 ){
                        
									this.alamatDomisili.baru = this.alamatDomisili.lama
									this.RTDomisili.baru = this.RTDomisili.lama
									this.RWDomisili.baru = this.RWDomisili.lama
									this.provinsiDomisili.baru = this.provinsiDomisili.id
									this.kotaDomisili.baru = this.kotaDomisili.id
									this.kecamatanDomisili.baru = this.kecamatanDomisili.id
									this.kelurahanDomisili.baru = this.kelurahanDomisili.id
									this.provinsiDomisili.nama = this.provinsiDomisili.lama
									this.kotaDomisili.nama = this.kotaDomisili.lama
									this.kecamatanDomisili.nama = this.kecamatanDomisili.lama
									this.kelurahanDomisili.nama = this.kelurahanDomisili.lama
									this.kodePosDomisili.baru = this.kodePosDomisili.lama
									this.statusKepemilikanDomisili.baru = this.statusKepemilikanDomisili.lama

                                    this.getKotaDomisliData()
                                    this.getKecamatanDomisiliData()
                                    this.getKelurahanDomisiliData()
                                    console.log(this.alamatDomisili.baru + "" + "DOMISILI 0")

                        if(data.length > 1 && data[1].ISPRIMARY == 0  && (data[1].LOCATIONNAME == "KTP" || data[1].LOCATIONNAME == "KTP dan Domisili") ){
                                    this.alamatKtp.baru = ""
									this.RT.baru =""
									this.RW.baru = ""
									this.provinsiKtp.baru = ""
									this.kotaKtp.baru = ""
									this.kecamatanKtp.baru = ""
									this.kelurahanKtp.baru = ""
									this.provinsiKtp.nama = ""
									this.kotaKtp.nama = ""
									this.kecamatanKtp.nama = ""
									this.kelurahanKtp.nama = ""
									this.kodePosKtp.baru = ""
									this.statusKepemilikanKtp.baru = ""
                                    console.log(this.alamatDomisili.baru + " " + "KTP 1")

                        }else if(data.length > 1 && data[1].ISPRIMARY == 1  && (data[1].LOCATIONNAME == "KTP" || data[1].LOCATIONNAME == "KTP dan Domisili")){
                        
									this.alamatKtp.baru = this.alamatKtp.lama
									this.RT.baru = this.RT.lama
									this.RW.baru = this.RW.lama
									this.provinsiKtp.baru = this.provinsiKtp.id
									this.kotaKtp.baru = this.kotaKtp.id
									this.kecamatanKtp.baru = this.kecamatanKtp.id
									this.kelurahanKtp.baru = this.kelurahanKtp.id
									this.provinsiKtp.nama = this.provinsiKtp.lama
									this.kotaKtp.nama = this.kotaKtp.lama
									this.kecamatanKtp.nama = this.kecamatanKtp.lama
									this.kelurahanKtp.nama = this.kelurahanKtp.lama
									this.kodePosKtp.baru = this.kodePosKtp.lama
									this.statusKepemilikanKtp.baru = this.statusKepemilikanKtp.lama

                                    this.getKotaData()
                                    this.getKecamatanData()
                                    this.getKelurahanData()
                                    console.log(this.alamatDomisili.baru + "" + "KTP 0")
                                    
                        }

                                    
                    }else if(data[0].LOCATIONNAME == "Domisili" && data[0].ISPRIMARY == 1 ){
                                    this.alamatDomisili.baru = ""
									this.RTDomisili.baru = ""
									this.RWDomisili.baru = ""
									this.provinsiDomisili.baru = ""
									this.kotaDomisili.baru = ""
									this.kecamatanDomisili.baru = ""
									this.kelurahanDomisili.baru = ""
									this.provinsiDomisili.nama = ""
									this.kotaDomisili.nama = ""
									this.kecamatanDomisili.nama = ""
									this.kelurahanDomisili.nama = ""
									this.kodePosDomisili.baru = ""
									this.statusKepemilikanDomisili.baru = ""
                                    console.log(this.alamatDomisili.baru + " " + "DOMISILI 1")

                        if(data.length > 1 && data[1].ISPRIMARY == 1  && (data[1].LOCATIONNAME == "Domisili" || data[1].LOCATIONNAME == "KTP dan Domisili") ){
                                    this.alamatDomisili.baru = ""
									this.RTDomisili.baru = ""
									this.RWDomisili.baru = ""
									this.provinsiDomisili.baru = ""
									this.kotaDomisili.baru = ""
									this.kecamatanDomisili.baru = ""
									this.kelurahanDomisili.baru = ""
									this.provinsiDomisili.nama = ""
									this.kotaDomisili.nama = ""
									this.kecamatanDomisili.nama = ""
									this.kelurahanDomisili.nama = ""
									this.kodePosDomisili.baru = ""
									this.statusKepemilikanDomisili.baru = ""
                                    console.log(this.alamatDomisili.baru + " " + "DOMISILI 1")

                        }else if(data.length > 1 && data[1].ISPRIMARY == 0  && (data[1].LOCATIONNAME == "Domisili" || data[1].LOCATIONNAME == "KTP dan Domisili")){
                        
									this.alamatDomisili.baru = this.alamatDomisili.lama
									this.RTDomisili.baru = this.RTDomisili.lama
									this.RWDomisili.baru = this.RWDomisili.lama
									this.provinsiDomisili.baru = this.provinsiDomisili.id
									this.kotaDomisili.baru = this.kotaDomisili.id
									this.kecamatanDomisili.baru = this.kecamatanDomisili.id
									this.kelurahanDomisili.baru = this.kelurahanDomisili.id
									this.provinsiDomisili.nama = this.provinsiDomisili.lama
									this.kotaDomisili.nama = this.kotaDomisili.lama
									this.kecamatanDomisili.nama = this.kecamatanDomisili.lama
									this.kelurahanDomisili.nama = this.kelurahanDomisili.lama
									this.kodePosDomisili.baru = this.kodePosDomisili.lama
									this.statusKepemilikanDomisili.baru = this.statusKepemilikanDomisili.lama

                                    this.getKotaDomisliData()
                                    this.getKecamatanDomisiliData()
                                    this.getKelurahanDomisiliData()
                                    console.log(this.alamatDomisili.baru + "" + "DOMISILI 0")
                                    
                        }

                    console.log(data[0].LOCATIONNAME + " " + "DOM")
                    console.log(data[0].ISPRIMARY + " " + "DOM")
                    }

                    if(data[0].LOCATIONNAME == "KTP dan Domisili" && data[0].ISPRIMARY == 1 ){
                                    this.alamatKtp.baru = this.alamatKtp.lama
									this.RT.baru = this.RT.lama
									this.RW.baru = this.RW.lama
									this.provinsiKtp.baru = this.provinsiKtp.id
									this.kotaKtp.baru = this.kotaKtp.id
									this.kecamatanKtp.baru = this.kecamatanKtp.id
									this.kelurahanKtp.baru = this.kelurahanKtp.id
									this.provinsiKtp.nama = this.provinsiKtp.lama
									this.kotaKtp.nama = this.kotaKtp.lama
									this.kecamatanKtp.nama = this.kecamatanKtp.lama
									this.kelurahanKtp.nama = this.kelurahanKtp.lama
									this.kodePosKtp.baru = this.kodePosKtp.lama
									this.statusKepemilikanKtp.baru = this.statusKepemilikanKtp.lama

                                    console.log(this.alamatKtp.baru + " " + "KTPDOMISILI")

                                    this.getKotaData()
                                    this.getKecamatanData()
                                    this.getKelurahanData()

                                    this.alamatDomisili.baru = ""
									this.RTDomisili.baru = ""
									this.RWDomisili.baru = ""
									this.provinsiDomisili.baru = ""
									this.kotaDomisili.baru = ""
									this.kecamatanDomisili.baru = ""
									this.kelurahanDomisili.baru = ""
									this.provinsiDomisili.nama = ""
									this.kotaDomisili.nama = ""
									this.kecamatanDomisili.nama = ""
									this.kelurahanDomisili.nama = ""
									this.kodePosDomisili.baru = ""
									this.statusKepemilikanDomisili.baru = ""

                    }else if (data[0].LOCATIONNAME == "KTP dan Domisili" && data[0].ISPRIMARY == 0 ){
                        
                                    this.alamatKtp.baru = ""
									this.RT.baru =""
									this.RW.baru = ""
									this.provinsiKtp.baru = ""
									this.kotaKtp.baru = ""
									this.kecamatanKtp.baru = ""
									this.kelurahanKtp.baru = ""
									this.provinsiKtp.nama = ""
									this.kotaKtp.nama = ""
									this.kecamatanKtp.nama = ""
									this.kelurahanKtp.nama = ""
									this.kodePosKtp.baru = ""
									this.statusKepemilikanKtp.baru = ""

                                    this.alamatDomisili.baru = this.alamatDomisili.lama
									this.RTDomisili.baru = this.RTDomisili.lama
									this.RWDomisili.baru = this.RWDomisili.lama
									this.provinsiDomisili.baru = this.provinsiDomisili.id
									this.kotaDomisili.baru = this.kotaDomisili.id
									this.kecamatanDomisili.baru = this.kecamatanDomisili.id
									this.kelurahanDomisili.baru = this.kelurahanDomisili.id
									this.provinsiDomisili.nama = this.provinsiDomisili.lama
									this.kotaDomisili.nama = this.kotaDomisili.lama
									this.kecamatanDomisili.nama = this.kecamatanDomisili.lama
									this.kelurahanDomisili.nama = this.kelurahanDomisili.lama
									this.kodePosDomisili.baru = this.kodePosDomisili.lama
									this.statusKepemilikanDomisili.baru = this.statusKepemilikanDomisili.lama

                                    console.log(this.alamatDomisili.baru + "" + "KTPDOMISILI")

                                    this.getKotaDomisliData()
                                    this.getKecamatanDomisiliData()
                                    this.getKelurahanDomisiliData()
                    }
                    console.log(data[0].LOCATIONNAME + " " + "KTPDOM")
                console.log(data[0].ISPRIMARY + " " + "KTPDOM")


                }else if(this.nikStatus == "DRAFT"){
                this.alamatKtp.baru = this.alamatKtp.draft
                this.RT.baru = this.RT.draft
                this.RW.baru = this.RW.draft
                this.provinsiKtp.baru = this.provinsiKtp.idDraft
                this.kotaKtp.baru = this.kotaKtp.idDraft
                this.kecamatanKtp.baru = this.kecamatanKtp.idDraft
                this.kelurahanKtp.baru = this.kelurahanKtp.idDraft
                this.provinsiKtp.nama = this.provinsiKtp.draft
                this.kotaKtp.nama = this.kotaKtp.draft
                this.kecamatanKtp.nama = this.kecamatanKtp.draft
                this.kelurahanKtp.nama = this.kelurahanKtp.draft
                this.kodePosKtp.baru = this.kodePosKtp.draft
                this.statusKepemilikanKtp.baru = this.statusKepemilikanKtp.draft

                

                this.alamatDomisili.baru = this.alamatDomisili.draft
                this.RTDomisili.baru = this.RTDomisili.draft
                this.RWDomisili.baru = this.RWDomisili.draft
                this.provinsiDomisili.baru = this.provinsiDomisili.idDraft
                this.kotaDomisili.baru = this.kotaDomisili.idDraft
                this.kecamatanDomisili.baru = this.kecamatanDomisili.idDraft
                this.kelurahanDomisili.baru = this.kelurahanDomisili.idDraft
                this.provinsiDomisili.nama = this.provinsiDomisili.draft
                this.kotaDomisili.nama = this.kotaDomisili.draft
                this.kecamatanDomisili.nama = this.kecamatanDomisili.draft
                this.kelurahanDomisili.nama = this.kelurahanDomisili.draft
                this.kodePosDomisili.baru = this.kodePosDomisili.draft
                this.statusKepemilikanDomisili.baru = this.statusKepemilikanDomisili.draft

                // console.log(this.kelurahanDomisili.baru);
                }else if(this.nikStatus == "REVISI"){
                this.alamatKtp.baru = this.alamatKtp.draft
                this.RT.baru = this.RT.draft
                this.RW.baru = this.RW.draft
                this.provinsiKtp.baru = this.provinsiKtp.idDraft
                this.kotaKtp.baru = this.kotaKtp.idDraft
                this.kecamatanKtp.baru = this.kecamatanKtp.idDraft
                this.kelurahanKtp.baru = this.kelurahanKtp.idDraft
                this.provinsiKtp.nama = this.provinsiKtp.draft
                this.kotaKtp.nama = this.kotaKtp.draft
                this.kecamatanKtp.nama = this.kecamatanKtp.draft
                this.kelurahanKtp.nama = this.kelurahanKtp.draft
                this.kodePosKtp.baru = this.kodePosKtp.draft
                this.statusKepemilikanKtp.baru = this.statusKepemilikanKtp.draft

                

                this.alamatDomisili.baru = this.alamatDomisili.draft
                this.RTDomisili.baru = this.RTDomisili.draft
                this.RWDomisili.baru = this.RWDomisili.draft
                this.provinsiDomisili.baru = this.provinsiDomisili.idDraft
                this.kotaDomisili.baru = this.kotaDomisili.idDraft
                this.kecamatanDomisili.baru = this.kecamatanDomisili.idDraft
                this.kelurahanDomisili.baru = this.kelurahanDomisili.idDraft
                this.provinsiDomisili.nama = this.provinsiDomisili.draft
                this.kotaDomisili.nama = this.kotaDomisili.draft
                this.kecamatanDomisili.nama = this.kecamatanDomisili.draft
                this.kelurahanDomisili.nama = this.kelurahanDomisili.draft
                this.kodePosDomisili.baru = this.kodePosDomisili.draft
                this.statusKepemilikanDomisili.baru = this.statusKepemilikanDomisili.draft
                }else{
                    this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Penarikan Data Gagal`
                    })
                }

                // // console.log(this.provinsiKtp.baru);
                this.getProvinsiData()
                this.getKotaData() 
                this.getKecamatanData()
                this.getKelurahanData()
                this.getProvinsiDomisiliData()
                this.getKotaDomisliData()
                this.getKecamatanDomisiliData()
                this.getKelurahanDomisiliData()
                this.getStatusKepemilikanData()

            })
            .catch((error)=>{
                // console.log(error + " ERROR MASE");
                this.person = this.tempPerson
                this.$swal({
                        title:"Perhatian !",
                        icon:"warning",
                        html:`Data Alamat Belum Lengkap`
										})
									// console.log(error)
            })
        },

        getProvinsiData(){
            let data = {
							Data: {
								type: "Alamat",
								code: "2",
								type_master: "State"
							}
            }
            this.getProvinsi(data)
            .then((response) => {
							let data = response.Result
							// console.log(data);

							this.masterProvinsi = data

							// console.log(this.masterProvinsi);  
            })
            .catch((error)=>{
							this.$swal({
								title:"GAGAL",
								icon:"error",
								html:`Data Gagal Di Tarik`
							})
							// console.log(error)
            })
        },
        getKotaData(){
            let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "County",
								state_recid: this.provinsiKtp.baru.STATERECID || this.provinsiKtp.baru
							}
            }
           this.getKota(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKota = data
                
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },
        getKotaChangeData(){
            this.clearProvinsiKtp()
            let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "County",
								state_recid: this.provinsiKtp.baru.STATERECID || this.provinsiKtp.baru
							}
            }
           this.getKotaChange(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKota = data
                
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },
        getKecamatanData(){
            let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "City",
								state_recid: this.provinsiKtp.baru.STATERECID || this.provinsiKtp.baru,
								county_recid: this.kotaKtp.baru.COUNTYRECID || this.kotaKtp.baru
							}
            }
           this.getKecamatan(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKecamatan = data
                
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },
         getKecamatanChangeData(){
             this.clearKotaKtp()
             let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "City",
								state_recid: this.provinsiKtp.baru.STATERECID || this.provinsiKtp.baru,
								county_recid: this.kotaKtp.baru.COUNTYRECID || this.kotaKtp.baru
							}
            }
           this.getKecamatanChange(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKecamatan = data
                
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },
        getKelurahanData(){
            let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "District",
								state_recid: this.provinsiKtp.baru.STATERECID || this.provinsiKtp.baru,
								city_recid: this.kecamatanKtp.baru.CITYRECID || this.kecamatanKtp.baru
							}
            }
           this.getKelurahan(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKelurahan = data
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },
         getKelurahanChangeData(){
             this.clearKecamatanKtp()
             let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "District",
								state_recid: this.provinsiKtp.baru.STATERECID|| this.provinsiKtp.baru,
								city_recid: this.kecamatanKtp.baru.CITYRECID || this.kecamatanKtp.baru
							}
            }
           this.getKelurahanChange(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKelurahan = data
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },

         getProvinsiDomisiliData(){
             let data = {
							Data: {
								type: "Alamat",
								code: "2",
								type_master: "State"
							}
            }
           this.getProvinsiDomisili(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterProvinsiDomisili = data

                // console.log(this.masterProvinsi);
                
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },

        getKotaDomisliData(){
            let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "County",
								state_recid: this.provinsiDomisili.baru.STATERECID || this.provinsiDomisili.baru
							}
            }
           this.getKotaDomisli(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKotaDomisili = data
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },
         getKotaDomisliChangeData(){
             this.clearProvinsiDomisili()
             let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "County",
								state_recid: this.provinsiDomisili.baru.STATERECID || this.provinsiDomisili.baru
							}
            }
           this.getKotaDomisliChange(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKotaDomisili = data
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },
        getKecamatanDomisiliData(){
            let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "City",
								state_recid: this.provinsiDomisili.baru.STATERECID || this.provinsiDomisili.baru,
								county_recid: this.kotaDomisili.baru.COUNTYRECID || this.kotaDomisili.baru
							}
            }
           this.getKecamatanDomisili(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKecamatanDomisili = data
                
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },
        getKecamatanDomisiliChangeData(){
            this.clearKotaDomisili()
            let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "City",
								state_recid: this.provinsiDomisili.baru.STATERECID || this.provinsiDomisili.baru,
								county_recid: this.kotaDomisili.baru.COUNTYRECID || this.kotaDomisili.baru
							}
            } 
           this.getKecamatanDomisiliChange(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKecamatanDomisili = data
                
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },
        getKelurahanDomisiliData(){
            let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "District",
								state_recid: this.provinsiDomisili.baru.STATERECID|| this.provinsiDomisili.baru,
								city_recid: this.kecamatanDomisili.baru.CITYRECID|| this.kecamatanDomisili.baru
							}
            }
           this.getKelurahanDomisili(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKelurahanDomisili = data
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },
         getKelurahanDomisiliChangeData(){
             this.clearKecamatanDomisili()
             let data = {
							Data: {
								type: "Alamat",
								code: "3",
								type_master: "District",
								state_recid: this.provinsiDomisili.baru.STATERECID || this.provinsiDomisili.baru,
								city_recid: this.kecamatanDomisili.baru.CITYRECID || this.kecamatanDomisili.baru
							}
            }
           this.getKelurahanDomisiliChange(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);

                this.masterKelurahanDomisili = data
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },

        getStatusKepemilikanData(){
					let data = {
						Data: {
							type: "Alamat",
							code: "2",
							type_master: "Ownership"
						}
					}
           this.getStatusKepemilikan(data)
            .then((response) => {
                let data = response.Result
                // // console.log(data);
                this.masterStatusKepemilikan = data

                
                
            })
            .catch((error)=>{
                this.$swal({
                        title:"GAGAL",
                        icon:"error",
                        html:`Data Gagal Di Tarik`
                    })
            })
        },

        saveAlamatKTPDomisiliData(){

            const ktpDomisili = {
            PERSONNELNUMBER: this.nikLogin,
            PERSON:this.person ,
            NOTICKET:this.tiket ,
            PRIMARYADDRESS:this.alamatKtp.baru,
            PRIMARYNEIGHBOURHOODNUMBER:this.RT.baru,
            PRIMARYWARDNUMBER:this.RW.baru,
            PRIMARYSTATENAME:this.provinsiKtp.baru.NAME || this.provinsiKtp.nama,
            PRIMARYSTATERECID:this.provinsiKtp.baru.STATERECID || this.provinsiKtp.baru,
            PRIMARYCOUNTYNAME:this.kotaKtp.baru.NAME || this.kotaKtp.nama,
            PRIMARYCOUNTYRECID:this.kotaKtp.baru.COUNTYRECID || this.kotaKtp.baru,
            PRIMARYCITYNAME:this.kecamatanKtp.baru.DESCRIPTION || this.kecamatanKtp.nama,
            PRIMARYCITYRECID:this.kecamatanKtp.baru.CITYRECID || this.kecamatanKtp.baru,
            PRIMARYDISTRICTNAME:this.kelurahanKtp.baru.DESCRIPTION || this.kelurahanKtp.nama,
            PRIMARYDISTRICTRECID:this.kelurahanKtp.baru.DISTRICTRECID || this.kelurahanKtp.baru,
            PRIMARYOWNERSHIPSTATUSID:this.statusKepemilikanKtp.baru,
            PRIMARYZIPCODE:this.kodePosKtp.baru,
            SECONDARYADDRESS:this.alamatKtp.baru,
            SECONDARYNEIGHBOURHOODNUMBER:this.RT.baru,
            SECONDARYWARDNUMBER:this.RW.baru,
            SECONDARYSTATENAME:this.provinsiKtp.baru.NAME || this.provinsiKtp.nama,
            SECONDARYSTATERECID:this.provinsiKtp.baru.STATERECID || this.provinsiKtp.baru,
            SECONDARYCOUNTYNAME:this.kotaKtp.baru.NAME || this.kotaKtp.nama,
            SECONDARYCOUNTYRECID:this.kotaKtp.baru.COUNTYRECID || this.kotaKtp.baru,
            SECONDARYCITYNAME:this.kecamatanKtp.baru.DESCRIPTION || this.kecamatanKtp.nama,
            SECONDARYCITYRECID:this.kecamatanKtp.baru.CITYRECID || this.kecamatanKtp.baru,
            SECONDARYDISTRICTNAME:this.kelurahanKtp.baru.DESCRIPTION || this.kelurahanKtp.nama,
            SECONDARYDISTRICTRECID:this.kelurahanKtp.baru.DISTRICTRECID || this.kelurahanKtp.baru,
            SECONDARYOWNERSHIPSTATUSID:this.statusKepemilikanKtp.baru,
            SECONDARYZIPCODE:this.kodePosKtp.baru,
            }
            const dataAlamatKtpDomisili = JSON.stringify(ktpDomisili)

            let data = {
							Data: {
								type: "Alamat-Transaksi",
								code: "1",
								type_ktp : "BOTH",
								model_alamat: dataAlamatKtpDomisili
							}
            }
            
           this.saveAlamatKTPDomisili(data)
            .then((response) => {

                let res = response.Result 
                let noTicket = response.NoTicket
                let message = response.Message
                

                if( res == "OK !"){
                this.$swal({
                    title:"Berhasil!",
                    icon: "success",
                    html:`Berhasil Menyimpan Data Alamat KTP Dan Domisili Dengan No Tiket : ${noTicket}`
                })
                .then(() => {
                this.$router.push({ name: "HomePage" })
            })

            }else{
                    this.$swal({
                    title:"Perhatian!",
                    icon: "warning",
                    html:`${message}`
                })
                
                }

            })
            .catch((error)=>{
                this.$swal({
                    title:"Gagal!",
                    icon: "error",
                    html:`Gagal Menyimpan Alamat <br/> Tolong Cek Koneksi Anda`
            })
            })
        },

        saveAlamatKTPData(){
            // console.log(this.person);

            const ktp = {
            PERSONNELNUMBER: this.nikLogin,
            PERSON:this.person ,
            NOTICKET:this.tiket ,
            PRIMARYADDRESS:this.alamatKtp.baru,
            PRIMARYNEIGHBOURHOODNUMBER:this.RT.baru,
            PRIMARYWARDNUMBER:this.RW.baru,
            PRIMARYSTATENAME:this.provinsiKtp.baru.NAME || this.provinsiKtp.nama,
            PRIMARYSTATERECID:this.provinsiKtp.baru.STATERECID || this.provinsiKtp.baru,
            PRIMARYCOUNTYNAME:this.kotaKtp.baru.NAME || this.kotaKtp.nama,
            PRIMARYCOUNTYRECID:this.kotaKtp.baru.COUNTYRECID || this.kotaKtp.baru,
            PRIMARYCITYNAME:this.kecamatanKtp.baru.DESCRIPTION || this.kecamatanKtp.nama,
            PRIMARYCITYRECID:this.kecamatanKtp.baru.CITYRECID || this.kecamatanKtp.baru,
            PRIMARYDISTRICTNAME:this.kelurahanKtp.baru.DESCRIPTION || this.kelurahanKtp.nama,
            PRIMARYDISTRICTRECID:this.kelurahanKtp.baru.DISTRICTRECID || this.kelurahanKtp.baru,
            PRIMARYOWNERSHIPSTATUSID:this.statusKepemilikanKtp.baru,
            PRIMARYZIPCODE:this.kodePosKtp.baru,
            
            }
            const dataAlamatKtp = JSON.stringify(ktp)

            let data = {
							Data: {
								type: "Alamat-Transaksi",
								code: "1",
								type_ktp : "KTP",
								model_alamat: dataAlamatKtp
							}
            }
            
           this.saveAlamatKTP(data)
            .then((response) => {
                // console.log(response);
                //!Stag Code
                let res = response.Result 
                let noTicket = response.NoTicket
                let message = response.Message

                //!Live Code
                // let data = response.Code
                // let noTicket = response.Result

                if(this.nikStatus == "ACCEPTABLE"){
                this.tiket = noTicket}
                else{
                    this.tiket = this.noTiketStatus
                }

                if(res == "OK !"){
                this.$swal({
                    title:"Berhasil!",
                    icon: "success",
                    html:`Berhasil Menyimpan Data Alamat KTP, WAJIB Melanjutkan
                    Pengisian data Alamat Domisili `
                })
                .then(() => {
                this.e1 = 2
                this.getProvinsiDomisiliData()
            })
                }else{
                    this.$swal({
                    title:"Perhatian!",
                    icon: "warning",
                    html:`${message}`
                })
                
                }
                
            })
            .catch((error)=>{
                // console.log(error);
                this.$swal({
                    title:"Gagal!",
                    icon: "error",
                    html:`Gagal Menyimpan Alamat <br/> Tolong Cek Koneksi Anda`
            })
            })
        },

        saveAlamatDomisiliData(){

            const domisili = {
            PERSONNELNUMBER: this.nikLogin,
            PERSON:this.person ,
            NOTICKET:this.tiket ,
            SECONDARYADDRESS:this.alamatDomisili.baru,
            SECONDARYNEIGHBOURHOODNUMBER:this.RTDomisili.baru,
            SECONDARYWARDNUMBER:this.RWDomisili.baru,
            SECONDARYSTATENAME:this.provinsiDomisili.baru.NAME || this.provinsiDomisili.nama,
            SECONDARYSTATERECID:this.provinsiDomisili.baru.STATERECID || this.provinsiDomisili.baru,
            SECONDARYCOUNTYNAME:this.kotaDomisili.baru.NAME || this.kotaDomisili.nama,
            SECONDARYCOUNTYRECID:this.kotaDomisili.baru.COUNTYRECID || this.kotaDomisili.baru,
            SECONDARYCITYNAME:this.kecamatanDomisili.baru.DESCRIPTION || this.kecamatanDomisili.nama,
            SECONDARYCITYRECID:this.kecamatanDomisili.baru.CITYRECID || this.kecamatanDomisili.baru,
            SECONDARYDISTRICTNAME:this.kelurahanDomisili.baru.DESCRIPTION || this.kelurahanDomisili.nama,
            SECONDARYDISTRICTRECID:this.kelurahanDomisili.baru.DISTRICTRECID || this.kelurahanDomisili.baru,
            SECONDARYOWNERSHIPSTATUSID:this.statusKepemilikanDomisili.baru,
            SECONDARYZIPCODE:this.kodePosDomisili.baru,
            }
            const dataAlamatDomisili = JSON.stringify(domisili)

            let data = {
							Data: {
								type: "Alamat-Transaksi",
								code: "1",
								type_ktp : "DOMISILI",
								model_alamat: dataAlamatDomisili
							}
            }
            
           this.saveAlamatDomisili(data)
            .then((response) => {
                let res = response.Result
                let message = response.Message
                let code = response.Code
                

                if(res == "OK !"){
                this.$swal({
                    title:"Berhasil!",
                    icon: "success",
                    html:`Berhasil Menyimpan Data Alamat Dengan Nomor Tiket: ${this.tiket}`
                })
            
                .then(() => {
                this.$router.push({ name: "HomePage" })
            })
            }else{
                    this.$swal({
                    title:"Perhatian!",
                    icon: "warning",
                    html:`${message}`
                })
                
                }
            })
            .catch((error)=>{
                this.$swal({
                    title:"Gagal!",
                    icon: "error",
                    html:`Gagal Menyimpan Alamat <br/> Tolong Cek Koneksi Anda`
            })
            })
        },

        clearProvinsiKtp(){
            this.kotaKtp.baru = ""
            this.kecamatanKtp.baru = ""
            this.kelurahanKtp.baru = ""

        },

        clearKotaKtp(){
            this.kecamatanKtp.baru = ""
            this.kelurahanKtp.baru = ""
        },

        clearKecamatanKtp(){
            this.kelurahanKtp.baru = ""
        },

        clearProvinsiDomisili(){
            this.kotaDomisili.baru = ""
            this.kecamatanDomisili.baru = ""
            this.kelurahanDomisili.baru = ""

        },

        clearKotaDomisili(){
            this.kecamatanDomisili.baru = ""
            this.kelurahanDomisili.baru = ""
        },

        clearKecamatanDomisili(){
            this.kelurahanDomisili.baru = ""
        }


        
    },


    }
    

</script>