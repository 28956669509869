"use strict"

import seragamApi from "@/api/seragam"
import store from "../index"

export default {
	state: {

	},
	mutations: {

	},
	actions: {

		setToken(context, payload) {
			// cookie.setCookie(payload)
			context.commit("SET_TOKEN", payload)
		},
		removeToken(context) {
			// cookie.removeCookie()
			context.commit("SET_TOKEN", null)
		},

		//Pengajuan Beasiswa

		getTransaksi(context, payload) {
			return new Promise ((resolve, reject) => {
				let token = store.getters.token
				seragamApi.getTransaksi(payload, token)
				.then(res => {
					const data = res
					resolve(data)
				}).catch(err => {
					reject(err)
				})
			})
		},
	},
	getters: {
		
	}
}