"use strict"

import cookie from "@/utils/auth.js"
import authApi from "@/api/auth"
import store from "../index"

export default {
    state: {
        token: cookie.getCookie() || null,
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
    },
    actions: {
        async login(context, payload) {
            return await new Promise((resolve, reject) => {
                // console.log(payload)
                authApi.login({
                    Data: {
                        'nik': payload.nik,
                        'pass': payload.password
                    }
                }).then(res => {
                    const data = res.data
                        // console.log(data)
                    store.dispatch('setUser', data)

                    resolve(data)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        async getAuthenticated(context, payload) {
            return await new Promise((resolve, reject) => {
                authApi.authenticated({ 'nik': sessionStorage.getItem('nik') })
                    .then(res => {
                        // console.log(res)
                        const data = res.data

                        store.dispatch('setUser', data)
                        resolve(data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },

        async changePasswordApi(context, payload) {
            return await new Promise((resolve, reject) => {
                authApi.updatePassword(payload)
                    .then(res => {
                        // console.log(res)
                        const data = res.data
                        resolve(data)

                    }).catch(err => {
                        reject(err)
                    })
            })
        },

        logoutSession(context) {
            return new Promise((resolve, reject) => {
                authApi.logout().then(res => {
                    const data = res
                        // console.log(data)

                    context.commit("SET_TOKEN", null)
                    cookie.removeCookie()
                    store.dispatch('setUser', {})

                    resolve(data)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        setToken(context, payload) {
            cookie.setCookie(payload)
            context.commit("SET_TOKEN", payload)
        },
        removeToken(context) {
            cookie.removeCookie()
            context.commit("SET_TOKEN", null)
        },
    },
    getters: {
        token: state => state.token,
        isAuthenticated: state => !!state.token,
    }
}