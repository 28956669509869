<template>
<div>
  
  <div v-if="isLogin">
    <v-app-bar app color="yellow darken-3"  dark dense>
    <v-app-bar-nav-icon   @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
      <span class="hidden-sm-and-down">ESS Online</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-title class="body-2">
      {{ (Object.keys(this.user).length === 0) 
      ? '' 
      : user.data[0].NikKaryawan 
      + " - " 
      + user.data[0].NamaKaryawan }}
    </v-toolbar-title>
    <v-btn icon @click="logout">
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
  </div>

  <div v-else>
    <!-- <v-app-bar>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Homepage Ess</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="this.$router.push({ name: 'Login' });">Login</v-btn>
    </v-app-bar> -->
  </div>
  
</div>
</template>

<script>
import cookie from '@/utils/auth.js'
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,  
      Role: "",
    };
  },

  mounted(){
    this.loadRole()
  },

  computed: {
    ...mapGetters({
      getDrawer: "drawer",
      user: "user",
      hasAllAccess: "hasAllAccess",
      isAuthenticated: "isAuthenticated",
      token: "token",
    }),

    drawer: {
      get() {
        return this.getDrawer;
      },
      set(value) {
        this.setDrawer(value);
      },
    },

    isLogin(){
      // return cookie.getCookie() != null
      return this.token !== null && Object.keys(this.user).length !== 0
    }
  },

  methods: {
    ...mapActions({
      setDrawer: "setDrawer",
      removeToken: "removeToken",
      removeUser: "removeUser",
      logoutSession: "logoutSession"
    }),

    logout() {
      this.removeToken();
      this.removeUser()
      this.logoutSession()

      this.$router.push({ name: "Login" });
      setTimeout(()=>
        window.location.reload(),
        
      )
    },

    loadRole() {
      // console.log(Object.keys(this.user).length)
      if(Object.keys(this.user).length === 0) {
        return
      } else {
        this.Role = this.user.data[0].ROLE
        // console.log(this.Role);
      }
    }
  },
};
</script>