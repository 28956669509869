<template>
  <div class="laporan">
    <c-toolbar />
    <c-navbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2">
              <v-row justify="center">
                <v-img
                  src="img/indomaret.png"
                  aspect-ratio="3"
                  max-width="250"
                >
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Rekapitulasi Penerima Bantuan Pendidikan
              </h3>

              <!-- <v-alert class="mx-4" type="info">
               <div v-show="this.userRole == 'ETD'">
                  Mohon memilih Unit, Cabang, dan Direktorat untuk melihat
                  <i>report</i> karyawan Head Office
                </div>
                <div v-show="this.userRole == 'ETD'">
                  Mohon memilih Unit dan Cabang untuk melihat
                  <i>report</i> karyawan selain Head Office
                </div>
                <div v-show="this.userRole == 'PIC HO'">
                  Mohon memilih Unit, Cabang, dan Direktorat untuk melihat
                  <i>report</i>
                </div>
                <div v-show="this.userRole == 'HR Cabang'">
                  Mohon memilih Unit dan Cabang untuk melihat
                  <i>report</i>
                </div>
              </v-alert> -->

              <form class="pa-4 mx-3">
                <div class="my-2">
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-autocomplete
                        :items="items_unit"
                        label="Unit"
                        v-model="unit"
                        item-text="PT"
                        item-value="ID_PT"
                        return-object
                        @change="dataCabang"
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-autocomplete
                        :items="items_cabang"
                        label="Cabang"
                        v-model="cabang"
                        item-text="CABANG"
                        item-value="ID_CABANG"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="3">
                      <v-select
                        :items="items_periode"
                        label="Periode"
                        v-model="periode"
                        item-text="periode"
                        item-value="periode"
                      ></v-select>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="3">
                      <v-select
                        :items="items_jenis"
                        label="Jenis"
                        v-model="jenis"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row> </v-row>
                  <v-row justify="center">
                    <v-col cols="12" sm="2" class="shrink text-end">
                      <v-btn
                        color="primary"
                        block
                        :loading="btnLoading"
                        @click="filterRekap()"
                        v-show="validRekap"
                        >Filter</v-btn
                      >
                    </v-col>
                  </v-row>
                  <div class="mt-10" v-show="dataTable">
                    <template>
                      <v-data-table
                        id="tbl_exporttable_to_xls"
                        :headers="headers"
                        :items="itemsTableRekap"
                        :search="search"
                        class="elevation-1"
                      >
                        <template slot="body.append">
                          <tr>
                            <th class="font-weight-bold">Total Keseluruhan</th>
                            <th class="font-weight-bold"></th>
                            <th class="font-weight-bold"></th>
                            <th class="font-weight-bold"></th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.OrangSDFinal) }}
                            </th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.PlafondSDFinal) }}
                            </th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.OrangSMPFinal) }}
                            </th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.PlafondSMPFinal) }}
                            </th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.OrangSMAFinal) }}
                            </th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.PlafondSMAFinal) }}
                            </th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.OrangAkademiFinal) }}
                            </th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.PlafondAkademiFinal) }}
                            </th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.OrangUniversitasFinal) }}
                            </th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.PlafondUniversitasFinal) }}
                            </th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.TotalOrangFinal) }}
                            </th>
                            <th class="font-weight-bold">
                              {{ formatPrice(totals.TotalPlafondFinal) }}
                            </th>
                          </tr>
                        </template>
                      
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-btn
                              color="success"
                              dark
                              class="mb-2"
                              @click="exportToExcel('xlsx')"
                            >
                              Export Excel
                            </v-btn>

                            <!-- jarak judul -->
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <!-- search -->
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-toolbar>
                        </template>
                      </v-data-table>
                    </template>
                  </div>
                </div>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
// import Sidebar from "@/components/Sidebar.vue";
// import Toolbar from "@/components/Toolbar.vue";
import CNavbar from '../../components/CNavbar.vue'
import CToolbar from '../../components/CToolbar.vue'
import { mapGetters, mapActions } from "vuex";
import XLSX from "xlsx";

export default {
  name: "Home",
  components: {
    CNavbar,
    CToolbar,
  },

  mounted() {
    this.TOKEN = this.token
    this.dataUnit();
    // this.dataUnit();
    // this.dataStatus();
    // this.userRole = sessionStorage.getItem("role");
    // this.dataBagian();
  },

  computed: {
    ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      token:"token"
      // getDrawer: "drawer",
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    computedDateFormatted() {
      // return this.dates.join(' ~ ');
      if (this.dates.length <= 0) {
        return "";
      } else {
        let date1 = this.formatDate(new Date(this.dates[0]));
        let date2 = this.formatDate(new Date(this.dates[1]));

        if (date2 < date1) {
          date2 = this.formatDate(new Date(this.dates[0]));
          date1 = this.formatDate(new Date(this.dates[1]));
        }
        const merge = [date1, date2];
        const temp = merge.join(" ~ ");
        return temp;
      }
    },

    validRekap() {
      // const userRole = sessionStorage.getItem("role");
      return (
        // this.cabang != "" &&
        // this.unit != "" &&
        // this.periode != "" 
        this.jenis != ""
      );
    },

    headers() {
      //set having unique values
      let s = new Set();
      var f;

      this.itemsTableRekap.forEach((item) => {
        for (f in item) {
          //adding an existing value doesn't override the old one
          s.add(f);
        }
      });
      //respect the headers format required by Vuetify which
      // should has at least two fields (text, value)
      return Array.from(s).map((a) => {
        return {
          text: a.toUpperCase(),
          value: a,
        };
      });
    },
  },

  data: () => ({
    // $baseurl: "http://localhost/",
    // $baseurl: "http://116.204.128.50:80/",
    // $baseurl: "https://ms.hrindomaret.com/",
    userRole: "",
    menu2: false,
    dates: [],
    btnLoading: false,
    dataTable: false,
    unit: "",
    cabang: "",
    periode: "",
    // periode_akhir: "",
    direktorat: "",
    bagian: "",
    status: "",
    jenis:"",
    // departemen: "",
    items_unit: [],
    items_cabang: [],
    items_periode: [],
    items_departemen: [],
    items_direktorat: [],
    items_bagian: [],
    items_status: [],
    items_jenis:["All","Karyawan", "Anak Karyawan"],
    search: null,
    rpSD:"",
    rpSMP:"",
    rpSMA:"",
    rpAKADEMI:"",
    rpUNIVERSITAS:"",
    rpTOTAL:"",
    // headers: [
    //   { text: "Unit", value: "Unit" },
    //   { text: "Cabang", value: "Cabang" },
    //   { text: "Jenis", value: "Jenis" },
    //   { text: "Tahun", value: "Periode" },
    //   // { text: "Bagian", value: "BAGIAN" },
    //   { text: "Jumlah Orang(SD)", value: "OrangSD" },
    //   { text: "Jumlah Plafond Rp. (SD)", value: "PlafondSD" },
    //   { text: "Jumlah Orang(SMP)", value: "OrangSMP" },
    //   { text: "Jumlah Plafond Rp. (SMP)", value: "PlafondSMP" },
    //   { text: "Jumlah Orang(SMA)", value: "OrangSMA" },
    //   { text: "Jumlah Plafond Rp. (SMA)", value: "PlafondSMA" },
    //   { text: "Jumlah Orang (Akademi)", value: "OrangAkademi" },
    //   { text: "Jumlah Plafond Rp. (Akademi)", value: "PlafondAkademi" },
    //   { text: "Jumlah Orang Universitas", value: "OrangUniversitas" },
    //   { text: "Jumlah Plafond Rp. (Universitas)", value: "PlafondUniversitas" },
    //   { text: "Jumlah Total Orang", value: "TotalOrang" },
    //   { text: "Jumlah Total Rp. (Total)", value: "TotalPlafond" },
    // ],

    itemsTableRekap: [],
    totals: {}
  }),

  methods: {
    ...mapActions({
            getDataReportBeasiswa:"getDataReportBeasiswa",
            getFilterReportBeasiswa:"getFilterReportBeasiswa",
        }),

        sumField(key) {
        // sum data in give key (property)
        return this.itemsTableRekap.reduce((a, b) => a + (b[key] || 0), 0)
    },

    filterRekap() {
      // let temp_unit = this.unit.map(item => item.ID_PT)
      // // console.log(temp_unit);
      // let isi_unit = ''
      // // let i = 0
      // for (let i= 0; i<temp_unit.length; i++){
      //   isi_unit += '\''+temp_unit[i]+'\','
      // }
      // // // console.log(isi_unit)
      // let final_unit = isi_unit.substr(0, isi_unit.length-1)
      // // console.log(final_unit)

      // console.log(this.unit.ID_PT);
      // console.log(this.cabang.ID_CABANG);
      // console.log(this.periode);
      // // console.log(this.periode_akhir);
      // // console.log(this.dates[1]);
      // this.btnLoading = false;
      // this.dataTable = true;
      // let date1 = this.dates[0];
      // let date2 = this.dates[1];

      //   if (date2 < date1) {
      //     date2 = this.dates[0];
      //     date1 = this.dates[1];
      //   }
      this.btnLoading = true;
      // const userRole = sessionStorage.getItem("role");
      const axios = require("axios");
      const code = {
        // nik: sessionStorage.getItem('nik'),
        // idpt: "IDM",
        // idcabang: "00",
        // periode1: "2018",
        // periode2: "2021",
        // kategori: "Rekapitulasi Penerima"
        nik: this.user.data[0].NikKaryawan,
        idpt: this.unit.ID_PT,
        idcabang: this.cabang.ID_CABANG,
        periode: this.periode,
        jenis: this.jenis,
        // periode2: this.periode_akhir,
        kategori: "Rekapitulasi Penerima",
      };
      // console.log(code);
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: this.user.NikKaryawan,
      // };
      // axios
      //   .post(this.$baseurl + "beasiswa/getDataReportBeasiswa", code, {
      //     headers,
      //   })
      this.getDataReportBeasiswa(code)
        .then((response) => {
          const hasil = response.data;
          // console.log(hasil);
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // this.itemsTableRekap = hasil.data;
            let result = hasil.data;
            console.log(result, 'result');
            console.log(result.length, 'length');
            if(result.length == 0){
              this.btnLoading = false;
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Data tidak ditemukan!",
              })
            }else{
              let hasiltest = result.map((item) => {
              let PlafondSD = item.PlafondSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              let PlafondSMP = item.PlafondSMP.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              let PlafondSMA = item.PlafondSMA.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              let PlafondAkademi = item.PlafondAkademi.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              let PlafondUniversitas = item.PlafondUniversitas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              let TotalPlafond = item.TotalPlafond.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

              return { 
                "Unit": item.Unit,
                "Cabang": item.Cabang,
                "Jenis": item.Jenis,
                "Periode": item.Periode, 
                "Jumlah Orang(SD)": item.OrangSD,
                "Jumlah Plafond Rp. (SD)": PlafondSD,
                "Jumlah Orang(SMP)": item.OrangSMP,
                "Jumlah Plafond Rp. (SMP)": PlafondSMP,
                "Jumlah Orang(SMA)": item.OrangSMA,
                "Jumlah Plafond Rp. (SMA)": PlafondSMA,
                "Jumlah Orang(Akademi)": item.OrangAkademi,
                "Jumlah Plafond Rp. (Akademi)": PlafondAkademi,
                "Jumlah Orang(Universitas)": item.OrangUniversitas,
                "Jumlah Plafond Rp. (Universitas)": PlafondUniversitas,
                "Jumlah Orang(Total)": item.TotalOrang,
                "Jumlah Plafond Rp. (Plafond)": TotalPlafond,
              };
              
            });

              // this.rpSD = PlafondSD
              // this.rpSMP = PlafondSMP
              // this.rpSMA = PlafondSMA
              // this.rpAKADEMI = PlafondAkademi
              // this.rpUNIVERSITAS = PlafondUniversitas
              // this.rpTOTAL = TotalPlafond

            this.itemsTableRekap = hasiltest;
            this.totals = hasil.data[0]
            // console.log(this.totals,"total")
            // // console.log(hasil);
            this.btnLoading = false;
            this.dataTable = true;
            }
          }
        })
        .catch((error) => {
          this.btnLoading = false;
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
        });
    },

    formatPrice(value) {
      let val = (value/1).toFixed()
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    formatDate(val) {
      const MyDate = new Date(val);

      const MyDateString =
        ("0" + MyDate.getDate()).slice(-2) +
        "-" +
        ("0" + (MyDate.getMonth() + 1)).slice(-2) +
        "-" +
        MyDate.getFullYear();
      return MyDateString;
    },

    showDate() {
      this.menu2 = false;
      // console.log(this.dates[0]);
      // console.log(this.dates[1]);
    },

    dataUnit() {
      // const getUnit = {nik: this.user.NikKaryawan}
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: this.user.NikKaryawan,
      // };
      const code = {
        nik: this.user.data[0].NikKaryawan,
        idpt: "",
        idcabang: "",
        statustransaksi: "",
      };
      // console.log(code)
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/getFilterReportBeasiswa/Stag", code, {
      //     headers,
      //   })
        this.getFilterReportBeasiswa(code)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // console.log(hasil);
          this.items_unit = hasil.data;
          this.items_periode = hasil.periode;
          this.dataCabang();
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Unit Tidak Ditemukan",
          });
        });
    },

    dataCabang() {
      // const getUnit = {nik: this.user.NikKaryawan}
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // NIK: this.user.NikKaryawan,
      // };
      // // console.log(this.unit.ID_UNITROLE)
      const code = {
        nik: this.user.data[0].NikKaryawan,
        idpt: this.unit.ID_PT,
        statustransaksi: "",
      };
      // const axios = require("axios");
      // axios
      //   .post(this.$baseurl + "beasiswa/getFilterReportBeasiswa", code, {
      //     headers,
      //   })
      this.getFilterReportBeasiswa(code)
        .then((response) => {
          // // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          this.items_cabang = hasil.data;
          // console.log(hasil.data);
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Cabang Tidak Ditemukan",
          });
        });
    },

    // dataDirektorat() {
    //   this.direktorat = "";
    //   this.bagian = "";
    //   this.dataBagian();
    //   const getDirektorat = {
    //     // level: "3",
    //     id_unit: this.unit.AliasUnit,
    //     id_cabang: this.cabang.AREAID,
    //   };
    //   const varToken = sessionStorage.getItem("token");
    //   const headers = {
    //     JWT: varToken,
    //     // NIK: this.user.NikKaryawan,
    //   };
    //   const role = sessionStorage.getItem("role");
    //   const axios = require("axios");

    //   if (role == "ETD") {
    //     axios
    //       .post(
    //         this.$baseurl + "api/v1/GetDirektoratByUnitCabang",
    //         getDirektorat,
    //         { headers }
    //       )
    //       .then((response) => {
    //         // // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data;
    //           this.items_direktorat = hasil;
    //         }
    //       })
    //       .catch((error) => {
    //         // console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Direktorat Tidak Ditemukan",
    //         });
    //       });
    //   } else if (role == "PIC HO") {
    //     const getBagianHO = {
    //       code: "7",
    //       data: {
    //         NIK: this.user.NikKaryawan,
    //         ROLE: sessionStorage.getItem("role"),
    //       },
    //     };
    //     axios
    //       .post(this.$baseurl + "api/v1/MasterUser", getBagianHO, {
    //         headers,
    //       })
    //       .then((response) => {
    //         // // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data.result;
    //           // // console.log(hasil);
    //           let hasiltest = hasil.map((item) => item.ID_BAGIAN);
    //           // // console.log(hasiltest);
    //           for (var i = 0; i < hasiltest.length; i++) {
    //             hasiltest[i] = hasiltest[i].substring(0, 6);
    //           }
    //           // // console.log(hasiltest);
    //           const getDirektoratHO = {
    //             id_direktorat: ";" + hasiltest + ";",
    //             id_cabang: "00",
    //           };
    //           axios
    //             .post(this.$baseurl + "api/v1/GetDirektorat", getDirektoratHO, {
    //               headers,
    //             })
    //             .then((response) => {
    //               if (response.data.Message) {
    //                 this.$swal({
    //                   icon: "info",
    //                   title: "Info!",
    //                   text: "Session Anda Telah Habis",
    //                 }).then(() => {
    //                   sessionStorage.clear();
    //                   this.$router.push({ name: "Login" });
    //                 });
    //               } else {
    //                 const hasil = response.data;
    //                 // // console.log(hasil)
    //                 this.items_direktorat = hasil;
    //               }
    //             });
    //         }
    //       })
    //       .catch((error) => {
    //         // console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Direktorat Tidak Ditemukan",
    //         });
    //       });
    //   } else {
    //     this.dataBagian();
    //   }
    // },

    // dataBagian() {
    //   this.bagian = "";
    //   const getBagian = {
    //     id: this.direktorat.OMOPERATINGUNITNUMBER,
    //   };
    //   const varToken = sessionStorage.getItem("token");
    //   const headers = {
    //     JWT: varToken,
    //     // NIK: this.user.NikKaryawan,
    //   };
    //   const axios = require("axios");
    //   const role = sessionStorage.getItem("role");

    //   if (role == "ETD"  &&  this.cabang.AREAID == '00') {
    //     axios
    //       .post(
    //         this.$baseurl + "api/v1/GetDepartemenByDirektorat",
    //         // "https://ms.hrindomaret.com/api/v1/DVC/Utility/Company/GetDepartemenByDirektorat",
    //         getBagian,
    //         { headers }
    //       )
    //       .then((response) => {
    //         // // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data;
    //           this.items_bagian = hasil;
    //         }
    //       })
    //       .catch((error) => {
    //         // console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Bagian Tidak Ditemukan",
    //         });
    //       });
    //   }
    //   else if(role == "ETD" &&  this.cabang.AREAID != '00'){
    //     const getDeptbyCabang = {
    //     id_unit: this.unit.AliasUnit,
    //     id_cabang: this.cabang.AREAID
    //   };
    //   // console.log(getDeptbyCabang)
    //   axios
    //       .post(
    //         this.$baseurl + "api/v1/GetDepartemenByUnitCabang",
    //         // "https://ms.hrindomaret.com/api/v1/DVC/Utility/Company/GetDepartemenByDirektorat",
    //         getDeptbyCabang,
    //         { headers }
    //       )
    //       .then((response) => {
    //         // // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data;
    //           this.items_bagian = hasil;
    //         }
    //       })
    //       .catch((error) => {
    //         // console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Bagian Tidak Ditemukan",
    //         });
    //       });
    //   }
    //   else if (role == "PIC HO") {
    //     const getBagianHO = {
    //       code: "7",
    //       data: {
    //         NIK: this.user.NikKaryawan,
    //         ROLE: role,
    //       },
    //     };
    //     axios
    //       .post(this.$baseurl + "api/v1/MasterUser", getBagianHO, { headers })
    //       .then((response) => {
    //         // // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data.result;
    //           let hasiltest = hasil.map((item) => {
    //             return {
    //               OMOPERATINGUNITNUMBER: item.ID_BAGIAN,
    //               NAME: item.BAGIAN,
    //             };
    //           });
    //           this.items_bagian = hasiltest;
    //         }
    //       })
    //       .catch((error) => {
    //         // console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Bagian Tidak Ditemukan",
    //         });
    //       });
    //   } else {
    //     //HR Cabang
    //     const getBagianHR = {
    //       id_unit: this.unit.AliasUnit,
    //       id_cabang: this.cabang.AREAID
    //     };
    //     axios
    //       .post(
    //         this.$baseurl + "api/v1/GetDepartemenByUnitCabang",
    //         getBagianHR,
    //         { headers }
    //       )
    //       .then((response) => {
    //         // // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data;
    //           this.items_bagian = hasil;
    //         }
    //       })
    //       .catch((error) => {
    //         // console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Bagian Tidak Ditemukan",
    //         });
    //       });
    //   }
    // },

    // dataStatus() {
    //   // const getUnit = {nik: this.user.NikKaryawan}
    //   const varToken = sessionStorage.getItem("token");
    //   const headers = {
    //     JWT: varToken,
    //     // NIK: this.user.NikKaryawan,
    //   };
    //   const getStatus = {
    //     code: "5",
    //   };
    //   const axios = require("axios");
    //   axios
    //     .post(this.$baseurl + "api/v1/MasterStatus", getStatus, { headers })
    //     .then((response) => {
    //       // // console.log(response.data.GetAllUnitResult);
    //       const hasil = response.data.message;
    //       this.items_status = hasil;
    //     })
    //     .catch((error) => {
    //       // console.log(error);
    //       this.$swal({
    //         icon: "error",
    //         title: "Maaf!",
    //         text: "Unit Tidak Ditemukan",
    //       });
    //     });
    // },
    exportToExcel(type, fn, dl) {
      var elt = document.getElementById('tbl_exporttable_to_xls');
      var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
      let temp = ''
      if(this.jenis == "Karyawan")
        temp = " Karyawan."
      else if(this.jenis == "Anak Karyawan")
        temp = " Anak Karyawan."
      else
        temp = "."
      return dl ?
        XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
        XLSX.writeFile(wb, fn || ('Report Rekapitulasi Bantuan Pendidikan' +temp+ (type || 'xlsx')));
    },
  },
};
</script>
