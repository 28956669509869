"use strict"

import axios from "axios"

const PREFIX = 'loginverif'
const PREFIX_LOGIN = process.env.VUE_APP_API_LOGIN
const PREFIX_AUTH = process.env.VUE_APP_API_AUTH
const PREFIX_NEW = process.env.VUE_APP_API_NEW
const PREFIX_PORTAL = process.env.VUE_APP_API_PORTAL

export default {
    login(data) {
        return axios.post(`${PREFIX_LOGIN}`, data, {
            withCredentials: true,
        })
    },
    authenticated(data) {
        return axios.post(`${PREFIX_AUTH}`, data, {
            withCredentials: true,
        })
    },
    updatePassword(data) {
        return axios.post(`${PREFIX_PORTAL}`, data, {
            withCredentials: true,
        })
    },
    logout() {
        return axios.post(`${PREFIX_NEW}`, {}, {
            // withCredentials: true,
        })
    },

}