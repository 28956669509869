<template>
  <v-app id="inspire">
    <c-toolbar />
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-row align="center" justify="center">
              <v-img src="img/EssOnline.png" 
              :aspect-ratio="1"
              width="250"

              height="400"
              ></v-img>

            </v-row>

            <v-spacer></v-spacer>

            <v-alert prominent type="info" dark dense>
              <v-row align="center">
                <v-col class="grow text-justify">
                  Pastikan Anda Melakukan
                  <span class="font-italic font-weight-bold"
                    >Clear Cache Browser
                  </span>
                  Dan Mendapatkan Versi Terbaru Web Ess Online. Disarankan Menggunakan Browser Firefox Atau Google Chrome.
                  <br>
                  Minimum Versi Web : 
                  <br>
                  - Firefox : 54
                  <br>
                  - Chrome: 51
                  <br>
                  - Safari: 10
                  <br>
                  - Internet Explorer: 11
                  <br>
                  - Edge: 12
                  <br>
                  Untuk Cek Versi Browser Anda, Bisa Melihat DiBawah.
                </v-col>
              </v-row>
            </v-alert>

            <v-card class="elevation-12 mt-3">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title class="flex text-center"
                  >ESS Online</v-toolbar-title
                >
              </v-toolbar>

              <v-card-text class="pa-4">
                <v-form
                  ref="loginForm"
                  v-model="isValid"
                  lazy-validation
                  @keyup.enter="handleLogin"
                >
                  <v-text-field
                    @keypress="onlyNumber"
                    label="NIK"
                    v-model="data.nik"
                    prepend-icon="mdi-account"
                    counter="10"
                    maxlength="10"
                    :rules="nikRules"
                    type="text"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="PASSWORD"
                    v-model="data.password"
                    autocomplete="off"
                    prepend-icon="mdi-lock"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-4 mt-n2">
                <v-btn
                  class="mb-1"
                  block
                  color="primary"
                  :loading="btnLoading"
                  @click="handleLogin"
                  >Login</v-btn
                >
                <!-- test -->
              </v-card-actions>

          <v-dialog
          v-model= "dialogRenewPass"
          max-width="800px">
          <v-card :loading="btnLoading">
                              <v-form ref="form">
                                <v-card-title>
                                  <span class="headline"
                                    >Password sudah tidak berlaku!</span
                                  >
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                      <span>Old Password</span>
                                      <v-text-field
                                        id="oldPassword"
                                        label="PASSWORD"
                                        v-model="oldPassword"
                                        autocomplete="off"
                                        prepend-icon="mdi-lock"
                                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="passwordRules"
                                        :type="show2 ? 'text' : 'password'"
                                        @click:append="show2 = !show2"
                                        ></v-text-field>

                                      <span>New Password</span>
                                      <v-text-field
                                        id="newPassword"
                                        label="PASSWORD"
                                        v-model="newPassword"
                                        @input="onInputNewPass"
                                        autocomplete="off"
                                        prepend-icon="mdi-lock"
                                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="passwordRules"
                                        :type="show3 ? 'text' : 'password'"
                                        @click:append="show3 = !show3"
                                        ></v-text-field>
                                        

                                        <div class="divInfoPassword">
                                        <div v-if="this.isValidUppercase == true" class="h1 green--text">
                                        <v-icon>mdi-checkbox-marked-circle</v-icon> one uppercase character
                                        </div>
                                        <div v-else class="h1 red--text">
                                        <v-icon>mdi-close-circle</v-icon> one uppercase character
                                        </div>
                                        <div v-if="this.isValidLowercase == true" class="h1 green--text">
                                        <v-icon>mdi-checkbox-marked-circle</v-icon> one lowercase character
                                        </div>
                                        <div v-else class="h1 red--text">
                                        <v-icon>mdi-close-circle</v-icon> one lowercase character
                                        </div>
                                        <div v-if="this.isValidNumber == true" class="h1 green--text">
                                        <v-icon>mdi-checkbox-marked-circle</v-icon> one number
                                        </div>
                                        <div v-else class="h1 red--text">
                                        <v-icon>mdi-close-circle</v-icon> one number
                                        </div>
                                        <div v-if="this.isValidCharacter == true" class="h1 green--text">
                                        <v-icon>mdi-checkbox-marked-circle</v-icon> 8 character minimum
                                        </div>
                                        <div v-else class="h1 red--text">
                                        <v-icon>mdi-close-circle</v-icon> 8 character minimum
                                        </div>
                                        </div>
                                        
                                        <div><span>Re-input New Password </span></div>
                                        <v-text-field
                                        id="reInputNewPassword"
                                        label="PASSWORD"
                                        v-model="rePassword"
                                        autocomplete="off"
                                        prepend-icon="mdi-lock"
                                        :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="passwordRules && validasiRePassword"
                                        :type="show4 ? 'text' : 'password'"
                                        @click:append="show4 = !show4"
                                        ></v-text-field>

                                        <div v-if="this.validasiRePassword == true" class="h1 green--text">
                                        <v-icon>mdi-checkbox-marked-circle</v-icon> Re-input New Password harus sama dengan New Password
                                        </div>
                                        <div v-else-if="this.validasiRePassword == false" class="h1 red--text">
                                        <v-icon>mdi-close-circle</v-icon> Re-input New Password harus sama dengan New Password
                                        </div>
                                        <div v-else class="h1 red--text">
                                        <v-icon>mdi-close-circle</v-icon> Re-Input New Password Tidak Boleh Kosong
                                        </div>
                                      
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                    <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="changePassword()"
                                  >
                                    Submit
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="dialogRenewPass = false"
                                  >
                                    Cancel
                                  </v-btn>
                                </v-card-actions>
                              </v-form>
                            </v-card>
          
          </v-dialog>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, Store } from "vuex";
import CToolbar from "../components/CToolbar.vue";

export default {
  components: { CToolbar },
  data() {
    return {
      data: {
        nik: "",
        password: ""
      },

      isValid: true,
      btnLoading: false,
      dialogRenewPass : false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      dataTokenFB:"",

      snackbar: true,
      timeout: 10000,

      isValidUppercase: false,
      isValidLowercase: false,
      isValidCharacter: false,
      isValidNumber: false,

      oldPassword: "",
      newPassword: "",
      rePassword: "",

      nikRules: [
        (v) => !!v || "Nik Tidak Boleh Kosong",
        // (v) => (v && v.length >= 10) | "Nik Harus 10 Karakter",
        // (v) => (v && v.length < 11) | "Nik Harus 10 Karakter",
      ],
      passwordRules: [(v) => !!v || "Password Tidak Boleh Kosong"],
    };
  },

  mounted() {
    
    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 13 && this.isAuthenticated == false) {
        this.handleLogin();
      }event.preventDefault()
    });
    this.$refs.loginForm;
  },

  computed: {
    ...mapGetters({
      getUser: "user",
      getNik:"nik",
      isAuthenticated: "isAuthenticated",
      currentTokenFirebase:"currentTokenFirebase",
    }),

    user: {
      get() {
        return this.getUser;
      },

      set(val) {
        this.setUser(val);
      },
    },

    checkBeforeSave() {
      return (
        !(
          this.isValidCharacter &&
          this.isValidLowercase &&
          this.isValidUppercase &&
          this.isValidNumber
        ) ||
        this.oldPassword == "" ||
        this.rePassword == "" ||
        !this.validasiRePassword
      );
    },
    validasiRePassword() {
      if (this.rePassword == "") {
        return null;
      }
      if (this.newPassword == "" && this.rePassword == "") {
        return false;
      } else if (this.newPassword != this.rePassword) {
        return false;
      } else {
        return true;
      }
    },
    validasiEmail() {
      if (this.newOfficeMail == "") {
        return null;
      }
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.newOfficeMail)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions({
      login: "login",
      setToken: "setToken",
      setUser: "setUser", 
      setNik: "setNik",
      logoutSession: "logoutSession",
      getAuthenticated:"getAuthenticated",
      getDataTokenFirebase:"getDataTokenFirebase",
      setTokenFirebase:"setTokenFirebase",
      setDataTokenFirebase:"setDataTokenFirebase",
      changePasswordApi: "changePasswordApi",
    }),

    handleLogin() {
      if (this.$refs.loginForm.validate()) {
        this.btnLoading = true;

        let loginfo = {
          Data:{
            nik: this.data.nik,
            pass: this.data.password
          }
        }
        
        // console.log(loginfo);
        
        this.login(this.data)
        .then((res) => {
          // console.log(res)
          const number = res.random
          let loginNik = 
          {nik:  this.data.nik}

          if (res.Status == "1") {
            
            this.setToken(true)
            sessionStorage.setItem('nik', this.data.nik)
            // this.getFirebase()
            this.$router.push({ name: "HomePage" });
            this.btnLoading = false;
          } else {
            if (res.Result == "gagal" || res.Result == "Unauthorized !!") {
               this.$swal({
              icon: "error",
              title: "Gagal",
              text: `Username atau Password yang Anda Masukkan Salah, Silahkan Coba Lagi!`,
            });
            this.btnLoading = false;

            }else if(res.Result == "Password sudah tidak berlaku. Silakan perbaharui password Anda di Web ESS"){
              this.$swal({
              icon: "error",
              title: "Gagal",
              html: `${res.Result}`,
            }).then(()=>{
              this.btnLoading = false
            this.dialogRenewPass = true
            })

            }else{
              this.$swal({
              icon: "error",
              title: "Gagal",
              html: `${res.Result}`,
            });
            this.btnLoading = false;
            }
           
          }

        }).catch((err) => {
          this.btnLoading = false;
          
          this.logoutSession();
          const errMsg = err.response.message;
          this.$swal("Oops...", errMsg, "error");
        });
      }
    },


    getFirebase(){
      
      let data = {
        Data:{
        code : "2",
        username : this.data.nik
        }
      }
      
      this.getDataTokenFirebase(data)
      .then(res=>{
        let data = res.Result;
        // console.log(data)

        let token_firebaseUser= data

        var getToken = []

        var isMatched = false;
      
        //kalau key Result ada isinya
        if(token_firebaseUser.length > 0)
        {
          for (var i=0; i<token_firebaseUser.length; i++){
          
            let tokenFb = token_firebaseUser[i].TOKEN;

            this.dataTokenFB = tokenFb

            if(this.currentTokenFirebase == tokenFb){
              store.dispatch('setTokenFirebase', this.dataTokenFB)
              isMatched = true;
              //Dipake Jika ada Data Yang di Compare
              break;
            }
          }

          if(!isMatched){ this.setFirebase();
          }

        } else {
          this.setFirebase()

        }
          return getToken;

      }).catch(err =>{
        console.log(err);
      })
    },

    setFirebase(){
        let data = {
        Data:{
        code : "1",
        username : this.data.nik,
        token : this.currentTokenFirebase
        }
    }

      this.setDataTokenFirebase(data)
      .then(response =>{
        let data = response
      }).catch(err =>{
        console.log(err);
      })
    },

    onInputNewPass(val) {
      // console.log(val);
      this.passwordChecklist = [];
      this.passwordError = "";

      // Check if the password has at least one uppercase character
      if (/[A-Z]/.test(val)) {
        this.isValidUppercase = true;
      } else {
        this.isValidUppercase = false;
      }

      // Check if the password has at least one lowercase character
      if (/[a-z]/.test(val)) {
        this.isValidLowercase = true;
      } else {
        this.isValidLowercase = false;
      }

      // Check if the password has at least one number
      if (/\d/.test(val)) {
        this.isValidNumber = true;
      } else {
        this.isValidNumber = false;
      }

      // Check if the password meets the minimum length of 8 characters
      if (val.length >= 8) {
        this.isValidCharacter = true;
      } else {
        this.isValidCharacter = false;
      }
    },

    validateData() {
      if (this.oldPassword == "") {
        return "Old Password kosong";
      } else if (this.newPassword == "") {
        return "New Password kosong";
      } else if (this.rePassword == "") {
        return "Re-Input New Password kosong";
      } else if (this.newPassword != this.rePassword) {
        return "New Password dan Re-Input New Password harus sama";
      } else {
        return "valid";
      }
    },

    async changePassword() {
      // console.log('change trigger?')
      let validate = this.validateData();
      let payload = {
        Data: {
          code: "201",
          parm: {
            nik: this.data.nik,
            parmPass: {
              oldPass: this.oldPassword,
              newPass: this.newPassword,
            },
          }
        }
      }

      if (validate != "valid") {
        // console.log('kevalid?')
        this.$swal({
              icon: "error",
              title: "Gagal",
              html: `${validate}`
            });
            this.btnLoading = false;
      } else {
        this.btnLoading = true;
        
        this.changePasswordApi(payload)
        .then(res =>{
          console.log(res)
          let code = res.Code
          let result = res.Result.result

          if(code == '200'){
            // console.log('success lho')
            this.$swal({
            icon: "success",
            title: "Berhasil",
            html: `Berhasil ubah password!`
          }).then(()=>{
            this.btnLoading = false
            window.location.reload()
          })
          }else{
            // console.log('201 tp ggl')
            this.$swal({
            icon: "error",
            title: "Gagal",
            html: `${result}`
          })
          this.btnLoading = false
          }
          
        }).catch(err =>{
          // console.log('masuk error')
          this.btnLoading = false
          this.$swal({
            icon: "error",
            title: "Gagal",
            html: `${err.data.result}`
          })
        })
        
      }
    },

    reload() {
      setTimeout(() => window.location.reload());
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    }

  },
};
</script>
