"use strict";

import axios from "axios";

const PREFIX = process.env.VUE_APP_API_PRESENSI;

export default {
    getPresensi(data) {
        return axios.post(`${PREFIX}`, data, {
            withCredentials: true,
        });
    },
    getBawahan(data) {
        return axios.post(`${PREFIX}`, data, {
            withCredentials: true,
        });
    },
    // approveRejectIzin(data) {
    //     return axios.post(`${PREFIX}/put/Stag`, data, {
    //         withCredentials: true
    //     })
    // },
    // insertIzin(data) {
    //     return axios.post(`${PREFIX}/post/Stag`, data, {
    //         withCredentials: true
    //     })
    // },
};