"use strict"

import axios from "axios"

const PREFIX = process.env.VUE_APP_API_SERAGAM

export default {
    getTransaksi(data) {
        return axios.post(`${PREFIX}`, data, {
            withCredentials: true
        })
    },

}