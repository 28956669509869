<template>
<div>
    <c-toolbar />
    <c-navbar />

    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-11 py-4 px-2">
              <v-row justify="center">
                <v-img src="img/indomaret.png" aspect-ratio="3" max-width="220" position="end"></v-img>
                <!-- <v-col cols="4">
                </v-col>-->
              </v-row>

              <form class="pa-4">
                <div class="my-2">
                  <v-row justify="center">
                    <v-col cols="12" sm="4">
                    </v-col>

  <v-data-table
      :headers="headers"
      :items="desserts"
      class="elevation-1"
    >
      <template v-slot:item.izin="{ item }">
        <v-simple-checkbox
          v-model="item.approve"
          
        ></v-simple-checkbox>
      </template>
    </v-data-table>

    <v-btn 
    color="primary"
    block
    @click="approveIzin">Approve</v-btn>
    <v-btn 
    color="primary"
    block
    @click="rejectIzin">Reject</v-btn>

                 </v-row>
                </div>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</div>
</template>

<script>
import { mapGetters,mapActions} from 'vuex'; 
import CNavbar from '../../components/CNavbar.vue'
import CToolbar from '../../components/CToolbar.vue'
export default {
    components: { CToolbar, CNavbar },
    
    data() {
        return {
            
        }
    },
}
</script>