<template>
  <div class="homepage">
    <c-toolbar />
    <c-navbar />
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row class="justify-center" align="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-11 py-4 px-2">
              <v-row class="justify-left">
                <v-img src="img/indomaret.png" aspect-ratio="3" max-width="220" position="end"></v-img>
                <!-- <v-col cols="4">
                  </v-col>-->
              </v-row>

              <!-- <v-row align="center" justify="center">
    <v-col cols="12" sm="12">
    <h4 >Selamat Datang <span> {{  name  }}
      </span>
    </h4>
    </v-col>
    </v-row> -->

              <v-row class="justify-center">
                <v-alert dismissible type="info" v-show="this.NotifIzin != 'Skipped'">
                  {{ this.NotifIzin }}
                </v-alert></v-row>

              <v-row align="center" justify="center">
                <v-col cols="12" sm="8">

                  <v-simple-table fixed-header dense class="fill-height">
                    <template v-slot:default>
                      <thead>
                        <tr>

                          <h3>Selamat Datang
                            <span> {{ data.NamaKaryawan }}
                            </span>
                          </h3>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Nama</td>
                          <td>:</td>
                          <td>{{ data.NamaKaryawan }}</td>
                        </tr>

                        <tr>
                          <td>Nik</td>
                          <td>:</td>
                          <td>{{ data.NikKaryawan }}</td>
                        </tr>

                        <tr>
                          <td>Nomor Hp Karyawan</td>
                          <td>:</td>
                          <td>{{ data.NomorHandphone }}</td>
                        </tr>

                        <tr>
                          <td>Email</td>
                          <td>:</td>
                          <td>{{ data.EmailKaryawan }}</td>
                        </tr>

                        <tr>
                          <td>KTP</td>
                          <td>:</td>
                          <td>{{ data.NoKTP }}</td>
                        </tr>

                        <tr>
                          <td>Alamat KTP</td>
                          <td>:</td>
                          <td>{{ data.AlamatKTP }}</td>
                        </tr>

                        <tr>
                          <td>Alamat Domisili</td>
                          <td>:</td>
                          <td>{{ data.AlamatDomisili }}</td>
                        </tr>

                        <tr>
                          <td>NPWP</td>
                          <td>:</td>
                          <td>{{ data.NoNPWP }}</td>
                        </tr>

                        <tr>
                          <td>BPJS TenagaKerja</td>
                          <td>:</td>
                          <td>{{ data.BPJSTenagaKerja }}</td>
                        </tr>

                        <tr>
                          <td>BPJS Kesehatan</td>
                          <td>:</td>
                          <td>{{ data.BPJSKesehatan }}</td>
                        </tr>

                        <tr>
                          <td>Status Nikah</td>
                          <td>:</td>
                          <td>{{ data.StatusNikah }}</td>
                        </tr>

                        <tr>
                          <td>JobClass</td>
                          <td>:</td>
                          <td>{{ data.JobClass }}</td>
                        </tr>

                        <tr>
                          <td>Status Kerja</td>
                          <td>:</td>
                          <td>{{ data.StatusKerja }}</td>
                        </tr>

                        <tr>
                          <td>Tanggal Tetap</td>
                          <td>:</td>
                          <td>{{ data.TanggalDiangkatTetap }}</td>
                        </tr>

                        <tr>
                          <td>Tanggal Masuk Kerja</td>
                          <td>:</td>
                          <td>{{ data.TanggalMasukKerja }}</td>
                        </tr>

                        <tr>
                          <td>ID_Telegram</td>
                          <td>:</td>
                          <td>{{ data.ID_Telegram }}</td>
                        </tr>

                        <tr>
                          <td>Pajak Tahun Berjalan</td>
                          <td>:</td>
                          <td>{{ data.PajakTahunBerjalan }} <span class="caption red--text">&nbsp; *status pajak untuk
                              tahun {{ new Date().getFullYear() + 1 }}, update melalui pengkinian data bila ada perubahan
                              status pada tgl 1 Jan {{ new Date().getFullYear() }} s/d 31 Des {{ new
                              Date().getFullYear() }}</span>
                          </td>
                        </tr>

                        <tr>
                          <td>Pajak Tahun Sebelumnya</td>
                          <td>:</td>
                          <td>{{ data.PajakTahunSebelumnya }}</td>
                        </tr>

                        <tr>
                          <td>Hari Kerja</td>
                          <td>:</td>
                          <td>{{ data.HariKerja }}</td>
                        </tr>

                        <tr>
                          <td>Unit</td>
                          <td>:</td>
                          <td>{{ data.PT }}</td>
                        </tr>

                        <tr>
                          <td>Lokasi</td>
                          <td>:</td>
                          <td>{{ data.Lokasi }}</td>
                        </tr>

                        <tr>
                          <td>Direktorat</td>
                          <td>:</td>
                          <td>{{ data.Direktorat }}</td>
                        </tr>

                        <tr>
                          <td>Divisi</td>
                          <td>:</td>
                          <td>{{ data.GrandParent_Organization }}</td>
                        </tr>

                        <tr>
                          <td>Departemen</td>
                          <td>:</td>
                          <td>{{ data.Departemen }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <!-- <v-col cols="12" sm="6">
                <div>

                  <span> NikAtasan: {{ nik_atasan }} </span>

                </div>
              </v-col>

              <v-col cols="12" sm="6">
                <div>

                  <span> NamaAtasan: {{ nama_atasan }} </span>

                </div>
              </v-col>

              <v-col cols="12" sm="6">
                <div>
                  <span> NomorHpAtasan: {{ phoneNumber_atasan }} </span>
                </div>
              </v-col> -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import CNavbar from '../components/CNavbar.vue';
import CToolbar from '../components/CToolbar.vue';

export default {
  name: "HomePage",
  components: {
    CToolbar,
    CNavbar,
  },

  data: () => ({
    data: {},
    token_firebaseUser: "",
    NotifIzin: "",
    NotifKosong: "",
    MsgArray: [],
    // arrayToken:[this.dataGetTokenFirebase],

  }),

  mounted() {
    this.loadData()
    this.NotifIzin = this.user.data[0].NotifIzin
    this.loadInfo()
    // this.setFirebse()
  },

  computed: {
    ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      isUser: "isUser",
      currentTokenFirebase: "currentTokenFirebase",
      dataGetTokenFirebase: "dataGetTokenFirebase"
    }),
  },

  methods: {

    ...mapActions({
      getTransaksi: "getTransaksi",
      // setTokenFirebase:"setTokenFirebase",
      // getTokenFirebase:"getTokenFirebase"
    }),

    renameField() {
      if (this.user.status == 'Kosong') {
        // console.log('Masuk Ga ya?')

        if (this.user.data[0].NikKaryawan == null) {
          this.MsgArray.push("NIK")
        }

        if (this.user.data[0].NamaKaryawan == null) {
          this.MsgArray.push("Nama")
        }

        if (this.user.data[0].NomorHandphone == null) {
          this.MsgArray.push("Handphone")
        }

        if (this.user.data[0].NikAtasan == null) {
          this.MsgArray.push("Nik Atasan")
        }

        if (this.user.data[0].NamaAtasan == null) {
          this.MsgArray.push("Nama Atasan")
        }

        if (this.user.data[0].NomorHpAtasan == null) {
          this.MsgArray.push("Nomor Handphone Atasan")
        }

        if (this.user.data[0].Lokasi == null) {
          this.MsgArray.push("Lokasi")
        }

        if (this.user.data[0].PT == null) {
          this.MsgArray.push("PT")
        }

        if (this.user.data[0].Parent_Organization == null) {
          this.MsgArray.push("Direktorat")
        }

        if (this.user.data[0].Parent_Organization_Number == null) {
          this.MsgArray.push("ID Direktorat")
        }

        if (this.user.data[0].GrandParent_Organization_Number == null) {
          this.MsgArray.push("ID Divisi")
        }

        if (this.user.data[0].GrandParent_Organization == null) {
          this.MsgArray.push("Divisi")
        }

        if (this.user.data[0].Organization == null) {
          this.MsgArray.push("Departemen")
        }

        if (this.user.data[0].Organization_Number == null) {
          this.MsgArray.push("ID Departemen")
        }

        if (this.user.data[0].EmailKaryawan == null) {
          this.MsgArray.push("Email Karyawan")
        }

        if (this.user.data[0].EmailAtasan == null) {
          this.MsgArray.push("Email Atasan")
        }

        if (this.user.data[0].TanggalBerhenti == null) {
          this.MsgArray.push("Tanggal Berhenti")
        }

        if (this.user.data[0].NoNPWP == null) {
          this.MsgArray.push("Nomor NPWP")
        }

        if (this.user.data[0].BPJSTenagaKerja == null) {
          this.MsgArray.push("BPJS Tenaga Kerja")
        }

        if (this.user.data[0].BPJSKesehatan == null) {
          this.MsgArray.push("BPJS Kesehatan")
        }

        if (this.user.data[0].StatusNikah == null) {
          this.MsgArray.push("Status Nikah")
        }

        if (this.user.data[0].AlamatKTP == null) {
          this.MsgArray.push("Alamat KTP")
        }

        if (this.user.data[0].AlamatDomisili == null) {
          this.MsgArray.push("Alamat Domisili")
        }

        if (this.user.data[0].ID_Telegram == null) {
          this.MsgArray.push("ID Telegram")
        }

        if (this.user.data[0].HariKerja == null) {
          this.MsgArray.push("Hari Kerja")
        }

        if (this.user.data[0].PajakTahunBerjalan == null) {
          this.MsgArray.push("Pajak Tahun Berjalan")
        }

        if (this.user.data[0].PajakTahunSebelumnya == null) {
          this.MsgArray.push("Pajak Tahun Sebelumnya")
        }

        if (this.user.data[0].TanggalDiangkatTetap == null) {
          this.MsgArray.push("Tanggal Tetap")
        }

        if (this.user.data[0].TanggalMasukKerja == null) {
          this.MsgArray.push("Tanggal Masuk")
        }

        if (this.user.data[0].StatusKerja == null) {
          this.MsgArray.push("Status Kerja")
        }

        if (this.user.data[0].NoKTP == null) {
          this.MsgArray.push("Nomor KTP")
        }

        if (this.user.data[0].Golongan_Darah == "") {
          this.MsgArray.push("Golongan Darah")
        }

        if (this.user.data[0].JobClass == null) {
          this.MsgArray.push("Job Class")
        }

        if (this.user.data[0].ID_PT == null) {
          this.MsgArray.push("ID PT")
        }

        if (this.user.data[0].ID_Lokasi == null) {
          this.MsgArray.push("ID Lokasi")
        }

        if (this.user.data[0].ID_Jabatan == null) {
          this.MsgArray.push("ID Jabatan")
        }

        if (this.user.data[0].Jabatan == null) {
          this.MsgArray.push("Jabatan")
        }

        if (this.user.data[0].JENIS_KELAMIN == null) {
          this.MsgArray.push("Jenis Kelamin")
        }

        if (this.user.data[0].AGAMA == null) {
          this.MsgArray.push("Agama")
        }

        if (this.user.data[0].TanggalHpAtasanTerdaftar == null) {
          this.MsgArray.push("Tanggal Hanphone Atasan Terdaftar")
        }

        if (this.user.data[0].TanggalHandphoneTerdaftar == null) {
          this.MsgArray.push("Tanggal Handphone Terdaftar")
        }

        return this.NotifKosong = `Data anda berikut ini: ${this.MsgArray.join(', ')} kosong`;

        // console.log(this.NotifKosong)

      } else {
        // console.log("Masuk else mase")
      }

    },

    loadInfo() {
      // console.log(this.user.data[0].NikKaryawan);
      const code = {
        code: 4,
        data: {
          nik: this.user.data[0].NikKaryawan
        }
      };
      this.getTransaksi(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // console.log(this.user.data[0].NotifIzin);
          this.renameField()
          if (this.user.status == 'Kosong' && result.message[0].jumlah > 0) {
            // this.btnLoadingNotif = false
            this.$swal({
              icon: "info",
              title: "Perhatian!",
              html: `<span>${this.NotifKosong}</span> <br/> Anda memiliki ${result.message[0].jumlah} pendingan penerimaan seragam`
            })
          }
          else if (this.user.status == 'Kosong') {
            this.$swal({
              icon: "info",
              title: "Perhatian!",
              html: `<span>${this.NotifKosong}</span>`
            })
          }
          else if (result.message[0].jumlah > 0) {
            this.$swal({
              icon: "info",
              title: "Perhatian!",
              html: `<span>Anda memiliki ${result.message[0].jumlah} pendingan penerimaan seragam`,
            })
          } else if (result.Message == 'Token Required !!!!' && result.Code == 400) {
            this.$router.push({ name: "Login" });
            setTimeout(() =>
              window.location.reload(),

            )
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            this.btnLoadingNotif = false
            // this.snackbar=true;
          }
        });
      // console.log(this.user)
      // if(this.user.status == 'Kosong'){
      //   this.$swal({
      //     icon: "info",
      //     title: "Perhatian!",
      //     html: `<span>${this.user.message}</span>`
      //   })
      // }
    },

    loadData() {
      this.data = Object.assign({}, this.user.data[0])
      // console.log(this.user)
      // if(this.user.status == "Kosong") {
      //   this.$swal({
      //     icon: "info",
      //     title: "Perhatian!",
      //     html: `<span>${this.user.message}</span>`,
      //   });
      // }
    },

    //  getFirebase(){
    //   let data = {
    //     Data:{
    //     code : "2",
    //     username : this.user.data[0].NikKaryawan
    //     }
    //   }

    //   this.getTokenFirebase(data)
    //   .then(res=>{
    //     let data = res.Result;
    //     console.log(data)
    //     let tokenGetAPI = data.TOKEN
    //     // store.dispatch("setGetTokenFirebase",token_firebaseUser)
    //     this.token_firebaseUser = tokenGetAPI

    //     // if (data.length == 0){
    //     //   alert("Belum ada data yang ditampilkan");
    //     // }else{

    //     // }

    //   }).catch(err =>{
    //     console.log(err);
    //   })
    // },

    // setFirebse(){
    //   // Pertama Kali Allow Token
    //   //firebaseUserToken adalah token dalam API
    // if(this.firebaseUserToken != null){
    //   let data = {
    //     Data:{
    //     code : "1",
    //     username : this.user.data[0].NikKaryawan,
    //     token : this.currentTokenFirebase
    //     }
    // }

    //   this.setTokenFirebase(data)
    //   .then(response =>{
    //     let dat = response
    //   }).catch(err =>{
    //     console.log(err);
    //   })
    // }
    //   //Untuk Beda Browser
    //   //currentTokenFirebase adalah Token Dalam Store prosedure
    //   else if (this.firebaseUserToken != this.currentTokenFirebase){
    //   let data = {
    //     Data:{
    //     code : "1",
    //     username : this.user.data[0].NikKaryawan,
    //     token : this.currentTokenFirebase
    //     }
    // }

    //   this.setTokenFirebase(data)
    //   .then(response =>{
    //     let dat = response
    //   }).catch(err =>{
    //     console.log(err);
    //   })
    // }else if(this.firebaseUserToken == null){
    //   this.getFirebase()
    // }

    // },

  },

}
</script>
