"use strict"

import pengkinianApi from "@/api/alamat.js"
import store from "../index"

export default {

	actions: {
		cekStatusApi(context, payload) {
			return new Promise((resolve, reject) => {
				let token = store.getters.token
				// console.log(payload)
				pengkinianApi.pengkinianAlamat(payload,token)
				.then(response => {
					// console.log(response)
					const data = response.data

					resolve(data)
				}).catch(err => {
					reject(err)
				})
			})
		},
			getDetailApi(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
						// console.log(response)
						const data = response.data

						resolve(data)


					}).catch(err => {
						reject(err)
					})
				})
			},
			getAlamatApi(context, payload) {
					return new Promise((resolve, reject) => {
							let token = store.getters.token
							pengkinianApi.pengkinianAlamat(payload, token)
							.then(response => {
									// console.log(response)
									const data = response.data

									resolve(data)

							}).catch(err => {
									reject(err)
							})
					})
			},
			getProvinsi(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
						// console.log(response)
						const data = response.data

						resolve(data)

					}).catch(err => {
						reject(err)
					})
				})
			},
			getKota(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
							// console.log(response)
							const data = response.data

							resolve(data)

					}).catch(err => {
							reject(err)
					})
				})
			},
			getKotaChange(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
						// console.log(response)
						const data = response.data

						resolve(data)

					}).catch(err => {
						reject(err)
					})
				})
			},
			getKecamatan(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
						// console.log(response)
						const data = response.data

						resolve(data)

					}).catch(err => {
						reject(err)
					})
				})
			},
			getKecamatanChange(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
							// console.log(response)
							const data = response.data

							resolve(data)

					}).catch(err => {
							reject(err)
					})
				})
			},
			getKelurahan(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
						// console.log(response)
						const data = response.data

						resolve(data)

					}).catch(err => {
						reject(err)
					})
				})
			},
			getKelurahanChange(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
							// console.log(response)
						const data = response.data

						resolve(data)

					}).catch(err => {
						reject(err)
					})
				})
			},
			getProvinsiDomisili(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
							// console.log(response)
						const data = response.data

						resolve(data)

					}).catch(err => {
						reject(err)
					})
				})
			},
			getKotaDomisli(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
						// console.log(response)
						const data = response.data
						
						resolve(data)
					}).catch(err => {
						reject(err)
					})
				})
			},
			getKotaDomisliChange(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
							// console.log(response)
						const data = response.data

						resolve(data)
					}).catch(err => {
						reject(err)
					})
				})
			},
			getKecamatanDomisili(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
				// 	console.log(response)
						const data = response.data

						resolve(data)
					}).catch(err => {
						reject(err)
					})
				})
			},
			getKecamatanDomisiliChange(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
							// console.log(response)
						const data = response.data

						resolve(data)

					}).catch(err => {
						reject(err)
					})
				})
			},
			getKelurahanDomisili(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
							// console.log(response)
						const data = response.data

						resolve(data)
					}).catch(err => {
							reject(err)
					})
				})
			},
			getKelurahanDomisiliChange(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
							// console.log(response)
						const data = response.data

						resolve(data)

					}).catch(err => {
						reject(err)
					})
				})
			},
			getStatusKepemilikan(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
				// 	console.log(response)
						const data = response.data

						resolve(data)

					}).catch(err => {
						reject(err)
					})
				})
			},
			saveAlamatKTPDomisili(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
						// console.log(response)
						const data = response.data

						resolve(data)

					}).catch(err => {
						reject(err)
					})
				})
			},
			saveAlamatKTP(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
						// console.log(response)
						const data = response.data

						resolve(data)

					}).catch(err => {
						reject(err)
					})
				})
			},
			saveAlamatDomisili(context, payload) {
				return new Promise((resolve, reject) => {
					let token = store.getters.token
					pengkinianApi.pengkinianAlamat(payload, token)
					.then(response => {
						// console.log(response)
						const data = response.data

						resolve(data)
					}).catch(err => {
						reject(err)
					})
				})
			},
	}
}