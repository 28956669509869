"use strict"

import axios from "axios"

const GET = process.env.VUE_APP_API_IZIN_GET
const PUT = process.env.VUE_APP_API_IZIN_PUT
const POST = process.env.VUE_APP_API_IZIN_POST

export default {
    getIzin(data) {
        return axios.post(`${GET}`, data, {
            withCredentials: true
        })
    },
    approveRejectIzin(data) {
        return axios.post(`${PUT}`, data, {
            withCredentials: true
        })
    },
    insertIzin(data) {
        return axios.post(`${POST}`, data, {
            withCredentials: true
        })
    },

}