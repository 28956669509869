"use strict"

import axios from "axios"

const SUBMIT = process.env.VUE_APP_API_KPR_SUBMIT
const GET_KARYAWAN = process.env.VUE_APP_API_KPR_GET_KARYAWAN
const GET_ATASAN = process.env.VUE_APP_API_KPR_GET_ATASAN

export default {
    submitKPR(data) {
        return axios.post(`${SUBMIT}`, data, {
            withCredentials: true,
        })
    },
    getKaryawan(data) {
        return axios.post(`${GET_KARYAWAN}`, data, {
            withCredentials: true
        })
    },
    getAtasan(data) {
        return axios.post(`${GET_ATASAN}`, data, { 
            withCredentials: true 
        })
    },

}