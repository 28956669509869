import CryptoJS from 'crypto-js';
import SecureLS from 'secure-ls';
import Cookies from 'js-cookie';

const ls = new SecureLS({ encodingType: "aes" })

export function encrypt(payload) {

    const config = Cookies.get(process.env.VUE_APP_ENCRYPT_COOKIE);
    const length = 32;

    const convertedKey = CryptoJS.SHA256(config).toString().substring(0, length);
    let iv = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.enc.Utf8.parse(convertedKey);
    let options = {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(payload), key, options);
    const encryptedValue = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    iv = CryptoJS.enc.Base64.stringify(iv);
    const hash = CryptoJS.HmacSHA256(iv + encryptedValue, key);
    const hashHex = hash.toString(CryptoJS.enc.Hex);
    let result = {
        iv: iv,
        value: encryptedValue,
        mac: hashHex
    }
    var encode = btoa(JSON.stringify(result));

    return { payload: encode };

}
export function decrypt(stringEncrypt) {

    const config = Cookies.get(process.env.VUE_APP_ENCRYPT_COOKIE);
    const length = 32;

    const convertedKey = CryptoJS.SHA256(config).toString().substring(0, length);
    var key = CryptoJS.enc.Utf8.parse(convertedKey);
    var encrypted_json2 = JSON.parse(atob(stringEncrypt));
    var decrypted2 = CryptoJS.AES.decrypt(encrypted_json2.value, key, {
        iv: CryptoJS.enc.Base64.parse(encrypted_json2.iv)
    });
    var decryptedString = CryptoJS.enc.Utf8.stringify(decrypted2);
    var decryptedJson = JSON.parse(decryptedString);
    return decryptedJson;
}
