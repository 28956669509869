<template>
  <v-app>
    <!-- <v-snackbar
      v-model="warningZone"
      absolute
      
      centered
      color="blue"
      elevation="24"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        
      </template>
    </v-snackbar> -->

    <v-dialog v-model="warningZone" width="500">
      <v-card color="primary">
        <v-card-title class="text-h5 blue lighten-2">
          {{ text }}
        </v-card-title>

        <v-card-text class="text-h7 blue lighten-2">
          Web Akan Terlogout Otomatis Jika Tidak Ada Aktivitas
        </v-card-text>
      </v-card>
      <template v-slot:action="{}"> </template>
    </v-dialog>

    <router-view />

    <v-footer padless rounded class="-mt-15">
      <span class="black-- text" style="width: 100%;text-align: center">
        <h5>
          <v-icon small>mdi-copyright</v-icon> {{ new Date().getFullYear() }} —
          <strong>
            Software Development II, Version 1.3.3 &nbsp;
            <a href="https://indomaret.co.id/" target="_blank" style=" color:blue"
              >Indomaret Group</a
            >
            </strong>
        </h5>
        <!-- test push -->
      </span>
      <br>
      <span class="red-- text" style=" color:red;width: 100%;text-align: center">
        <h5>
          Browser Specification: ( Name : {{ $browserDetect.meta.name}} , Version : {{ $browserDetect.meta.version}} ).
      </h5>
      </span>
      
    </v-footer>
  </v-app>
</template>

<script>
import cookie from "@/utils/auth";
import { mapActions } from "vuex";
import store from "@/store";
import * as firebase from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export default {
  name: "App",

  data: () => ({
    token: "",
    events: ["click", "scroll", "mousemove", "mousedown", "keypress", "load"],

    warningTimer: null,
    logoutTimer: null,
    tokenTimer: null,
    text: "Tidak Ada Aktivitas Selama 15 Menit",
    warningZone: false,
  }),

  created() {
    window.addEventListener("beforeunload", this.logout());
    const messaging = getMessaging();
    getToken(messaging, {
      vapidKey:
        "BO9RpzCwKoiprlAg9cj5gGjM3YL2mKpv1QKPC5grJBAWjs0SjqWSVIzYEH9UacI1hCGeuADGL3hbquYDGJkLpR8",
    })
      .then((currentTokenFirebase) => {
        if (currentTokenFirebase) {
          // Send the token to your server and update the UI if necessary
          // ...
          console.log(currentTokenFirebase);
          store.dispatch("setTokenFireBase", currentTokenFirebase);
          this.receiveMessage();
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
    // try {
    //   firebase
    //     .messaging()
    //     .requestPermission()
    //     .then(() => {
    //       console.log("Notification permission granted");
    //       firebase
    //         .messaging()
    //         .getToken()
    //         .then((token) => {
    //           window.console.log("token ", token);
    //           this.receiveMessage();
    //         });
    //     })
    //     .catch((err) => {
    //       console.log("Unable to get token ", err);
    //     });
    // } catch (e) {
    //   console.log(e);
    // }
  },

  mounted() {
    this.token = store.getters.token;

    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimer);
    }, this);

    this.setTimers();
  },

  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer);
    }, this);

    this.resetTimer();
  },

  watch: {
    token(val) {
      // console.log(val)
      let route = this.$route.name;

      if (val === null && route !== "Login") {
        this.removeToken();

        // console.log("banana");

        // this.window.location.reload();
        this.$router.push({ name: "Login" });
      }
    },
  },

  methods: {
    ...mapActions({
      removeToken: "removeToken",
      removeUser: "removeUser",
      setTokenFirebase: "setTokenFirebase",
      logoutSession: "logoutSession",
    }),

    receiveMessage() {
      try {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
          console.log("Message received. ", payload);
          let notification = payload.notification;
          const notificationOptions = {
            body: notification.body,
            icon: "Indomaret.png",
          };
          navigator.serviceWorker.getRegistrations().then((registration) => {
            registration[0].showNotification(
              notification.title,
              notificationOptions
            );
          });
        });
      } catch (e) {
        console.log(e);
      }
    },

    setTimers() {
      if (this.$route.name !== "Login") {
        this.warningTimer = setTimeout(
          this.waningMessage,
          0.4 * 60 * 60 * 1000
        ); // before 30 minutes
        this.logoutTimer = setTimeout(this.logOutUser, 0.5 * 60 * 60 * 1000); // 30 minutes
        // this.tokenTimer = setTimeout(this.tokenExpired, 8 * 60 * 60 * 1000)

        this.warningZone = false;
      }
    },

    waningMessage() {
      this.warningZone = true;
    },

    logout() {
      if (
        String(window.performance.getEntriesByType("navigation")[0].type) !==
        "reload"
      ) {
        this.removeToken();
        this.removeUser();
        this.logoutSession();
      }
    },

    logOutUser() {
      this.removeToken();
      this.removeUser();
      this.logoutSession();
      // this.$router.push({ name: "Login" })
      this.warningZone = false;
      window.location.reload();
    },

    // tokenExpired(){
    //   this.removeToken()
    //   this.$router.push({name: "Login"})
    //   this.warningZone = false
    // },

    resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);

      this.setTimers();
    },

    // async setTimers() {

    //   this.warningTimer =  setTimeout(() => {
    //     this.warningMessage()
    //   },10 * 1000)

    // },

    // warningMessage() {
    //   this.$swal({
    //     icon: "error",
    //     title: "Session Expired",
    //   })
    //   .then(() => {

    //   this.$router.push({
    //     name: "Login",
    //   });
    //   })
    // },

    // resetTimer() {
    //   if (this.$route.name != "Login") {

    //     if (this.token === null) {
    //       setTimeout(() => {
    //         this.removeToken();
    //         // console.log("aaa");
    //         this.setTimers();
    //       }, 10 * 1000);
    //       // window.location.reload();
    //       this.$router.push({
    //       name: "Login",
    //     });
    //     }
    //   }
    // },
  },
};
</script>
