<template>
<div>
  
    <c-toolbar/>
    <c-navbar/>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-11 py-4 px-2"
            :loading="vloading">
            <template slot="progress">
            <v-progress-linear
            color="deep-purple"
            height="20"
            indeterminate
            ></v-progress-linear>
            </template>
            <span></span>
              <v-row justify="center">
                <v-img src="img/Indomaret.png" aspect-ratio="3" max-width="300" position="end"></v-img>
                <!-- <v-col cols="4">
                </v-col>-->
              </v-row>

              <h2 class="text-center my-4">Form Pengajuan KPR BTN</h2>
              

              <!-- <v-alert class="mx-2" type="info">
                
                <div>
                Sebagai bentuk dukungan kami untuk memutus mata rantai penularan Corona Virus Disease 2019 (COVID-19). 
                </div>
                <div>Kami mohon bantuan Bapak/Ibu yang akan bertamu ke Indomaret group (HO Kemayoran/Ancol/Menara PIK) untuk mengisi data diri dan menjawab pertanyaan - pertanyaan yang kami berikan.
                    
                </div>
              </v-alert> -->


              <v-form class="pa-4" >
                <div class="my-2">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <span><h3 bold> Input Pengajuan </h3> </span>
                    </v-col>
                  </v-row>

                  <v-row >
                    <v-col cols="12" sm="6">
                      <div>

                        <span> NIK :  <v-text-field v-model="nik"
                        solo
                        readonly>
                        </v-text-field>
                        </span>

                      </div>
                    </v-col>
                  

                  
                    <v-col cols="12" sm="6">

                      <span> Nama Karyawan: <v-text-field v-model="nama_kary"
                        solo
                        readonly>
                        </v-text-field>
                      </span>

                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">

                      <span> Status Kerja:<v-text-field v-model="statusKerja"
                        solo
                        readonly>
                        </v-text-field>
                      </span>

                      
                    </v-col>
                  
                    <v-col cols="12" sm="6">

                      <span> Tanggal Lahir: <v-text-field v-model="Tanggal_Lahir"
                        solo
                        readonly>
                        </v-text-field>
                      </span>

                      
                    </v-col>
                  </v-row>

                  <v-row>

                    <v-col cols="12" sm="6">
                      <div>

                        <span> Tanggal Tetap: <v-text-field v-model="Tanggal_diangkatTetap"
                        solo
                        readonly>
                        </v-text-field>
                      </span>

                      </div>
                    </v-col>

                    <v-col cols="12" sm="6">

                      <span> Unit: <v-text-field v-model="PT"
                        solo
                        readonly>
                        </v-text-field>
                      </span>

                
              </v-col>

              <v-col cols="12" sm="6" >

                <span> Departement: <v-text-field v-model="Departemen"
                        solo
                        readonly>
                        </v-text-field>
                      </span>

                

              </v-col>

              <v-col cols="12" sm="6">

                <span> Cabang: <v-text-field v-model="Cabang"
                        solo
                        readonly>
                        </v-text-field>
                      </span>
                      
                    </v-col>

                    <v-col cols="12" sm="6">

                <span> Jabatan: <v-text-field v-model="Jabatan"
                        solo
                        readonly>
                        </v-text-field>
                      </span>
                      
                    </v-col>

                    <v-col cols="12" sm="6">

                <span> Job Class: <v-text-field v-model="jobClass"
                        solo
                        readonly>
                        </v-text-field>
                      </span>
                      
                    </v-col>

                    <v-col cols="12" sm="6">

                      <span> Alamat KTP:<v-textarea v-model="alamatKtp"
                      auto-grow
                      readonly
                      solo
                        >
                        </v-textarea>
                      </span>
                      
                    </v-col>

                    <v-col cols="12" sm="6">

                    <span> Alamat Domisili: <v-textarea v-model="alamatDomisili"
                    auto-grow
                    readonly
                    solo
                        >
                        </v-textarea>
                      </span>
                      
                    </v-col>

                    <v-col cols="12" sm="6">

                <span> No. HP: <v-text-field v-model="phoneNumber"
                        solo
                        readonly>
                        </v-text-field>
                      </span>
                    </v-col>
                    

                    <v-col cols="12" sm="6">
                    <v-card-text>
                      <h2
                        class="subtitle-1 font-weight-regular text-justify"
                      >Pengajuan KPR</h2>

                      <v-radio-group v-model="dataPengajuan" row
                      :disabled="this.status == 'Failed' || this.nik_atasan == null && this.nama_atasan == null">
                        <!-- <v-radio
                          v-for="item in itemJawab"
                          :key="item.id"
                          :label="`${item.name}`"
                          :value="item.id"
                        ></v-radio> -->
                        <v-radio
                        label="Diri Sendiri"
                        value="1"
                        >

                        </v-radio>
                        <v-radio
                        label="Dengan Pasangan"
                        value="2"
                        :disabled="this.statusNikah =='Belum Menikah' || this.statusNikah =='Janda/Duda' ||this.statusNikah == 'Duda/Janda' ">

                        </v-radio>
                      </v-radio-group>
                    </v-card-text>

                    <h5 class="red--text">*Untuk Status Belum Menikah Maksimal Penghasilan 6 Juta</h5> <br>
                    <h5 class="red--text"> Untuk Status Menikah Maksimal Penghasilan 8 Juta</h5>
                    

                    </v-col>
                    
                    <v-col cols="12" sm="6"  v-show="dataPengajuan != ''">

                    <span v-show="dataPengajuan != ''"> Input Jumlah Penghasilan Karyawan (Gaji yang diterima melalui transfer +i.saku) : <v-text-field v-model="tunjanganKaryawan"
                        
                        :rules="dataPengajuan == '1' &&['Belum Menikah','Duda/Janda','Janda/Duda'].includes(statusNikah)  ? pasanganRulesIsSendiri : pasanganRulesIsPasangan"
                        v-on:keypress="isNumber($event)"
                        label="Rp."
                        v-show="dataPengajuan != '' "> 
                        </v-text-field>
                      </span>
                      
                    </v-col>

                    <v-col cols="12" sm="6" v-show="dataPengajuan == '2'">

                    <span  v-show="dataPengajuan == '2'"> Input Jumlah Penghasilan Pasangan (Gaji + Tunjangan) : <v-text-field v-model="tunjanganPasangan"
                        
                        :rules="pasanganRulesIsPasangan"
                        v-on:keypress="isNumber($event)"
                        v-show="dataPengajuan == '2'"
                        label="Rp.">
                        </v-text-field>
                      </span>
                      
                    </v-col>

                    <v-col cols="12" sm="6">
                      <span>Jangka Waktu KPR(Bulan)</span>
                      <v-select
                      v-model="lamaKPR"
                      :items="bulanKPR"
                      :disabled="this.status == 'Failed' || this.nik_atasan == null && this.nama_atasan == null"
                      dense
                      outlined
                    ></v-select>

                    <h5 class="red--text">*
                    1. File Upload Tidak boleh Lebih dari 1 MB dan Berformat Gambar (JPEG,JPG,PNG)!</h5><br/>
                    <h5 class="red--text">
                    2. Slip Penghasilan Berupa Printscreen / Screenshoot yang berasal dari Slip Penghasilan yang diberikan payroll!</h5><br/>
                    <h5 class="red--text">
                    3. Mohon Pastikan Gambar Terlihat Jelas dan Tidak Corrupt!</h5>

                      </v-col>

                    <v-col cols="12" sm="6">
                      <v-btn 
                      :href="this.$baseurl + `kpr/getSuratPernyataanKPR?nik=${nik}&namaktp=${nama_kary}&nomorktp=${nomorktp}&alamatktp=${alamatKtp}`" download>
                        Download Surat Pernyataan
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6">

                      <h5 class="red--text">* Untuk Scan Document / Foto Mohon Menggunakan Applikasi CAMSCANNER! (Download Applikasi Di Playstore)</h5>

                  <v-file-input
                  v-model="suratPernyataan"
                  :rules="rules"
                  :disabled="dataPengajuan == ''"
                  ref="fileInput"
                  show-size
                  counter
                  accept="image/jpeg, image/png, image/jpg"
                  label="Upload Surat Pernyataan" 
                ></v-file-input>

                <h5 class="red--text">* File Upload Surat Pernyataan Belum Memiliki Rumah Berformat Gambar (JPEG,JPG,PNG)!</h5>

                    </v-col>

                    <v-col cols="12" sm="6" v-show="dataPengajuan != ''">

                  <v-file-input
                  v-show="dataPengajuan != ''"
                  :rules="rules"
                  :disabled="dataPengajuan == ''"
                  show-size
                  counter
                  
                  v-model="slipGajiKaryawan"
                  accept="image/jpeg, image/png, image/jpg"
                  label="Upload Slip Gaji Karyawan" 
                ></v-file-input>

                <h5 class="red--text">* File Upload Slip Penghasilan Karyawan Berformat Gambar (JPEG,JPG,PNG)!</h5>

                    </v-col>

                    <v-col cols="12" sm="6" v-show="dataPengajuan == '2'">

                  <v-file-input
                  v-show="dataPengajuan == '2'"
                  :rules="rules"
                  :disabled="dataPengajuan == ''"
                  show-size
                  counter
                  
                  v-model="slipGajiPasangan"
                  accept= "image/jpeg, image/png, image/jpg"
                  label="Upload Slip Gaji Pasangan" 
                ></v-file-input>

                 <h5 class="red--text">* File Upload Slip Penghasilan Pasangan Berformat Gambar (JPEG,JPG,PNG)!</h5>
                 
                    </v-col>

                <v-col cols="12" sm="6">

                          <span> Atasan ESS: <v-text-field v-model="Atasan"
                        solo
                        readonly>
                        </v-text-field>
                      </span>
                              
                              </v-col>

                  <v-col cols="12" sm="6">
                    <span>Atasan Aproval 1</span>
                      <v-autocomplete
                      v-model="approval1"
                      :items="atasanApproval1"
                      item-text="APPROVAL1"
                      item-value="APPROVAL1"
                      dense
                      label="Silahkan Pilih Atasan"
                      :disabled="this.status == 'Failed'|| this.nik_atasan == null && this.nama_atasan == null"
                      outlined
                      @change="getAtasanApproval2Data"
                    ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <span>Atasan Aproval 2</span>
                      <v-autocomplete
                      v-model="approval2"
                      :items="atasanApproval2"
                      item-text="APPROVAL2"
                      item-value="APPROVAL2"
                      label="Silahkan Pilih Atasan"
                      dense
                      :disabled="this.status == 'Failed'||this.nik_atasan == null && this.nama_atasan == null"
                      outlined
                      @change="getAtasanApproval3Data"
                    ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" v-show="validateAtasan3">
                        <span v-show="validateAtasan3">Atasan Aproval 3</span>
                      <v-autocomplete
                      v-model="approval3"
                      :items="atasanApproval3"
                      item-text="APPROVAL3"
                      item-value="APPROVAL3"
                      dense
                      label="Silahkan Pilih Atasan"
                      :disabled="this.status == 'Failed'&& this.validateAtasan3 || this.nik_atasan == null && this.nama_atasan == null"
                      v-show="validateAtasan3"
                      outlined
                    ></v-autocomplete>
                      </v-col>

                </v-row>
    

          <v-row justify="center">
            <v-col cols="12" sm="6">
          <v-btn @click="submitDataKPRData" color="primary"
          
          block
          :loading="loading"
          :disabled="this.status == 'Failed' || this.nik_atasan == null && this.nama_atasan == null"
          v-show="validateInput && validateInputPasangan && validateInputAtasan"
            >Submit</v-btn>
          </v-col>
            </v-row>
            

                </div>
              </v-form>
              
              
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</div>
</template>

<script>
import axios from "axios";
import { required, maxLength } from "vuelidate/lib/validators"
import { validationMixin } from "vuelidate";
import CToolbar from "@/components/CToolbar.vue";

import { mapActions, mapGetters } from "vuex"
import jwt_decode from "jwt-decode"
import moment from 'moment'
import CNavbar from '../../components/CNavbar.vue';

export default {
    name: "PengajuanKprBtn",
  components: {
    CNavbar,
    CToolbar
    
    
  },


    data() {

        
        return {

        rules: [
        v => !!v ||  'File Harus Di isi',
        v => !v || v.size <= 1000000 || 'Data Tidak Boleh Lebih Dari 1 MB!',
        v => !v || v.type == 'image/jpeg'||v.type == 'image/png' || v.type == 'image/bmp' || 'Data Harus .jpeg'
        
        
      ],

      pasanganRulesIsSendiri: [
        v => !!v ||  'Data Harus Di isi',
        v => v && v <=6000000 || 'Tidak Boleh Lebih Dari Rp 6.000.000',
        v => v && v.length >= 7 || 'Maks 6.000.000',
        v => v && v.length < 8 || 'Maks 6.000.000'
      ],

      pasanganRulesIsPasangan:[
        v => !!v ||  'Data Harus Di isi',
        v => v && v <=8000000 || 'Tidak Boleh Lebih Dari Rp 8.000.000',
        
      ],

        numberRules: [
          v => Number.isInteger(v) || 'Numbers Only Input',
          v => v >= 0 || 'The value must be greater than zero',
          
  ],  

          formData: {
        month: [],
        day: []
      },

            status:"",
            // nikDecode:"",

            nikData:"",
            nomorktp:"",
            nama_kary:"",
            nik:"",
            phoneNumber:"",
            nik_atasan:"",
            nikAtasanApproval1:"",
            nikAtasanApproval2:"",
            nama_atasan:"",
            phoneNumber_atasan:"",
            Atasan:"",
            email:"",
            email_atasan:"",
            PT:"",
            idunit:"",
            Cabang:"",
            idcabang:"",
            Jabatan:"",
            Tanggal_Lahir:"",
            jobClass:"",
            Tanggal_diangkatTetap:"",
            statusKerja:"",
            statusNikah:"",
            Departemen:"",
            alamatKtp:"",
            alamatDomisili:"",
            tunjanganKaryawan:"",
            tunjanganPasangan:"", 
            dataPengajuan:"",   
            approval1:"",
            approval2:"",
            approval3:"",
            
            lamaKPR:"",
            birthDate:"",
            tetapDate:"",

            
            loading:false,
            vloading:false,
            // valid: true,

            atasanApproval1:[],
            atasanApproval2:[],
            atasanApproval3:[],

            suratPernyataan:[],
            suratPernyatanString:"",
            slipGajiKaryawan:[],
            gajiKaryawanString:"",
            slipGajiPasangan:[],
            gajiPasanganString:"",


            itemJawab: [
          {
          id: "1",
          name: "Diri Sendiri"
          },
          {
          id: "2",
          name: "Dengan Pasangan"
          }
          ],
      
          bulanKPR:['60','120', '180', '240']



        }
    },


    mounted(){
    this.nikData = this.user.data[0].NikKaryawan;
    this.nomorktp = this.user.data[0].NoKTP
    this.statusNikah = this.user.data[0].StatusNikah
    this.email_atasan = this.user.data[0].EmailAtasan
    this.vloading = true
    this.getKaryawanData()
    // this.name = this.user.NamaKaryawan;
    this.TOKEN = this.token

    
    

    // var decoded = jwt_decode(this.TOKEN)

    // let splitNik = decoded.split()

    // // console.log(decoded);

    // // console.log(splitNik);
    
    
      // // console.log(this.user);
      // this.reload()
      
    },

    computed:{

      
      ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      token:"token"
    }),

    calcutateGajiPasangan(){

      return this.tunjanganKaryawan + this.tunjanganPasangan 
    },

    // rulesIsMenikah(){

    //   if(this.statusNikah == "Belum Menikah"){
    //     return(
    //     this.pasanganRulesIsSendiri)
    //   }else{
    //     return(
    //     this.pasanganRulesIsPasangan)
    //   }
    // },

    tanggalLahir(){

      let birthDate = this.testReformatDate(new Date(this.Tanggal_Lahir));
      return birthDate
      
    },

    tanggalTetap(){
      let tetapDate = this.testReformatDate(new Date(this.Tanggal_diangkatTetap));
      return tetapDate
      
    },

    validateInput(){
      
      return(
        this.nik != ""&&
        this.nama_kary != ""&&
        this.statusKerja != ""&&
        this.Tanggal_Lahir != ""&&
        this.Tanggal_diangkatTetap != ""&&
        this.PT != ""&&
        this.Departemen != ""&&
        this.Cabang != ""&&
        this.Jabatan != ""&&
        this.jobClass != ""&&
        this.alamatKtp != ""&&
        this.alamatDomisili != ""&&
        this.phoneNumber != ""&&
        this.dataPengajuan != ""&&
        this.tunjanganKaryawan != ""&&
        this.lamaKPR != ""&&
        this.suratPernyataan != ""&&
        this.slipGajiKaryawan != ""&&
        this.Atasan != ""&&
        this.approval1 != ""&&
        this.approval2 != "" && 
        this.rules 
        
      )
    },

    validateInputPasangan(){
      if(this.dataPengajuan == '2')
      return(
        this.dataPengajuan == '2'&&
        this.tunjanganPasangan != ""&&
        this.slipGajiPasangan != ""
        
      )
      else return true
    },

  validateInputAtasan(){
    if((this.idunit == "IDM" && this.idcabang != "00")  || 
        (this.idunit == "ACL" && this.idcabang != "00") ||
        this.idunit == "ICC" && this.idcabang != "00" )
    return(
      this.approval3 != ""
    )
    else return true

  },

    validateAtasan3(){
      return(
        (this.idunit == "IDM" && this.idcabang != "00")  || 
        (this.idunit == "ACL" && this.idcabang != "00") ||
        this.idunit == "ICC" && this.idcabang != "00" 
      )
    },
    


    //! Nemtuin Allowed Date
    // :min="new Date().toISOString().substr(0, 10)"
    // :max="new Date(Date.now()+(120*24*60*60*1000)).toISOString().substr(0, 10)"

    // tanggalAkhir(){
    //   if(this.formData.day == ""){
    //     return ""
    //   }else{
    //   let endDate = this.testReformatDate(new Date(this.formData.day));
    //   return endDate
    //   }
    // },


    },




    methods: {

      ...mapActions({
        getKaryawan:"getKaryawan",
        getAtasan1:"getAtasan1",
        getAtasan2:"getAtasan2",
        getAtasan3:"getAtasan3",
        submitDataKpr:"submitDataKpr",
      }),

  
        //! Mengubah Format Tanggal
      testReformatDate(val) {
      let tempDate = new Date(val);
      return `${tempDate.getDate()}-${
        tempDate.getMonth() + 1
      }-${tempDate.getFullYear()}`;
    },


  //! Limiting Input
    isLetter(e) {
  let char = String.fromCharCode(e.keyCode); // Get the character
  if(/^[A-Za-z ]+$/.test(char)) return true; // Match with regex 
  else e.preventDefault(); // If not match, don't add to input text
},

isNumber(n) {
  let char = String.fromCharCode(n.keyCode); // Get the character
  if(/^[0-9 . ,]+$/.test(char)) return true; // Match with regex 
  else n.preventDefault(); // If not match, don't add to input text
},

    //! Auto Reload Project
    reload(){
      setTimeout(()=>
        window.location.reload(),
        1000
      )
    

    },


    //! Mengambil Data Karyawan
    getKaryawanData(){

      let data = {nik : this.nikData}
      
      
     this.getKaryawan(data)
      .then((response) => {
        ;
        let data = response.data[0]
        // console.log(data);
        let status = response.status
        
        // console.log(status);
        // // console.log(data);
        this.nik = data.NIK
        this.nama_kary = data.NAMA
        this.Jabatan = data.JABATAN
        this.Tanggal_Lahir = data.TANGGAL_LAHIR
        this.phoneNumber = data.NOMOR_TELEPON;
        this.nik_atasan = data.NIK_ATASAN;
        this.nama_atasan = data.NAMA_ATASAN;
        this.Atasan = this.nik_atasan + '-' + this.nama_atasan
        this.alamatKtp = data.ALAMAT_KTP
        this.alamatDomisili = data.ALAMAT_DOMISILI;
        this.Tanggal_diangkatTetap = data.TANGGAL_PENGANGKATAN_TETAP;
        this.jobClass = data.JOB_CLASS;
        this.statusKerja = data.STATUS_KERJA;
        this.PT = data.NAMA_PT;
        this.idunit = data.ID_PT
        this.Cabang = data.NAMA_CABANG;
        this.idcabang = data.ID_CABANG
        this.Departemen = data.ORGANIZATION

        // // console.log(data,"awda");

        // if(data.NIK_ATASAN == null  && data.NAMA_ATASAN == null ){
        //   //! Alert Yang Bisa Di modifikasi
        //   this.$swal({
        //     title:"Gagal!",
        //     icon:"error",
        //     html:`Atasan Kosong, Silahkan Setting Atasan`
        //   })
        // }else
        if(status == "Success" ){
        this.vloading = false
        this.getAtasanApproval1Data()
        

        }else if(response.Message == 'Token Required !!!!' && response.Code == 400){
                    this.$router.push({ name: "Login" });
                    setTimeout(()=>
                    window.location.reload(),
        
                    )
                }else{
          this.vloading = false
          this.Atasan = ""
          this.$swal({
            title:"Perhatian!",
            icon:"error",
            html:`${response.data}`
          })
        }
      })
      .catch((error) =>{
        this.vloading = false
        this.$swal({
              title:"Gagal",
              icon:"error",
              text:"Server Sedang Bermasalah Mohon Di coba Kembali"
            })
      })
    },

    getAtasanApproval1Data(){
      let data = {
        nik0 : this.nik,
        nik1 : null,
        nik2 : null,
      }

     this.getAtasan1(data)
     .then((response)=> {
        let data = response.data
        
        this.atasanApproval1 = data
        // this.getAtasanApproval2Data()
        
        

      }).catch((error)=>{
        alert("gagal")
      })
    },

    getAtasanApproval2Data(){
      
      let splitAtasan = this.approval1.split(" - ",1)
      let nikAtasan1 = splitAtasan[0]
      let namaAtasan1 = splitAtasan[1]

      let data = {
        nik0 : this.nik,
        nik1 : nikAtasan1,
        nik2 : null,
      }

      this.nikAtasanApproval1 = nikAtasan1

     this.getAtasan2(data)
     .then((response)=> {

        let data = response.data
        
        this.atasanApproval2 = data
        // // console.log(atasanApproval1);
        // this.getAtasanApproval3Data()
        

      }).catch((err)=>{
        // // console.log(err);
        
      })
    },

    getAtasanApproval3Data(){
      let splitAtasan = this.approval2.split(" - ",1)
      let nikAtasan2 = splitAtasan[0]
      let namaAtasan2 = splitAtasan[1]
      // // console.log(this.idcabang);
      let data = {
        nik0 : this.nik,
        nik1 : this.nikAtasanApproval1,
        nik2 : nikAtasan2,
      }

      if(this.validateAtasan3){
      this.getAtasan3(data)
      .then((response)=> {

        let data = response.data
        
        this.atasanApproval3 = data
        // // console.log(atasanApproval3);
        

      }).catch((err)=>{
        // // console.log(err);
        
      })
      }else{
        return false
      }
    },

  //   downloadSuratPernyataan(){
  //     axios.get('http://hrindomaret.com/api/kpr/getSuratPernyataanKPRdev', {
  //   params: {
  //     nik: this.nik,
  //     namaktp:this.name,
  //     alamatktp:this.alamatKtp,
  //     nomorktp:this.nomorktp
  //   }
  // })
  // .then((response) => {
  //   // console.log(response);
  //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));

  //   var fileLink = document.createElement('a');

  

  //   fileLink.href = fileURL;

  //   fileLink.setAttribute('download', 'SuratPernyataan.pdf');

  //   document.body.appendChild(fileLink);

  

  //   fileLink.click();
  // })
        
  //       .catch(() => // console.log('error occured'))
    
    
  //   },

    convertSurat(){
      var fileConvert =  new FileReader()
        fileConvert.readAsDataURL(this.suratPernyataan)

        fileConvert.onload=()=>{
          var convert = fileConvert.result
          var hasilSurat = convert.split(",")

          this.suratPernyatanString = hasilSurat[1]

          // console.log(this.suratPernyatanString);
        }
    },

    convertGajiKaryawan(){
      var gajiKaryawanConvert =  new FileReader()
        gajiKaryawanConvert.readAsDataURL(this.slipGajiKaryawan)
        gajiKaryawanConvert.onload=()=>{
          var convert = gajiKaryawanConvert.result
          var hasilKaryawan = convert.split(",")

          this.gajiKaryawanString = hasilKaryawan[1]

          // console.log(this.gajiKaryawanString);
        }
    },

    convertGajiPasangan(){
      var gajiPasanganConvert =  new FileReader()
        gajiPasanganConvert.readAsDataURL(this.slipGajiPasangan)
        gajiPasanganConvert.onload=()=>{
          var convert = gajiPasanganConvert.result
          var hasilPasangan = convert.split(",")

          this.gajiPasanganString = hasilPasangan[1]

          // console.log(this.gajiPasanganString);
        }
    },
    


      submitDataKPRData(){
      

        //!untuk Send Data Dengan Cara Lebih Baik(Serta File Upload)
        const formData = new FormData()
        
        formData.set('nikkaryawan', this.nik)
        formData.set('namakaryawan', this.nama_kary)
        formData.set('statuskerja', this.statusKerja)
        formData.set('tanggallahir', this.Tanggal_Lahir)
        formData.set('tanggaltetap', this.Tanggal_diangkatTetap)
        formData.set('namaunit', this.PT)
        formData.set('idunit', this.idunit )
        formData.set('namacabang', this.Cabang)
        formData.set('idcabang', this.idcabang)
        formData.set('departemen', this.Departemen)
        formData.set('jabatan', this.Jabatan)
        formData.set('jobclass', this.jobClass)
        formData.set('alamatktp', this.alamatKtp)
        formData.set('alamatdomisili', this.alamatDomisili)
        formData.set('nomorhp', this.phoneNumber)
        formData.append('suratpernyataan', this.suratPernyataan)
        formData.set('jeniskpr', this.dataPengajuan)
        formData.set('gajikaryawan', this.tunjanganKaryawan)
        formData.set('gajipasangan', this.tunjanganPasangan)
        formData.append('slipgajikaryawan', this.slipGajiKaryawan)
        formData.append('slipgajipasangan', this.slipGajiPasangan)
        formData.set('jumlahwaktukpr', this.lamaKPR)
        formData.set('nikatasan', this.nik_atasan)
        formData.set('emailatasan',this.email_atasan)
        formData.set('atasanapproval1', this.approval1)
        formData.set('atasanapproval2', this.approval2)
        formData.set('atasanapproval3', this.approval3)

        // const data = {
        // nikkaryawan: this.nik,
        // namakaryawan: this.nama_kary,
        // statuskerja: this.statusKerja,
        // tanggallahir: this.Tanggal_Lahir,
        // tanggaltetap: this.Tanggal_diangkatTetap,
        // namaunit: this.PT,
        // idunit: this.idunit ,
        // namacabang: this.Cabang,
        // idcabang: this.idcabang,
        // departemen: this.Departemen,
        // jabatan: this.Jabatan,
        // jobclass: this.jobClass,
        // alamatktp: this.alamatKtp,
        // alamatdomisili: this.alamatDomisili,
        // nomorhp: this.phoneNumber,
        // suratpernyataan: this.suratPernyatan,
        // jeniskpr: this.dataPengajuan,
        // gajikaryawan: this.tunjanganKaryawan,
        // gajipasangan: this.tunjanganPasangan,
        // slipgajikaryawan: this.slipgajiKaryawan,
        // slipgajipasangan: this.slipgajiPasangan,
        // jumlahwaktukpr: this.lamaKPR,
        // nikatasan: this.nik_atasan,
        // emailatasan:this.email_atasan,
        // atasanapproval1: this.approval1,
        // atasanapproval2: this.approval2,
        // atasanapproval3: this.approval3,
        // }

        // const dataKPR = JSON.stringify(data)

        // // console.log(this.TOKEN);

        let data = formData

          this.loading = true

          // this.$baseurl + "kpr/insertDataKaryawanKPR"

          

        this.submitDataKpr(data)
        .then((response) => {
            // // console.log(response.data.status);
            let status = response.status
            let message = response.message
            let res = response.data[0]
            // console.log(res);

            if( status == 'Warning'&& message == 'Silahkan Cek Kembali, Tipe format dan size File Anda harus berformat JPEG, JPG, PNG dan berukuran maximal 1MB') {
              this.$swal({
              title:"Warning!",
              icon: "warning",
              html: `${res.Pesan}`
            })
            this.loading = false
            
            } 
            else if(status =='Success' && message == 'Sukses'){
            this.$swal({
              title:"Pengajuan Diterima!",
              icon: "success",
              text: "Pengajuan KPR BTN Anda Telah Berhasil Disimpan"
            })
            .then(() => {
              this.$router.push({ name: "HomePage" })
            })
            this.loading = false
            
            // this.$router.push({ name: "HomePage" });

            }

            else if(status =='Success' && message == 'Gagal, Total Penghasilan Melebihi Nominal 8 Juta'){
              this.$swal({
              title:"Warning!",
              icon: "warning",
              html: `${res.Pesan}`
            })
            this.loading = false
            // this.$router.push({ name: "HomePage" });

            }else if(status =='Success' && message == 'Data gagal di proses'){
              this.$swal({
              title:"Warning!",
              icon: "warning",
              html: `${res.Pesan}`
            })
            this.loading = false
            // this.$router.push({ name: "HomePage" });

            }
            else if(status =='Success' && message == 'Gagal, Status Pengajuan Telah di Proses'){
              this.$swal({
              title:"Warning!",
              icon: "warning",
              html: `${res.Pesan}`
            })
            this.loading = false
            // this.$router.push({ name: "HomePage" });

            }else {
              this.$swal({
              title:"Warning!",
              icon: "warning",
              html: `${res.Pesan}`
              })
              this.loading = false

            // this.$router.push({ name: "HomePage" });
            } 
            
        })
        .catch((error)=>{
          this.loading = false
            // // console.log(error);
            this.$swal({
              title:"Gagal",
              icon:"error",
              text:"Mohon Maaf Silahkan Di coba Kembali"
            })
        })
        
      }

    
    },
}
</script>