<template>
  <div>
    <c-toolbar />
    <c-navbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2"
              :loading="vloading">
              <template slot="progress">
                <v-progress-linear
                  color="green"
                  indeterminate
                ></v-progress-linear>
              </template>
              <br />
              <v-row justify="center">
                <v-img
                  src="img/indomaret.png"
                  aspect-ratio="3"
                  max-width="250"
                >
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Master Besaran Plafond Bantuan Pendidikan
              </h3>
              <!-- <v-alert class="mx-4" type="info">
                <div>
                  <b>Master plafond beasiswa periode 2021/2022 sudah ditutup</b>
                </div>
              </v-alert> -->

              <div class="pa-1 mx-4">
                <v-data-table
                  :headers="headers"
                  :items="itemsTablePlafond"
                  :search="search"
                  sort-by="nama"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-dialog v-model="dialog" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            New Plafond
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="form">
                            <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      :items="itemsTingkatPendidikan"
                                      :disabled = "editedIndex > -1"
                                      item-text="TINGKAT_PENDIDIKAN"
                                      item-value="ID_TINGKATPENDIDIKAN"
                                      label="Tingkat Pendidikan"
                                      v-model="editedItem.ID_TINGKATPENDIDIKAN"
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="editedItem.PLAFOND"
                                      label="Besaran Plafond (Rp)"
                                      v-on:keypress="isNumber($event)"
                                      :rules="rulesPlafond"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="close">
                                Cancel
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                                v-show="valid"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>

                      <!-- jarak judul -->
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <!-- search -->
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:[`item.PLAFOND`]="{ item }">
                    <td class="text-xs-right">
                      Rp. {{ rupiah(item.PLAFOND) }}
                    </td>
                  </template>
                  <!-- <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">
                      Refresh
                    </v-btn>
                  </template> -->
                  
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
// import Sidebar from "@/components/Sidebar.vue";
// import Toolbar from "@/components/Toolbar.vue";
import CNavbar from '../../components/CNavbar.vue'
import CToolbar from '../../components/CToolbar.vue'
import { mapGetters, mapActions } from "vuex";
const axios = require("axios");

export default {
  name: "Home",
  components: {
    CNavbar,
    CToolbar,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: null,
    headers: [
      { text: "Tingkat Pendidikan", value: "TINGKAT_PENDIDIKAN" },
      { text: "Besaran Plafond", value: "PLAFOND" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemsTablePlafond: [],
    itemsTingkatPendidikan: [],
    tingkatpendidikan: null,
    rulesNama: [(v) => !!v || "Nama harus diisi"],
    rulesPlafond: [
      // (v) => !v || v.length <= 2 || "Jumlah Melebihi 2 Karakter!",
      (v) => !!v || "Besaran plafond harus diisi",
    ],
    editedIndex: -1,
    editedItem: {
      PLAFOND: "",
      TINGKAT_PENDIDIKAN: "",
    },
    defaultItem: {
      PLAFOND: "",
      TINGKAT_PENDIDIKAN: "",
    },
    vloading: false,
  }),

  // created() {
  //   this.itemTingkatPendidikan();
  //   this.initialize();
  // },

  mounted(){
    this.TOKEN = this.token
    this.vloading = true
    this.itemTingkatPendidikan();
    this.initialize();
  },

  computed: {
    ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      token:"token"
      // getDrawer: "drawer",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Plafond" : "Edit Plafond";
    },
    valid() {
      let id_tp = this.editedItem.ID_TINGKATPENDIDIKAN;
      let plafond = this.editedItem.PLAFOND;
      return id_tp != null && plafond != null;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  // created() {
  //   // this.initialize();
  // },

  methods: {
    ...mapActions({
            insertDataMstPlafondBeasiswa:"insertDataMstPlafondBeasiswa",
        }),

    initialize() {
      const code = {
        nik: this.user.data[0].NikKaryawan,
        status: "read",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // 'Access-Control-Allow-Origin': '*',
      // };
      // const axios = require("axios");
      // axios
      //   .post(
      //     this.$baseurl + "beasiswa/insertDataMstPlafondBeasiswa",
      //     code,
      //     { headers }
      //   )
      this.insertDataMstPlafondBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
              this.vloading = false;
            });
          } else {
            // // console.log(result.message);
            this.itemsTablePlafond = result;
            this.vloading = false;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            this.vloading = false;
            // this.snackbar=true;
          }
        });
    },

     rupiah(nominal) {
      // if (!date) return null;

      // const [year, month, day] = date.split("-");
      // return `${day}-${month}-${year}`;

      const numb = nominal;
            const format = numb
              .toString()
              .split("")
              .reverse()
              .join("");
            const convert = format.match(/\d{1,3}/g);
            const rupiah = convert
              .join(".")
              .split("")
              .reverse()
              .join("");
            // // console.log(rupiah)
            // this.besaranbeasiswa = rupiah;
          return rupiah;
     },
    

    itemTingkatPendidikan() {
      const code = {
        nik: this.user.data[0].NikKaryawan,
        status: "read",
        kategori: "tingkatpendidikan",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   Authorization: "Bearer" + this.TOKEN,
      //   // 'Access-Control-Allow-Origin': '*',
      // };
      // const axios = require("axios");
      // axios
      //   .post(
      //     this.$baseurl + "beasiswa/insertDataMstPlafondBeasiswa",
      //     code,
      //     { headers }
      //   )
      this.insertDataMstPlafondBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.itemsTingkatPendidikan = result.data;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    isNumber(n) {
      let char = String.fromCharCode(n.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else n.preventDefault(); // If not match, don't add to input text
    },

    editItem(item) {
      this.editedIndex = this.itemsTablePlafond.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      // // console.log(this.editedItem);
    },

    deleteItem(item) {
      this.$swal({
        icon: "warning",
        title: "Apakah Anda Yakin?",
        text: "Untuk Menghapus Plafond",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Delete`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.editedIndex = this.itemsTablePlafond.indexOf(item);
          this.editedItem = Object.assign({}, item);
          // // console.log(this.editedItem);
          const ID_Plafond = this.editedItem.ID_PLAFOND;
          const NIK = this.user.data[0].NikKaryawan;
          const deletePlafond = {
            nik: NIK,
            status: "delete",
            kategori: "plafond",
            idplafond: ID_Plafond,
          };
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   Authorization: "Bearer" + this.TOKEN,
          //   // NIK: this.user.NikKaryawan,
          // };
          // axios
          //   .post(
          //     this.$baseurl + "beasiswa/insertDataMstPlafondBeasiswa",
          //     deletePlafond,
          //     {
          //       headers,
          //     }
          //   )
          this.insertDataMstPlafondBeasiswa(deletePlafond)
            .then((response) => {
              // // console.log(response.data.message.message);
              if (response.data.status == "Token is Expired") {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (response.data.status == "Success") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: response.data.data[0].Pesan,
                });
                this.initialize();
              } else {
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menghapus",
                });
              }
            })
            .catch((error) => {
              // console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menghapus",
              });
            });
        }
      });
    },

    deleteItemConfirm() {
      this.itemsTablePlafond.splice(this.editedIndex, 1);
      this.close();
    },

    close() {
      this.dialog = false;
      // this.$refs.form.resetValidation()
      // this.$refs.form.reset();
      this.editedItem.TINGKAT_PENDIDIKAN = null;
      this.editedItem.PLAFOND = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onlyNumber($event) {
      //// console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      const ID_TP = this.editedItem.ID_TINGKATPENDIDIKAN;
      const Plafond = this.editedItem.PLAFOND;
      const ID_Plafond = this.editedItem.ID_PLAFOND;
      const NIK = this.user.data[0].NikKaryawan;
      // console.log(this.editedItem);
      if (this.editedIndex > -1) {
        // edit
        this.$swal({
          icon: "info",
          title: "Apakah Anda Yakin?",
          text: "Untuk Mengubah Plafond",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          confirmButtonText: `Yes`,
        }).then((response) => {
          if (response.isConfirmed) {
            const editPlafond = {
              nik: NIK,
              idplafond: ID_Plafond,
              plafond: Plafond,
              status: "update",
              kategori: "plafond",
            };
            // console.log(editPlafond);
            // const varToken = sessionStorage.getItem("token");
            // const headers = {
            //   Authorization: "Bearer" + this.TOKEN,
            //   // NIK: this.user.NikKaryawan,
            // };
            // const axios = require("axios");
            // axios
            //   .post(
            //     this.$baseurl + "beasiswa/insertDataMstPlafondBeasiswa",
            //     editPlafond,
            //     {
            //       headers,
            //     }
            //   )
            this.insertDataMstPlafondBeasiswa(editPlafond)
              .then((response) => {
                // // console.log(response.data.message.message);
                if (response.data.status == "Token is Expired") {
                  this.$swal({
                    icon: "info",
                    title: "Info!",
                    text: "Session Anda Telah Habis",
                  }).then(() => {
                    sessionStorage.clear();
                    this.$router.push({ name: "Login" });
                  });
                } else if (response.data.status == "Success") {
                  this.$swal({
                    icon: "success",
                    title: "Berhasil",
                    text: response.data.data[0].Pesan,
                  });
                  this.initialize();
                  this.deleteItemConfirm();
                  // this.clearForm();
                } else {
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Mengubah, " + response.data.message,
                  });
                }
              })
              .catch((error) => {
                // console.log(error);
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Mengubah",
                });
              });
          }
        });
      } else {
        // add new
        // this.desserts.push(this.editedItem);
        this.$swal({
          icon: "info",
          title: "Apakah Anda Yakin?",
          text: "Untuk Menambah Plafond",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          confirmButtonText: `Yes`,
        }).then((response) => {
          if (response.isConfirmed) {
            const addPlafond = {
              nik: NIK,
              plafond: Plafond,
              status: "create",
              kategori: "plafond",
              idtingkatpendidikan: ID_TP,
            };
            // // console.log(editVaksinasi);
            // const varToken = sessionStorage.getItem("token");
            // const headers = {
            //   Authorization: "Bearer" + this.TOKEN,
            //   // NIK: this.user.NikKaryawan,
            // };
            // const axios = require("axios");
            // axios
            //   .post(
            //     this.$baseurl + "beasiswa/insertDataMstPlafondBeasiswa",
            //     addPlafond,
            //     {
            //       headers,
            //     }
            //   )
            this.insertDataMstPlafondBeasiswa(addPlafond)
              .then((response) => {
                // console.log(response.data);
                if (response.data.status == "Token is Expired") {
                  this.$swal({
                    icon: "info",
                    title: "Info!",
                    text: "Session Anda Telah Habis",
                  }).then(() => {
                    sessionStorage.clear();
                    this.$router.push({ name: "Login" });
                  });
                } else if (response.data.status == "Success") {
                  this.$swal({
                    icon: "success",
                    title: "Berhasil",
                    text: response.data.data[0].Pesan,
                  });
                  this.initialize();
                  this.clearForm();
                } else {
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Menambahkan, " + response.data.message,
                  });
                }
              })
              .catch((error) => {
                // console.log(error);
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menambahkan",
                });
              });
          }
        });
      }
      this.close();
    },
    clearForm(){
      this.editedItem.PLAFOND == "",
      this.editedItem.ID_TINGKATPENDIDIKAN = ""
    }
  },
};
</script>
