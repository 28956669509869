<template>
  <div>
    <c-toolbar />
    <c-navbar />
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card class="mx-auto elevation-11" width="2000">
            <v-img src="img/indomaret.png" aspect-ratio="3" width="220" position="end"></v-img>

            <h1 class="text-center mt-4">Riwayat Cuti Karyawan</h1>

            <v-col cols="12" sm="4">
              <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                :close-on-content-click="false"
                offset-y
                max-width="290px"
                min-width="290px"
              >

              <v-alert type="info">Jika Ingin Memilih 1 Hari Mohon Pilih Hari Yang Sama 2 Kali!</v-alert>
              
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Pilih Range Hari"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="formData.month" range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Batal</v-btn>
                  <v-btn text color="primary" @click="getRiwayat" :loading="btnLoading">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <div v-if="showTable">
                    <v-data-table
                        :headers="headers"
                        :items="results"
                        :search="search"
                        class="elevation-1"
                    >
                    </v-data-table>
            </div>

                       <div v-else>
                      <v-card :loading="loadingHasil">
                        <v-card-text
                          v-show="!loadingHasil"
                          class="text-center font-italic error--text"
                        >Please Completely field the form to show the table</v-card-text>
                        <template slot="progress">
                          <v-card-text class="text-center">
                            <v-progress-circular color="primary" indeterminate></v-progress-circular>
                          </v-card-text>
                        </template>
                      </v-card>
                    </div>


        </v-card>
        </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>import Axios from "axios";
import { mapGetters } from "vuex";
import CToolbar from "@/components/CToolbar.vue";

import CNavbar from "@/components/CNavbar.vue";
export default {
name: "Riwayat-Cuti",
components: {
    CToolbar,
    CNavbar,
},

 data() {
    return {
      search: "",
      headers: [
        {
          text: "NIK",
          align: "start",
          sortable: true,
          value: "NikKaryawan",
        },
        { text: "Nama", value: "NamaKaryawan" },
        { text: "Nomor Handphone", value: "NomorHandphone" },
        { text: "Nama Atasan", value: "NamaAtasan" },
        { text: "Nomor Handphone Atasan", value: "NomorHpAtasan" },
        { text: "PT" , value: "PT" },
        { text: "Lokasi", value: "Lokasi" },
        { text: "Direktorat", value: "Direktorat" },
        { text: "Departemen", value: "Departemen" },
        { text: "Tanggal", value: "TanggalInput" },
      ],
    startDate: "",
    endDate: "",
    results: [],

    showFieldDay: false,
    menu: false,
    isLoading: false,
    loadingHasil: false,
    showTable: false,

    formData: {
        month: [],
    },
    
    }
    },

    computed: {
    ...mapGetters({
      user: "user",
    }),

    dateRangeText(val) {
      if (this.formData.month.length <= 0) {
        return "";
      } else {
        let startDate = this.testReformatDate(new Date(this.formData.month[0]));
        let endDate = this.testReformatDate(new Date(this.formData.month[1]));
        let mergeDate = [startDate, endDate];
        let temp = mergeDate.join(" ~ ");
        return temp;
      }
    },

    },

    methods: {

        getRiwayat(){

        },

        testReformatDate(val) {
      let tempDate = new Date(val);
      return `${tempDate.getDate()}-${
        tempDate.getMonth() + 1
      }-${tempDate.getFullYear()}`;
    },

    }

}
</script>