<template>
  <div>
    <c-toolbar />
    <c-navbar />

    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-11 py-4 px-2">
              <v-row justify="left">
                <v-img src="img/indomaret.png" aspect-ratio="3" max-width="220" position="end"></v-img>
                <!-- <v-col cols="4">
                </v-col>-->
              </v-row>

              <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay>

              <v-row align="center" justify="center">
                <v-col cols="12" sm="12">
                  <h4>Input Izin

                  </h4>
                </v-col>
              </v-row>

              <v-col cols="12" sm="4">
                <span> Jenis Izin :
                  <v-select v-model="jenisIzin" :items="itemIzinTarik" solo dense item-text="DESCRIPTION"
                    item-value="ABSENCEID" class="caption" @change="goToSakit"></v-select>
                </span>
              </v-col>

              <v-col cols="12" sm="4" v-show="jenisIzin == 'D3'">
                <v-menu ref="menuD3" v-model="dateD3" :close-on-content-click="false"
                  :return-value.sync="formData.change" transition="scale-transition" offset-y max-width="290px"
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="tanggalD3" label="Tanggal Libur/Off" hint="DD/MM/YYYY format" persistent-hint
                      :rules="[() => !!tanggalD3 || 'This field is required']" prepend-icon="mdi-calendar"
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="formData.change" :allowed-dates="tanggalLibur" :min="minGantiHari"
                    :max="nowDate" no-title>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateD3 = false">Batal</v-btn>
                    <v-btn text color="primary" @click="getValidasiLibur">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="4"
                v-show="jenisIzin != '06' && jenisIzin != 'DL' && jenisIzin != 'LN' && jenisIzin != ''">
                <v-menu ref="menu1" v-model="date1" :close-on-content-click="false" :return-value.sync="formData.month"
                  transition="scale-transition" offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="tanggalIzin" label="Tanggal Izin" hint="DD/MM/YYYY format" persistent-hint
                      :rules="[() => !!tanggalIzin || 'This field is required']" prepend-icon="mdi-calendar"
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="formData.month"
                    v-show="jenisIzin != '06' && jenisIzin != 'DL' && jenisIzin != 'LN' && jenisIzin != '' && jenisIzin != 'D3'"
                    no-title>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date1 = false">Batal</v-btn>
                    <v-btn text color="primary" @click="$refs.menu1.save(formData.month)">OK</v-btn>
                  </v-date-picker>

                  <v-date-picker v-model="formData.month" v-show="jenisIzin == 'D3'" :min="this.formData.change"
                    :max="maxGantiHari" no-title :disabled="disableGantiHari">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date1 = false">Batal</v-btn>
                    <v-btn text color="primary" @click="$refs.menu1.save(formData.month)">OK</v-btn>
                  </v-date-picker>

                </v-menu>
              </v-col>

              <v-col cols="12" sm="4"
                v-show="jenisIzin != 'D1' && jenisIzin != 'D2' && jenisIzin != 'I1' && jenisIzin != 'I2' && jenisIzin != 'LK' && jenisIzin != 'S2' && jenisIzin != 'S1' && jenisIzin != 'D3'">
                <v-menu ref="menu2" v-model="date2" :close-on-content-click="false" :return-value.sync="formData.day"
                  transition="scale-transition" offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="changeDate" label="Tanggal Izin" hint="DD/MM/YYYY format" multiple chips
                      small-chips readonly :rules="[() => !!formData.day || 'This field is required']"
                      prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="formData.day" multiple no-title scrollable>
                    <v-btn text color="primary" @click="$refs.menu2.save(formData.day)">OK</v-btn>
                    <v-btn text color="primary" @click="date2 = false">
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6" sm="3"
                v-show="jenisIzin != '06' && jenisIzin != 'DL' && jenisIzin != 'LN' && jenisIzin != '' && jenisIzin != 'D3'">
                <!-- && jenisIzin != 'I1' && jenisIzin != 'I2' -->
                <v-container class="justify-left" style="max-width: 300px;">
                  <v-digital-time-picker v-model="startTime" label="Jam Izin Awal" rounded filled />
                </v-container>

                <v-container class="justify-left" style="max-width: 300px;">
                  <v-digital-time-picker v-model="endTime" label="Jam Izin Akhir" rounded filled />
                </v-container>
              </v-col>

              <v-col cols="12" sm="4">

                <span> Keterangan : <v-textarea v-model="keterangan" auto-grow solo dense>
                  </v-textarea>
                </span>

              </v-col>


              <v-col cols="12" sm="4">
                <span> Atasan ESS :
                  <v-text-field v-model="Atasan" label="Atasan" solo required readonly>

                  </v-text-field>
                </span>
              </v-col>

              <v-row justify="center">
                <v-col cols="12" sm="2" class="shrink text-end">
                  <v-btn color="primary" block @click="submitIzin()" :loading="isLoading"
                    :disabled="!validateInput">submit</v-btn>
                </v-col>
              </v-row>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import CNavbar from '../../components/CNavbar.vue'
import CToolbar from '../../components/CToolbar.vue'
export default {
  components: { CToolbar, CNavbar },

  data() {
    return {
      formData: {
        day: [],
        month: [],
        change: [],
      },
      multipleDay: [],
      selected: [],
      date: new Date(),
      nowDate: new Date().toISOString().slice(0, 10),

      hariIzin: "",
      nik: "",
      nama_atasan: "",
      nik_atasan: "",
      Atasan: "",
      tokenFB: "",
      token_atasan: "",
      jenisIzin: "",
      startDate: "",
      startDateD3: "",

      startTime: "",
      endTime: "",
      startTimeTerlambat: "",
      endTimeTerlambat: "",
      startTimePulang: "",
      endTimePulang: "",

      keterangan: "",
      dataHariIzin: "",
      isLoading: false,
      disableGantiHari: false,
      overlay: false,

      tglTest: ["2023-09-01", "2023-09-02", "2023-09-03", "2023-09-04", "2023-09-05"],

      date1: "",
      date2: "",
      dateD3: "",
      tglIzinSingle: "",
      tglIzinGantiHari: "",
      tglIzinMultiple: "",
      kodeToko: "",
      tglMultiple: [],

      tglIzinMultiple: [],

      izinSehari: "",
      izinMultiple: "",

      itemIzinTarik: [],
      itemHoliday: [],
      itemIzin: [
        {
          id: "06",
          name: "Training",
        },
        {
          id: "D1",
          name: "Dinas Luar Kantor Pagi",
        },
        {
          id: "D2",
          name: "Dinas Luar Kantor Siang",
        },
        {
          id: "DL",
          name: "Dinas Luar Kota",
        },
        {
          id: "I1",
          name: "Ijin Datang Terlambat",
        },
        {
          id: "I2",
          name: "Ijin Pulang Cepat",
        },
        {
          id: "LK",
          name: "Dinas Luar Kantor",
        },
        {
          id: "LN",
          name: "Dinas Luar Negeri",
        },
        {
          id: "S2",
          name: "Ijin Pulang Karena Sakit",
        },
        {
          id: "S3",
          name: "Ijin Sakit",
        },
      ],


    }
  },

  computed: {
    ...mapGetters({
      user: "user",
      tokenFirebase: "tokenFirebase",
      dataGetTokenFirebase: "dataGetTokenFirebase"
    }),

    tanggalIzin() {
      // console.log(this.formData)
      if (this.formData.month == "") {
        return ""
      } else {
        let startDate = this.testReformatDate(new Date(this.formData.month));
        return startDate
      }
    },

    tanggalD3() {
      // console.log(this.formData)
      if (this.formData.change == "") {
        return ""
      } else {
        let startDateD3 = this.testReformatDate(new Date(this.formData.change));
        return startDateD3
      }
    },

    minGantiHari() {
      var tglAwalMin = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 30);
      return tglAwalMin.toISOString().slice(0, 10)
    },

    maxGantiHari() {
      // const gantiHari = new Date(new Date(this.formData.month)
      // .setDate(new Date(this.formData.month).getDate() + 30))

      // let tglIzinGantiHari = this.testReformatDate(new Date(gantiHari))
      // return tglIzinGantiHari

      //   let gantiMax = new Date(this.formData.change)

      //   var tglAkhirMax = new Date(gantiMax.getDate()+ 30);
      //  return tglAkhirMax
      if (
        this.formData.change != null &&
        this.formData.change != "" &&
        this.formData.change != undefined
      ) {
        // console.log('hasil tgl libur: '+this.formData.change)
        let max = new Date(this.formData.change);
        let valueDate = max.setDate(max.getDate() + 30);
        return new Date(valueDate).toISOString().substr(0, 10);
      }
    },



    changeDate() {
      // console.log(this.formData.day)
      if (this.formData.day == []) {
        return ""
      } else {

        let tglMulti = this.formData.day;
        var arr = []
        // console.log(tglMulti.length)

        for (var i = 0; i < tglMulti.length; i++) {

          let multi = this.testReformatDate(new Date(tglMulti[i]));
          arr.push(multi);

        }

        return arr;

        //! After Array Been Reformat Arr.push(multi) push multi to arr as in empty Array Then Group the Array Together again


      }

    },

    validateInput() {
      if (this.jenisIzin != '06' && this.jenisIzin != 'DL' && this.jenisIzin != 'LN' && this.jenisIzin != '' && this.jenisIzin != 'D3')
        return (
          this.jenisIzin != "" &&
          this.startTime != "" &&
          this.endTime != "" &&
          this.tanggalIzin != "" &&
          this.nik_atasan != "" &&
          this.nik != "" &&
          this.keterangan != ""


        )
      else if (this.jenisIzin == 'D3')
        return (
          this.jenisIzin != "" &&
          this.tanggalIzin != "" &&
          this.tanggalD3 != "" &&
          this.nik_atasan != "" &&
          this.nik != "" &&
          this.keterangan != ""
        )
      else if (this.jenisIzin != 'D1' && this.jenisIzin != 'D2' && this.jenisIzin != 'I1' && this.jenisIzin != 'I2' && this.jenisIzin != 'LK' && this.jenisIzin != 'S2' && this.jenisIzin != 'S1' && this.jenisIzin != 'D3')
        return (
          this.jenisIzin != "" &&
          this.changeDate.toString() != "" &&
          this.nik_atasan != "" &&
          this.nik != "" &&
          this.keterangan != ""


        )
      else
        return true

    },

  },

  mounted() {

    this.nik = this.user.data[0].NikKaryawan
    this.name = this.user.data[0].NamaKaryawan
    this.nik_atasan = this.user.data[0].NikAtasan
    this.nama_atasan = this.user.data[0].NamaAtasan
    this.tokenFB = this.tokenFirebase
    this.kodeToko = this.Organization_Number

    // console.log(this.tokenFB);
    this.Atasan = this.nik_atasan + '-' + this.nama_atasan
    this.getDataIzin()




    //!  Untuk Membatasi Tanggal Dalam Calender 
    //*:min="new Date().toISOString().substr(0,10)"
  },

  methods: {

    ...mapActions({
      listIzin: "listIzin",
      inputIzin: "inputIzin",
      getDataTokenFirebase: "getDataTokenFirebase",
      messageFirebase: "messageFirebase",
    }),

    getDataIzin() {
      this.isLoading = true

      let data = {
        code: "5",

        nik: this.nik,
        idizin: "s1",
        nikpengaju: this.nik
      }

      this.listIzin(data)
        .then(response => {
          let tipeIzin = response.data
          this.itemIzinTarik = tipeIzin
          this.isLoading = false

        })
        .catch(error => {
          alert("Data Gagal Di tarik")
          this.isLoading = false
        })

    },

    getDataHoliday() {
      let data = {
        code: "9",
        holiday: "",
        nik: this.nik,
        idizin: "",
        nikpengaju: ""
      }

      this.listIzin(data)
        .then(response => {
          let holiday = response.data
          this.itemHoliday = holiday

          // console.log(this.itemHoliday);
        })
        .catch(error => {
          alert("Data Gagal Di tarik")
          this.isLoading = true
        })
    },

    getValidasiLibur() {
      this.overlay = true
      this.isLoading = true
      this.dateD3 = false
      this.$refs.menuD3.save(this.formData.change)

      let data = {
        code: "9",
        holiday: this.formData.change,
        nik: this.nik,
        nikpengaju: this.nik
      }

      this.listIzin(data)
        .then(response => {
          let validasi = response.message
          let status = response.status


          // console.log(validasi);
          if (status == "Warning") {
            this.$swal({
              title: "Gagal!",
              icon: "error",
              html: `${validasi}`
            }).then(() => {
              this.clearDataSingleD3()
              this.disableGantiHari = true
              this.isLoading = false
              this.overlay = false
            })
          } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
            this.$router.push({ name: "Login" });
            setTimeout(() =>
              window.location.reload(),

            )
          }
          else if (status == 'Failed') {
            this.$swal({
              title: "Gagal!",
              icon: "error",
              html: `Gagal Mengambil Data Validasi`
            }).then(() => {
              this.clearDataSingleD3()
              this.disableGantiHari = true
              this.isLoading = false
              this.overlay = false
            })

          } else {
            this.disableGantiHari = false
            this.isLoading = false
            this.overlay = false
          }

        })
        .catch(error => {
          alert("Gagal Mengirim Validasi")
          this.overlay = false
          this.isLoading = false
        })
    },

    tanggalLibur(val) {
      for (var i = 0; i < this.itemHoliday.length; i++) {
        if (this.itemHoliday[i].HOLIDAY == val) {
          // tglTest
          // console.log(val);
          return val
        }
      }
    },


    getFirebase() {
      let data = {
        Data: {
          code: "2",
          username: this.nik_atasan
        }
      }

      this.getDataTokenFirebase(data)
        .then(res => {
          let data = res.Result;
          // console.log(data)
          let token_firebaseUser = data

          for (var i = 0; i < token_firebaseUser.length; i++) {

            let tokenFb = token_firebaseUser[i].TOKEN;

            this.token_atasan = tokenFb
            // break
            this.sendFirebase()
            this.clearDataSingle()
            this.clearDataSingleD3()
            this.clearDataMultiple()

          }


        }).catch(err => {
          console.log(err);
        })
    },

    sendFirebase() {
      let dataFirebase = {
        collapse_key: "green",
        to: this.token_atasan,
        notification:
        {
          body: `Permintaan Izin Baru! Atas Nama ${this.nik} - ${this.name}`,
          title: "Notif Izin",
          icon: "Indomaret.png"
        }

      }

      this.messageFirebase(dataFirebase)
        .then(response => {
          let data = response
          // console.log(response);


        }).catch(error => {
          console.log(error);
        })
    },



    submitIzin() {
      // console.log(this.multipleDay);

      this.isLoading = true

      if (this.jenisIzin != '06' && this.jenisIzin != 'DL' && this.jenisIzin != 'LN' && this.jenisIzin != '' && this.jenisIzin != 'D3') {
        let dataSingle = {
          code: "1",
          ID: this.jenisIzin,
          FROMD: this.tanggalIzin,
          HOLIDAY: this.tanggalD3,
          TIMEIN: this.startTime,
          TIMEOUT: this.endTime,
          APPROVEDBY: this.nik_atasan,
          EMPLOYEEID: this.nik,
          KETERANGAN: this.keterangan,
          EMPLOYEENAME: this.name,
        }
        // this.izinSehari = dataSingle

        this.inputIzin(dataSingle)
          .then(response => {
            let status = response.status
            let type = response.data[0]
            let message = response.message

            if (status == "Success") {
              this.$swal({
                title: "Permohonan izin Anda berhasil disimpan!",
                icon: "success",
                html: `${message}`
              }).then(() => {
                this.getFirebase()


              })
              this.isLoading = false

            } else if (status == "Time Error") {
              this.$swal({
                title: "Gagal!",
                icon: "error",
                html: `Waktu Izin Lebih Dari 4 Jam`
              })
              this.isLoading = false
            } else if (status == "Warning") {
              this.$swal({
                title: "Permohonan izin Anda gagal disimpan!",
                icon: "error",
                html: `${message}`
              })
              this.isLoading = false

            } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
              this.$router.push({ name: "Login" });
              setTimeout(() =>
                window.location.reload(),

              )
            } else {
              this.$swal({
                title: "Gagal!",
                icon: "error",
                html: `Izin Gagal DiSubmit`
              })
              this.isLoading = false
            }
          }).catch(error => {
              this.$swal({
                title: "Gagal!",
                icon: "error",
                html: `Maaf Server Sedang Error`
              })
              this.isLoading = false

            })

    } else if(this.jenisIzin == 'D3'){
  let dataSingleD3 = {
    code: "1",
    ID: this.jenisIzin,
    FROMD: this.tanggalIzin,
    HOLIDAY: this.tanggalD3,
    TIMEIN: this.startTime,
    TIMEOUT: this.endTime,
    APPROVEDBY: this.nik_atasan,
    EMPLOYEEID: this.nik,
    KETERANGAN: this.keterangan,
    EMPLOYEENAME: this.name,
  }
  // this.izinSehari = dataSingle

  this.inputIzin(dataSingleD3)
    .then(response => {
      let status = response.status
      let type = response.data[0]
      let message = response.message

      if (status == "Success") {


        this.$swal({
          title: "Sukses!",
          icon: "success",
          html: `Izin Berhasil DiSubmit`
        }).then(() => {
          this.getFirebase()


        })
        this.isLoading = false
      } else if (status == "Time Error") {
        this.$swal({
          title: "Gagal!",
          icon: "error",
          html: `Waktu Izin Lebih Dari 4 Jam`
        })
        this.isLoading = false
      } else if (status == "Warning") {
        this.$swal({
          title: "Gagal!",
          icon: "error",
          html: `${message}`
        })
        this.isLoading = false

      } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
        this.$router.push({ name: "Login" });
        setTimeout(() =>
          window.location.reload(),

        )
      } else {
        this.$swal({
          title: "Gagal!",
          icon: "error",
          html: `Izin Gagal DiSubmit`
        })
        this.isLoading = false
      }
    })
    .catch(error => {
      this.$swal({
        title: "Gagal!",
        icon: "error",
        html: `Maaf Server Sedang Error`
      })
    })
} else if (this.jenisIzin == 'D3') {
  let dataSingleD3 = {
    code: "1",
    ID: this.jenisIzin,
    FROMD: this.tanggalIzin,
    HOLIDAY: this.tanggalD3,
    TIMEIN: this.startTime,
    TIMEOUT: this.endTime,
    APPROVEDBY: this.nik_atasan,
    EMPLOYEEID: this.nik,
    KETERANGAN: this.keterangan,
    EMPLOYEENAME: this.name,
  }
  // this.izinSehari = dataSingle

  this.inputIzin(dataSingleD3)
    .then(response => {
      let status = response.status
      let type = response.data[0]
      let message = response.message

      if (status == "Success") {


        this.$swal({
          title: "Sukses!",
          icon: "success",
          html: `Izin Berhasil DiSubmit`
        }).then(() => {
          this.getFirebase()


        })
        this.isLoading = false
      } else if (status == "Time Error") {
        this.$swal({
          title: "Gagal!",
          icon: "error",
          html: `Waktu Izin Lebih Dari 4 Jam`
        })
        this.isLoading = false
      } else if (status == "Warning") {
        this.$swal({
          title: "Gagal!",
          icon: "error",
          html: `${message}`
        })
        this.isLoading = false

      } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
        this.$router.push({ name: "Login" });
        setTimeout(() =>
          window.location.reload(),

        )
      } else {
        this.$swal({
          title: "Gagal!",
          icon: "error",
          html: `Izin Gagal DiSubmit`
        })
        this.isLoading = false
      }
    })
    .catch(error => {
      this.$swal({
        title: "Gagal!",
        icon: "error",
        html: `Maaf Server Sedang Error`
      })
      this.isLoading = false

    })

} else {

  let dataMultiple = {
    code: "1",
    ID: this.jenisIzin,
    FROMD: this.changeDate.toString(),
    HOLIDAY: this.tanggalD3,
    APPROVEDBY: this.nik_atasan,
    EMPLOYEEID: this.nik,
    KETERANGAN: this.keterangan,
    TIMEIN: this.startTime,
    TIMEOUT: this.endTime,
    EMPLOYEENAME: this.name,
  }
  // this.izinMultiple = dataMultiple

  this.inputIzin(dataMultiple)
    .then(response => {

      let status = response.status

      let type = response.data[0]
      let message = response.message

      if (status == "Success") {


        this.$swal({
          title: "Sukses!",
          icon: "success",
          html: `Izin Berhasil DiSubmit`
        }).then(() => {
          this.getFirebase()


        })
        this.isLoading = false

      } else if (status == "Warning") {
        this.$swal({
          title: "Gagal!",
          icon: "error",
          html: `${message}`
        })
        this.isLoading = false

      } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
        this.$router.push({ name: "Login" });
        setTimeout(() =>
          window.location.reload(),

        )
      } else {
        this.$swal({
          title: "Gagal!",
          icon: "error",
          html: `Izin Gagal DiSubmit`
        })
        this.isLoading = false
      }
    })
    .catch(error => {
      this.$swal({
        title: "Gagal!",
        icon: "error",
        html: `Maaf Server Sedang Error`
      })
      this.isLoading = false
    })

}

},

goToSakit() {
  if (this.jenisIzin == 'S1') {
    this.$router.push({ name: "InputIzinSakit" })
  } else if (this.jenisIzin == 'D3') {
    this.getDataHoliday()
  }
},



testReformatDate(val) {
  let tempDate = new Date(val);
  return `${tempDate.getDate()}/${tempDate.getMonth() + 1
    }/${tempDate.getFullYear()}`;
},

clearDataSingle() {
  this.jenisIzin = ""
  this.startTime = ""
  this.endTime = ""
  this.tanggalIzin = ""
  this.formData.month = ""
  this.formData.change = ""
  this.keterangan = ""
},

clearDataSingleD3() {
  this.jenisIzin = ""
  this.tanggalIzin = ""
  this.tanggalD3 = ""
  this.formData.month = ""
  this.formData.change = ""
  this.keterangan = ""
},

clearDataMultiple() {
  this.jenisIzin = ""
  this.changeDate = ""
  this.changeDate.toString = ""
  this.keterangan = ""
},

  },

}

</script>

<style></style>