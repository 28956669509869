"use strict"

import axios from "axios"

const PREFIX = process.env.VUE_APP_API_HRMS

export default {
    pengkinianAlamat(data) {
        return axios.post(`${PREFIX}`, data, {
            withCredentials: true
        })
    },
}