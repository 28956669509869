<template>
<div>
    <c-toolbar />
    <c-navbar />

    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-11 py-4 px-2">
              <v-row justify="center">
                <v-img src="img/indomaret.png" aspect-ratio="3" max-width="220" position="end"></v-img>
                <!-- <v-col cols="4">
                </v-col>-->
              </v-row>

              <form class="pa-4">
                <div class="my-2">
                  <v-row justify="center">
                    <v-col cols="12" sm="4">
                      <div>

                <v-select
                  v-model="formData.jenisCuti"
                  :items="itemCuti"
                  label="Pilih Jenis Cuti"
                  item-text="name"
                  item-value="id"
                  class="caption"
                ></v-select>
                      </div>
              </v-col>

  <v-col cols="12" sm="4">
      <v-text-field
      v-model="hariCuti"
      label="Input Jumlah Hari"></v-text-field>

                <v-menu
                  ref="menu"
                  v-model="menu"
                  transition="scale-transition"
                  :close-on-content-click="false"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >

                <!-- <v-alert type="info">Jika Ingin Memilih 1 Hari Mohon Pilih Hari Yang Sama 2 Kali!</v-alert> -->

                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="changeDate"
                      label="Pilih Tanggal Cuti"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="formData.month" multiple :allowed-dates="hariCuti" @input="menu = false">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">Batal</v-btn>
                    <!-- <v-btn text color="primary" @click="getHasil" :disabled="validateFilter" :loading="btnLoading">OK</v-btn> -->
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-row justify="end">
                  <v-col cols="12" sm="2" class="shrink text-end">
                    <v-btn
                      color="primary"
                      block
                      @click="submitCuti()"
                      :loading="btnLoading"
                      v-show="validateJawabanTidak || validateJawabanYa6"
                    >submit</v-btn>
                  </v-col>

                  <v-col cols="12" sm="2" class="shrink text-end">
                    <v-btn
                      color="primary"
                      block
                      @click="clearCuti()"
                      :loading="btnLoading"
                      v-show="validateJawabanTidak || validateJawabanYa6"
                    >Cancel</v-btn>
                  </v-col>
                </v-row>

                  </v-row>
                </div>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</div>
</template>

<script>
import { mapGetters,mapActions} from 'vuex'; 
import CNavbar from '../../components/CNavbar.vue'
import CToolbar from '../../components/CToolbar.vue'
export default {
  components: { CToolbar, CNavbar },

  data() {
      return {
        formData: {
        day: [],
      },
    multipleDay: [],
    selected: [],

    hariCuti:"",
    dataHariCuti:"",

    itemCuti: [
        {
          id: "Melahirkan",
          name: "Cuti Melahirkan",
        },
        {
          id: "Cuti Tahunan",
          name: "Cuti Tahunan Karyawan",
        },
        {
          id: "Haji",
          name: "Cuti Naik Haji",
        },
        {
          id: "Menikah",
          name: "Cuti Menikah",
        },
        {
          id: "Keluarga Inti Menginggal",
          name: "Cuti Keluarga Inti Meninggal",
        },
        {
          id: "Keluarga Serumah Menginggal",
          name: "Cuti Keluarga Serumah Meninggal",
        },
        {
          id: "Khitan/Baptis",
          name: "Cuti Khitan/Baptis",
        },
      ],


      }
  },

  computed: {
    ...mapGetters({
      user: "user"
    }),

//    dateRangeText(val) {
//       if (this.formData.month.length <= 0) {
//         return "";
//       } else {
//         let startDate = this.testReformatDate(new Date(this.formData.month[0]));
//         let endDate = this.testReformatDate(new Date(this.formData.month[1]));
//         let mergeDate = [startDate, endDate];
//         let temp = mergeDate.join(" ~ ");
//         return temp;
//       }
//     },

   },

   methods: {

    changeDate(val) {
      let dataHariCuti = this.testReformatDate(new Date(formData.day));

      return dataHariCuti
    },
       
    testReformatDate(val) {
      let tempDate = new Date(val);
      return `${tempDate.getDate()}-${
        tempDate.getMonth() + 1
      }-${tempDate.getFullYear()}`;
    },
   },

}

</script>

<style>

</style>