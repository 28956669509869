<template>
  <v-navigation-drawer
    v-model="drawer"
    color="anchor"
    dark
    app
    :clipped="$vuetify.breakpoint.lgAndUp"
  >
    <v-list-item class="text-center">
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ user.data[0].NamaKaryawan }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ `${user.data[0].NikKaryawan}` }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <template v-for="item in items">
        <v-row v-if="item.heading" :key="item.heading" align="center">
          <v-col cols="6">
            <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
          </v-col>
          <v-col cols="6" class="text-center">
            <a href="#!" class="body-2 black--text">EDIT</a>
          </v-col>
        </v-row>

        <v-list-group
          v-if="item.children"
          :key="item.text"
          v-model="item.model"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          append-icon=""
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            link
            v-show="rolePIC(child.show)"
            @click="redirectTo(child.route)"
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-if="item.subchildren"
            :key="item.subtext"
            v-model="item.submodel"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(subchild, i) in item.subchildren"
              :key="i"
              link
              @click="redirectTo(subchild.route)"
            >
              <v-list-item-action v-if="subchild.icon">
                <v-icon>{{ subchild.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ subchild.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-group>

        <v-list-item
          v-else
          :key="item.text"
          link
          @click="redirectTo(item.route)"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      results: [],
      nik: "",
      arrayRole: [],
      // hasilRole: [],

      items: [
        {
          icon: "mdi-home",
          text: "Home",
          route: "HomePage",
        },
        //   {
        //   icon: 'mdi-chevron-up',
        //   'icon-alt': 'mdi-chevron-down',
        //   text: 'Cuti',

        //   model: false,
        //   children: [
        //     { text: 'Input Cuti', route :'InputCuti' },
        //     { text: 'Riwayat Cuti',  route :'RiwayatCuti' },
        //     { text: 'Approve Cuti',  route :'ApproveCuti' }
        //   ],
        // },

        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Izin",

          model: false,
          children: [
            { text: "Input Izin", route: "InputIzin" },
            // { text: 'Input Izin Sakit',  route :'InputIzinSakit' },
            { text: "Riwayat Izin", route: "RiwayatIzin" },
            { text: "Approve Izin", route: "ApproveIzin" },
          ],
        },

        // {
        //   icon: 'mdi-chevron-up',
        //   'icon-alt': 'mdi-chevron-down',
        //   text: 'Fasilitas',
        //   model:false,
        //   children:[

        //     {
        //     icon: "mdi-bank",
        //     text: "Pengajuan KPR BTN",
        //     route: "PengajuanKprBtn",

        //   },

        //     {
        //   icon: 'mdi-chevron-up',
        //   'icon-alt': 'mdi-chevron-down',
        //   text: 'Beasiswa',

        //   submodel:false,
        //   subchildren: [
        //     { subtext: 'Pengajuan Beasiswa', route:'PengajuanBeasiswa', show: "pengajuan_beasiswa" },
        //     { subtext: 'Approval Beasiswa',  route:'ApprovalBeasiswa', show: "approval_beasiswa" },
        //     { subtext: 'Master Plafond',  route:'PlafondBeasiswa', show: "m_plafond" },
        //     { subtext: 'Master Mata Pelajaran',  route:'MapelBeasiswa', show: "m_matpel" },
        //     { subtext: 'Master Pejabat',  route:'PejabatBeasiswa', show: "m_pejabat" },
        //     { subtext: 'Rekapitulasi Beasiswa',  route:'RekapBeasiswa', show: "r_rekap" },
        //     { subtext: 'Daftar Beasiswa',  route:'DaftarBeasiswa', show: "r_rekap" }
        //   ],
        // },

        //   {
        //     icon: "mdi-home-edit",
        //     text: "Pengkinian Alamat",
        //     route: "PengkinianAlamat",

        //   },
        //   ]
        // },

        // {
        //   icon: "mdi-chevron-up",
        //   "icon-alt": "mdi-chevron-down",
        //   text: "Bantuan Pendidikan",

        //   model: false,
        //   children: [
        //     {
        //       text: "Pengajuan Bantuan Pendidikan",
        //       route: "PengajuanBeasiswa",
        //       show: "pengajuan_beasiswa",
        //     },
        //     {
        //       text: "Approval Bantuan Pendidikan",
        //       route: "ApprovalBeasiswa",
        //       show: "approval_beasiswa",
        //     },
        //     {
        //       text: "Master Plafond",
        //       route: "PlafondBeasiswa",
        //       show: "m_plafond",
        //     },
        //     {
        //       text: "Master Mata Pelajaran",
        //       route: "MapelBeasiswa",
        //       show: "m_matpel",
        //     },
        //     {
        //       text: "Master Pejabat",
        //       route: "PejabatBeasiswa",
        //       show: "m_pejabat",
        //     },
        //     {
        //       text: "Rekapitulasi Bantuan Pendidikan",
        //       route: "RekapBeasiswa",
        //       show: "r_rekap",
        //     },
        //     {
        //       text: "Daftar Bantuan Pendidikan",
        //       route: "DaftarBeasiswa",
        //       show: "r_daftar",
        //     },
        //     {
        //       text: "Monitoring Pengajuan",
        //       route: "MonitoringPengajuan",
        //       show: "m_pengajuan",
        //     },
        //     {
        //       text: "Tools Ganti Pejabat",
        //       route: "ToolsMutasiPejabat",
        //       show: "t_mutasi",
        //     },
        //     {
        //       text: "Status Pengajuan Bantuan Pendidikan",
        //       route: "RevisiPengajuan",
        //       show: "r_pengajuan",
        //     },
        //   ],
        // },

        //   {
        //     icon: "mdi-cog",
        //     text: "Pengaturan",
        //     route: "Pengaturan",

        //   },
        {
          icon: "mdi-bank",
          text: "Pengajuan KPR BTN",
          route: "PengajuanKprBtn",
        },
        //   {
        //   icon: 'mdi-chevron-up',
        //   'icon-alt': 'mdi-chevron-down',
        //   text: 'KPR',


        //   model: false,
        //   children: [
        //     { text: 'Pengajuan KPR BTN', route :'PengajuanKprBtn', icon: "mdi-bank" },
        //     { text: 'Approve KPR',  route :'ApproveKPR' }
        //   ],
        // },

        {
          icon: "mdi-home-edit",
          text: "Pengkinian Alamat",
          route: "PengkinianAlamat",
        },

        {
          icon: "mdi-account-tie",
          text: "Serah Terima Seragam",
          route: "KonfirmasiSeragam",
        },
        {
          icon: "mdi-file-table-outline",
          text: "Report Presensi",
          route: "ReportPresensi",
        },
      ],
    };
  },

  mounted() {
    this.loadData();
  },

  computed: {
    ...mapGetters({
      sideBar: "drawer",
      user: "user",
    }),

    drawer: {
      get() {
        return this.sideBar;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },

  methods: {
    ...mapActions({
      setDrawer: "setDrawer",
    }),

    redirectTo(item) {
      this.$router.push({
        name: item,
      });
      this.setDrawer(false);
    },

    rolePIC(item) {
      // const hasilRole = this.arrayRole;
      // console.log("hasil role= " +hasilRole);
      // console.log(hasilRole);

      if(this.arrayRole.includes('Admin')){
        return true;
      }else if(this.arrayRole.includes('Otorisasi Akhir')){
        return item != "m_plafond" && item != "m_matpel" && item != "m_pejabat" && item != "t_mutasi";
      }else if(this.arrayRole.includes('Verifikasi')){
        return item != "m_plafond" && item != "m_matpel" && item != "m_pejabat" && item != "m_pengajuan"
      }else if(this.arrayRole.includes('Otorisasi Awal')){
        return item != "m_plafond" && item != "m_matpel" && item != "m_pejabat" && item != "m_pengajuan" && item != "t_mutasi"
      }else{
        return (
          item != "m_plafond" &&
          item != "m_matpel" &&
          item != "m_pejabat" &&
          item != "r_rekap" &&
          item != "r_daftar" && 
          item != "m_pengajuan" && 
          item != "t_mutasi"
        )
      }

      // if (userRole.includes("Verifikasi")) {
      //   // return item != "m_user" && item != "m_vaksin" && item != userPejabat == "1" ? "rencana" : ""
      //   return item != "m_plafond" && item != "m_matpel" && item != "m_pejabat" && item != "m_pengajuan"
      // } else if (userRole.includes == "Otorisasi Awal") {
      //   // return item != "m_user" && item != "m_vaksin" && item != userPejabat == "1" ? "rencana" : ""
      //   return item != "m_plafond" && item != "m_matpel" && item != "m_pejabat" && item != "m_pengajuan" && item != "t_mutasi"
      // } else if (userRole.includes == "Otorisasi Akhir") {
      //   // return item != "m_user" && item != "m_vaksin" && item != userPejabat == "1" ? "rencana" : ""
      //   return item != "m_plafond" && item != "m_matpel" && item != "m_pejabat" && item != "t_mutasi";
      // } else if (userRole.includes == "" || userRole.includes == null) {
      //   // return item != "m_user"  && item != "m_vaksin" && item != "RPVC" && item != "DSVC" && item != "MPVC" && item != (userPejabat == "1" ? "rencana" : "")
      //   return (
      //     item != "m_plafond" &&
      //     item != "m_matpel" &&
      //     item != "m_pejabat" &&
      //     item != "r_rekap" &&
      //     item != "r_daftar" && 
      //     item != "m_pengajuan" && 
      //     item != "t_mutasi"
      //   );
      //   // && item != "RPVC" && item != "MPVC"
      // } else {
      //   return true;
      // }
    },

    loadData() {
      this.nik = this.user.data[0].NIK;
      // this.userRole = this.user.data[0].RoleBeasiswa;
      this.userRole = this.user.data[0].RoleBeasiswa;
      let userRole = this.userRole;
      const mapRole = userRole.map((item) => item.ROLE);
      this.arrayRole = mapRole;
      console.log(this.arrayRole);
      if (this.user !== null) this.Role = this.user.data[0].ROLE;
    },

    // rolePIC(item){
    //   // console.log(item);
    //   if(item == 'User' || item == 'Presensi'){
    //     if (this.Role == 'PIC')
    //     {return false}
    //     else return true
    //   }
    //   return true
    // },
  },
};
</script>
