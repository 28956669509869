<template>
  <div class="laporan">
    <c-toolbar />
    <c-navbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2">
              <v-row justify="center">
                <v-img src="img/indomaret.png" aspect-ratio="3" max-width="250">
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Tools Perubahan Pejabat Bantuan Pendidikan
              </h3>

              <form class="pa-4 mx-3">
                <v-dialog v-model="dialogMutasi" max-width="1000px">
                  <v-card>
                    <v-form ref="form">
                      <v-card-title>
                        <span class="headline"
                          >Detail Perubahan Pejabat Bantuan Pendidikan</span
                        >
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-overlay :value="overlayDialog">
                            <v-progress-circular
                              indeterminate
                              size="64"
                            ></v-progress-circular>
                          </v-overlay>
                          <v-simple-table dense class="pa-4 mx-3">
                            <tbody>
                              <tr>
                                <td style="width:25%">NIK</td>
                                <td style="width:1%">:</td>
                                <td colspan="2">{{ this.editedItem.NIK }}</td>
                              </tr>
                              <tr>
                                <td>Nama</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.NAMA_KARYAWAN }}
                                </td>
                              </tr>
                              <tr>
                                <td>Unit</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.UNIT_NAME }}
                                </td>
                              </tr>
                              <tr>
                                <td>Cabang</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.CABANG_NAME }}
                                </td>
                              </tr>
                              <tr>
                                <td>Bagian</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.BAGIAN_NAME }}
                                </td>
                              </tr>
                              <tr>
                                <td>Jabatan</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.JABATAN }}
                                </td>
                              </tr>
                              <tr>
                                <td>Job Class</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.JOBCLASS }}
                                </td>
                              </tr>
                              <tr>
                                <td>Periode Bantuan Pendidikan</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.PERIODE_BEASISWA }}
                                </td>
                              </tr>
                              <tr>
                                <td>Penerima Bantuan Pendidikan</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.PENERIMA_BEASISWA }}
                                </td>
                              </tr>
                              <tr>
                                <td>Tingkat Pendidikan</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.NAMA_TINGKAT_BEASISWA }}
                                </td>
                              </tr>
                              <tr>
                                <td>Usulan Bantuan Pendidikan</td>
                                <td>:</td>
                                <td colspan="2">
                                  {{ this.editedItem.USULAN_BEASISWA }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Approval Manager
                                </td>
                                <td>:</td>
                                <td colspan="2">
                                  <v-autocomplete
                                    v-model="approvemgr"
                                    :items="itemsapprovalmgr"
                                    item-text="Karyawan"
                                    item-value="Karyawan"
                                    hide-no-data
                                    label="Silakan Pilih"
                                    solo
                                    :disabled="
                                      this.editedItem
                                        .STATUS_TRANSAKSI_DESKRIPSI ==
                                        'Approve Manager' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Approve Verifikasi' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Approve Otorisasi Awal' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Approve Otorisasi Akhir' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Revisi Verifikasi' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Revisi Otorisasi Awal' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Revisi Otorisasi Akhir'
                                    "
                                  ></v-autocomplete>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Verifikasi
                                </td>
                                <td>:</td>
                                <td>
                                  <v-autocomplete
                                    v-model="verifikasi"
                                    :items="itemsverifikasi"
                                    item-text="Karyawan"
                                    item-value="Karyawan"
                                    hide-no-data
                                    label="Silakan Pilih"
                                    solo
                                    :disabled="
                                      this.editedItem
                                        .STATUS_TRANSAKSI_DESKRIPSI ==
                                        'Approve Verifikasi' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Approve Otorisasi Awal' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Approve Otorisasi Akhir' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Revisi Otorisasi Awal' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Revisi Otorisasi Akhir'
                                    "
                                  ></v-autocomplete>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Otorisasi Awal
                                </td>
                                <td>:</td>
                                <td colspan="2">
                                  <v-autocomplete
                                    v-model="otorisasiawal"
                                    :items="itemsotorisasiawal"
                                    item-text="Karyawan"
                                    item-value="Karyawan"
                                    hide-no-data
                                    label="Silakan Pilih"
                                    solo
                                    :disabled="
                                      this.editedItem
                                        .STATUS_TRANSAKSI_DESKRIPSI ==
                                        'Approve Otorisasi Awal' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Approve Otorisasi Akhir' ||
                                        this.editedItem
                                          .STATUS_TRANSAKSI_DESKRIPSI ==
                                          'Revisi Otorisasi Akhir'
                                    "
                                  ></v-autocomplete>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Otorisasi Final HR HO
                                </td>
                                <td>:</td>
                                <td colspan="2">
                                  <v-autocomplete
                                    v-model="otorisasifinal"
                                    :items="itemsotorisasifinal"
                                    item-text="Karyawan"
                                    item-value="Karyawan"
                                    hide-no-data
                                    label="Silakan Pilih"
                                    solo
                                  ></v-autocomplete>
                                </td>

                                <td colspan="3"></td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          :loading="btnLoading"
                          v-show="validSubmitMutasi"
                          @click="submitMutasi"
                        >
                          Submit Perubahan
                        </v-btn>
                        <v-btn
                          color="red darken-1"
                          text
                          @click="dialogMutasi = false"
                        >
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
                <div class="my-2">
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group
                        small
                        v-model="pilihanFilter"
                        row
                        @change="changeFilter"
                      >
                        <v-radio label="Nomor Tiket" value="noTiket"></v-radio>
                        <v-radio label="NIK" value="NIK"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-autocomplete
                        :items="items_noTiket"
                        label="Nomor Tiket"
                        v-model="notiket"
                        item-text="name"
                        item-value="name"
                        v-show="pilihanFilter == 'noTiket'"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-autocomplete
                        :items="items_nikKaryawan"
                        label="Nik Karyawan"
                        v-model="nikKary"
                        item-text="name"
                        item-value="name"
                        v-show="pilihanFilter == 'NIK'"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row> </v-row>
                  <v-row justify="center">
                    <v-col cols="12" sm="2" class="shrink text-end">
                      <v-btn
                        color="primary"
                        block
                        :loading="btnLoading"
                        @click="filterRekap()"
                        v-show="validRekap"
                        >Filter</v-btn
                      >
                    </v-col>
                  </v-row>
                  <div class="mt-10" v-show="dataTable">
                    <template>
                      <v-data-table
                        :headers="headers"
                        :items="itemsTableMutasi"
                        :search="search"
                        class="elevation-1"
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            small
                            rounded
                            v-show="
                              item.STATUS_TRANSAKSI_DESKRIPSI !=
                                'Approve Otorisasi Final'
                            "
                            class="mr-2"
                            color="green"
                            @click="bukaDialogMutasi(item)"
                          >
                            Ganti
                          </v-btn>
                        </template>
                        <template v-slot:[`item.CREATEDDATE`]="{ item }">
                          {{ formatDate(item.CREATEDDATE) }}
                        </template>
                        <template v-slot:top>
                          <v-toolbar flat>
                            <!-- <v-btn
                              color="success"
                              dark
                              class="mb-2"
                              @click="downloadExcel()"
                            >
                              Export Excel
                            </v-btn> -->

                            <!-- jarak judul -->
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <!-- search -->
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-toolbar>
                        </template>
                      </v-data-table>
                    </template>
                  </div>
                </div>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
// import Sidebar from "@/components/Sidebar.vue";
// import Toolbar from "@/components/Toolbar.vue";
import CNavbar from "../../components/CNavbar.vue";
import CToolbar from "../../components/CToolbar.vue";
import { mapGetters, mapActions } from "vuex";
import XLSX from "xlsx";

export default {
  name: "Home",
  components: {
    CNavbar,
    CToolbar,
  },

  mounted() {
    this.TOKEN = this.token;
    this.dataTiketDanNik();
  },

  computed: {
    ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      token: "token",
      // getDrawer: "drawer",
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    computedDateFormatted() {
      // return this.dates.join(' ~ ');
      if (this.dates.length <= 0) {
        return "";
      } else {
        let date1 = this.formatDate(new Date(this.dates[0]));
        let date2 = this.formatDate(new Date(this.dates[1]));

        if (date2 < date1) {
          date2 = this.formatDate(new Date(this.dates[0]));
          date1 = this.formatDate(new Date(this.dates[1]));
        }
        const merge = [date1, date2];
        const temp = merge.join(" ~ ");
        return temp;
      }
    },

    validRekap() {
      // const userRole = sessionStorage.getItem("role");
      if (this.pilihanFilter == "NIK") {
        return (
          // this.cabang != "" &&
          this.nikKary
          // this.periode != ""
          // this.status != ""
        );
      } else {
        return this.notiket;
      }
    },

    validSubmitMutasi() {
      return (
        this.approvemgr != "" &&
        this.verifikasi != "" &&
        this.otorisasiawal != "" &&
        this.otorisasifinal != ""
      );
    },

    // headers() {
    //   //set having unique values
    //   let s = new Set();
    //   var f;

    //   this.itemsTableMutasi.forEach((item) => {
    //     for (f in item) {
    //       //adding an existing value doesn't override the old one
    //       s.add(f);
    //     }
    //   });
    //   //respect the headers format required by Vuetify which
    //   // should has at least two fields (text, value)
    //   return Array.from(s).map((a) => {
    //     return {
    //       text: a.toUpperCase(),
    //       value: a,
    //     };
    //   });
    // },
  },

  data: () => ({
    // $baseurl: "http://localhost/",
    // $baseurl: "http://116.204.128.50:80/",
    // $baseurl: "https://ms.hrindomaret.com/",
    userRole: "",
    overlay: false,
    overlayDialog: false,
    menu2: false,
    dates: [],
    btnLoading: false,
    dataTable: false,
    dialogMutasi: false,
    nikKary: "",
    notiket: "",
    nikTampilan: "",
    noTiketTampilan: "",
    approvemgr: "",
    verifikasi: "",
    otorisasiawal: "",
    otorisasifinal: "",
    pilihanFilter: "",
    items_noTiket: [],
    items_nikKaryawan: [],
    search: null,
    headers: [
      { text: "Nomor Tiket", value: "NO_TIKET" },
      { text: "NIK Karyawan", value: "NIK" },
      { text: "Nama Karyawan", value: "NAMA_KARYAWAN" },
      { text: "Tanggal Pengajuan", value: "CREATEDDATE" },
      { text: "Status", value: "STATUS_TRANSAKSI_DESKRIPSI" },
      { text: "Approval Manager", value: "MANAGER" },
      { text: "Verifikasi", value: "VERIFIKASI" },
      { text: "Otorisasi Awal", value: "OTORISASI_AWAL" },
      { text: "Otorisasi Akhir", value: "OTORISASI_AKHIR" },
      { text: "Action", value: "actions", sortable: false },
    ],

    editedItem: {
      NIK: "",
      NAMA_KARYAWAN: "",
      UNIT_NAME: "",
      CABANG_NAME: "",
      BAGIAN_NAME: "",
      JABATAN: "",
      JOBCLASS: "",
      PERIODE_BEASISWA: "",
      PENERIMA_BEASISWA: "",
      NAMA_ANAK_KARYAWAN: "",
      NAMA_TINGKAT_BEASISWA: "",
      USULAN_BEASISWA: "",
      NPSN_SEKOLAH: "",
      NAMA_SEKOLAH: "",
      AKREDITASI_JURUSAN: "",
      AKREDITASI_KAMPUS: "",
      TAHUN_MASUK: "",
      PLAFOND_BEASISWA: "",
      TAHUN_AJARAN_BARU: "",
      NILAI_GANJIL: "",
      NILAI_GENAP: "",
      NILAI_RATARATA: "",
      FILEBLOB_IJAZAH: "",
      // FILEPATH_RAPORT: "",
      FILEBLOB_RAPORT: "",
      FIEBLOB_SURATDITERIMA: "",
      EXTENSION_RAPORT: "",
      EXTENSION_IJAZAH: "",
      EXTENSION_SURATDITERIMA: "",
      MANAGER: "",
      NAMA_MGR: "",
      VERIFIKASI: "",
      NAMA_VERIF: "",
      OTORISASI_AWAL: "",
      NAMA_OTORISASI_AWAL: "",
      OTORISASI_AKHIR: "",
      NAMA_OTORISASI_AKHIR: "",
    },

    itemsTableMutasi: [],
    itemsTableShowData: [],
    itemsapprovalmgr: [],
    itemsverifikasi: [],
    itemsotorisasiawal: [],
    itemsotorisasifinal: [],
  }),

  methods: {
    ...mapActions({
      getDataMutasiBeasiswa: "getDataMutasiBeasiswa",
      insertDataMstUserBeasiswa: "insertDataMstUserBeasiswa",
    }),

    dataTiketDanNik() {
      // const userRole = sessionStorage.getItem("role");
      const axios = require("axios");
      const code = {
        nik: "",
        notiket: "",
        niklogin: this.user.data[0].NikKaryawan,
        nikmgr: "",
        nikverif: "",
        nikotorisasiawal: "",
        nikotorisasiakhir: "",
      };

      this.getDataMutasiBeasiswa(code)
        .then((response) => {
          const hasil = response.data;
          //   console.log(hasil);
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            if (hasil.data < 0) {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Anda Tidak Memiliki Nomor Tiket Pengajuan",
              });
              this.btnLoading = false;
            } else {
              // this.itemsTableRekap = hasil.data;

              // this.itemsTableMonitoring= hasil;
              this.items_noTiket = hasil.notiket;
              this.items_nikKaryawan = hasil.nik;
              // // console.log(hasil);
              this.btnLoading = false;
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
        });
    },

    filterRekap() {
      this.btnLoading = true;
      this.overlay = true;

      const axios = require("axios");
      const code = {
        nik: this.nikKary,
        notiket: this.notiket,
        niklogin: this.user.data[0].NikKaryawan,
        nikmgr: "",
        nikverif: "",
        nikotorisasiawal: "",
        nikotorisasiakhir: "",
      };

      this.getDataMutasiBeasiswa(code)
        .then((response) => {
          const hasil = response.data;
          //   console.log(hasil.data[0]);
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else if (hasil.status == "Success") {
            if (hasil.data.length < 0) {
              // console.log('Sini?');
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Anda Tidak Memiliki Data Pengajuan",
              });
              this.btnLoading = false;
              this.overlay = false;
            } else {
              let result = hasil.data;
              // console.log(result);
              let hasiltest = result.map((item) => {
                return {
                  "Nomor Tiket": item.NO_TIKET,
                  "NIK Karyawan": item.NIK,
                  "Nama Karyawan": item.NAMA_KARYAWAN,
                  "Tanggal Pengajuan": item.CREATEDDATE,
                  Status: item.STATUS_TRANSAKSI_DESKRIPSI,
                  "Approval Manager": item.MANAGER,
                  Verifikasi: item.VERIFIKASI,
                  "Otorisasi Awal": item.OTORISASI_AWAL,
                  "Otorisasi Akhir": item.OTORISASI_AKHIR,
                };
              });
              // console.log("ASD");
              this.itemsTableMutasi = result;
              // console.log(this.itemsTableMutasi);
              this.itemsTableShowData = hasiltest;
              // console.log(this.itemsTableShowData);

              // console.log("BWA");
              // this.getApproveManager();
              // this.getVerifikasi();
              // this.getOtorisasiAwal();
              // this.getOtorisasiFinal();
              // // console.log(hasil);
              this.btnLoading = false;
              this.overlay = false;
              this.dataTable = true;
            }
          } else {
            this.$swal({
              icon: "warning",
              title: "Maaf!",
              text: "Role Anda Tidak Dapat Mengambil Data Ini",
            });
            this.btnLoading = false;
            this.overlay = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
          this.btnLoading = false;
          this.overlay = false;
        });
    },

    bukaDialogMutasi(item) {
      this.editedIndex = this.itemsTableMutasi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem.MANAGER, "manager");

      this.approvemgr = this.editedItem.MANAGER;
      this.verifikasi = this.editedItem.VERIFIKASI;
      this.otorisasiawal = this.editedItem.OTORISASI_AWAL;
      this.otorisasifinal = this.editedItem.OTORISASI_AKHIR;

      this.nikTampilan = this.editedItem.NIK;
      this.noTiketTampilan = this.editedItem.NO_TIKET;

      this.getApproveManager();
      this.getVerifikasi();
      this.getOtorisasiAwal();
      this.getOtorisasiFinal();

      //!Untuk Data Autocomplete harus sama antara data yang mau di replace sama data dari back

      // console.log(this.approvemgr)
      // console.log(this.verifikasi)
      // console.log(this.otorisasiawal)
      // console.log(this.otorisasifinal)

      this.dialogMutasi = true;
    },

    submitMutasi() {
      // const userRole = sessionStorage.getItem("role");
      this.btnLoading = true;
      this.overlayDialog = true;

      const axios = require("axios");
      const code = {
        nik: this.nikKary || this.nikTampilan,
        notiket: this.notiket || this.noTiketTampilan,
        niklogin: this.user.data[0].NikKaryawan,
        nikmgr: this.approvemgr,
        nikverif: this.verifikasi,
        nikotorisasiawal: this.otorisasiawal,
        nikotorisasiakhir: this.otorisasifinal,
      };

      this.getDataMutasiBeasiswa(code)
        .then((response) => {
          const hasil = response.data;
          //   console.log(hasil);
          if (hasil.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else if (hasil.status == "Success") {
            // this.itemsTableRekap = hasil.data;

            this.$swal({
              icon: "success",
              title: "Sukses!",
              text: "Berhasil Ganti Pejabat",
            }).then(() => {
              this.btnLoading = false;
              this.overlayDialog = false;
              window.location.reload();
            });
          } else {
            this.$swal({
              icon: "error",
              title: "Gagal!",
              text: "Gagal Ganti Pejabat",
            });
            this.btnLoading = false;
            this.overlayDialog = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
          this.btnLoading = false;
          this.overlayDialog = false;
        });
    },

    getApproveManager() {
      const code = {
        // nik: this.user.data[0].NikKaryawan,
        nik: this.nikTampilan,
        niklogin: this.user.data[0].NikKaryawan,
        role: "",
        status: "read",
        email: "",
        unitrole: [],
        cabangrole: [],
        kategori: "Approval Manager",
        arrayobj_role: "",
      };

      this.insertDataMstUserBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            console.log(result.data);
            this.itemsapprovalmgr = result.data;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    getVerifikasi() {
      const code = {
        // nik: this.user.data[0].NikKaryawan,s
        nik: this.nikTampilan,
        niklogin: this.user.data[0].NikKaryawan,
        role: "",
        status: "read",
        email: "",
        unitrole: [],
        cabangrole: [],
        kategori: "Verifikasi",
        arrayobj_role: "",
      };

      this.insertDataMstUserBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // // console.log(result.data);
            this.itemsverifikasi = result.data;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    getOtorisasiAwal() {
      const code = {
        // nik: this.user.data[0].NikKaryawan,
        nik: this.nikTampilan,
        niklogin: this.user.data[0].NikKaryawan,
        role: "",
        status: "read",
        email: "",
        unitrole: [],
        cabangrole: [],
        kategori: "Otorisasi Awal",
        arrayobj_role: "",
      };

      this.insertDataMstUserBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // // console.log(result.data);
            this.itemsotorisasiawal = result.data;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    getOtorisasiFinal() {
      const code = {
        // nik: this.user.data[0].NikKaryawan,
        nik: this.nikTampilan,
        niklogin: this.user.data[0].NikKaryawan,
        role: "",
        status: "read",
        email: "",
        unitrole: [],
        cabangrole: [],
        kategori: "Otorisasi Akhir",
        arrayobj_role: "",
      };

      this.insertDataMstUserBeasiswa(code)
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          // // console.log(result)
          if (result.status == "Token is Expired") {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // // console.log(result.data);
            this.itemsotorisasifinal = result.data;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    changeFilter() {
      this.notiket = "";
      this.nikKary = "";
    },

    formatRp(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatDate(val) {
      const MyDate = new Date(val);

      const MyDateString =
        ("0" + MyDate.getDate()).slice(-2) +
        "-" +
        ("0" + (MyDate.getMonth() + 1)).slice(-2) +
        "-" +
        MyDate.getFullYear();
      return MyDateString;
    },

    showDate() {
      this.menu2 = false;
      // console.log(this.dates[0]);
      // console.log(this.dates[1]);
    },

    downloadExcel() {
      const wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(this.itemsTableShowData, {
        origin: "A2",
        // skipHeader: true,
      });
      // XLSX.utils.sheet_add_aoa(ws, headings); //heading: array of arrays

      XLSX.utils.book_append_sheet(wb, ws);

      XLSX.writeFile(wb, "Report Monitoring Pengajuan Bantuan Pendidikan.xlsx");
    },
  },
};
</script>
