"use strict"

import kprApi from "@/api/kpr.js"
import store from "../index"

export default {

    actions: {
        getKaryawan(context, payload) {
            return new Promise((resolve, reject) => {

                kprApi.getKaryawan(
                    payload
                ).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        getAtasan1(context, payload) {
            return new Promise((resolve, reject) => {

                kprApi.getAtasan(
                    payload
                ).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        getAtasan2(context, payload) {
            return new Promise((resolve, reject) => {

                kprApi.getAtasan(
                    payload
                ).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        getAtasan3(context, payload) {
            return new Promise((resolve, reject) => {

                kprApi.getAtasan(
                    payload
                ).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        submitDataKpr(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                kprApi.submitKPR(
                    payload, token).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
    }
}