"use strict"

import presensiApi from "@/api/presensi.js"
import store from "../index"

export default {

    actions: {
        presensiKaryawan(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                presensiApi.getPresensi(payload, token).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        daftarBawahan(context, payload) {
            return new Promise((resolve, reject) => {
                let token = store.getters.token
                presensiApi.getBawahan(payload, token).then(response => {
                    // console.log(response)
                    const data = response.data

                    resolve(data)

                }).catch(err => {
                    reject(err)
                })
            })
        },
        // getPendingIzin(context, payload) {
        //     return new Promise((resolve, reject) => {
        //         let token = store.getters.token
        //         izinApi.getIzin(payload).then(response => {
        //             // console.log(response)
        //             const data = response.data

        //             resolve(data)

        //         }).catch(err => {
        //             reject(err)
        //         })
        //     })
        // },
        // getDetailPendingIzin(context, payload) {
        //     return new Promise((resolve, reject) => {
        //         let token = store.getters.token
        //         izinApi.getIzin(payload).then(response => {
        //             // console.log(response)
        //             const data = response.data

        //             resolve(data)

        //         }).catch(err => {
        //             reject(err)
        //         })
        //     })
        // },
        // approveIzinApi(context, payload) {
        //     return new Promise((resolve, reject) => {
        //         let token = store.getters.token
        //         izinApi.approveRejectIzin(payload, token).then(response => {
        //             // console.log(response)
        //             const data = response.data

        //             resolve(data)

        //         }).catch(err => {
        //             reject(err)
        //         })
        //     })
        // },
        // rejectIzinApi(context, payload) {
        //     return new Promise((resolve, reject) => {
        //         let token = store.getters.token
        //         izinApi.approveRejectIzin(payload, token).then(response => {
        //             // console.log(response)
        //             const data = response.data

        //             resolve(data)

        //         }).catch(err => {
        //             reject(err)
        //         })
        //     })
        // },
    }
}