import Vue from 'vue'
import VueRouter from 'vue-router'
import cookie from '@/utils/auth.js'

import Login from '../views/Login.vue'
import InputCuti from '../views/Cuti/InputCuti'
import RiwayatCuti from '../views/Cuti/RiwayatCuti.vue'
import ApproveCuti from '../views/Cuti/ApproveCuti'
import InputIzin from '../views/Izin/InputIzin'
import InputIzinSakit from '../views/Izin/InputIzinSakit'
import RiwayatIzin from '../views/Izin/RiwayatIzin'
import ApproveIzin from '../views/Izin/ApproveIzin'

import PengajuanBeasiswa from '../views/Beasiswa/PengajuanBeasiswa'
import ApprovalBeasiswa from '../views/Beasiswa/ApprovalBeasiswa'
import PlafondBeasiswa from '../views/Beasiswa/PlafondBeasiswa'
import MapelBeasiswa from '../views/Beasiswa/MapelBeasiswa'
import PejabatBeasiswa from '../views/Beasiswa/PejabatBeasiswa'
import RekapBeasiswa from '../views/Beasiswa/RekapBeasiswa'
import DaftarBeasiswa from '../views/Beasiswa/DaftarBeasiswa'
import MonitoringPengajuan from '../views/Beasiswa/MonitoringStatus'
import ToolsMutasiPejabat from '../views/Beasiswa/ToolsMutasiPejabat'
import RevisiPengajuan from '../views/Beasiswa/RevisiPengajuan'

import HomePage from '../views/HomePage.vue'
import Pengaturan from '../views/Pengaturan.vue'
import PengajuanKprBtn from '../views/KPR_BTN/PengajuanKprBtn'
import ApprovalKPR from '../views/KPR_BTN/ApprovalKPR'
// import IndexHome from '../views/HomePortal/IndexHome'
// import Pengumuman from '../views/HomePortal/Pengumuman'
// import Profil from '../views/HomePortal/Profil'
import PengkinianAlamat from '../views/PengkinianAlamat.vue'
import KonfirmasiSeragam from '../views/Seragam/KonfirmasiSeragam.vue'
import ReportPresensi from '../views/ReportPresensi/ReportPresensi.vue'

import { required } from 'vuelidate/lib/validators'
import cookies from '../utils/cookies'
import store from '@/store'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/homepage',
        name: 'HomePage',
        component: HomePage,
        meta: { requiresAuth: true }
    },
    {
        path: '/input-cuti',
        name: 'InputCuti',
        component: InputCuti,
        meta: { requiresAuth: true }
    },
    {
        path: '/riwayat-cuti',
        name: 'RiwayatCuti',
        component: RiwayatCuti,
        meta: { requiresAuth: true }
    },
    {
        path: '/approve-cuti',
        name: 'ApproveCuti',
        component: ApproveCuti,
        meta: { requiresAuth: true }
    },
    {
        path: '/input-izin',
        name: 'InputIzin',
        component: InputIzin,
        meta: { requiresAuth: true }
    },
    {
        path: '/input-izinsakit',
        name: 'InputIzinSakit',
        component: InputIzinSakit,
        meta: { requiresAuth: true }
    },
    {
        path: '/riwayat-izin',
        name: 'RiwayatIzin',
        component: RiwayatIzin,
        meta: { requiresAuth: true }
    },
    {
        path: '/approve-izin',
        name: 'ApproveIzin',
        component: ApproveIzin,
        meta: { requiresAuth: true }
    },

    {
        path: '/pengajuan-beasiswa',
        name: 'PengajuanBeasiswa',
        component: PengajuanBeasiswa,
        meta: { requiresAuth: true }
    },
    {
        path: '/approval-beasiswa',
        name: 'ApprovalBeasiswa',
        component: ApprovalBeasiswa,
        meta: { requiresAuth: true }
    },
    {
        path: '/master-plafond',
        name: 'PlafondBeasiswa',
        component: PlafondBeasiswa,
        meta: { requiresAuth: true }
    },
    {
        path: '/master-mapel',
        name: 'MapelBeasiswa',
        component: MapelBeasiswa,
        meta: { requiresAuth: true }
    },
    {
        path: '/master-pejabat',
        name: 'PejabatBeasiswa',
        component: PejabatBeasiswa,
        meta: { requiresAuth: true }
    },
    {
        path: '/report-rekap',
        name: 'RekapBeasiswa',
        component: RekapBeasiswa,
        meta: { requiresAuth: true }
    },
    {
        path: '/report-daftar',
        name: 'DaftarBeasiswa',
        component: DaftarBeasiswa,
        meta: { requiresAuth: true }
    },
    {
        path: '/monitoring-pengajuan',
        name: 'MonitoringPengajuan',
        component: MonitoringPengajuan,
        meta: { requiresAuth: true }
    },
    {
        path: '/tools-mutasi',
        name: 'ToolsMutasiPejabat',
        component: ToolsMutasiPejabat,
        meta: { requiresAuth: true }
    },
    {
        path: '/status-pengajuan',
        name: 'RevisiPengajuan',
        component: RevisiPengajuan,
        meta: { requiresAuth: true }
    },
    {
        path: '/pengaturan',
        name: 'Pengaturan',
        component: Pengaturan,
        meta: { requiresAuth: true }
    },
    {
        path: '/pengajuankpr',
        name: 'PengajuanKprBtn',
        component: PengajuanKprBtn,
        meta: { requiresAuth: true }
    },
    {
        path: '/approvalkpr',
        name: 'ApprovalKPR',
        component: ApprovalKPR,
        meta: { requiresAuth: true }
    },
    // {
    //     path: '/indexhome',
    //     name: 'IndexHome',
    //     component: IndexHome
    // },
    // {
    //     path: '/pengumuman',
    //     name: 'Pengumuman',
    //     component: Pengumuman
    // },
    // {
    //     path: '/profil',
    //     name: 'Profil',
    //     component: Profil
    // },
    // {
    //     path: '*',
    //     
    //     component: MaintenanceMode
    // },
    {
        path: '/pengkinianalamat',
        name: 'PengkinianAlamat',
        component: PengkinianAlamat,
        meta: { requiresAuth: true }
    },
    {
        path: '/konfirmasi-seragam',
        name: 'KonfirmasiSeragam',
        component: KonfirmasiSeragam,
        meta: { requiresAuth: true }
    },
    {
        path: '/report-presensi',
        name: 'ReportPresensi',
        component: ReportPresensi,
        meta: { requiresAuth: true }
    },
]

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') throw err
    });
}

const router = new VueRouter({
    mode: 'history',
    routes
})

const doBeforeEach = (to, from, next) => {
    const isAuth = store.getters.isAuthenticated
    const isData = store.getters.user
    const isUser = cookie.getCookie()
        // console.log(isData)

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // console.log("masuk kondisi ini")
        // console.log(status)
        if (!isAuth) {
            console.log("masuk isAuth")
            next({
                name: 'Login',
                query: { redirect: to.fullPath }
            })
        } else {
            if (isUser !== null) {
                // console.log("lewat sini")
                // store.dispatch("getAuthenticated")
                // next()
                store
                    .dispatch("getAuthenticated")
                    .then((res) => {
                        // console.log(res)
                        let data = res
                            // console.log(data)
                        if (data.Message == "Token & Signature Invalid !!!!") {
                            store.dispatch("logoutSession")
                            next({ name: 'Login' })
                        } else {
                            store.dispatch('setUser', data)
                                .then(() => {
                                    if (to.name === 'Login')
                                        next({
                                            name: 'HomePage'
                                        })
                                    else
                                        next()
                                }).catch(err => {
                                    // console.log(err);
                                    store.dispatch("logoutSession")
                                    next({
                                        name: 'Login'
                                    })
                                })
                        }
                    })
            } else if (to.name === 'Login') {
                next({ name: 'HomePage' });
            } else {
                store.dispatch("logoutSession")
                next({
                    name: 'Login'
                })
            }

        }
    } else {
        // console.log("masuk sini ternyata gan")
        if (to.name === 'Login' && isUser == null) {
            next();
        } else {
            // console.log("masuk else ini")
            next({ name: 'HomePage' });
        }
    }
}

router.beforeEach(doBeforeEach)

export default router